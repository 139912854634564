//Tabellen er hentet og så justert til JSON basert på hva skatteoppgjør bruker selv
//(https://git.aurora.skead.no/projects/SKO/repos/skatteoppgjoer-personlig/browse/skatteoppgjoer-personlig-felles/src/main/resources/skvis-rekkefoelge/2018/gjeldsreduksjonFastland.csv)
//(https://git.aurora.skead.no/projects/SKO/repos/skatteoppgjoer-personlig/browse/skatteoppgjoer-personlig-felles/src/main/resources/skvis-rekkefoelge/2019/gjeldsreduksjon.csv)
//(https://git.aurora.skead.no/projects/SKO/repos/skatteoppgjoer-personlig/browse/skatteoppgjoer-personlig-felles/src/main/resources/skvis-rekkefoelge/2020/gjeldsreduksjonFastland.csv)
//(https://git.aurora.skead.no/projects/SKO/repos/skatteoppgjoer-personlig/browse/skatteoppgjoer-personlig-felles/src/main/resources/skvis-rekkefoelge/2021/gjeldsreduksjonFastland.csv)
//(https://git.aurora.skead.no/projects/SKO/repos/skatteoppgjoer-personlig/browse/skatteoppgjoer-personlig-felles/src/main/resources/skvis-rekkefoelge/2021/gjeldsreduksjonSvalbard.csv)

/*
Oppskrift for tilpasning fra skatteopgjør sitt oppsett til json-elementer som vi bruker selv.
 1. Kjør `npm run importerGjeldsreduksjonTabell`.
*/

import { gjeldsreduksjonFastland, gjeldsreduksjonSvalbard } from './resource';

export const gjeldsreduksjonDetaljTabell = (inntektsaar, svalbard) => {
  const tabell = svalbard
    ? gjeldsreduksjonSvalbard[inntektsaar]
    : gjeldsreduksjonFastland[inntektsaar];

  if (tabell) {
    return tabell;
  }
  console.log(
    `Har ikke gjeldsreduksjontabell ${
      svalbard ? 'Svalbard' : 'fastland'
    } for inntektsaar=${inntektsaar}`,
  );
  return [];
};
