export const NYESTE_INNTEKTSAAR: number = 2024;

export const INNTEKTSAAR: {
  [key in number]: {
    vdotAar: number;
  };
} = {
  2024: {
    vdotAar: 2023,
  },
};
