import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Button from '@skatteetaten/frontend-components/Button';

import { nyLeggTilOpplysningerDialog } from '../../../actions/dialog';

export const LeggTilOpplysninger = () => {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(nyLeggTilOpplysningerDialog());
  };

  return (
    <div style={{ margin: '60px 0 32px' }}>
      <h2 className="xdl-card__title-text" style={{ marginBottom: '16px' }}>
        <FormattedMessage id="ledetekst.mangler.noe.i.skattemeldingen.din" />
      </h2>
      <Button onClick={onClick}>
        <FormattedMessage id="ledetekst.se.alle.tilgjengelige.opplysninger" />
      </Button>
    </div>
  );
};
