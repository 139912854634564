import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { ListeIDialog } from '../utils/ListeIDialog';
import { getDialog } from '../../../../reducers';
import { getDynamiskegrupper } from '../../../../utils/visningsdefinisjonUtil';
import { leggTilDynamiskFelt } from './kortOpplysninger.actions';
import { nyDynamiskFelt } from '../../../../utils/feltUtils';
import { DIALOGTYPE_DIALOG } from '../../../../actions/dialog';
import { finnDynamiskeFelterSomIkkeErLagtTil } from './tilleggsopplysningerUtil';

export const ListeBehandlingsart = ({ kortdefinisjon, data }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { fraDialog = DIALOGTYPE_DIALOG, kortIKortDialogProps = {} } =
    useSelector(getDialog);
  const { underforekomstDataSti = {} } = kortIKortDialogProps;
  const dynamiskegrupper = getDynamiskegrupper(kortdefinisjon);

  if (!dynamiskegrupper.length) {
    return false;
  }

  const velgDynamiskFelt = (felt) => {
    return dispatch(
      leggTilDynamiskFelt(
        felt.dynamiskGruppeReferanse,
        nyDynamiskFelt(felt),
        underforekomstDataSti,
        fraDialog,
      ),
    );
  };
  const dynamiskeFelterSomIkkeErLagtTil = finnDynamiskeFelterSomIkkeErLagtTil(
    kortdefinisjon,
    data,
    underforekomstDataSti,
  );

  const elementer = dynamiskeFelterSomIkkeErLagtTil.map((dynamiskfelt) => ({
    valgTekst: intl.formatMessage({
      id: `kodeliste.${dynamiskfelt.dynamiskGruppeKodeliste}.${dynamiskfelt.datatype}`,
    }),
    klikkAksjon: () => velgDynamiskFelt(dynamiskfelt),
  }));

  const tittel = intl.formatMessage(
    { id: 'ledetekst.antall.treff' },
    { antall: elementer.length },
  );

  const ingenElementer = intl.formatMessage({
    id: 'ledetekst.filterbehandlingsart.ingen.behandlingsarter',
  });

  return (
    <ListeIDialog
      tittel={tittel}
      elementer={elementer}
      ingenElementerTekst={ingenElementer}
    />
  );
};
