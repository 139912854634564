import { visningslogikk } from '@skatteetaten/visningsdefinisjoner-og-tekster';
import {
  forekomsterForKorttype,
  hentKortgruppedefinisjon,
  hentKorttyperForKortgruppe,
} from './soekeutils';

const { kort: kortUtils } = visningslogikk;

const groupByKortgruppe = (res, cur) => ({
  ...res,
  [cur.item.ider.kortgruppe]: [...(res[cur.item.ider.kortgruppe] || []), cur],
});

const finnResultatMedBesteScore = (res, cur) =>
  (res || cur).score > cur.score ? cur : res || cur;

const aggregerKortgrupper = (resultat) => {
  return Object.values(
    resultat
      // eslint-disable-next-line no-shadow
      .filter((resultat) => resultat.item.ider.kortgruppe)
      .reduce(groupByKortgruppe, {}),
  )
    .map((resultaterMedSammeGruppe) => {
      const resultatMedBesteScore = resultaterMedSammeGruppe.reduce(
        finnResultatMedBesteScore,
      );
      const id = {
        tema: resultatMedBesteScore.item.ider.tema,
        kortgruppe: resultatMedBesteScore.item.ider.kortgruppe,
      };
      return {
        id,
        tittel: resultatMedBesteScore.item.titler.kortgruppe,
        score: resultatMedBesteScore.score,
        treffFra: resultatMedBesteScore.item,
      };
    })
    .sort((a, b) => a.score - b.score);
};

const korttypeKanLeggeTil = (korttype, forekomster, visningsdata) => {
  const kanIkkeLeggesTilAvSkattyter = kortUtils.kanIkkeLeggeTilKort(
    korttype,
    visningsdata,
  );
  const kanIkkeLeggeTilFlereKort =
    korttype.maksEttKort && forekomster.length > 0;
  return !(kanIkkeLeggesTilAvSkattyter || kanIkkeLeggeTilFlereKort);
};

const korttypeKanVises = (korttype, forekomster) => {
  return forekomster.length > 0;
};

const leggTilKontrolldataForKortgruppe = (visningsdefinisjon, visningsdata) => {
  return (resultat) => {
    const kortgruppe = hentKortgruppedefinisjon(
      visningsdefinisjon,
      resultat.id.kortgruppe,
    );
    const korttyper = hentKorttyperForKortgruppe(kortgruppe);
    const korttyperMedForekomster = korttyper.map((korttype) => [
      korttype,
      forekomsterForKorttype(korttype, visningsdata),
    ]);

    const metaDataForKorttyper = korttyperMedForekomster.map(
      ([korttype, forekomster]) => {
        const kanLeggeTil = korttypeKanLeggeTil(
          korttype,
          forekomster,
          visningsdata,
        );
        const kanVises = korttypeKanVises(korttype, forekomster);
        return {
          kanLeggeTil,
          kanVises,
        };
      },
    );

    const kanLeggeTil = metaDataForKorttyper.some((it) => it.kanLeggeTil);

    const kanVises = metaDataForKorttyper.some((it) => it.kanVises);

    return {
      ...resultat,
      kanLeggeTil,
      kanVises,
    };
  };
};

const visningForKortgruppe = (kortgruppe) => ({
  ...kortgruppe,
  visningslinje1: kortgruppe.tittel,
  visningslinje2: [
    kortgruppe.treffFra.titler.tema,
    kortgruppe.treffFra.titler.korttype,
    kortgruppe.treffFra.titler.feltgruppe,
    kortgruppe.treffFra.titler.repeterendeFelt,
    kortgruppe.treffFra.titler.felt,
    kortgruppe.treffFra.titler.dynamiskfelt,
    kortgruppe.treffFra.titler.feltMedEgenskaper,
    kortgruppe.treffFra.titler.flerFeltVisning,
  ]
    .filter((it) => it)
    .join(' → '),
});

const resultatHarGyldigeAksjoner = (resutat) =>
  resutat.kanLeggeTil || resutat.kanVises;

export const behandleResultaterSme = (
  resultater,
  visningsdata,
  visningsdefinisjon,
) =>
  aggregerKortgrupper(resultater)
    .map(leggTilKontrolldataForKortgruppe(visningsdefinisjon, visningsdata))
    .filter(resultatHarGyldigeAksjoner)
    .map(visningForKortgruppe);
