import { forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { language } from '@skatteetaten/visningsdefinisjoner-og-tekster';

import { KommandoKort } from '../KommandoKort';
import { leggTilNyKortgruppe } from '../../../actions/dialog';
import { korttyperSomKanLeggesTil } from '../../../utils/skalSkjules';
import { getVisningsdata } from '../../../reducers';
import { useAnalytics } from '../../../analytics/analytics.hooks';

export const LeggTilKnapp = forwardRef(({ kortgruppe, temaId }, ref) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const analytics = useAnalytics(temaId);

  const visningsdata = useSelector(getVisningsdata);

  const harBarnSomKanLeggesTil = !!korttyperSomKanLeggesTil(
    visningsdata,
    kortgruppe,
  ).length;

  if (!harBarnSomKanLeggesTil) {
    return null;
  }
  const leggTil = () => {
    dispatch(leggTilNyKortgruppe(kortgruppe, temaId));
    analytics.leggTilKlikk(kortgruppe.id);
  };

  return (
    <KommandoKort ikonnavn="add_circle_outline" onClick={leggTil} ref={ref}>
      <FormattedMessage
        id={language.leggtilKnappId(kortgruppe.id)}
        defaultMessage={intl.formatMessage({ id: 'knapp.leggTil' })}
      />
    </KommandoKort>
  );
});
