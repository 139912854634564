import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

const REGEX_FANT_TAG_MED_ATTRIBUT = /<[a-z]+ /;

type FormattedMessageProps = {
  id: string;
  tagName?: React.ElementType;
  TagName?: React.ElementType;
  values?: any;
  defaultMessage?: string;
};

/**
 * Se: https://formatjs.io/docs/react-intl/upgrade-guide-4x#migrating-off-embedded-html-in-messages
 * Så lenge vi har a tags (eller andre tags med attributer) i meldinger, så kan vi ikke gjøre det slik.
 */
export const FormattedHTMLMessageUglyInnerHtml = ({
  id,
  TagName = 'div',
  defaultMessage,
}: FormattedMessageProps) => {
  const intl = useIntl();
  const melding = intl.messages[id] ?? `${defaultMessage ?? id}`;
  const markup = () => ({ __html: melding });

  return <TagName dangerouslySetInnerHTML={markup()} />;
};

/**
 * Faller tilbake til FormattedHTMLMessageUglyInnerHTML,
 * dersom meldingen inneholder tags med attributer, f.eks <a href=
 *
 * Ingen slike meldinger krever values, som FormattedHTMLMessageUglyInnerHTML ikke støtter
 */
export const FormattedHTMLMessageWithFallbackToUgly = ({
  id,
  tagName = 'div',
  values,
  defaultMessage,
}: FormattedMessageProps) => {
  const intl = useIntl();
  const melding = intl.messages[id] ?? `${id}`;

  const matcher = (melding as string).match(REGEX_FANT_TAG_MED_ATTRIBUT);

  return matcher ? (
    <FormattedHTMLMessageUglyInnerHtml
      id={id}
      TagName={tagName}
      defaultMessage={defaultMessage}
    />
  ) : (
    <FormattedHTMLMessage
      id={id}
      tagName={tagName}
      values={values}
      defaultMessage={defaultMessage}
    />
  );
};

/**
 * Kan ikke brukes på meldinger med tags med attributer
 */
const FormattedHTMLMessage = ({
  id,
  tagName = 'div',
  values,
  defaultMessage,
}: FormattedMessageProps) => (
  <FormattedMessage
    id={id}
    tagName={tagName}
    values={{
      b: (chunks) => <b>{chunks}</b>,
      i: (chunks) => <i>{chunks}</i>,
      em: (chunks) => <em>{chunks}</em>,
      strong: (chunks) => <strong>{chunks}</strong>,
      div: (chunks) => <div>{chunks}</div>,
      p: (chunks) => <p>{chunks}</p>,
      ol: (chunks) => <ol>{chunks}</ol>,
      ul: (chunks) => <ul>{chunks}</ul>,
      li: (chunks) => <li>{chunks}</li>,
      h2: (chunks) => <h2>{chunks}</h2>,
      fieldset: (chunks) => <fieldset>{chunks}</fieldset>,
      legend: (chunks) => <legend>{chunks}</legend>,
      linebreak: <br />,
      ...values,
    }}
    defaultMessage={defaultMessage}
  />
);

export default FormattedHTMLMessage;
