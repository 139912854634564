import { DynamiskFelt } from './DynamiskFelt';
import { skalFeltSkjules } from '../../../../utils/skalSkjules';

export const DynamiskGruppe = ({ data, gruppe }) => {
  const { barnliste = [], referanse } = gruppe;
  const behandlingsarter = data[referanse] || [];

  return barnliste
    .filter((barn) =>
      behandlingsarter.find((art) => art.behandlingsart === barn.datatype),
    )
    .filter((barn) => !skalFeltSkjules(barn, data))
    .map((barn) => {
      const dynamiskfeltData = behandlingsarter.find(
        (art) => art.behandlingsart === barn.datatype,
      );
      const feltindex = behandlingsarter.findIndex(
        (art) => art.behandlingsart === barn.datatype,
      );
      const feltReferanse = `${referanse}.${feltindex}`;

      return (
        <DynamiskFelt
          key={barn.id}
          kortdata={data}
          feltdata={dynamiskfeltData}
          dynamiskfelt={barn}
          feltReferanse={feltReferanse}
        />
      );
    });
};
