import classnames from 'classnames';
import ActionButton from '@skatteetaten/frontend-components/ActionButton';
import cssStyle from './ListeIDialog.module.scss';

export const ListeIDialog = ({
  tittel,
  elementer,
  ingenElementerTekst,
  style,
  tittelStil,
}) => {
  return (
    <div>
      {tittel && (
        <div className={tittelStil || cssStyle.tittelStil}>{tittel}</div>
      )}
      <ul className={cssStyle.listeStil}>
        {elementer.length
          ? elementer.map((element, index) => (
              <li className={classnames(style)} key={index}>
                <ActionButton
                  buttonStyle="secondarySimple"
                  icon={element.iconName || 'ArrowForward'}
                  onClick={element.klikkAksjon}
                  key={index}
                  disabled={element.disabled}
                  className={cssStyle.listeknappStil}
                >
                  {element.valgTekst}
                </ActionButton>
              </li>
            ))
          : ingenElementerTekst}
      </ul>
    </div>
  );
};
