import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import ActionButton from '@skatteetaten/frontend-components/ActionButton';
import style from './ResterendeKortgrupper.module.css';
import { nyLeggTilOpplysningerDialog } from '../../../actions/dialog';

export const ResterendeKortgrupper = ({ temaId, inkluderTittel = true }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(nyLeggTilOpplysningerDialog(temaId));
  };

  const temaNavn = intl.formatMessage({ id: temaId });

  return (
    <fieldset>
      {inkluderTittel && (
        <legend className={style.tittel}>
          {intl.formatMessage(
            { id: 'hjelpetekst.andreOpplysninger' },
            { tema: temaNavn },
          )}
        </legend>
      )}
      <div className={style.valg}>
        <ActionButton icon="AddOutline" onClick={onClick}>
          {intl.formatMessage({
            id: 'ledetekst.resterendekortgrupper.visLeggTil',
          })}
        </ActionButton>
      </div>
    </fieldset>
  );
};
