import { DIALOG_NY_LAGRE_EKTEFELLE, DIALOG_NY_OK } from '../actions/dialog';
import { getData, getKortdefinisjon } from '../reducers/dialog';

export const TOEM_OPPRETTET = 'TOEM_OPPRETTET';
export const FOKUSER_VISNINGSDEFINISJON_ELEMENT =
  'FOKUSER_VISNINGSDEFINISJON_ELEMENT';
export const NULLSTILL_FOKUS = 'NULLSTILL_FOKUS';

export const toemOpprettet = () => ({
  type: TOEM_OPPRETTET,
});

export const fokuserVisningsdefinisjonElement = (id, type) => ({
  id,
  type: FOKUSER_VISNINGSDEFINISJON_ELEMENT,
  elementType: type,
});

export const nullstillFokus = () => ({
  type: NULLSTILL_FOKUS,
});

export const getFokus = (state) => state.fokus;
export const getVisningselementForFokus = (state) =>
  state.fokus.visningselementForFokus;

export const lagKortForekomstId = (kortdefinisjon = {}, forekomstId) => {
  return kortdefinisjon.statiskId
    ? // Vi bruker referanse + id, ettersom mange korttyper i næringsspesifikasjon har identisk, statisk id
      `${kortdefinisjon.referanse}-${forekomstId}`
    : forekomstId;
};

const defaultState = {
  opprettetId: null,
  visningselementForFokus: null,
};

export default (state = defaultState, action = {}, gammelDialog) => {
  switch (action.type) {
    case DIALOG_NY_OK:
    case DIALOG_NY_LAGRE_EKTEFELLE: {
      const data = getData(gammelDialog);
      const kortdefinisjon = getKortdefinisjon(gammelDialog);

      return {
        ...state,
        opprettetId: lagKortForekomstId(kortdefinisjon, data.id),
      };
    }

    case TOEM_OPPRETTET: {
      return {
        ...state,
        opprettetId: defaultState.opprettetId,
      };
    }

    case FOKUSER_VISNINGSDEFINISJON_ELEMENT: {
      return {
        ...state,
        visningselementForFokus: {
          id: action.id,
        },
      };
    }
    case NULLSTILL_FOKUS: {
      return {
        ...state,
        visningselementForFokus: defaultState.visningselementForFokus,
      };
    }

    default:
      return state;
  }
};
