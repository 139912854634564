import { isEmpty } from 'lodash';
import {
  language,
  visningslogikk,
} from '@skatteetaten/visningsdefinisjoner-og-tekster';
import style from './feltUtils.module.scss';
import { v4 as uuid } from 'uuid';
import { FormattedHTMLMessageUglyInnerHtml } from '../web/components/FormattedHTMLMessage';
import { truncateString } from './truncate-string';
import { nyFeil, overskrivKontekst } from './feil';
import { MSSF_013_mangler_intl_id } from '../constants/feilkoder';
// import { rapporterFeilTilBackend } from './rapporterFeil';

const {
  felt: feltUtils,
  transformer: vdotTransformer,
  sorter,
} = visningslogikk;

const { feltHarAktiverDeaktiverGitt } = feltUtils;

export const flatten = (list) => {
  return list.reduce((a, b) => a.concat(Array.isArray(b) ? flatten(b) : b), []);
};

export const flatListe = (type, liste, unntaksliste = []) => {
  return flatListeMedFilter(
    type,
    liste,
    (felt) => !unntaksliste.includes(felt.type),
  );
};

export const flatListeMedFilter = (type, liste, filter = (a) => a) => {
  if (liste) {
    return [
      ...liste,
      ...flatten(
        liste
          .filter(filter)
          .map((felt) =>
            felt.barnliste
              ? flatListeMedFilter(type, felt.barnliste, filter)
              : [],
          ),
      ),
    ].filter((felt) => felt.type === type);
  }
  return [];
};

export const flatListMangeTyper = (liste, ...typer) => {
  if (!liste) return [];
  return typer
    .map((type) => flatListe(type, liste))
    .flat()
    .filter((felt) => !felt.typeEgenskap);
};

// export const flatListeMedFelter = (liste, unntaksliste) => {
//   return flatListe('felt', liste, unntaksliste);
// };

export const flatListeMedRepeterendeFelter = (liste) => {
  return flatListe('repeterendefelt', liste);
};

export const flatListeMedKorttyper = (liste) => {
  return flatListe('korttype', liste);
};

export const flatListeMedInaktiveFelter = (liste) => {
  if (liste) {
    return [
      ...liste,
      ...flatten(
        liste.map((felt) =>
          felt.barnliste ? flatListeMedInaktiveFelter(felt.barnliste) : [],
        ),
      ),
    ].filter((felt) => feltHarAktiverDeaktiverGitt(felt));
  }

  return [];
};

const intlFormatMessage = (
  intl,
  messageDescriptor = {},
  args = {},
  kontekst = {},
) => {
  if (!messageDescriptor.id) {
    // const feil =
    nyFeil(MSSF_013_mangler_intl_id, [overskrivKontekst(kontekst)]);
    // rapporterFeilTilBackend(feil);

    messageDescriptor.id = '-';
  }

  return intl.formatMessage(messageDescriptor, args);
};

export function lagLabel(intl, { id } = {}, obligatorisk) {
  return (
    intlFormatMessage(intl, { id: id, defaultMessage: ' ' }, undefined, {
      beskrivelse: 'lagLabel',
      id,
    }) + (obligatorisk ? ' *' : '')
  );
}

export const lagFeilmeldinger = (
  feil,
  valideringsstate,
  intl,
  feltId,
  kortId,
) => {
  if (isEmpty(feil)) {
    return;
  }

  const formatertRepeterendeFeltReferanse = () => {
    if (valideringsstate && valideringsstate.repeterendeFeltReferanse)
      return {
        repeterendeFeltReferanse: intlFormatMessage(
          intl,
          { id: valideringsstate.repeterendeFeltReferanse },
          {
            beskrivelse:
              'formatertFeilmelding, defaultMessage med repeterendeFeltReferanse',
          },
        ),
      };
  };

  const formatertFeilmelding = (melding) =>
    intlFormatMessage(
      intl,
      {
        id: melding.feilmelding + '.' + (feltId || kortId),
        defaultMessage: intlFormatMessage(
          intl,
          {
            id: melding.feilmelding,
          },
          {
            ...valideringsstate,
            ...melding.args,
            ...formatertRepeterendeFeltReferanse(),
          },
          {
            beskrivelse: 'formatertFeilmelding, defaultMessage',
            melding,
            feltId,
          },
        ),
      },
      { ...valideringsstate, ...melding.args },
      { beskrivelse: 'formatertFeilmelding', melding, feltId },
    );
  const lagFeilmelding = (melding) => (
    <span>
      {feltId
        ? `${truncateString(
            intlFormatMessage(intl, { id: feltId }, undefined, {
              beskrivelse: 'lagFeilmelding',
              melding,
              feltId,
            }),
            40,
          )}: `
        : null}
      {formatertFeilmelding(melding)}
    </span>
  );
  return feil.length === 1 ? (
    lagFeilmelding(feil[0])
  ) : (
    <div>
      <span>{lagFeilmelding(feil[0])}</span>
      {feil.slice(1).map((feilmelding) => (
        <p key={feilmelding}>{lagFeilmelding(feilmelding)}</p>
      ))}
    </div>
  );
};

export function lagHjelpetekst(intl, { id } = {}) {
  const hjelpetekstId = language.hjelpetekstId(id);

  if (!intl.messages[hjelpetekstId]) {
    return null;
  }

  return (
    <div className={style.resetLabelWithCalloutMargin}>
      <FormattedHTMLMessageUglyInnerHtml id={hjelpetekstId} />
    </div>
  );
}

export const erObligatorisk = (definisjon) =>
  definisjon.valideringer?.includes('obligatorisk');

export const transformer = (felt, verdi, intl) =>
  vdotTransformer.transformerFelt(felt, verdi, { intl });

export const hjelpetekstId = (felt) => language.hjelpetekstId(tekstId(felt));
export const tekstId = (definisjon = {}) => definisjon.id;

export const nyForekomstId = (definisjon) => definisjon.statiskId || uuid();

export const nyDynamiskFelt = (felt) => ({
  id: nyForekomstId(felt),
  behandlingsart: felt.datatype,
});

const OPTION_DEVIDER = [
  {
    key: 'divider',
    text: '-',
    itemType: 1,
  },
];

const mapVerdi = (valgtVerdi, obligatorisk) => (valg) => ({
  key: valg.id,
  text: valg.tekst,
  value: valg.id,
  selected: valgtVerdi === valg.id,
  disabled: !valg.id && obligatorisk,
});

export const mapOpsjoner = (felt, valgtVerdi, obligatorisk, intl, locale) => {
  if (!felt.opsjon.gruppering) {
    return sorter
      .sorterNedtrekksliste(felt, intl, locale)
      .map(mapVerdi(valgtVerdi, obligatorisk));
  }

  const grupperteOpsjoner = sorter.sorterNedtrekkslisteEtterGruppering(
    felt,
    intl,
    locale,
  );

  return Object.entries(grupperteOpsjoner).reduce(
    (prev, [gruppe, elementer], currentIndex, array) => {
      if (gruppe === 'ingen') {
        return [
          ...elementer.map(mapVerdi(valgtVerdi, obligatorisk)),
          ...OPTION_DEVIDER,
        ];
      }
      const skalHaDevider =
        currentIndex < array.length - 1 ? OPTION_DEVIDER : [];
      return [
        ...prev,
        {
          key: gruppe,
          text: transformer(felt, gruppe, intl),
          itemType: 2,
          styles: {
            optionText: {
              fontSize: '1.2rem',
              color: 'black',
            },
          },
        },
        ...elementer.map(mapVerdi(valgtVerdi, obligatorisk)),
        ...skalHaDevider,
      ];
    },
    [],
  );
};
