import { get } from 'lodash';

export const hentBeloep = (value) =>
  typeof value === 'object' && value.beloep !== undefined
    ? value.beloep
    : value;

export const skjulHvisAlleReferanserErNull = (referanser, data, barnliste) => {
  if (barnliste) {
    return barnliste.every((barn) => {
      const referanseVerdi = get(data, barn.referanse);
      return !hentBeloep(referanseVerdi);
    });
  }

  return (
    referanser &&
    referanser.every((referanse) => {
      const referanseVerdi = get(data, referanse);
      return (
        !referanseVerdi ||
        Object.keys(referanseVerdi).every((o) => !hentBeloep(referanseVerdi[o]))
      );
    })
  );
};

export const harIkkeGrunnlag = (data, value) => {
  const { skjulHvisGrunnlagErNull } = data;
  return skjulHvisGrunnlagErNull && !(value.grunnlag > 0);
};

export const harIkkeFradrag = (data, value) => {
  const { skjulHvisBeloepErNull } = data;
  return skjulHvisBeloepErNull && !(value.beloep > 0);
};

export const defaultValueZero = {
  grunnlag: '0',
  beloep: '0',
};

export const hentVerdi = (linjeDef, beregnetSkatt) => {
  const verdi = get(beregnetSkatt, linjeDef.referanse);

  return typeof verdi === 'object' ? verdi : { beloep: verdi };
};
