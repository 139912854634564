import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import analytics from './analytics.api';
import { getDialogTemaId, getKortdefinisjonIDialog } from '../reducers';

export const useAnalytics = (temaId) => {
  const dialogTemaId = useSelector(getDialogTemaId);
  const kortdefinisjon = useSelector(getKortdefinisjonIDialog);

  const temaId_ = temaId || dialogTemaId;

  return useMemo(
    () => createAnalytics(temaId_, kortdefinisjon?.id),
    [temaId_, kortdefinisjon],
  );
};

const createAnalytics = (temaId, korttypeId) => {
  const hjelpetekstKlikk = (feltId) =>
    analytics.sendEventKlikkHjelpetekst(temaId, korttypeId, feltId);
  const hjelpetekstLukkKlikk = (feltId) =>
    analytics.sendEventLukkKlikkHjelpetekst(temaId, korttypeId, feltId);

  return {
    knappKlikk: (knapp, lokasjon) =>
      analytics.sendEventTilAnalytics(['Knapp', knapp, lokasjon]),
    visning: (lokasjon) =>
      analytics.sendEventTilAnalytics(['Visning', lokasjon]),
    feltKlikk: (feltnavn, lokasjon) =>
      analytics.sendEventTilAnalytics([lokasjon, 'Felt - klikk', feltnavn]),
    feltEndret: (feltnavn, lokasjon, nyVerdi) =>
      analytics.sendEventTilAnalytics([
        lokasjon,
        'Felt - endring',
        `${feltnavn} (${nyVerdi})`,
      ]),

    // Vdot-spesifikke events
    dialogFeltKlikk: (feltId) =>
      analytics.sendEventKlikkFelt(temaId, korttypeId, feltId),
    endringAvFeltIDialog: (feltId, nyVerdi) =>
      analytics.sendEventEndringAvFelt(temaId, korttypeId, feltId, nyVerdi),
    hjelpetekstKlikk,
    hjelpetekstLukkKlikk,
    haandterHjelpetekstToggle: (feltId) => (calloutState) => {
      calloutState === 'OPEN'
        ? hjelpetekstLukkKlikk(feltId)
        : hjelpetekstKlikk(feltId);
    },
    leggTilKlikk: (korttypeId) =>
      analytics.sendEventKlikkLeggTil(temaId, korttypeId),
    endreKortKlikk: (korttypeId) =>
      analytics.sendEventKlikkEndreKort(temaId, korttypeId),
    sletteKortKlikk: (korttypeId) =>
      analytics.sendEventSlettkort(temaId, korttypeId),
    // denne ser ut til å kunne være brukt mange flere steder enn den er nå (i SME)
    endringKansellert: (korttypeId) =>
      analytics.sendEventEndringKansellert(temaId, korttypeId),
    leggTilFullfoert: () =>
      analytics.sendEventKlikkLeggTilFullfoert(temaId, korttypeId),
    leggTilEgenskap: (egenskapId) =>
      analytics.sendEventLeggTilEgenskap(temaId, korttypeId, egenskapId),
  };
};
