import { compact, each, find, isUndefined } from 'lodash';
import { visningslogikk } from '@skatteetaten/visningsdefinisjoner-og-tekster';

import {
  BELOEPSFELT,
  DYNAMISK_GRUPPE,
  FELTGRUPPE,
  KORTGRUPPE,
  TEMA,
  REPETERENDE_FELTGRUPPE,
  erFeltMedEgenskaper,
  erFelt,
  FELT_MED_EGENSKAPER,
  FELT,
  DYNAMISK_FELT,
  erKortIKort,
  erFeltgruppe,
  finnDefinisjonFraVisningsdefinisjonBasertPaaReferanse,
  erDynamiskgruppe,
  erRepeterendeFeltgruppe,
  erKorttype,
  erKortgruppe,
  erTema,
  erDynamiskfelt,
  KORTTYPE,
} from './visningsDataDefinisjonUtil';
import { flatListeMedFilter } from './feltUtils';

const { visningsdefinisjonUtils } = visningslogikk;

const stiMed = (type, id) => (sti) => ({ ...sti, [type]: id });

// export const getId = (def) => def.id;

// export const feltId = (feltDef) =>
//   erFeltMedEgenskaper(feltDef) ? getId(feltDef[BELOEPSFELT]) : getId(feltDef);

export const referanseForFelt = (feltDef) =>
  erFeltMedEgenskaper(feltDef)
    ? `${feltDef.referanse}.${feltDef[BELOEPSFELT].referanse}`
    : feltDef.referanse;

const mapVerdi =
  (f) =>
  ([k, v]) => [k, f(v)];

export const definisjonsstiForId = (visningsDefinisjon) => {
  const feltTilDefinisjonsstier = (def) =>
    [[def.id, {}]].map(mapVerdi(stiMed(FELT, def.id)));

  const feltMedEgenskaperTilDefinisjonsstier = (def) =>
    [
      ...feltTilDefinisjonsstier(def.belopsfelt),
      ...def.barnliste.filter(erFelt).flatMap(feltTilDefinisjonsstier),
      [def.id, {}],
    ].map(mapVerdi(stiMed(FELT_MED_EGENSKAPER, def.id)));

  const repeterendeFeltgruppeTilDefinisjonsstier = (def) =>
    [
      ...def.barnliste.filter(erFelt).flatMap(feltTilDefinisjonsstier),
      ...def.barnliste
        .filter(erFeltMedEgenskaper)
        .flatMap(feltMedEgenskaperTilDefinisjonsstier),
      [def.id, {}],
    ].map(mapVerdi(stiMed(REPETERENDE_FELTGRUPPE, def.id)));

  const dynamiskfeltTilDefinisjonsstier = (def) =>
    [
      ...def.barnliste.filter(erFelt).flatMap(feltTilDefinisjonsstier),
      ...def.barnliste
        .filter(erFeltMedEgenskaper)
        .flatMap(feltMedEgenskaperTilDefinisjonsstier),
      [def.id, {}],
    ].map(mapVerdi(stiMed(DYNAMISK_FELT, def.id)));

  const dynamiskgruppeTilDefinisjonsstier = (def) =>
    [
      ...def.barnliste
        .filter(erDynamiskfelt)
        .flatMap(dynamiskfeltTilDefinisjonsstier),
      [def.id, {}],
    ].map(mapVerdi(stiMed(DYNAMISK_GRUPPE, def.id)));

  const feltgruppeTilDefinisjonsstier = (def) =>
    [
      ...def.barnliste.filter(erFelt).flatMap(feltTilDefinisjonsstier),
      ...def.barnliste
        .filter(erFeltMedEgenskaper)
        .flatMap(feltMedEgenskaperTilDefinisjonsstier),
      ...def.barnliste
        .filter(erRepeterendeFeltgruppe)
        .flatMap(repeterendeFeltgruppeTilDefinisjonsstier),
      ...def.barnliste
        .filter(erDynamiskgruppe)
        .flatMap(dynamiskgruppeTilDefinisjonsstier),
      [def.id, {}],
    ].map(mapVerdi(stiMed(FELTGRUPPE, def.id)));

  const korttypeTilDefinisjonsstier = (def) =>
    [
      ...def.barnliste
        .filter(erFeltgruppe)
        .flatMap(feltgruppeTilDefinisjonsstier),
      ...def.barnliste
        .filter(erDynamiskgruppe)
        .flatMap(dynamiskgruppeTilDefinisjonsstier),
      ...def.barnliste
        .filter(erRepeterendeFeltgruppe)
        .flatMap(repeterendeFeltgruppeTilDefinisjonsstier),
      ...def.barnliste
        .filter(erFeltMedEgenskaper)
        .flatMap(feltMedEgenskaperTilDefinisjonsstier),
      [def.id, {}],
    ].map(mapVerdi(stiMed(KORTTYPE, def.id)));

  const kortgruppeTilDefinisjonsstier = (def) =>
    [
      ...def.barnliste.filter(erKorttype).flatMap(korttypeTilDefinisjonsstier),
      [def.id, {}],
    ].map(mapVerdi(stiMed(KORTGRUPPE, def.id)));

  const temaTilDefinisjonsstier = (def) =>
    [
      ...def.barnliste
        .filter(erKortgruppe)
        .flatMap(kortgruppeTilDefinisjonsstier),
      [def.id, {}],
    ].map(mapVerdi(stiMed(TEMA, def.id)));

  return Object.fromEntries(
    visningsDefinisjon.filter(erTema).flatMap(temaTilDefinisjonsstier),
  );
};

export const definisjonerForId = (visningsdefinisjon) => {
  const feltTilDefinisjoner = (def) => [[def.id, def]];

  const feltMedEgenskaperTilDefinisjoner = (def) => [
    ...feltTilDefinisjoner(def.belopsfelt),
    ...def.barnliste.filter(erFelt).flatMap(feltTilDefinisjoner),
    [def.id, def],
  ];

  const repeterendeFeltgruppeTilDefinisjoner = (def) => [
    ...def.barnliste.filter(erFelt).flatMap(feltTilDefinisjoner),
    ...def.barnliste
      .filter(erFeltMedEgenskaper)
      .flatMap(feltMedEgenskaperTilDefinisjoner),
    [def.id, def],
  ];

  // eslint-disable-next-line sonarjs/no-identical-functions
  const dynamiskfeltTilDefinisjoner = (def) => [
    ...def.barnliste.filter(erFelt).flatMap(feltTilDefinisjoner),
    ...def.barnliste
      .filter(erFeltMedEgenskaper)
      .flatMap(feltMedEgenskaperTilDefinisjoner),
    [def.id, def],
  ];

  const dynamiskgruppeTilDefinisjoner = (def) => [
    ...def.barnliste
      .filter(erDynamiskfelt)
      .flatMap(dynamiskfeltTilDefinisjoner),
    [def.id, def],
  ];

  const feltgruppeTilDefinisjoner = (def) => [
    ...def.barnliste.filter(erFelt).flatMap(feltTilDefinisjoner),
    ...def.barnliste
      .filter(erFeltMedEgenskaper)
      .flatMap(feltMedEgenskaperTilDefinisjoner),
    ...def.barnliste
      .filter(erDynamiskgruppe)
      .flatMap(dynamiskgruppeTilDefinisjoner),
    ...def.barnliste
      .filter(erRepeterendeFeltgruppe)
      .flatMap(repeterendeFeltgruppeTilDefinisjoner),
    [def.id, def],
  ];

  const korttypeTilDefinisjoner = (def) => [
    ...def.barnliste.filter(erFeltgruppe).flatMap(feltgruppeTilDefinisjoner),
    ...def.barnliste
      .filter(erDynamiskgruppe)
      .flatMap(dynamiskgruppeTilDefinisjoner),
    ...def.barnliste
      .filter(erRepeterendeFeltgruppe)
      .flatMap(repeterendeFeltgruppeTilDefinisjoner),
    ...def.barnliste
      .filter(erFeltMedEgenskaper)
      .flatMap(feltMedEgenskaperTilDefinisjoner),
    [def.id, def],
  ];

  const kortgruppeTilDefinisjoner = (def) => [
    ...def.barnliste.filter(erKorttype).flatMap(korttypeTilDefinisjoner),
    [def.id, def],
  ];

  const temaTilDefinisjoner = (def) => [
    ...def.barnliste.filter(erKortgruppe).flatMap(kortgruppeTilDefinisjoner),
    [def.id, def],
  ];

  return Object.fromEntries(
    visningsdefinisjon.filter(erTema).flatMap(temaTilDefinisjoner),
  );
};

export const lagFeltSti = (repeterendeFeltId, felt, forekomstid) => {
  return compact([repeterendeFeltId, felt.id, forekomstid]).join('.');
};

export const finnAlle = (type = 'korttype', kortdefinisjon) =>
  visningsdefinisjonUtils.finnAlle(type, kortdefinisjon.barnliste);

export const finnDefinisjoner = (predikat) => (node) => {
  const recurse = (inner_node) => {
    const barn = Array.isArray(inner_node)
      ? inner_node
      : inner_node.barnliste ?? [];
    const underDefinisjoner = barn.flatMap(recurse);

    if (Array.isArray(inner_node) || !predikat(inner_node)) {
      return underDefinisjoner;
    }
    return [inner_node, ...underDefinisjoner];
  };
  return recurse(node);
};

export const filterDefinisjon = (filter) => (definisjon) => {
  const barnliste = definisjon?.barnliste
    ?.filter(filter)
    .map((barn) => filterDefinisjon(filter)(barn));
  return {
    ...definisjon,
    barnliste,
  };
};

// export const alleKorttyper = finnDefinisjoner(erKorttype);

export const alleFelter = finnDefinisjoner(erFelt);
// export const alleFeltMedEgenskaper = finnDefinisjoner(erFeltMedEgenskaper);
// export const alleRepeterendeFelter = finnDefinisjoner(erRepeterendeFeltgruppe);
// export const alleDynamiskeGrupper = finnDefinisjoner(erDynamiskgruppe);

export const finnDynamiskfeltDefinisjon = (
  kortdefinisjon,
  dynamiskgruppeReferanse,
  behandlingsart,
) => {
  const { barnliste = [] } =
    finnDefinisjonFraVisningsdefinisjonBasertPaaReferanse(
      kortdefinisjon,
      dynamiskgruppeReferanse,
    );
  return find(
    barnliste,
    (dynamiskfelt) => dynamiskfelt.datatype === behandlingsart.behandlingsart,
  );
};

export const dialogtypeForKortgruppe = (kortgruppe) => {
  const { dialogtype, dialogmetadata = {} } = kortgruppe;
  return dialogtype && dialogmetadata.visAlltid ? dialogtype : 'dialog';
};

export const flatUtFeltgrupper = (barnliste = []) =>
  barnliste.flatMap((f) => (erFeltgruppe(f) ? f.barnliste : [f]));

export const getDynamiskegrupper = (kortdefinisjon) =>
  flatListeMedFilter(
    DYNAMISK_GRUPPE,
    kortdefinisjon.barnliste,
    (felt) => !erKortIKort(felt),
  );

export const finnFokusElement = (
  visningsdefinisjon,
  visningsdataRef,
  tema = undefined,
  kortgruppe = undefined,
) => {
  let fokusElement = find(visningsdefinisjon, { referanse: visningsdataRef });
  let nyttFokusTema = tema;
  let nyFokusKortgruppe = kortgruppe;
  if (isUndefined(fokusElement)) {
    each(visningsdefinisjon, (barn) => {
      if (barn.type === 'tema' && barn.id !== tema) {
        nyttFokusTema = barn.id;
      } else if (barn.type === 'kortgruppe' && barn.id !== kortgruppe) {
        nyFokusKortgruppe = barn.id;
      }
      if (barn.barnliste) {
        fokusElement = finnFokusElement(
          barn.barnliste,
          visningsdataRef,
          nyttFokusTema,
          nyFokusKortgruppe,
        );
      }
      return isUndefined(fokusElement, nyttFokusTema, nyFokusKortgruppe); // Avbryte loop hvis objekt finnes
    });
  } else {
    return { tema: nyttFokusTema, kortgruppe: nyFokusKortgruppe };
  }
  return fokusElement;
};
