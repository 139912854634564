import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { language } from '@skatteetaten/visningsdefinisjoner-og-tekster';

import { StorDialog } from './utils/StorDialog';
import { ListeIDialog } from './utils/ListeIDialog';
import { DialogKnapperad } from './utils/DialogKnapperad';
import { DialogAvbryt } from './utils/DialogAvbryt';
import { getDialog } from '../../../reducers';
import { nyDialog } from '../../../actions/dialog';

export const VelgInngangTilKorttypeDialog = () => {
  const dispatch = useDispatch();
  const { kortgruppe, temaId } = useSelector(getDialog);
  const intl = useIntl();

  const { barnliste = [], dialogmetadata: { innganger = [] } = {} } =
    kortgruppe;

  const elementer = innganger.map(
    ({ id, korttypeReferanse, defaultEgenskaper = {}, defaultData = {} }) => {
      const korttype = barnliste.find(
        (barn) => barn.referanse === korttypeReferanse,
      );
      return {
        valgTekst: intl.formatMessage({ id: language.flervalgId(id) }),
        klikkAksjon: () =>
          dispatch(nyDialog(korttype, temaId, defaultData, defaultEgenskaper)),
      };
    },
  );

  return (
    <StorDialog
      titleId={kortgruppe.id}
      subtitleId={language.kortgruppeSubHeaderId(kortgruppe.id)}
      visObligatoriskFeltMelding={false}
    >
      <ListeIDialog elementer={elementer} />
      <DialogKnapperad>
        <DialogAvbryt />
      </DialogKnapperad>
    </StorDialog>
  );
};
