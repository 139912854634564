import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import Button from '@skatteetaten/frontend-components/Button';
import style from './DialogKnapperad.module.css';
import { lukkDialog } from '../../../../actions/dialog';

export const DialogKnapp = ({
  hoved = false,
  aksjon = null,
  tekstid = null,
  tekst = null,
  lukkEtterAksjon = false,
  disabled = false,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const tekst_ = tekstid ? intl.formatMessage({ id: tekstid }) : tekst || '';
  const aksjon_ = useMemo(() => {
    return (e) => {
      aksjon && aksjon(e);
      if (lukkEtterAksjon) {
        dispatch(lukkDialog());
      }
    };
  }, [dispatch, aksjon, lukkEtterAksjon]);
  return (
    <Button
      buttonStyle={hoved ? 'primary' : 'secondary'}
      onClick={aksjon_}
      disabled={disabled}
      className={style.luftMellomKnapper}
    >
      {tekst_}
    </Button>
  );
};
