// import { isNil } from 'lodash';
import {
  CONFLICT_HTTP_409,
  MSSF_003_fikk_ikke_svar_fra_apikall,
  MSSF_004_svar_fra_api_kall_uten_kode,
  MSSF_006_klarte_ikke_parse_json,
  MSSF_007_sesjon_utgikk,
  MSSF_012_blokkert_av_big_ip,
} from '../constants/feilkoder';
import {
  feilFraError,
  feilFraErrorOgLokasjon,
  medFeilkode,
  medLoggSomInfo,
  nyFeil,
  overskrivKontekst,
  settFeilLoggetIBackend,
} from './feil';
// import { getCookie } from '../utils/cookies';

export const KORRELASJONSID = 'Korrelasjonsid';
// const REQUEST_METHODS_SOM_SENDER_ENDRINGER = ['POST', 'PUT', 'PATCH', 'DELETE'];

/* eslint-disable sonarjs/no-duplicate-string */
export const jsonHeaderUtenBody = {
  Accept: 'application/json',
};

// const filterHeaders = (headers) =>
//   Object.fromEntries(
//     Object.entries(headers).filter(([_ignore, v]) => !isNil(v))
//   );

// const getTokensFromCookie = () => {
//   const tokenCookie = getCookie('csrfTokens');
//
//   if (!tokenCookie) {
//     return [];
//   }
//
//   try {
//     return JSON.parse(tokenCookie);
//   } catch (err) {
//     return [];
//   }
// };

// function leggTilNyttTokenFoerstIListen(token) {
//   const csrfTokens = getTokensFromCookie();
//   csrfTokens.unshift(token);
//   csrfTokens.length = csrfTokens.length < 3 ? csrfTokens.length : 3;
//   return csrfTokens;
// }

// export const sesjonHeader = (method = 'GET') => {
//   const token = uuidv4();
//
//   const sesjonmarkoer = accessSesjonMarkoer();
//   const headers = {
//     [SESJONMARKOER]: sesjonmarkoer,
//     [partForSaksbehandlerHeader]: partForsaksbehandler,
//   };
//
//   if (REQUEST_METHODS_SOM_SENDER_ENDRINGER.includes(method.toUpperCase())) {
//     const csrfTokens = leggTilNyttTokenFoerstIListen(token);
//     const expires = Date.now() + 60000;
//
//     document.cookie = `csrfTokens=${JSON.stringify(
//       csrfTokens
//     )};expires=${new Date(expires).toUTCString()};Secure;path=/`;
//
//     headers['X-CSRF-TOKEN'] = token;
//   }
//
//   return filterHeaders(headers);
// };

const feilfelterFraApiRespons = (response) => ({
  korrelasjonsid: response?.headers?.get(KORRELASJONSID),
  httpStatus: response?.status,
  url: response?.url,
});

export const feilFraFetchError = (url) => async (error) => {
  throw feilFraError(error, [
    medLoggSomInfo,
    medFeilkode(MSSF_003_fikk_ikke_svar_fra_apikall),
    overskrivKontekst({ url }),
  ]);
};

const erJson = (response) => {
  const contentType = response?.headers?.get('Content-Type');
  return contentType && contentType.indexOf('application/json') !== -1;
};

export const feilForBadHttpStatus = async (response) => {
  if (response.ok) {
    return response;
  }
  let kontekst = feilfelterFraApiRespons(response);

  let feilkode = MSSF_004_svar_fra_api_kall_uten_kode;
  let feilLoggetIBackend;

  if (erJson(response)) {
    try {
      const json = await response.json();
      feilkode = (json && json.kode) || feilkode;
      feilLoggetIBackend = !!json?.kode;
    } catch (e) {
      console.error('feil under parsing av feiljson', e);
    }
  } else {
    try {
      const text = await response.text();
      const contentType = response?.headers?.get('Content-Type');
      if (isBlockedByBigIp(response.status, contentType, text)) {
        feilkode = MSSF_012_blokkert_av_big_ip;
        kontekst = { ...kontekst, supportId: parseBigIpSupportId(text) };
      }
    } catch (e) {
      console.error('feil under parsing av feiltekst', e);
    }
  }

  const erTimeout =
    response.status === 403 &&
    feilkode === MSSF_004_svar_fra_api_kall_uten_kode;

  const feil = nyFeil(feilkode, [
    overskrivKontekst(kontekst),
    settFeilLoggetIBackend(feilLoggetIBackend),
  ]);

  if (erTimeout) {
    throw medLoggSomInfo(medFeilkode(MSSF_007_sesjon_utgikk)(feil));
  }
  if (response.status === 409) {
    throw medLoggSomInfo({ ...feil, feilkode: CONFLICT_HTTP_409 });
  }
  throw feil;
};

export const responseAsJsonObject = async (response) => {
  if (erJson(response)) {
    try {
      return await response.json();
    } catch (error) {
      throw feilFraErrorOgLokasjon(error, MSSF_006_klarte_ikke_parse_json, [
        overskrivKontekst(feilfelterFraApiRespons(response)),
      ]);
    }
  }
  return Promise.resolve({});
};

export const responseMedKontekstAsJsonObject = async (response) => {
  let kontekst = feilfelterFraApiRespons(response);

  if (erJson(response)) {
    try {
      const json = await response.json();
      return { kontekst, json };
    } catch (error) {
      throw feilFraErrorOgLokasjon(error, MSSF_006_klarte_ikke_parse_json, [
        overskrivKontekst(feilfelterFraApiRespons(response)),
      ]);
    }
  }
  return Promise.resolve({ kontekst, json: {} });
};

export const standardJsonGet = (url, { headers = {} } = {}) => {
  return fetch(url, {
    method: 'GET',
    credentials: 'include',
    headers: {
      ...jsonHeaderUtenBody,
      // ...sesjonHeader(),
      // ...altinnHeader(),
      ...headers,
    },
  })
    .catch(feilFraFetchError(url))
    .then(feilForBadHttpStatus)
    .then(responseAsJsonObject);
};

export const standardJsonMedKontekstGet = (url, { headers = {} } = {}) => {
  return fetch(url, {
    method: 'GET',
    credentials: 'include',
    headers: {
      ...jsonHeaderUtenBody,
      // ...sesjonHeader(),
      // ...altinnHeader(),
      ...headers,
    },
  })
    .catch(feilFraFetchError(url))
    .then(feilForBadHttpStatus)
    .then(responseMedKontekstAsJsonObject);
};

export const jsonHeaderMedBody = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const standardJsonPost = (url, body, { headers = {} } = {}) => {
  const method = 'POST';
  return fetch(url, {
    method,
    credentials: 'include',
    headers: {
      ...jsonHeaderMedBody,
      // ...sesjonHeader(method),
      // ...altinnHeader(),
      ...headers,
    },
    body: JSON.stringify(body),
  })
    .catch(feilFraFetchError(url))
    .then(feilForBadHttpStatus)
    .then(responseAsJsonObject);
};

export const standardJsonPut = (url, body) => {
  const method = 'PUT';
  return fetch(url, {
    method,
    credentials: 'include',
    headers: {
      ...jsonHeaderMedBody,
      // ...sesjonHeader(method),
      // ...altinnHeader(),
    },
    body: JSON.stringify(body),
  })
    .catch(feilFraFetchError(url))
    .then(feilForBadHttpStatus)
    .then(responseAsJsonObject);
};

export const standardJsonMedKontekstPut = (url, body) => {
  const method = 'PUT';
  return fetch(url, {
    method,
    credentials: 'include',
    headers: {
      ...jsonHeaderMedBody,
      // ...sesjonHeader(method),
      // ...altinnHeader(),
    },
    body: JSON.stringify(body),
  })
    .catch(feilFraFetchError(url))
    .then(feilForBadHttpStatus)
    .then(responseMedKontekstAsJsonObject);
};

export const standardJsonDelete = (url) => {
  const method = 'DELETE';
  return fetch(url, {
    method,
    headers: {
      ...jsonHeaderUtenBody,
      // ...sesjonHeader(method),
      // ...altinnHeader(),
    },
    credentials: 'include',
  })
    .catch(feilFraFetchError(url))
    .then(feilForBadHttpStatus)
    .then(responseAsJsonObject);
};

function isBlockedByBigIp(httpStatus, contentTypeHeader, bodyText) {
  return (
    httpStatus === 403 &&
    contentTypeHeader?.includes('text/html') &&
    bodyText?.includes(supportIdLabel)
  );
}

function parseBigIpSupportId(bodyText) {
  const index = bodyText?.lastIndexOf(supportIdLabel) + supportIdLabel.length;
  const regExNumberFromStartOfString = /^(\d+)/;
  return bodyText?.slice(index).match(regExNumberFromStartOfString)?.[1];
}

export function feilFraAxiosRespons(response, url) {
  const httpStatus = response?.status;
  const bodyText = response?.data;
  const contentTypeHeader = response?.headers['content-type'];

  let kontekst = {
    Korrelasjonsid: response?.headers?.korrelasjonsid,
    httpStatus,
    url,
  };

  if (isBlockedByBigIp(httpStatus, contentTypeHeader, bodyText)) {
    const supportId = parseBigIpSupportId(bodyText);
    return nyFeil(MSSF_012_blokkert_av_big_ip, [
      overskrivKontekst({ ...kontekst, supportId }),
    ]);
  }

  const feilkode = response?.data?.kode || MSSF_004_svar_fra_api_kall_uten_kode;
  return nyFeil(feilkode, [
    overskrivKontekst(kontekst),
    settFeilLoggetIBackend(!!response?.data?.kode),
  ]);
}

const supportIdLabel = 'Your support ID is: ';
