import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { get, has } from 'lodash';
import classnames from 'classnames';

import { language } from '@skatteetaten/visningsdefinisjoner-og-tekster';
import LabelWithCallout from '@skatteetaten/frontend-components/LabelWithCallout';
import ActionButton from '@skatteetaten/frontend-components/ActionButton';
import Grid from '@skatteetaten/frontend-components/Grid';
import ErrorMessage from '@skatteetaten/frontend-components/ErrorMessage';
import { getFeilIDialog } from '../../../../reducers';
import {
  DIALOGTYPE_DIALOG,
  visKortIKortDialog,
} from '../../../../actions/dialog';
import {
  erObligatorisk,
  lagFeilmeldinger,
  lagHjelpetekst,
  lagLabel,
  tekstId,
} from '../../../../utils/feltUtils';
import {
  DYNAMISK_GRUPPE,
  FELT,
  FELT_MED_EGENSKAPER,
} from '../../../../utils/visningsDataDefinisjonUtil';
import kortStyle from '../../tema/kort/Kort.module.scss';
import style from './KortIKortListeIDialog.module.scss';
import { Kortfelt } from '../../tema/kort/Kortfelt';
import { KortfeltMedEgenskaper } from '../../tema/kort/KortfeltMedEgenskaper';
import { medUnderforekomst } from '../../../../utils/visningsDataDefinisjonUtil';
import { DynamiskGruppe } from '../../tema/kort/DynamiskGruppe';
import { EndreKnapp } from '../../tema/kort/KortKnapper';
import { useBetingelseHook } from '../../../../hooks/useBetingelseHook';
import { useAnalytics } from '../../../../analytics/analytics.hooks';

export const KortIKortListeIDialog = ({
  repeterendeFeltgruppe,
  repeterendeFeltData,
  definisjonsSti,
  dataSti: dataStiTilFeltgruppe,
  leggTilRepeterendefelt,
  readOnly,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { skalFeltDisables, skalFeltSkjules } = useBetingelseHook();
  const analytics = useAnalytics();

  const feil = useSelector(getFeilIDialog);
  const feilForRepeterendeFelt = get(feil, repeterendeFeltgruppe.id);

  const underforekomster = repeterendeFeltData[repeterendeFeltgruppe.referanse];
  const kanLeggeTil = has(repeterendeFeltgruppe, 'kanLeggeTil')
    ? repeterendeFeltgruppe.kanLeggeTil
    : has(repeterendeFeltgruppe, 'tabell.kanLeggeTilRader')
    ? repeterendeFeltgruppe.tabell.kanLeggeTilRader
    : true;

  const visDialogHandler = (underforekomst) => {
    dispatch(
      visKortIKortDialog(
        {
          definisjonsSti,
          underforekomstDataSti: medUnderforekomst(
            dataStiTilFeltgruppe,
            underforekomst.id,
          ),
          readOnly,
        },
        DIALOGTYPE_DIALOG,
      ),
    );
  };

  // Dersom ny underforekomst er lagt til, åpne den
  if (underforekomster?.some((uf) => uf.erNyUnderforekomst)) {
    visDialogHandler(underforekomster.find((uf) => uf.erNyUnderforekomst));
  }

  const keyHandler = (e, underforekomst) => {
    if (e.keyCode === 13 || e.keyCode === 32) {
      e.preventDefault();
      visDialogHandler(underforekomst);
    }
  };

  const label = lagLabel(
    intl,
    repeterendeFeltgruppe,
    erObligatorisk(repeterendeFeltgruppe),
  );

  const hjelpetekst = lagHjelpetekst(intl, repeterendeFeltgruppe);

  const classes = classnames(
    'mss-renderkort',
    kortStyle.aktivtKort,
    style.kortIKortStil,
  );

  return (
    <div className={style.kortIKortListeStil}>
      <LabelWithCallout
        label={label}
        help={hjelpetekst}
        onCalloutToggle={analytics.haandterHjelpetekstToggle(
          repeterendeFeltgruppe.id,
        )}
      />

      <div>
        {underforekomster?.map((underforekomst) => (
          <div className={classes} tabIndex="-1" key={underforekomst.id}>
            <div
              className={kortStyle.klikkbarKort}
              onClick={() => visDialogHandler(underforekomst)}
              onKeyDown={(e) => keyHandler(e, underforekomst)}
            >
              <dl className={style.stretchWidth}>
                {repeterendeFeltgruppe.barnliste
                  .filter((barn) =>
                    [FELT, FELT_MED_EGENSKAPER, DYNAMISK_GRUPPE].includes(
                      barn.type,
                    ),
                  )
                  .filter((barn) => !barn.skjulIKort)
                  .map((barn, feltIndex) => {
                    const harTransformerMedReferanser = has(
                      barn,
                      'transformer.referanser',
                    );
                    const feltData = harTransformerMedReferanser
                      ? underforekomst
                      : get(underforekomst, barn.referanse);
                    const transformerReferanserFelt =
                      !harTransformerMedReferanser
                        ? {}
                        : Object.fromEntries(
                            repeterendeFeltgruppe.barnliste
                              .filter((f) => f.transformer)
                              .filter((f) =>
                                barn.transformer.referanser.includes(
                                  f.referanse,
                                ),
                              )
                              .map((f) => [f.referanse, f]),
                          );

                    switch (barn.type) {
                      case FELT:
                        return (
                          <Kortfelt
                            viseKunTransformertVerdi={
                              !(barn.transformer?.id === 'tall')
                            }
                            key={`${underforekomst.id}.${barn.referanse}`}
                            hidden={
                              skalFeltSkjules(barn, {
                                ...repeterendeFeltData,
                                ...underforekomst,
                              }) ||
                              skalFeltDisables(barn, {
                                ...repeterendeFeltData,
                                ...underforekomst,
                              })
                            }
                            feltdefinisjon={barn}
                            data={feltData}
                            transformerOptions={{
                              transformerReferanserFelt,
                              intl,
                            }}
                            fetSkrift={feltIndex === 0}
                            obligatorisk={erObligatorisk(barn)}
                          />
                        );

                      case FELT_MED_EGENSKAPER:
                        return (
                          <KortfeltMedEgenskaper
                            key={`${underforekomst.id}.${barn.id}`}
                            data={underforekomst}
                            {...barn}
                          />
                        );

                      case DYNAMISK_GRUPPE:
                        return (
                          <DynamiskGruppe
                            key={barn.id}
                            data={underforekomst}
                            gruppe={barn}
                          />
                        );

                      default:
                        return null;
                    }
                  })}
              </dl>
              <EndreKnapp />
            </div>
          </div>
        ))}
        {!readOnly && kanLeggeTil && (
          <Grid.Row>
            <ActionButton
              icon="AddOutline"
              onClick={() => {
                leggTilRepeterendefelt(repeterendeFeltgruppe.referanse, true);
              }}
              className={style.leggTilKnapp}
            >
              {intl.formatMessage({
                id: language.leggtilKnappId(tekstId(repeterendeFeltgruppe)),
              })}
            </ActionButton>
          </Grid.Row>
        )}
        {feilForRepeterendeFelt?.length > 0 && (
          <ErrorMessage>
            {lagFeilmeldinger(
              feilForRepeterendeFelt,
              { repeterendeFeltReferanse: repeterendeFeltgruppe.referanse },
              intl,
            )}
          </ErrorMessage>
        )}
      </div>
    </div>
  );
};
