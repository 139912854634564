import { SYSTEMFEIL, TOEM_ALLE_SYSTEMFEIL } from './feil.action';

export const getSystemmeldingDialogIsOpen = (state) => !!state.feil;
export const getSystemfeil = (state) => state.feil;

const defaultState = {};

export default function systemmeldingDialog(state = defaultState, action = {}) {
  switch (action.type) {
    case SYSTEMFEIL: {
      const { feil } = action;

      return {
        feil,
      };
    }

    case TOEM_ALLE_SYSTEMFEIL: {
      return defaultState;
    }

    default:
      return state;
  }
}
