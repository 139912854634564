import Fuse from 'fuse.js';

const standardThreshold = 0.3;

const fuseOptions = {
  shouldSort: true,
  includeScore: true,
  tokenize: true,
  matchAllTokens: true,
  threshold: standardThreshold,
  location: 0,
  distance: 400,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  ignoreLocation: true,
  keys: [
    { name: 'tittel', weight: 0.1 },
    { name: 'synonym', weight: 0.1 },
  ],
};

export class Soekemotor {
  constructor(indekseringsgrunnlag) {
    this.fuse = new Fuse(indekseringsgrunnlag, fuseOptions);
  }

  soek(soekestreng) {
    return this.fuse.search(soekestreng);
  }
}
