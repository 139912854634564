import { visningslogikk } from '@skatteetaten/visningsdefinisjoner-og-tekster';
import { Kort } from './Kort';
import { KortGridCell } from '../../util/KortGridCell';

const { kort: kortUtils, sorter } = visningslogikk;
const { kortSkalSkjules } = kortUtils;

export const KorttypeComp = ({
  korttype,
  forekomster = [],
  temaId,
  locale,
  skatteplikt = {},
}) => {
  // Sorter på forekomstId hvis det ikke er angitt sorteringsfelter på korttypen.
  // Det gir ikke optimal rekkefølge, men den blir i alle fall konsistent etter endring av kort.
  const { barnliste, sortering = ['id'] } = korttype;
  const sorterteForekomster = sorter.sorterForekomster(
    forekomster,
    sortering,
    locale,
  );

  const kortSkalVises = (forekomst) => {
    return !kortSkalSkjules(
      korttype,
      sorterteForekomster,
      forekomst,
      skatteplikt,
    );
  };

  function lagKort() {
    if (sorterteForekomster && barnliste) {
      return sorterteForekomster.filter(kortSkalVises).map((forekomst) => {
        // eslint-disable-next-line no-shadow
        const data = {
          ...forekomst,
          skatteplikt,
        };
        return (
          <KortGridCell data-type="scroll-anchor" key={forekomst.id}>
            <Kort kortdefinisjon={korttype} data={data} temaId={temaId} />
          </KortGridCell>
        );
      });
    }
  }

  const lagdKort = lagKort();

  if (lagdKort) {
    return lagdKort;
  }

  return null;
};
