const synonymId = (id) => `synonym.${id}`;

const indekser = (
  ting,
  messageFormatter,
  parentIder = {},
  parentTitler = {},
  kortreferanse,
) => {
  const id = ting.id;
  const type = ting.type;
  const tittel = messageFormatter(id);
  const synonym = messageFormatter(synonymId(id));

  const indexEntry = {
    type,
    synonym,
    tittel,
    ider: {
      ...parentIder,
      [type]: id,
    },
    titler: {
      ...parentTitler,
      [type]: tittel,
    },
    kortreferanse:
      kortreferanse || (type === 'korttype' ? ting.referanse : undefined),
    feltreferanse: ['felt', 'feltMedEgenskaper'].includes(ting.type)
      ? ting.referanse
      : undefined,
  };

  const indexEntriesBarn =
    // ikke ta med feltMedEgenskaper sine barn, vi trenger ikke 1000 indekserte valuta-felter
    type !== 'feltMedEgenskaper' && Array.isArray(ting.barnliste)
      ? ting.barnliste.flatMap((barn) =>
          indekser(
            barn,
            messageFormatter,
            indexEntry.ider,
            indexEntry.titler,
            indexEntry.kortreferanse,
          ),
        )
      : [];

  return [indexEntry, ...indexEntriesBarn];
};

export const indekserVisningsdefinisjon = (visningsdef, messageFormatter) => {
  return visningsdef
    .filter((it) => it.type === 'tema')
    .flatMap((tema) => indekser(tema, messageFormatter));
};
