import { useEffect, useRef } from 'react';

const queryWithoutNegativeTabindex =
  'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
const queryWithNegativeTabindex =
  'button, [href], input, select, textarea, [tabindex]';

export const FocusOnMount = ({
  children,
  skipFocus,
  skipTabindexMinusOne = false,
}) => {
  const myRef = useRef(null);
  useEffect(() => {
    if (!skipFocus && myRef.current) {
      const query = skipTabindexMinusOne
        ? queryWithoutNegativeTabindex
        : queryWithNegativeTabindex;
      const focusableElement = myRef.current.querySelector(query);
      if (focusableElement) {
        focusableElement.focus();
      }
    }
  }, [skipFocus, skipTabindexMinusOne]);
  return <div ref={myRef}>{children}</div>;
};
