import { Temaer } from './tema/Temaer';
import { OppsummeringOgSkatteberegning } from './oversikt/OppsummeringOgSkatteberegning';
import { StickyHeader } from './StickyHeader';
import { MutableRefObject, useEffect } from 'react';
import { useKildeSkattPaaLoenn } from './kildeskattePaaLoenn/useKildeskattPaaLoenn';
import { scrollTilToppen } from '../../utils/scroll';
import { OppsummeringOgBeregningKildeskattPaaLoenn } from './kildeskattePaaLoenn/OppsummeringOgBeregningKildeSkattPaaLoenn';

export const Skattemeldingside = ({
  skatteberegningRef,
  isSkatteberegningOpen,
  setIsSkatteberegningOpen,
  toggleIsSkatteberegningOpen,
}: {
  skatteberegningRef: MutableRefObject<HTMLDivElement | null>;
  isSkatteberegningOpen: boolean;
  setIsSkatteberegningOpen: (isOpen: boolean) => void;
  toggleIsSkatteberegningOpen: () => void;
}) => {
  const { beregnKplModus } = useKildeSkattPaaLoenn();

  useEffect(() => {
    scrollTilToppen();
  }, []);

  return (
    <>
      <div id="hovedinnhold">
        <StickyHeader
          skatteberegningRef={skatteberegningRef}
          setIsSkatteberegningOpen={setIsSkatteberegningOpen}
        />
        <div className="mss-layout__kjerne">
          <Temaer />
          {beregnKplModus ? (
            <OppsummeringOgBeregningKildeskattPaaLoenn />
          ) : (
            <OppsummeringOgSkatteberegning
              skatteberegningRef={skatteberegningRef}
              isSkatteberegningOpen={isSkatteberegningOpen}
              toggleIsSkatteberegningOpen={toggleIsSkatteberegningOpen}
            />
          )}
        </div>
      </div>
    </>
  );
};
