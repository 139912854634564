const focusFirstInputElement = (element) => {
  if (element.querySelector('input, select, fieldset')) {
    element.querySelector('input, select, fieldset').focus();
  } else {
    element.focus();
  }
};

export const scrollTilFoersteFeil = (domElement) => {
  const feilmeldingDomElement = domElement.querySelector('[data-type=harFeil]');
  if (feilmeldingDomElement) {
    focusFirstInputElement(feilmeldingDomElement);
    feilmeldingDomElement.scrollIntoView();
    return true;
  }
  return false;
};

export function getPos(domNode) {
  let element = domNode;
  let y = 0;
  while (element) {
    y += element.offsetTop;
    element = element.offsetParent;
  }
  return y;
}
