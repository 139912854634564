import { compact, get, has, isEmpty, isUndefined } from 'lodash';
import { v4 as uuid } from 'uuid';
import fp from 'lodash/fp';

import { visningslogikk } from '@skatteetaten/visningsdefinisjoner-og-tekster';
import {
  defaultVerdiForOpsjon,
  leggTilDefaultVerdier,
} from '../utils/visningsdataUtil';
import {
  erDynamiskfelt,
  erRepeterendeFeltgruppe,
} from '../utils/visningsDataDefinisjonUtil';
import { finnAlle, getDynamiskegrupper } from '../utils/visningsdefinisjonUtil';
import {
  erIkkeAlleredeLagtTil,
  harSynligeBarnFelt,
} from '../web/components/dialog/KortOpplysninger/tilleggsopplysningerUtil';

const { felt: feltUtils } = visningslogikk;

// eslint-disable-next-line sonarjs/cognitive-complexity
const initierOpsjoner = (
  data,
  barnliste,
  referanse,
  kortAlleredeAapent = false,
) => {
  let nyData = data;
  const erGrupperteForekomster = Array.isArray(data);

  if (!erGrupperteForekomster && barnliste) {
    barnliste.forEach((feltdefinisjon) => {
      let referanseSti = compact([referanse, feltdefinisjon.referanse]).join(
        '.',
      );
      if (feltdefinisjon.opsjon) {
        // Evt. default verdi for egenskap på objekt settes når den legges til. Hvis verdi settes her
        // så behandler koden egenskapen som om den er lagt til, selv om den ikke er det.
        if (!feltdefinisjon.erEgenskapPaaObjekt) {
          nyData = leggTilDefaultVerdiHvisVerdiErUndefined(
            nyData,
            feltdefinisjon,
            referanseSti,
            referanse,
          );
        }
        nyData = fiksVerdiForAvkrysningsboks(
          nyData,
          feltdefinisjon,
          referanseSti,
        );
      } else if (erDynamiskfelt(feltdefinisjon)) {
        const dynamiskfeltIndex = get(data, referanseSti, []).findIndex(
          (felt) => felt.behandlingsart === feltdefinisjon.datatype,
        );
        if (dynamiskfeltIndex > -1) {
          nyData = initierOpsjoner(
            nyData,
            feltdefinisjon.barnliste,
            `${referanseSti}.${dynamiskfeltIndex}`,
          );
        }
      } else if (erRepeterendeFeltgruppe(feltdefinisjon)) {
        nyData = initierRepeterendeFelt(
          nyData,
          feltdefinisjon,
          referanseSti,
          kortAlleredeAapent,
        );
      } else if (feltdefinisjon.barnliste) {
        nyData = initierOpsjoner(
          nyData,
          feltdefinisjon.barnliste,
          referanseSti,
        );
      }
    });
  }

  return nyData;
};

export const initierData = (
  data,
  kortdefinisjon,
  kortAlleredeAapent = false,
) => {
  const dataForOpsjoner = initierOpsjoner(
    data,
    kortdefinisjon.barnliste,
    undefined,
    kortAlleredeAapent,
  );
  return leggTilDefaultVerdier(dataForOpsjoner, kortdefinisjon);
};

/**
 * siden verdier i visningsdata kan komme fra backend som strings så må vi fikse verdiene slik at avkrysningsboks ikke
 * snur verdi fra "false" til true (SH-2496). Når og hvis visningsdata får en typet kontrakt kan denne fiksen fjernes.
 */
function fiksVerdiForAvkrysningsboks(dataIDialog, feltdefinisjon, referanse) {
  if (feltUtils.feltErAvkrysningsboks(feltdefinisjon)) {
    const feltData = get(dataIDialog, referanse);

    // Beholder eksplisitt feltverdi "feltSkalSkjules", som brukes for å markere at et felt ikke har verdi,
    // men i stedet skal skjules
    if (
      has(feltdefinisjon, 'opsjon.defaultVerdi') &&
      feltData === 'feltSkalSkjules'
    ) {
      return dataIDialog;
    }
    return fp.set(
      referanse,
      'true' === feltData || true === feltData,
      dataIDialog,
    );
  }
  return dataIDialog;
}

const leggTilDefaultVerdiHvisVerdiErUndefined = (
  data,
  feltdefinisjon,
  referanse,
  subforekomstreferanse,
) => {
  const subforekomst = get(data, subforekomstreferanse, {});
  let defaultverdi = defaultVerdiForOpsjon(feltdefinisjon, {
    ...data,
    ...subforekomst,
  });
  if (isUndefined(get(data, referanse)) && !isUndefined(defaultverdi)) {
    return fp.set(referanse, defaultverdi, data);
  }

  return data;
};

function initierRepeterendeFelt(
  data,
  felt,
  referanseSti,
  nektTomForekomst = false,
) {
  if (isEmpty(get(data, referanseSti))) {
    if (felt.lagSubforekomsterMedVerdierFraFelt) {
      const referansefelt = felt.barnliste.find(
        (barn) => barn.referanse === felt.lagSubforekomsterMedVerdierFraFelt,
      );
      const verdier = referansefelt.opsjon.verdier.filter(
        (verdi) => verdi !== '',
      );
      for (let i = 0; i < verdier.length; i++) {
        data = fp.set(
          `${referanseSti}.${i}`,
          {
            [referansefelt.referanse]: verdier[i],
            id: uuid(),
          },
          data,
        );
      }
    } else if (skalOppretteTomSubforekomst(felt, nektTomForekomst)) {
      const initieltTomtFelt = { id: uuid() };
      if (felt.tabell) {
        initieltTomtFelt.erNyUnderforekomst = true;
      }
      data = fp.set(`${referanseSti}.0`, initieltTomtFelt, data);
    }
  }

  if (felt.barnliste) {
    get(data, referanseSti, []).forEach((repeterendefeltData, index) => {
      data = initierOpsjoner(data, felt.barnliste, `${referanseSti}.${index}`);
    });
  }

  return data;
}

const skalOppretteTomSubforekomst = (
  { kortIKort, tabell },
  nektTomForekomst = false,
) => {
  if (kortIKort || nektTomForekomst) {
    return false;
  }

  return !tabell?.skalIkkeOppretteRadVedTomListe;
};

/**
 * Visse dynamiske felter skal legges til automatisk ved åpning av kort. Denne
 * funksjonen gjør dette rekursivt for gitt definisjon og tilhørende data.
 * */
export const leggTilDefaultDynamiskeFelt = (
  data,
  kortdefinisjon,
  inntektsaar,
) => {
  const dynamiskeGrupperMedDefaultLagtTilFelter = {};

  const skalLeggesTilSomDefault = (dynamiskFelt) => {
    return dynamiskFelt.skalVaereLagtTilSomDefault;
  };

  getDynamiskegrupper(kortdefinisjon).forEach((dynamiskgruppe) => {
    const dynamiskeFelterSomSkalLeggesTilSomDefault = finnAlle(
      'dynamiskfelt',
      dynamiskgruppe,
    ).filter(skalLeggesTilSomDefault);

    const dynamiskFeltListe = get(data, dynamiskgruppe.referanse) || [];

    const nyeDefaultFelterSomSkalLeggesTil =
      dynamiskeFelterSomSkalLeggesTilSomDefault
        .filter(erIkkeAlleredeLagtTil(dynamiskFeltListe))
        .filter(harSynligeBarnFelt(data))
        .map((dynamiskFelt) => ({
          id: dynamiskFelt.statiskId || uuid(),
          behandlingsart: dynamiskFelt.datatype,
        }));

    dynamiskeGrupperMedDefaultLagtTilFelter[dynamiskgruppe.referanse] =
      dynamiskFeltListe.concat(nyeDefaultFelterSomSkalLeggesTil);
  });

  const nyData = {
    ...data,
    ...dynamiskeGrupperMedDefaultLagtTilFelter,
  };

  (kortdefinisjon.barnliste || [])
    .filter(erRepeterendeFeltgruppe)
    .forEach((repeterendeFeltgruppe) => {
      nyData[repeterendeFeltgruppe.referanse] = (
        nyData[repeterendeFeltgruppe.referanse] || []
      ).map((subforekomst) =>
        leggTilDefaultDynamiskeFelt(
          subforekomst,
          repeterendeFeltgruppe,
          inntektsaar,
        ),
      );
    });

  return nyData;
};
