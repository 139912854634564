import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getSynligSeksjon } from '../reducers';
import { scrolletTilSeksjon } from '../actions/navigasjon';
import { circaHeaderHoeyde } from '../web/components/StickyHeader';

/**
 * Denne hooken sier ifra hvilken seksjon som er synlig, som igjen vises i StickyHeader. Brukes i tema(er) og oppsummering.
 */
export const useSynligSeksjon = (id, ref) => {
  const dispatch = useDispatch();
  const synligTema = useSelector(getSynligSeksjon);

  useEffect(() => {
    const scrollListener = () => {
      const rect = ref.current?.getBoundingClientRect();

      const erITema =
        rect && rect.top < circaHeaderHoeyde && rect.bottom > circaHeaderHoeyde;
      const nyttTema = erITema && id !== synligTema;

      if (nyttTema) {
        dispatch(scrolletTilSeksjon(id));
      }
    };

    document.addEventListener('scroll', scrollListener);

    return () => document.removeEventListener('scroll', scrollListener);
  }, [dispatch, id, ref, synligTema]);
};
