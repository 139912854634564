import { useSelector } from 'react-redux';
import {
  korttyperSomKanLeggesTil,
  skalKortgruppeSkjules,
} from '../../../utils/skalSkjules';
import { getVisningsdata } from '../../../reducers';

export const useSkalKortgruppeSkjules = (kortgruppe = {}) => {
  const visningsdata = useSelector(getVisningsdata);

  const harVerdierSomSkalVises = !skalKortgruppeSkjules(
    kortgruppe,
    visningsdata,
  );

  return !harVerdierSomSkalVises;
};

export const useKanLeggeTilKort = (kortgruppe) => {
  const visningsdata = useSelector(getVisningsdata);

  // eslint-disable-next-line sonarjs/prefer-immediate-return
  const harBarnSomKanLeggesTil = !!korttyperSomKanLeggesTil(
    visningsdata,
    kortgruppe,
  ).length;

  return harBarnSomKanLeggesTil;
};
