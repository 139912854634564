import { forwardRef, ReactNode } from 'react';
import {
  RadioGroup as DSRadioGroup,
  RadioProps as DSRadioProps,
} from '@skatteetaten/ds-forms';
import { StringishChildren } from './DSTypes';
import { CheckboxProps as DSCheckboxProps } from '@skatteetaten/ds-forms/src/Checkbox/Checkbox.types';

export interface RadioProps
  extends Omit<DSRadioProps, 'children' | 'description'> {
  children: StringishChildren;
  description?: ReactNode;
}

export const Radio = forwardRef<HTMLInputElement, RadioProps>(
  ({ children, description, ...props }: RadioProps, ref) => {
    return (
      <DSRadioGroup.Radio
        ref={ref}
        description={description as DSCheckboxProps['description']}
        {...props}
      >
        {children as DSRadioProps['children']}
      </DSRadioGroup.Radio>
    );
  },
);

Radio.displayName = 'SkattekalkulatorRadio';
