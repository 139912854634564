import { useEffect, useState } from 'react';

export const TABLET_START_WIDTH = 481;
export const DESKTOP_START_WIDTH = 1172;

/**
 * Hook som returnerer størrelsen på vindu, samt klassifisering av mediastørrelse.
 * Mediastørrelse er true kun for det relevantet intervallet. F.eks 800px vil gi
 * { mobile: false, tablet: true, desktop: false }
 */

const getWindowSize = () => ({
  width: window?.innerWidth,
  height: window?.innerHeight,
});

export function useMediaQuery() {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    if (!window) {
      return false;
    }

    function handleResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return mediaSizeFromWindowSize(windowSize);
}

export function mediaSizeFromWindowSize(windowSize) {
  return {
    width: windowSize.width,
    height: windowSize.height,

    mobile: windowSize.width < TABLET_START_WIDTH,
    tablet:
      windowSize.width >= TABLET_START_WIDTH &&
      windowSize.width < DESKTOP_START_WIDTH,
    desktop: windowSize.width >= DESKTOP_START_WIDTH,
  };
}
