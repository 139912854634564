import { get, has } from 'lodash';

import style from './FlerFeltVisning.module.scss';
import { FormattedHTMLMessageWithFallbackToUgly } from './FormattedHTMLMessage';
import { Infoboks } from './infobokser/Infoboks';
import { mapObjectValues } from '../../utils/object';
import { medReferanse } from '../../utils/visningsDataDefinisjonUtil';
import { useTransformerFelt } from '../../utils/transformerHooks';
import { useBetingelseHook } from '../../hooks/useBetingelseHook';
import { useInntektsaar } from '../../hooks/useInntektsaar';

export const FlerFeltVisning = ({
  feltdefinisjon,
  forekomstData = {},
  kortdefinisjon = {},
  parentForekomstData = {},
}) => {
  const { inntektsaar } = useInntektsaar();
  const transformerFelt = useTransformerFelt();
  const { skalFeltSkjules } = useBetingelseHook();

  if (
    skalFeltSkjules(feltdefinisjon, {
      ...parentForekomstData,
      ...forekomstData,
    })
  ) {
    return null;
  }

  const feltverdi = (feltreferanse) => {
    const feltDefinisjon = kortdefinisjon.barnliste.find(
      medReferanse(feltreferanse),
    );
    const harTransformer = (feltdef) => has(feltdef, 'transformer');

    return harTransformer(feltDefinisjon)
      ? transformerFelt(feltDefinisjon, get(forekomstData, feltreferanse))
      : get(forekomstData, feltreferanse);
  };

  const flerFeltData = mapObjectValues(
    feltdefinisjon.feltreferanser,
    feltverdi,
  );

  const harVerdi = Object.values(flerFeltData).some(
    (value) => value !== undefined && value !== '',
  );

  if (feltdefinisjon.feltreferanser && !harVerdi) {
    return null;
  }

  const tekstId = !feltdefinisjon.tekstId
    ? `transformer.${feltdefinisjon.id}`
    : feltdefinisjon.tekstId.startsWith('transformer')
    ? feltdefinisjon.tekstId
    : `transformer.${feltdefinisjon.tekstId}`;

  switch (feltdefinisjon.visningstype) {
    case 'infoboks':
      return (
        <div
          style={{ marginBottom: '16px', marginTop: '5px' }}
          className={style.infoTekst}
        >
          <Infoboks type={Infoboks.Type.info}>
            <FormattedHTMLMessageWithFallbackToUgly
              id={tekstId}
              values={{ inntektsaar, ...flerFeltData }}
              tagName="div"
            />
          </Infoboks>
        </div>
      );
    case 'tekstblokk':
      return (
        <div style={{ marginBottom: '16px' }} className={style.infoTekst}>
          <FormattedHTMLMessageWithFallbackToUgly
            id={tekstId}
            values={{ inntektsaar, ...flerFeltData }}
            tagName="div"
          />
        </div>
      );
    case 'renTekst':
      return (
        <FormattedHTMLMessageWithFallbackToUgly
          id={tekstId}
          values={{ inntektsaar, ...flerFeltData }}
          tagName="div"
        />
      );
    default:
      return null;
  }
};
