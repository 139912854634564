import { call, delay, put, select } from 'redux-saga/effects';

import {
  beregnDialog,
  dialogEndreOk,
  dialogNyOk,
  lukkDialog,
} from '../actions/dialog';
import { startLoading, stopLoading } from '../reducers/spinner.action';
import {
  getDataIDialog,
  getKortdefinisjonIDialog,
  getSynligeEgenskaperIDialog,
  getVisningsdata,
} from '../reducers';
import {
  erBeregnetFelt,
  erFlerFeltVisning,
  erLesModus,
} from '../utils/visningsDataDefinisjonUtil';
import { feilFraErrorOgLokasjon } from '../utils/feil';
import { systemfeil } from '../reducers/feil.action';
import { beregn } from '../actions/skattemelding';
import { skalFeltSkjules } from '../utils/skalSkjules';

export function* endreDialogSaga() {
  yield call(nyEndreDialogSaga, dialogEndreOk);
}

export function* lagreDialogSaga() {
  yield call(nyEndreDialogSaga, dialogNyOk);
}

export function* nyEndreDialogSaga(suksessActionFn) {
  const suksessAction = suksessActionFn();
  try {
    yield put(suksessAction);
  } catch (err) {
    const feillokasjon = 'FEIL_' + suksessAction.type;
    const feil = feilFraErrorOgLokasjon(err, feillokasjon);
    yield put(systemfeil(feil));
  }
}

export function* dialogLagretSaga() {
  yield put(startLoading());
  yield put(lukkDialog());
  yield put(beregn());
  yield put(stopLoading());
}

export function* slettKortViaDialogSaga() {
  yield put(startLoading());
  yield put(beregn());
  yield put(stopLoading());
}

export function* beregnDialogVedFeltEndringSaga() {
  const kortdefinisjon = yield select(getKortdefinisjonIDialog);
  const dialogData = yield select(getDataIDialog);
  const visningsdata = yield select(getVisningsdata);
  const synligeEgenskaper = yield select(getSynligeEgenskaperIDialog);

  // Får å hindre beregn ved både onBlur og lagring av tabellrad mens et felt er aktivt
  yield delay(100);

  // eslint-disable-next-line no-shadow
  const inneholderSynligBeregnetFelt = (kortdefinisjon) =>
    kortdefinisjon.barnliste.some((barn) => harSynligBeregnetFelt(barn));

  const harSynligBeregnetFelt = (barn) => {
    if (
      erBeregnetFelt(barn) &&
      erLesModus(barn) &&
      !skalFeltSkjules(barn, dialogData, visningsdata)
    ) {
      return true;
    }
    if (barn.belopsfelt && harSynligBeregnetFelt(barn.belopsfelt)) {
      return true;
    }
    if (barn.barnliste) {
      // eslint-disable-next-line no-shadow
      return barn.barnliste.some((barn) => harSynligBeregnetFelt(barn));
    }
    return false;
  };

  /** Sjekker om det finnes infobokser (FlerFeltVisning) som inneholder beregnede verdier fra kortet */
  // eslint-disable-next-line no-shadow
  const inneholderAndreReferanserTilBeregnedeFelter = (kortdefinisjon) => {
    if (!kortdefinisjon.barnliste) {
      return false;
    }
    const kreverReberegningVedEndringIDialog = kortdefinisjon.barnliste
      .filter(erFlerFeltVisning)
      .some(
        (feltdefinisjon) => feltdefinisjon.kreverReberegningVedEndringIDialog,
      );

    if (!kreverReberegningVedEndringIDialog) {
      return false;
    }

    const referanserTilBeregnedeFelter = kortdefinisjon.barnliste
      .filter(erFlerFeltVisning)
      .flatMap((feltdefinisjon) =>
        Object.values(feltdefinisjon.feltreferanser),
      );

    return (
      !!referanserTilBeregnedeFelter &&
      kortdefinisjon.barnliste
        .filter((feltdefinisjon) => feltdefinisjon.beregnetFelt)
        .some((feltdefinisjon) =>
          referanserTilBeregnedeFelter.includes(feltdefinisjon.referanse),
        )
    );
  };

  if (
    inneholderSynligBeregnetFelt(kortdefinisjon) ||
    inneholderAndreReferanserTilBeregnedeFelter(kortdefinisjon) ||
    synligeEgenskaper?.valutakode === true
  ) {
    yield put(beregnDialog());
  }
}
