import { useIntl } from 'react-intl';
import { visningslogikk } from '@skatteetaten/visningsdefinisjoner-og-tekster';

const { transformer } = visningslogikk;

export const useTransformerFelt = () => {
  const intl = useIntl();

  return (felt, verdi, options = {}) =>
    transformerFelt({
      felt,
      verdi,
      options,
      intl,
    });
};

export const transformerFelt = ({ felt, verdi, options = {}, intl }) => {
  let extraOptions = {};

  return transformer.transformerFelt(felt, verdi, {
    ...options,
    ...extraOptions,
    intl,
  });
};

export const transformerTall = (
  verdi,
  intl,
  { minimumFractionDigits, maximumFractionDigits },
) => {
  return transformer.transformerTall(verdi, intl.locale, {
    minimumFractionDigits,
    maximumFractionDigits,
  });
};
