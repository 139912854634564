import { useEffect, useRef } from 'react';
import { scrollToPosition } from '../utils/scroll';
import { getPos } from '../utils/scrollUtils';
import { useDispatch } from 'react-redux';
import { nullstillFokus } from './fokus.reducer';

export const useFocusRef = (skalFokusere: boolean) => {
  const focusRef = useRef<HTMLElement>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (skalFokusere && focusRef.current) {
      scrollToPosition(getPos(focusRef.current));
      focusRef.current?.focus();
      dispatch(nullstillFokus());
    }
  }, [dispatch, skalFokusere]);

  return focusRef;
};
