export const SCROLL_TIL_TOPPEN = 'SCROLL_TIL_TOPPEN';
export const SCROLLET_TIL_SEKSJON = 'SCROLLET_TIL_SEKSJON';
export const GAA_TIL_SKATTEMELDING = 'GAA_TIL_SKATTEMELDING';
export const GAA_TIL_SKATTEMELDING_SUKSESS = 'GAA_TIL_SKATTEMELDING_SUKSESS';
export const GAA_TIL_EKTEFELLESIDE = 'GAA_TIL_EKTEFELLESIDE';
export const GAA_TIL_EKTEFELLESIDE_SUKSESS = 'GAA_TIL_EKTEFELLESIDE_SUKSESS';

export const scrollTilToppen = () => ({
  type: SCROLL_TIL_TOPPEN,
});

export const scrolletTilSeksjon = (seksjonId) => ({
  type: SCROLLET_TIL_SEKSJON,
  seksjonId,
});

export const gaaTilSkattemeldingSide = () => ({
  type: GAA_TIL_SKATTEMELDING,
});

export const gaaTilSkattemeldingSideSuksess = () => ({
  type: GAA_TIL_SKATTEMELDING_SUKSESS,
});
export const gaaTilEktefelleSide = () => ({
  type: GAA_TIL_EKTEFELLESIDE,
});

export const gaaTilEktefelleSideSuksess = () => ({
  type: GAA_TIL_EKTEFELLESIDE_SUKSESS,
});
