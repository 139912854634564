import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import Button from '@skatteetaten/frontend-components/Button';
import style from './DialogKnapperad.module.css';
import { lukkDialog } from '../../../../actions/dialog';

export const DialogAvbryt = ({
  hoved = false,
  aksjon = null,
  tekstid = 'knapp.dialog.avbryt',
  lukkEtterAksjon = true,
  icon,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const aksjon_ = useMemo(() => {
    return (e) => {
      aksjon && aksjon(e);
      lukkEtterAksjon && dispatch(lukkDialog());
    };
  }, [dispatch, aksjon, lukkEtterAksjon]);

  return (
    <Button
      buttonStyle={hoved ? 'primary' : 'secondary'}
      onClick={aksjon_}
      icon={icon}
      className={style.luftMellomKnapper}
    >
      {intl.formatMessage({ id: tekstid })}
    </Button>
  );
};
