import { useIntl } from 'react-intl';
import { OverskriftLinje } from '../OverskriftLinje';
import { GjeldBlokk } from './GjeldBlokk';
import { GjeldsreduksjonLinje } from './GjeldsreduksjonLinje';

export const GjeldsreduksjonOppsummering = ({
  skvisData,
  harEktefelle,
  svalbard,
}) => {
  const intl = useIntl();

  const getUtregning = (
    sumBeggesGjeld,
    sumVerdsettingsrabatt,
    samletFormue,
    // eslint-disable-next-line no-shadow
    harEktefelle,
  ) => {
    const overBroekTekst = harEktefelle
      ? 'ledetekst.gjeldsreduksjon.utregning.overBroek'
      : 'ledetekst.gjeldsreduksjon.utregning.overBroek.ugift';
    const underBroekTekst = harEktefelle
      ? 'ledetekst.gjeldsreduksjon.utregning.underBroek'
      : 'ledetekst.gjeldsreduksjon.utregning.underBroek.ugift';
    return (
      <>
        {intl.formatMessage(
          { id: overBroekTekst },
          {
            gjeld: intl.formatNumber(sumBeggesGjeld),
            verdsettingsrabatt: intl.formatNumber(sumVerdsettingsrabatt),
          },
        )}
        <br />
        {intl.formatMessage(
          { id: underBroekTekst },
          {
            formue: intl.formatNumber(samletFormue),
          },
        )}
      </>
    );
  };

  const {
    sumVerdsettingsrabattSomGirGjeldsreduksjon: sumVerdsettingsrabatt = 0,
    sumGjeldsreduksjonFoerKorrigering: sumGjeldsreduksjonFoerOeverfoering,
    gjeldsreduksjonSomOverfoeresFraEktefelle: overfoertFraEktefelle,
    gjeldsreduksjonSomOverfoeresTilEktefelle: overfoertTilEktefelle,
    sumGjeldsreduksjon,
  } = skvisData;

  const sumBeggesGjeld = svalbard
    ? skvisData?.samletGjeld
    : skvisData?.ektefellenesSamledeGjeldFoerGjeldsreduksjon;

  const samletFormue = svalbard
    ? skvisData?.samletVerdiFoerVerdsettingsrabattForAlleFormuesobjekter
    : skvisData?.ektefellenesSamledeVerdiFoerVerdsettingsrabattForAlleFormuesobjekter;

  return (
    <div>
      <GjeldBlokk skvisData={skvisData} sumBeggesGjeld={sumBeggesGjeld} />
      <OverskriftLinje
        id={'ledetekst.gjeldsreduksjon.utregningAvGjeldsreduksjon'}
      />
      <GjeldsreduksjonLinje
        tekst={getUtregning(
          sumBeggesGjeld,
          sumVerdsettingsrabatt,
          samletFormue,
          harEktefelle,
        )}
        value={sumGjeldsreduksjonFoerOeverfoering}
      />
      <GjeldsreduksjonLinje
        tekst={intl.formatMessage({
          id: 'ledetekst.gjeldsreduksjon.overfoertFraEktefelle',
        })}
        value={overfoertFraEktefelle}
      />
      <GjeldsreduksjonLinje
        tekst={intl.formatMessage({
          id: 'ledetekst.gjeldsreduksjon.overfoertTilEktefelle',
        })}
        value={overfoertTilEktefelle}
      />
      <GjeldsreduksjonLinje
        tekst={intl.formatMessage({
          id: 'ledetekst.gjeldsreduksjon.reduksjonAvGjeld',
        })}
        value={sumGjeldsreduksjon}
        uthevetSkrift={true}
      />
    </div>
  );
};
