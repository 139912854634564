import joinUrl from '../../src/utils/join-url';
import request from '../utils/request';
import { jsonHeaderMedBody, standardJsonPost } from '../utils/fetch';

const API_ENDPOINT = '/api/skattemelding-core/';
const SKATTEMELDING_BEREGN = 'skatteberegner';

export const beregnForSkattemelding = (body) => {
  const url = joinUrl(API_ENDPOINT, SKATTEMELDING_BEREGN);
  return standardJsonPost(url, body);
};

export const loggTilNode = (messages, onError) => {
  const url = joinUrl(API_ENDPOINT, `/logger/`);
  const method = 'POST';
  request(url, {
    method,
    headers: {
      ...jsonHeaderMedBody,
      // ...sesjonHeader(method),
      // ...altinnHeader(),
    },
    credentials: 'include',
    body: JSON.stringify({
      messages: messages,
    }),
  }).catch((err) => {
    const errStr = () =>
      err.response
        ? `${err.response.status} - ${err.response.statusText}`
        : err;
    onError(errStr);
  });
};

export const loggFeilTilNode = (feil) => {
  const url = joinUrl(
    API_ENDPOINT,
    feil.logAsInfo ? '/logger' : '/logger/error',
  );
  const Korrelasjonsid = feil.kontekst?.korrelasjonsid;

  return standardJsonPost(url, feil, { headers: { Korrelasjonsid } });
};
