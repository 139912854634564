import { useIntl } from 'react-intl';
import { language } from '@skatteetaten/visningsdefinisjoner-og-tekster';
import { OverskriftLinje } from '../OverskriftLinje';
import { GjeldsreduksjonLinje } from './GjeldsreduksjonLinje';

export const GjeldBlokk = ({ skvisData, sumBeggesGjeld }) => {
  const intl = useIntl();

  const {
    samletGjeld: dinGjeld,
    ektefellesSamledeGjeldFoerGjeldsreduksjon: ektefellesGjeld,
  } = skvisData;

  if (dinGjeld > 0) {
    return (
      <>
        <OverskriftLinje
          id={language.skvisOverskriftId(
            'summertSkattegrunnlagForVisning.samletGjeld',
          )}
        />
        <GjeldsreduksjonLinje
          tekst={intl.formatMessage({
            id: 'ledetekst.gjeldsreduksjon.dinGjeld',
          })}
          value={dinGjeld}
        />
        {ektefellesGjeld > 0 && (
          <>
            <GjeldsreduksjonLinje
              tekst={intl.formatMessage({
                id: 'ledetekst.gjeldsreduksjon.ektefellesGjeld',
              })}
              value={ektefellesGjeld}
            />
            <GjeldsreduksjonLinje
              tekst={intl.formatMessage({
                id: 'ledetekst.gjeldsreduksjon.sumBeggesGjeld',
              })}
              value={sumBeggesGjeld}
              uthevetSkrift={true}
            />
          </>
        )}
      </>
    );
  }
  return null;
};
