import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const fullLocaleToLang = (lang) => {
  switch (lang) {
    case 'en_GB':
      return 'en';
    case 'nn_NO':
      return 'nn';
    case 'se_NO':
      return 'se';
    default:
      return 'nb';
  }
};

export const langToFulllocale = (lang) => {
  switch (lang) {
    case 'en':
      return 'en_GB';
    case 'nn':
      return 'nn_NO';
    case 'se':
      return 'se_NO';
    default:
      return 'nb_NO';
  }
};

export const getParameterByName = (name) => {
  const url = new URL(window.location.href);
  return url.searchParams.get(name);
};

export const getLocaleFromCookie = () => {
  const locale = cookies.get('spraak');

  return (
    (locale && fullLocaleToLang(locale)) ||
    getParameterByName('language') ||
    'nb'
  );
};

const matchTopDomainFromHost = /[^.]+\.[^.]+$/;

export const getTopDomain = (host = document.location.host) => {
  const match = host.match(matchTopDomainFromHost);

  return match && match.length ? match[0] : undefined;
};
