import {
  ChangeEvent,
  forwardRef,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  ForwardedRef,
  MutableRefObject,
  ReactNode,
} from 'react';
import {
  TextField as DSTextField,
  TextFieldProps as DSTextFieldProps,
  TextboxRefHandle,
} from '@skatteetaten/ds-forms';

import style from './TextField.module.css';
import { Icon } from './Icon';
import classnames from 'classnames';

export type TextFieldRefHandle = TextboxRefHandle;

type TextFieldClassNames = DSTextFieldProps['classNames'] & {
  wrapper?: string;
};

export interface TextFieldProps
  extends Omit<DSTextFieldProps, 'onChange' | 'helpText'> {
  onChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    value: string,
  ) => void;
  iconRightSvgPath?: ReactElement;
  autoFocus?: boolean;
  helpText?: ReactNode;
  classNames?: TextFieldClassNames;
}

const isRefObj = (
  ref: ForwardedRef<TextFieldRefHandle>,
): ref is MutableRefObject<TextFieldRefHandle | null> =>
  !!ref && typeof ref === 'object';

export const TextField = forwardRef<TextFieldRefHandle, TextFieldProps>(
  (
    {
      autoFocus,
      iconRightSvgPath,
      onChange,
      required,
      showRequiredMark,
      errorMessage,
      hasError = !!errorMessage,
      as = 'input',
      autosize,
      rows,
      pattern,
      thousandSeparator,
      helpText,
      classNames,
      ...props
    },
    ref,
  ) => {
    const localRef = useRef<TextFieldRefHandle>(null);

    useEffect(() => {
      if (autoFocus) {
        if (isRefObj(ref)) {
          ref.current?.textboxRef.current?.focus();
        } else {
          localRef.current?.textboxRef.current?.focus();
        }
      }
    }, [autoFocus, ref]);

    const requiredProps = required
      ? {
          required,
          showRequiredMark,
        }
      : {};
    const errorProps = errorMessage
      ? {
          errorMessage,
          hasError,
        }
      : {};
    const typeProps =
      as === 'input'
        ? {
            as,
            thousandSeparator,
            pattern,
          }
        : {
            as,
            autosize,
            rows,
          };

    const dsOnChange = useCallback(
      (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        onChange?.(event, event.target.value),
      [onChange],
    );

    const { wrapper, ...dsClassNames } = classNames || {};

    return (
      <div className={classnames(wrapper, style.wrapper)}>
        <DSTextField
          ref={ref || localRef}
          onChange={dsOnChange}
          helpText={helpText as DSTextFieldProps['helpText']}
          classNames={dsClassNames}
          {...requiredProps}
          {...errorProps}
          {...typeProps}
          {...props}
        />
        {iconRightSvgPath && (
          <Icon svgPath={iconRightSvgPath} className={style.iconRight} />
        )}
      </div>
    );
  },
);

TextField.displayName = 'SkattekalkulatorTextField';
