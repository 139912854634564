import { get } from 'lodash';
import { Kortfelt } from './Kortfelt';
import { KortfeltMedEgenskaper } from './KortfeltMedEgenskaper';
import {
  FELT,
  FELT_MED_EGENSKAPER,
} from '../../../../utils/visningsDataDefinisjonUtil';
import { not } from '../../../../utils/visningsdataUtil';
import { useBetingelseHook } from '../../../../hooks/useBetingelseHook';

export const DynamiskFelt = ({
  dynamiskfelt,
  feltReferanse,
  kortdata,
  feltdata,
}) => {
  const {
    skalBeholdeVerdi,
    skalFeltDisables,
    skalFeltSkjules,
    skalSkjuleFeltIKort,
  } = useBetingelseHook();
  const { barnliste = [] } = dynamiskfelt;

  const feltOgKortData = { ...kortdata, ...feltdata };

  const _skalSkjuleFeltIKort = (felt) =>
    skalSkjuleFeltIKort(felt, feltOgKortData);

  return barnliste.filter(not(_skalSkjuleFeltIKort)).map((barn) => {
    const hidden =
      (skalFeltSkjules(barn, feltOgKortData) ||
        skalFeltDisables(barn, feltOgKortData)) &&
      !skalBeholdeVerdi(barn, feltOgKortData);

    switch (barn.type) {
      case FELT:
        return (
          <Kortfelt
            key={barn.id}
            hidden={hidden}
            feltdefinisjon={barn}
            data={get(feltdata, barn.referanse)}
          />
        );
      case FELT_MED_EGENSKAPER:
        return (
          <KortfeltMedEgenskaper
            key={barn.id}
            data={kortdata}
            feltReferanse={feltReferanse}
            {...barn}
          />
        );
      default:
        return null;
    }
  });
};
