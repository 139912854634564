import { useEffect, useState } from 'react';
import { useMediaQuery } from './mediaQueryHook';

const hentDialogMinWidth = (mobile, tablet) => {
  if (mobile) {
    return `${window.innerWidth - 30}px`;
  } else if (tablet || window.innerWidth < 600) {
    return '480px';
  }
  return '600px';
};

export const useWindowWidth = () => {
  const { mobile, tablet } = useMediaQuery();
  const [dialogMinWidth, setDialogMinWidth] = useState(
    hentDialogMinWidth(mobile, tablet),
  );

  useEffect(() => {
    const handleResize = () => {
      setDialogMinWidth(hentDialogMinWidth(mobile, tablet));
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [mobile, tablet]);

  return dialogMinWidth;
};
