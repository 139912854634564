import fp from 'lodash/fp';
import unionBy from 'lodash/unionBy';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { visningslogikk } from '@skatteetaten/visningsdefinisjoner-og-tekster';
import {
  DIALOG_ENDRE_OK,
  DIALOG_NY_OK,
  DIALOG_SLETT_KORT,
} from '../actions/dialog';
import { getData, getKortdefinisjon } from './dialog';
import { toemInaktiveFeltverdier } from '../utils/deaktiverteFeltUtils';
import { fiksBolskeVerdier } from '../utils/visningsdataUtil';
import { API_BEREGN_SUKSESS } from '../actions/skattemelding';
import { SKATTEPLIKT_OPPDATERT } from './skatteplikt';
import { utledFeltverdierForPensjon } from '../utils/pensjon';
import { NYESTE_INNTEKTSAAR } from '../constants/inntektsaar';

const { visningsdefinisjonUtils, kort: kortUtils } = visningslogikk;

export const getForekomster = (visningsdata, referanse) =>
  get(visningsdata, referanse);

export const oppdaterVisningsdata = (
  state,
  kortdefinisjon,
  forekomstIDialog,
) => {
  if (kortdefinisjon.referanse.startsWith('grupper.')) {
    return state;
  }

  const fjernTommeDynamiskeFelt = (forekomst) =>
    kortUtils.fjernTommeDynamiskeFelt(kortdefinisjon, forekomst);
  const _toemInaktiveFeltverdier = (forekomst) =>
    toemInaktiveFeltverdier(kortdefinisjon, forekomst, {}, false, state);

  // TODO Legger på kommune grunnet at beregningene i dette kortet gikk i ball uten kommunenummer.
  //  Har laget en sak for å se nærmere på rotårsaken til problemet: SH-20766
  const leggTilKommuneFritidsbaatMedSalgsverdiOverSalgsverdigrense = (
    forekomst,
  ) => {
    if (kortdefinisjon.id === 'fritidsbaatMedSalgsverdiOverSalgsverdigrense') {
      return {
        ...forekomst,
        kommunenummer: '0301',
      };
    }
    return forekomst;
  };

  forekomstIDialog = fp.flow(
    fjernTommeDynamiskeFelt,
    _toemInaktiveFeltverdier,
    // TODO kan vi klare oss uten denne?
    fiksBolskeVerdier,
    leggTilKommuneFritidsbaatMedSalgsverdiOverSalgsverdigrense,
  )(forekomstIDialog);

  // TODO dette er mongo...
  delete forekomstIDialog['skatteplikt'];

  return {
    ...state,
    [kortdefinisjon.referanse]: unionBy(
      [forekomstIDialog],
      state[kortdefinisjon.referanse],
      'id',
    ),
  };
};

export const lagreKort = (state, dialog) => {
  const forekomstIDialog = getData(dialog);
  const kortdefinisjon = getKortdefinisjon(dialog);

  return oppdaterVisningsdata(state, kortdefinisjon, forekomstIDialog);
};

export const slettKort = (state, dialog) => {
  const forekomstIDialog = getData(dialog);
  const kortdefinisjon = getKortdefinisjon(dialog);

  return {
    ...state,
    [kortdefinisjon.referanse]: state[kortdefinisjon.referanse].filter(
      (forekomst) => forekomst.id !== forekomstIDialog.id,
    ),
  };
};

const defaultArbeidsgiver = [
  {
    id: 'arbeidsgiver-id-1',
    organisasjonsnavn: 'arbeidsgiver',
    samledeYtelserFraArbeidsgiverPerBehandlingsart: [
      {
        id: 'loennstype-id-1',
        behandlingsart: 'LONN',
        beloep: { beloepIValuta: '0' },
      },
    ],
  },
];

const defaultKonto = [
  {
    id: 'konto-id-1',
    bankensNavn: 'bankensNavn',
    kontonummer: 'kontonr',
    innskudd: { beloepIValuta: '0' },
  },
];

const defaultState = {
  inntektsaar: NYESTE_INNTEKTSAAR,
  arbeidsgiver: defaultArbeidsgiver,
  konto: defaultKonto,
};

const defaultStateKplModus = {
  inntektsaar: NYESTE_INNTEKTSAAR,
  arbeidsgiver: defaultArbeidsgiver,
};

export const harVisningsdata = (vdata) =>
  Object.keys(vdata).filter((key) => key !== 'inntektsaar').length > 0;

export const getBeregnetVisningsdata = (
  beregningsresultat,
  visningsdefinisjon,
) => {
  if (isEmpty(beregningsresultat)) {
    return undefined;
  }
  let beregnetVisningsdata = beregningsresultat.visningsdata;

  if (beregnetVisningsdata?.pensjonsinntektFraArbeidsforhold) {
    const pensjonskort = visningsdefinisjonUtils.finnKorttypedefinisjon(
      'pensjonsinntektFraArbeidsforhold',
      visningsdefinisjon,
    );
    beregnetVisningsdata.pensjonsinntektFraArbeidsforhold =
      beregnetVisningsdata.pensjonsinntektFraArbeidsforhold.map((forekomst) =>
        utledFeltverdierForPensjon(pensjonskort, forekomst),
      );
  }
  return beregnetVisningsdata;
};

export default function visningsdataReducer(
  state = defaultState,
  action = {},
  visningsdefinisjon = {},
  gammelDialog = {},
) {
  switch (action.type) {
    case DIALOG_ENDRE_OK:
    case DIALOG_NY_OK: {
      return lagreKort(state, gammelDialog);
    }

    case DIALOG_SLETT_KORT: {
      return slettKort(state, gammelDialog);
    }

    case API_BEREGN_SUKSESS: {
      const {
        skattemeldingResultat: { beregningsresultat },
      } = action;

      return getBeregnetVisningsdata(beregningsresultat, visningsdefinisjon);
    }

    case SKATTEPLIKT_OPPDATERT: {
      const startState = action.opplysninger.vilBeregneKildeskattPaaLoenn
        ? defaultStateKplModus
        : defaultState;

      return {
        ...startState,
        inntektsaar: action.opplysninger.skatteberegningAar,
      };
    }

    default:
      return state;
  }
}
