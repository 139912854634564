import { useEffect, useMemo } from 'react';
import { get } from 'lodash';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { visningslogikk } from '@skatteetaten/visningsdefinisjoner-og-tekster';
import Grid from '@skatteetaten/frontend-components/Grid';
import IconButton from '@skatteetaten/frontend-components/IconButton';
import style from './TabellradRedigering.module.scss';
import { FeltIDialog } from '../FeltIDialog';
import { FeltMedEgenskaperIDialog } from '../FeltMedEgenskaperIDialog';
import { FeltIDialogWrapper } from '../FeltIDialogWrapper';
import { VisningsfeltIDialog } from '../VisningsfeltIDialog';
import {
  getAktivVisningsdata,
  getDefinisjonForId,
  getTemaFraKorttype,
  getUnderforekomstIDialog,
} from '../../../../../reducers';
import { lagFeltreferanseIDialog } from '../../../../../utils/visningsdataUtil';
import {
  BELOEPSFELT,
  medFelt,
  medUnderforekomst,
  UNDERFOREKOMST,
} from '../../../../../utils/visningsDataDefinisjonUtil';
import {
  avbrytRedigeringAvUnderforekomstFraListevisning,
  lagreUnderforekomstFraListevisning,
  redigerUnderforekomstFraListevisning,
  slettUnderforekomstFraListevisning,
} from '../../../../../actions/dialog';
import { lagFeltSti } from '../../../../../utils/visningsdefinisjonUtil';
import {
  erDynamiskfelt,
  erDynamiskgruppe,
  erFeltMedEgenskaper,
  FELT,
  FELT_MED_EGENSKAPER,
  KORTTYPE,
  REPETERENDE_FELTGRUPPE,
  TEMA,
} from '../../../../../utils/visningsDataDefinisjonUtil';
import { getForekomstForDialog } from '../../../../../reducers/';
import { mapObject } from '../../../../../utils/object';
import {
  fjernFlereFeilIDialog,
  overskrivFeilIDialog,
} from '../../../../../reducers/feil.action';
import { FocusOnMount } from '../../../util/FocusOnMount';
import { ScrollIntoViewOnMount } from '../../../util/ScrollIntoViewOnMount';
import { useScrollTilFoersteFeil } from '../../../util/useScrollTilFoersteFeil';
import { useBetingelseHook } from '../../../../../hooks/useBetingelseHook';
import { useValideringsstate } from '../../../../../hooks/useValideringsstate';

const { valider } = visningslogikk;

export function lagFeltReferanser(
  feltDefinisjoner,
  definisjonsSti,
  dataStiForRad,
) {
  return feltDefinisjoner
    .flatMap((feltDefinisjon) => {
      if (erDynamiskgruppe(feltDefinisjon)) {
        return feltDefinisjon.barnliste
          .filter(erDynamiskfelt)
          .flatMap((dynamiskfelt) => dynamiskfelt.barnliste);
      }
      return feltDefinisjon;
    })
    .map((feltDefinisjon) => {
      const definisjon = erFeltMedEgenskaper(feltDefinisjon)
        ? feltDefinisjon[BELOEPSFELT]
        : feltDefinisjon;
      return lagFeltSti(
        definisjonsSti[REPETERENDE_FELTGRUPPE],
        definisjon,
        dataStiForRad[UNDERFOREKOMST],
      );
    });
}

export const feilForFelt = (tabellFeltReferanser, feilmeldinger = []) =>
  mapObject(feilmeldinger, (entries) =>
    entries.filter(([key]) =>
      tabellFeltReferanser.some(
        (tabellFeltReferanse) => key.indexOf(tabellFeltReferanse) > -1,
      ),
    ),
  );

export const TabellradRedigering = ({
  definisjonsSti,
  datastiTilFeltgruppe,
  radData,
  kollapsRad,
  erNyUnderforekomst,
}) => {
  const intl = useIntl();
  const { scrollTilForesteFeilRef, scrollTilFoersteFeil } =
    useScrollTilFoersteFeil();
  const dataSti = useMemo(
    () => medUnderforekomst(datastiTilFeltgruppe, radData.id),
    [datastiTilFeltgruppe, radData.id],
  );

  const underforekomst = useSelector(getUnderforekomstIDialog(dataSti));

  const valideringsstate = useValideringsstate();
  const korttypeDefinisjon = useSelector(
    getDefinisjonForId(definisjonsSti[KORTTYPE]),
  );
  const forekomstForDialog = useSelector(getForekomstForDialog);
  const kortgruppeFelt = useSelector(
    getDefinisjonForId(definisjonsSti[REPETERENDE_FELTGRUPPE]),
  );
  const tema = useSelector(getTemaFraKorttype(korttypeDefinisjon));
  const aktivVisningsdata = useSelector(getAktivVisningsdata);

  const dispatch = useDispatch();
  const { skalFeltSkjules, skalFeltDisables } = useBetingelseHook();

  const feltDefinisjoner = kortgruppeFelt.barnliste
    .filter((felt) => felt.type === FELT || felt.type === FELT_MED_EGENSKAPER)
    .filter((felt) => !felt.skjulIDialog)
    .filter(
      (felt) =>
        !skalFeltSkjules(felt, { ...forekomstForDialog, ...underforekomst }),
    );
  const feltReferanser = lagFeltReferanser(
    feltDefinisjoner,
    definisjonsSti,
    dataSti,
  );

  const kanSletteRader = kortgruppeFelt.tabell.kanSletteRader;

  const dataStiForFelt = useMemo(() => {
    return Object.fromEntries(
      feltDefinisjoner.map(({ id, referanse }) => [
        id,
        medFelt(dataSti, referanse),
      ]),
    );
  }, [dataSti, feltDefinisjoner]);

  useEffect(() => {
    dispatch(redigerUnderforekomstFraListevisning(dataSti));
  }, [dispatch, dataSti]);

  const avbrytAction = () => {
    kollapsRad();
    dispatch(fjernFlereFeilIDialog(feltReferanser));
    dispatch(avbrytRedigeringAvUnderforekomstFraListevisning());
  };

  const lagreAction = () => {
    const feilmeldinger = valider.feilmeldingerForKortType(
      korttypeDefinisjon,
      forekomstForDialog,
      valideringsstate,
      forekomstForDialog,
      aktivVisningsdata,
    );

    const feilmeldingerForTabellfelt = feilForFelt(
      feltReferanser,
      feilmeldinger,
    );
    const feltITabellHarFeil = Object.values(feilmeldingerForTabellfelt).some(
      (feilmeldingliste) => feilmeldingliste.length > 0,
    );

    dispatch(overskrivFeilIDialog(feilmeldinger));
    if (feltITabellHarFeil) {
      scrollTilFoersteFeil();
    } else {
      kollapsRad();
      dispatch(lagreUnderforekomstFraListevisning());
    }
  };

  const slettAction = () => {
    kollapsRad();
    dispatch(fjernFlereFeilIDialog(feltReferanser));
    dispatch(slettUnderforekomstFraListevisning(dataSti));
  };

  const felt = (feltDefinisjon) => {
    const dataStiTilFelt = dataStiForFelt[feltDefinisjon.id];
    const feltReferanse = lagFeltreferanseIDialog(
      forekomstForDialog,
      dataStiTilFelt,
    );
    const feltData = get(underforekomst, feltDefinisjon.referanse);

    if (feltDefinisjon.erTittelHvisLesmodus) {
      return <VisningsfeltIDialog felt={feltDefinisjon} data={feltData} />;
    }

    switch (feltDefinisjon.type) {
      case FELT:
        const feltErDisabled = skalFeltDisables(feltDefinisjon, {
          ...forekomstForDialog,
          ...underforekomst,
        });

        return (
          <Grid.Col lg={12} key={feltDefinisjon.id}>
            <FeltIDialogWrapper key={feltDefinisjon.id} temaId={tema?.id}>
              <FeltIDialog
                disabled={feltErDisabled}
                felt={feltDefinisjon}
                feltReferanse={feltReferanse}
                feltData={feltData}
                kortdefinisjon={korttypeDefinisjon}
                repeterendeFeltId={definisjonsSti[REPETERENDE_FELTGRUPPE]}
                data={underforekomst}
                forekomstId={underforekomst.id}
                placeholder={feltDefinisjon.placeholder}
                underforekomstId={underforekomst.id}
              />
            </FeltIDialogWrapper>
          </Grid.Col>
        );
      case FELT_MED_EGENSKAPER:
        return (
          <FeltMedEgenskaperIDialog
            key={feltDefinisjon.id}
            data={forekomstForDialog}
            feltReferanse={feltReferanse}
            kortdefinisjon={korttypeDefinisjon}
            felt={feltDefinisjon}
            temaId={definisjonsSti[TEMA]}
            underforekomst={underforekomst}
            repeterendeFeltId={definisjonsSti[REPETERENDE_FELTGRUPPE]}
          />
        );

      default:
        return false;
    }
  };
  return (
    <>
      <div ref={scrollTilForesteFeilRef} className={style.wrapperStyle}>
        <Grid>
          <Grid.Row>
            <FocusOnMount>
              <fieldset>{feltDefinisjoner.map(felt)}</fieldset>
            </FocusOnMount>
          </Grid.Row>
          <Grid.Row>
            <div className={style.blockCenterStyle}>
              <IconButton
                onClick={lagreAction}
                title={intl.formatMessage({
                  id: 'knapp.tabellredigering.lagre',
                })}
                ariaLabel={intl.formatMessage({
                  id: 'knapp.tabellredigering.lagre',
                })}
                type="button"
                circle
                icon="Check"
              />
              {'  '}
              {!erNyUnderforekomst && (
                <ScrollIntoViewOnMount>
                  <IconButton
                    onClick={avbrytAction}
                    ariaLabel={intl.formatMessage({
                      id: 'knapp.tabellredigering.angre',
                    })}
                    title={intl.formatMessage({
                      id: 'knapp.tabellredigering.angre',
                    })}
                    type="button"
                    circle
                    icon="Cancel"
                  />{' '}
                </ScrollIntoViewOnMount>
              )}
              {kanSletteRader && (
                <>
                  <IconButton
                    title={intl.formatMessage({
                      id: 'knapp.tabellredigering.slett',
                    })}
                    ariaLabel={intl.formatMessage({
                      id: 'knapp.tabellredigering.slett',
                    })}
                    onClick={slettAction}
                    type="button"
                    circle
                    icon="Delete"
                  />
                </>
              )}
            </div>
          </Grid.Row>
        </Grid>
      </div>
      <div className={style.overlay} />
    </>
  );
};
