import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { ListeIDialog } from '../utils/ListeIDialog';
import {
  leggTilEgenskaper,
  nyLeggTilEgenskaperDialog,
} from './kortOpplysninger.actions';
import { getDialog } from '../../../../reducers';
import {
  egenskaperSomErLagtTil,
  finnAlleEgenskaper,
} from './tilleggsopplysningerUtil';

export const ListeEgenskaper = ({ kortdefinisjon, data, beregnKplModus }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { synligeEgenskaper = {}, fraDialog } = useSelector(getDialog);

  const alleEgenskaper = finnAlleEgenskaper(kortdefinisjon, data).filter(
    (egenskap) =>
      !beregnKplModus || egenskap.referanse === 'trygdeEllerSkattemessigUnntak',
  );

  const egenskaperSomKanLeggesTil = alleEgenskaper.filter(
    egenskaperSomErLagtTil(synligeEgenskaper),
  );

  const klikkAksjon = (egenskap) =>
    // Hvis det bare gjenstår 1 valg, så sender vi ikke bruker til dialog for å velge flere egenskaper
    egenskaperSomKanLeggesTil?.length > 1
      ? dispatch(
          nyLeggTilEgenskaperDialog(
            egenskap,
            egenskaperSomKanLeggesTil,
            fraDialog,
          ),
        )
      : dispatch(leggTilEgenskaper([egenskap], fraDialog));

  let elementer = egenskaperSomKanLeggesTil.map((egenskap) => ({
    valgTekst: intl.formatMessage({ id: egenskap.id }),
    klikkAksjon: () => klikkAksjon(egenskap),
  }));

  const tittel = intl.formatMessage(
    { id: 'ledetekst.antall.egenskaper.treff' },
    {
      antall: elementer.length,
    },
  );

  return (
    <ListeIDialog
      tittel={tittel}
      elementer={elementer}
      ingenElementerTekst={intl.formatMessage({
        id: 'ledetekst.ingen.egenskaper',
      })}
    />
  );
};
