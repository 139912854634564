import style from './FeilmeldingHeader.module.scss';

export const FeilmeldingHeader = ({ overskrift, melding }) => (
  <header>
    <div>
      <div>
        <strong>{overskrift}</strong>
      </div>
      <div className={style.content}>{melding}</div>
    </div>
  </header>
);
