//Hvor i applikasjonen ble feilen håndtert -
export const FEIL_BEREGN_OG_KONTROLLER_API = 'FEIL_BEREGN_OG_KONTROLLER_API';
export const FEIL_API_HOOK = 'FEIL_API_HOOK';
export const TOPLEVEL_ERROR_BOUNDARY = 'TOPLEVEL_ERROR_BOUNDARY';
export const UHAANDTERT_FEIL = 'UHAANDTERT_FEIL';
export const SAGA_TOPLEVEL = 'SAGA_TOPLEVEL';
export const UNCAUGHT_IN_PROMISE = 'UNCAUGHT_IN_PROMISE';
export const FEILRAPPORTERING = 'FEILRAPPORTERING';
export const POLYFILL = 'POLYFILL';
export const API_BEREGN = 'API_BEREGN';

export const LASTER_ANALYTICS = 'LASTER_ANALYTICS';
export const ANALYTICS_OPERASJON = 'ANALYTICS_OPERASJON';
