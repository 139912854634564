import { useSelector } from 'react-redux';

import { getVisningsdefinisjon } from '../../../reducers';
import style from './Temaer.module.scss';
import { Tema } from './Tema';
import { LeggTilOpplysninger } from './LeggTilOpplysninger';
import { useKildeSkattPaaLoenn } from '../kildeskattePaaLoenn/useKildeskattPaaLoenn';

export const Temaer = () => {
  const temaer = useSelector(getVisningsdefinisjon);
  const { beregnKplModus, erBeregnKplOgTemaSkalSkjules } =
    useKildeSkattPaaLoenn();

  return (
    <div className={style.temaer}>
      {temaer
        .filter((tema) => !erBeregnKplOgTemaSkalSkjules(tema.id))
        .map((tema) => (
          <Tema key={tema?.id} tema={tema} />
        ))}
      {!beregnKplModus && <LeggTilOpplysninger />}
    </div>
  );
};
