import { NY_SOEKESTRENG, NYTT_SOEKERESULTAT } from '../actions/soek';
import { Soekemotor } from '../utils/soek/motor';
import { indekserVisningsdefinisjon } from '../utils/soek/indekserer';
import { INIT_VDOT_OG_TEKSTER_SUKSESS } from './visningsdefinisjon';

export const getResultat = (state) => state.resultat;
export const getSoekemotor = (state) => state.soek;
export const getSoekeStreng = (state) => state.soekeStreng;

const nyState = (visningsdefinisjon, tekster) => ({
  soek: new Soekemotor(
    indekserVisningsdefinisjon(visningsdefinisjon, (id) => tekster[id]),
  ),
  resultat: [],
  soekeStreng: '',
});

export default function soekReducer(state = [], action) {
  switch (action.type) {
    case NY_SOEKESTRENG:
      return {
        ...state,
        soekeStreng: action.soekestreng,
      };
    case NYTT_SOEKERESULTAT:
      return {
        ...state,
        resultat: action.resultater,
      };

    case INIT_VDOT_OG_TEKSTER_SUKSESS: {
      return nyState(action.visningsdefinisjon, action.tekster);
    }

    default:
      return state;
  }
}
