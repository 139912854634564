export const KortHeader = ({ tittel, undertittel, tagName = 'h4' }) => {
  const Tag = tagName;

  return (
    <Tag className={'mss-renderkort__tittel'}>
      {tittel && <KortHeaderTittel tittel={tittel} />}
      {undertittel && <KortHeaderUnderTittel undertittel={undertittel} />}
    </Tag>
  );
};

export const KortHeaderTittel = ({ tittel, tagName = 'span' }) => {
  const Tag = tagName;

  return (
    <Tag className="mss-renderkort__titteltekstwrapper">
      <span className="mss-renderkort__titteltekst1">{tittel}</span>
    </Tag>
  );
};

export const KortHeaderUnderTittel = ({ undertittel }) => (
  <span className="mss-renderkort__titteltekst2">{undertittel}</span>
);
