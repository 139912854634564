import { get } from 'lodash';
import { useDispatch } from 'react-redux';

import { FeltIDialog } from './utils/FeltIDialog';
import { FeltMedEgenskaperIDialog } from './utils/FeltMedEgenskaperIDialog';
import {
  erBeregnetFelt,
  erFelt,
  erLesModus,
  FELT,
  FELT_MED_EGENSKAPER,
} from '../../../utils/visningsDataDefinisjonUtil';
import { FeltIDialogWrapper } from './utils/FeltIDialogWrapper';
import { useBetingelseHook } from '../../../hooks/useBetingelseHook';
import { slettDynamiskfeltIDialog } from '../../../actions/dialog';
import { fjernFeilIDialog } from '../../../reducers/feil.action';

export const DynamiskFeltIDialog = ({
  dynamiskfelt,
  feltReferanse,
  kortdata,
  feltdata,
  kortdefinisjon,
  temaId,
  readOnly,
  focusElement,
  disabled,
  repeterendeFeltId,
  underforekomstId,
}) => {
  const dispatch = useDispatch();
  const { skalFeltDisables, skalFeltSkjules } = useBetingelseHook();
  const { barnliste = [] } = dynamiskfelt;
  const alleBarnHarLesemodus = barnliste.every((felt) => felt.modus === 'les');

  const slettDynamiskfelt = (feltreferanse, dynamiskfelt) => {
    dispatch(slettDynamiskfeltIDialog(feltreferanse, dynamiskfelt));

    // fjerner eventuelle valideringsfeil som ligger på feltet
    dynamiskfelt.barnliste
      .filter(erFelt)
      .filter((felt) => !erLesModus(felt) && !erBeregnetFelt(felt))
      .forEach((redigerbartFelt) => {
        dispatch(fjernFeilIDialog(redigerbartFelt.id));
      });
  };

  const felt = (barn, index) => {
    const lesmodus = readOnly || alleBarnHarLesemodus;
    const erFoersteFelt = index === 0;
    const gruppenHarFlereFelt = barnliste.length > 1;
    const sisteRad = index + 1 === barnliste.length;

    const skalDisables = skalFeltDisables(barn, { ...kortdata, ...feltdata });

    switch (barn.type) {
      case FELT:
        return (
          <FeltIDialogWrapper
            key={barn.id}
            temaId={temaId}
            onSlett={() => slettDynamiskfelt(feltReferanse, dynamiskfelt)}
            erFeltIGruppe
            erFoersteFeltIGruppe={erFoersteFelt}
            erSisteFeltIGruppe={sisteRad}
            gruppenHarFlereFelt={gruppenHarFlereFelt}
            readOnly={lesmodus}
          >
            <FeltIDialog
              disabled={disabled || skalDisables}
              felt={barn}
              feltReferanse={`${feltReferanse}.${barn.referanse}`}
              feltData={get(feltdata, barn.referanse)}
              kortdefinisjon={kortdefinisjon}
              forekomstId={feltdata.id}
              readOnly={readOnly}
              focusElement={erFoersteFelt && focusElement}
              repeterendeFeltId={repeterendeFeltId}
              underforekomstId={underforekomstId}
            />
          </FeltIDialogWrapper>
        );

      case FELT_MED_EGENSKAPER:
        return (
          <FeltMedEgenskaperIDialog
            key={barn.id}
            disabled={disabled || skalDisables}
            data={kortdata}
            feltReferanse={`${feltReferanse}.${barn.referanse}`}
            readOnly={readOnly}
            kortdefinisjon={kortdefinisjon}
            felt={barn}
            temaId={temaId}
            erFeltIGruppe
            onSlett={() => slettDynamiskfelt(feltReferanse, dynamiskfelt)}
            erFoersteFeltIGruppe={erFoersteFelt}
            erSisteFeltIGruppe={sisteRad}
            gruppenHarFlereFelt={gruppenHarFlereFelt}
            lesmodus={lesmodus}
            focusElement={erFoersteFelt && focusElement}
            repeterendeFeltId={repeterendeFeltId}
            underforekomst={feltdata}
          />
        );

      default:
        break;
    }
  };

  return (
    <div>
      {barnliste
        .filter((barn) => !barn.skjulIDialog)
        .filter((barn) => !skalFeltSkjules(barn, { ...kortdata, ...feltdata }))
        .map(felt)}
    </div>
  );
};
