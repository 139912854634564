import '@skatteetaten/ds-core-designtokens/index.css';
import './_reset-default.css';
import { createRoot } from 'react-dom/client';
import { SkeBasis } from '@skatteetaten/frontend-components/SkeBasis';
import { App } from './web/App';
import './web/index.css';

const mountId = 'root';
const container = document.getElementById(mountId);

if (!container) {
  throw new Error(`Kan ikke finne element med id=${mountId}`);
}

createRoot(container).render(
  // TODO skrur av midlertidig for StrictMode ødelegger rendring av Combobox fra ds: https://chat.sits.no/skatt-it/pl/o88huibsa7rx8gkyhtaawfwzty
  // <StrictMode>
  <SkeBasis>
    <App />
  </SkeBasis>,
  // </StrictMode>,
);
