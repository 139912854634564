export const transformToKey = (
  languageObject,
  transformer,
  utvidetDefinisjon,
) =>
  Object.keys(languageObject).reduce((object, key) => {
    //visningsrammeverk har andre nøkler enn interne sme tekster og må transformeres
    const foundKey = utvidetDefinisjon.some(
      (field) => field.id === transformer(key),
    );
    object[foundKey ? transformer(key) : key] = languageObject[key];
    return object;
  }, {});
