import { useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { get } from 'lodash';

import Button from '@skatteetaten/frontend-components/Button';
import IconButton from '@skatteetaten/frontend-components/IconButton';
import Dialog from '@skatteetaten/frontend-components/Dialog';
import Link from '@skatteetaten/frontend-components/Link';
import style from './GrunnlagForGjeldsreduksjon.module.scss';

import { GjeldsreduksjonOppsummering } from './GjeldsreduksjonOppsummering';
import { GjeldsreduksjonTabell } from './GjeldsreduksjonTabell';
import { verdsettingsrabatt } from '../../../../../constants/lenker';
import { useSkvisData } from '../../../../../hooks/useAktivSkvis';
// import { useApiSystemfeilOnError } from '../../../../api/apiHooks';
// import { hentSkatteplikt } from '../../../../api/api';

const ikon = (utvidet) => {
  return utvidet ? 'CircleUp' : 'CircleDown';
};
export const GrunnlagForGjeldsreduksjon = ({ closeDialog, svalbard }) => {
  const intl = useIntl();
  const skvisData = useSkvisData(svalbard);

  const [visutvidet, settUtvidet] = useState(false);
  // const skatteplikt = useApiSystemfeilOnError(hentSkatteplikt, []);

  // const harEktefelle = !!skatteplikt?.relasjoner?.find(erEktefelle);

  const sumVerdsettingsrabatt = get(
    skvisData,
    'sumVerdsettingsrabattSomGirGjeldsreduksjon[0].beloep',
    0,
  );

  return (
    <>
      <Dialog
        id="gjeldsreduksjon"
        hidden={false}
        type={Dialog.Type.normal}
        onDismiss={closeDialog}
        title={intl.formatMessage({ id: 'ledetekst.gjeldsreduksjon.tittel' })}
        minWidth="400px"
        maxWidth="1000px"
        forceFocusInsideTrap
        modalProps={{ isBlocking: true, isModeless: false }}
      >
        <p>
          <FormattedMessage
            id={'ledetekst.gjeldsreduksjon.SideForklaring'}
            values={{
              verdsettingsLenke: (
                <Link
                  path={verdsettingsrabatt}
                  text={intl.formatMessage({
                    id: 'ledetekst.gjeldsreduksjon.SideForklaring.lenketekst',
                  })}
                  icon={'OpenInNew'}
                  openInNew={true}
                  placement="after"
                />
              ),
            }}
          />
        </p>

        <p style={{ marginTop: '24px', marginBottom: '0' }}>
          {intl.formatMessage({
            id: 'ledetekst.gjeldsreduksjon.sumVerdsettingsrabatt',
          })}{' '}
          {intl.formatNumber(sumVerdsettingsrabatt)}
        </p>

        <header
          className={style.undertittel}
          onClick={() => settUtvidet(!visutvidet)}
        >
          <span>
            {intl.formatMessage({ id: 'ledetekst.gjeldsreduksjon.seDetaljer' })}
          </span>
          <span>
            <IconButton
              style={{ marginLeft: '12px' }}
              title={intl.formatMessage({
                id: 'ledetekst.gjeldsreduksjon.sumVerdsettingsrabatt',
              })}
              type="button"
              buttonType="default"
              icon={ikon(visutvidet)}
            />
          </span>
        </header>
        {visutvidet && (
          <GjeldsreduksjonTabell
            // harEktefelle={harEktefelle}
            svalbard={svalbard}
          />
        )}
        <hr />
        <GjeldsreduksjonOppsummering
          skvisData={skvisData}
          // harEktefelle={harEktefelle}
          svalbard={svalbard}
        />
        <Dialog.Footer>
          <Button buttonType="primary" onClick={closeDialog} hoved>
            {intl.formatMessage({ id: 'knapp.dialog.ok' })}
          </Button>
        </Dialog.Footer>
      </Dialog>
    </>
  );
};
