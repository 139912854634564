import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import classnames from 'classnames';

import { visningslogikk } from '@skatteetaten/visningsdefinisjoner-og-tekster';
import { getVisningsdata } from '../../../../reducers';
import { tekstId } from '../../../../utils/feltUtils';
import { useTransformerFelt } from '../../../../utils/transformerHooks';
import { useInntektsaar } from '../../../../hooks/useInntektsaar';

const { transformer: transformerUtils } = visningslogikk;

const useTransformertVerdi = (feltdefinisjon, transformerOptions, data) => {
  const visningsdata = useSelector(getVisningsdata);
  const { inntektsaar } = useInntektsaar();
  const transformerFelt = useTransformerFelt();

  if (!harVerdi(data)) {
    return undefined;
  }

  return transformerFelt(feltdefinisjon, data, {
    visningsdata,
    inntektsaar,
    ...transformerOptions,
  });
};

const harVerdi = (verdi) => verdi !== undefined && verdi !== '';

export const Kortfelt = ({
  feltdefinisjon = {},
  data,
  transformerOptions,
  visSomUtledetVerdi = false,
  obligatorisk,
  hidden,
  suffix,
  viseKunTransformertVerdi,
  fetSkrift,
}) => {
  const { transformer, visTomVerdiIKort } = feltdefinisjon;

  const transformertVerdi = useTransformertVerdi(
    feltdefinisjon,
    transformerOptions,
    data,
  );

  if (hidden || (!obligatorisk && !harVerdi(data) && !visTomVerdiIKort)) {
    return null;
  }
  const labelId = uuid();
  const verdiId = uuid();

  const clazznames = classnames('mss-tekst-verdi', {
    'mss-tekst-verdi__beregnet': visSomUtledetVerdi,
    'mss-tekst-verdi__tekst_bold': fetSkrift,
  });

  let verdiklasse = 'mss-tekst-verdi__tekstverdi';
  if (transformerUtils.erNumerisk(transformer)) {
    verdiklasse = visSomUtledetVerdi
      ? 'mss-tekst-verdi__beregnet_tallverdi'
      : 'mss-tekst-verdi__tallverdi';
  }

  if (viseKunTransformertVerdi) {
    return (
      <>
        <dt style={{ display: 'none' }} />
        <dd className={clazznames}>{transformertVerdi}</dd>
      </>
    );
  }

  return (
    <div className={clazznames}>
      <KortFeltLabel
        id={tekstId(feltdefinisjon)}
        labelId={labelId}
        cssClasses={
          visSomUtledetVerdi
            ? 'mss-tekst-verdi__beregnet_tekst'
            : 'mss-tekst-verdi__tekst'
        }
      />
      <KortFeltVerdi
        verdi={obligatorisk && !harVerdi(data) ? '-' : transformertVerdi}
        verdiId={verdiId}
        labelId={labelId}
        cssClasses={verdiklasse}
        suffix={suffix}
      />
    </div>
  );
};

const KortFeltLabel = ({ id, labelId, cssClasses }) => {
  return (
    <dt id={labelId} className={cssClasses}>
      <FormattedMessage id={id} />
    </dt>
  );
};

const KortFeltVerdi = ({ verdi, verdiId, labelId, cssClasses, suffix }) => {
  return (
    <dd
      id={verdiId}
      aria-describedby={labelId}
      className={cssClasses}
      title={verdi}
    >
      {verdi}
      {suffix && ` ${suffix}`}
    </dd>
  );
};
