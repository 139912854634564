import { useIntl } from 'react-intl';
import classnames from 'classnames';
import style from './GrunnlagForGjeldsreduksjon.module.scss';
import { useSkvisData } from '../../../../../hooks/useAktivSkvis';

export const GjeldsreduksjonTabellLinje = ({
  linje,
  harEktefelle,
  svalbard,
}) => {
  const intl = useIntl();
  const skvisData = useSkvisData(svalbard);

  const getVerdi = (element) => skvisData?.[element];

  const id = 'ledetekst.gjeldsreduksjon.' + linje[0];
  const bruttoverdi = getVerdi(linje[1]);
  const verdsettingsrabatt = getVerdi(linje[2]);
  const formuesverdi = getVerdi(linje[3]);

  const erSkattefri = linje[4] === 'F';
  const visUavhengigAvEktefelle =
    !(linje[4] === true) &&
    linje[0] !== 'samletEktefellenesAlleFormuesobjekter';

  const harVerdi =
    bruttoverdi > 0 || verdsettingsrabatt > 0 || formuesverdi > 0;
  const skalVises = visUavhengigAvEktefelle || harEktefelle;

  if (harVerdi && skalVises && !erSkattefri) {
    return (
      <tr className={style.flexTabell}>
        <th scope={'row'} className={style.tittelKolonne}>
          {intl.formatMessage({ id: id })}
        </th>
        <td className={classnames(style.celle, style.flexCol)}>
          {bruttoverdi && intl.formatNumber(bruttoverdi)}
        </td>
        <td className={classnames(style.celle, style.flexCol)}>
          {verdsettingsrabatt && intl.formatNumber(verdsettingsrabatt)}
        </td>
        <td className={classnames(style.celle, style.flexCol)}>
          {formuesverdi && intl.formatNumber(formuesverdi)}
        </td>
      </tr>
    );
  }

  return null;
};
