import { forwardRef } from 'react';
import {
  Icon as DSIcon,
  IconProps as DSIconProps,
} from '@skatteetaten/ds-icons';

// https://skatteetaten.github.io/frontend-components/#icon

export type IconProps = DSIconProps;

export const Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ ...props }, ref) => <DSIcon ref={ref} {...props} />,
);

Icon.displayName = 'SkattekalkulatorIcon';
