import { isEmpty } from 'lodash';

export const values = (object) => {
  return Object.keys(object).map((key) => object[key]);
};

export const oekendeSortering = (a, b) => {
  if (a > b) {
    return 1;
  } else if (a < b) {
    return -1;
  }
  return 0;
};

export const synkendeSortering = (a, b) => {
  if (a < b) {
    return 1;
  } else if (a > b) {
    return -1;
  }
  return 0;
};

export const sortArrayOfObjects = (
  objectArray,
  mapper,
  compare = oekendeSortering,
) => {
  if (isEmpty(objectArray)) {
    return [];
  }
  return [...objectArray].sort((a, b) => {
    return compare(mapper(a), mapper(b));
  });
};

export const mapObject = (toMap = {}, f = (a) => a) => {
  const ret = {};
  f(Object.entries(toMap)).forEach(([key, value]) => {
    ret[key] = value;
  });
  return ret;
};

export const mapObjectEntries = (toMap = {}, f = (a) => a) => {
  const ret = {};
  Object.entries(toMap)
    .map(f)
    .forEach(([key, value]) => {
      ret[key] = value;
    });
  return ret;
};

export const filterObjects = (toMap = {}, f = (a) => a) => {
  const ret = {};
  Object.entries(toMap)
    .filter(f)
    .forEach(([key, value]) => {
      ret[key] = value;
    });
  return ret;
};

export const mapObjectValues = (toMap = {}, f = (a) => a) => {
  const ret = {};
  Object.entries(toMap).forEach(([key, value]) => {
    ret[key] = f(value);
  });
  return ret;
};

export const mapObjectKeys = (toMap = {}, f = (a) => a) => {
  const ret = {};
  Object.entries(toMap).forEach(([key, value]) => {
    ret[f(key)] = value;
  });
  return ret;
};
export const addProperty = (key, value) => (obj) => ({ ...obj, [key]: value });
