import {
  LASTER_ANALYTICS,
  ANALYTICS_OPERASJON,
} from '../constants/feillokasjoner';
import { feilFraError, medFeillokasjon, medLoggSomInfo } from '../utils/feil';
import {
  HELHETLIG_MÅLING_SITE_ID,
  filtrerEventerTilHelhetligMåling,
  matomoTrackerURL,
} from '@skatteetaten/brukerinnsikt-utils';
// import { rapporterFeilTilBackend } from './rapporterFeil';

/**
 * Utility for Matomo.
 * Bare en instans blir laget.
 * Eksempler:
 * piwik.createTracker({scriptUrl: 'http://foo.js', trackerUrl: 'http://bar.php', siteId: 2})
 * piwik.getTracker().push(['trackEvent', 'Kort', 'Klikk']);
 */

const BASE_URL = window.location.origin;
const ANALYTICS_BASE_URL = BASE_URL.includes(
  'skattekalkulator.formueinntekt.skatt.skatteetaten.no',
)
  ? 'https://wta.sits.no/'
  : 'https://wta-test.sits.no/';
const ANALYTICS_TRACKER_URL = `${ANALYTICS_BASE_URL}matomo/matomo.php`;
const ANALYTICS_SCRIPT_URL = `${ANALYTICS_BASE_URL}matomo/matomo.js`;
const ANALYTICS_SITE_ID = 41;

const defaultConfig = {
  siteId: ANALYTICS_SITE_ID,
  trackerUrl: ANALYTICS_TRACKER_URL,
  scriptUrl: ANALYTICS_SCRIPT_URL,
  // Hindrer 404-feil i produksjon. Vi har heatmaps på
  // serveren for andre prosjekter. vi bruker ikke heatmaps,
  // får en 404 i produksjon hvis vi ikke setter dette
  extraConfig: [['HeatmapSessionRecording::disable']],
};

export default (() => {
  function AnalyticsCore(config) {
    console.debug(
      `Matomo config: \n trackerUrl: ${config.trackerUrl} \n siteId: ${config.siteId}`,
    );
    return createTracker(config);
  }

  let tracker;
  let inntektsaar;
  let tekster;
  return {
    createTracker(aar) {
      if (tracker === undefined) {
        tracker = new AnalyticsCore(defaultConfig);
      }
      if (aar) {
        inntektsaar = aar;
      }
      return tracker;
    },

    setTekster(tekster_) {
      if (tekster_) {
        tekster = tekster_;
      }
    },

    getTekster() {
      return tekster;
    },

    tekst(tekstId) {
      return tekster[tekstId];
    },

    getInntektsaar() {
      return inntektsaar;
    },

    sendEventTilPiwik(informasjon) {
      if (tracker) {
        tracker.push(['trackEvent', ...informasjon]);
      }
    },
  };
})();

// Bygger paa tracking code fra matomo-oppsett, men tar hensyn til runtime-konfigurasjon
// Oppsettet av matomo er hentet fra https://developer.matomo.org/guides/tracking-javascript-guide
const createTracker = (config) => {
  const { push, bufferedPush, scriptDoneLoading } = setupPushToMatomo();
  setMatomoOptions(push, config);

  const scriptUrl =
    config.scriptUrl || (config.url && config.url + '/matomo.js');

  if (!scriptUrl) {
    return {
      push: (_ignore) => {},
    };
  }

  loadScript(scriptUrl)
    .then(scriptDoneLoading)
    .catch((e) => {
      const feil = feilFraError(e, [
        medLoggSomInfo,
        medFeillokasjon(LASTER_ANALYTICS),
      ]);
      console.error(feil);
      // rapporterFeilTilBackend(feil); // TODO
    });
  return {
    push: bufferedPush,
  };
};

const setMatomoOptions = (push, config) => {
  let trackerUrl = config.trackerUrl || config.url + '/matomo.php';
  push(['setTrackerUrl', trackerUrl]);
  push(['setSiteId', config.siteId]);

  push(['addTracker', matomoTrackerURL, HELHETLIG_MÅLING_SITE_ID]);
  push(['setCustomRequestProcessing', filtrerEventerTilHelhetligMåling]);

  // Før første rapportering slik at vi kan legge til metainformasjon
  (config.extraConfig || []).forEach(push);

  //Tracker første lasting av siden
  push(['trackPageView']);

  // Outlink tracking. Neppe viktig for applikasjonen, men standard for matomo.
  // Tar det med bare så vi er mest mulig lik den gemene hop.
  // https://developer.matomo.org/guides/tracking-javascript-guide#download-and-outlink-tracking
  push(['enableLinkTracking']);

  // pulse for å tracke besøkstid
  push(['enableHeartBeatTimer']);
};

//Matomo benytter en "push" metode til en kø som scriptet plukker fra.
//Det er litt ekstra stæsj for å bufre kall imens scriptet laster
//(ellers spytter matomo ut warnings)
const setupPushToMatomo = () => {
  rethrowOgRapporterVedFeil(() => {
    window._paq = window._paq || [];
  });

  const push = (args) =>
    rethrowOgRapporterVedFeil(() => {
      window._paq.push(args);
    });
  let initialized = false;
  const queue = [];

  const bufferedPush = (args) => {
    if (initialized) {
      push(args);
    } else {
      queue.push(args);
    }
  };
  const scriptDoneLoading = () => {
    initialized = true;
    queue.forEach(push);
  };
  return { push, bufferedPush, scriptDoneLoading };
};

function rethrowOgRapporterVedFeil(actionFn) {
  try {
    actionFn();
  } catch (err) {
    // rapporterFeilTilBackend(
    //   feilFraError(err, [medLoggSomInfo, medFeillokasjon(PIWIK_OPERASJON)]),
    // );
    console.error(
      feilFraError(err, [medLoggSomInfo, medFeillokasjon(ANALYTICS_OPERASJON)]),
    );
  }
}

//Standard lasting av script. Hvis vi har dette i et eksisterende framework kan vi fint bytte det ut.
function loadScript(scriptUrl) {
  return new Promise(function (resolve, reject) {
    const scriptElement = document.createElement('script');
    scriptElement.src = scriptUrl;
    scriptElement.onload = resolve;
    scriptElement.onerror = reject;
    document.head.appendChild(scriptElement);
  });
}
