import { korttyperSomKanLeggesTil } from '../../../utils/skalSkjules';

export const filtrerVisningsdata = (visningsdata, visningsdefinisjon) => {
  return visningsdefinisjon
    .map((tema) => {
      const kortgrupper = (tema.barnliste || [])
        .filter((kortgruppe) => kortgruppe.type === 'kortgruppe')
        .map((kortgruppe) => {
          const korttyper = korttyperSomKanLeggesTil(visningsdata, kortgruppe);

          return {
            ...kortgruppe,
            barnliste: korttyper,
            temaId: tema?.id,
          };
        })
        .filter((kortgruppe) => kortgruppe.barnliste.length);

      return {
        ...tema,
        barnliste: kortgrupper,
      };
    })
    .filter((tema) => tema.barnliste.length > 0);
};
