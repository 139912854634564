import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash/isEmpty';

import Dialog from '@skatteetaten/frontend-components/Dialog';
import Button from '@skatteetaten/frontend-components/Button';

import { DIALOGTYPE_TEKST, lukkDialog } from '../../../../actions/dialog';
import {
  getDialogFeltSomErEndret,
  getDialogIsOpen,
} from '../../../../reducers';
import { FormattedHTMLMessageUglyInnerHtml } from '../../FormattedHTMLMessage';
import { SmeDialog } from '../smeDialog/SmeDialog';
import { useKildeSkattPaaLoenn } from '../../kildeskattePaaLoenn/useKildeskattPaaLoenn';

export const StorDialog = ({
  children,
  title = '',
  titleId,
  subtitleId,
  avbrytAksjon = null,
  visObligatoriskFeltMelding = true,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const isOpen = useSelector(getDialogIsOpen);
  const feltSomErEndret = useSelector(getDialogFeltSomErEndret);
  const { beregnKplModus } = useKildeSkattPaaLoenn();
  const [visBekreftAvbrytDialog, setVisBekreftAvbrytDialog] = useState(false);

  const avbrytAksjon_ = useMemo(() => {
    return (e) => {
      if (avbrytAksjon) {
        avbrytAksjon(e);
      } else if (isEmpty(feltSomErEndret)) {
        dispatch(lukkDialog());
      } else {
        setVisBekreftAvbrytDialog(true);
      }
    };
  }, [avbrytAksjon, feltSomErEndret, dispatch]);

  const titleText = (titleId && intl.formatMessage({ id: titleId })) || title;
  const harSubtitleTekst =
    subtitleId && intl.formatMessage({ id: subtitleId }) !== subtitleId;

  return (
    <>
      <SmeDialog
        id={DIALOGTYPE_TEKST}
        hidden={!isOpen}
        onDismiss={avbrytAksjon_}
        title={titleText}
        isBlocking={true}
      >
        {visObligatoriskFeltMelding && !beregnKplModus && (
          <p style={{ marginTop: 0 }}>
            {intl.formatMessage({
              id: 'ledetekst.informasjon.obligatoriskFelt',
            })}
          </p>
        )}
        {harSubtitleTekst && (
          //  Kan gjøres mye bedre fom. react-intl 3. Da trenger vi ikke full link-
          // syntaks i tekstfila, og kan bruke Lenke-komponenten og få med lenkeikon osv
          <FormattedHTMLMessageUglyInnerHtml id={subtitleId} />
        )}

        {children}
      </SmeDialog>
      <BekreftAvbrytDialog
        show={visBekreftAvbrytDialog}
        setVisBekreftAvbrytDialog={setVisBekreftAvbrytDialog}
      />
    </>
  );
};

const BekreftAvbrytDialog = ({ show, setVisBekreftAvbrytDialog }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  if (!show) {
    return null;
  }

  const ikkeLukkDialog = () => setVisBekreftAvbrytDialog(false);

  return (
    <SmeDialog
      id="tab-utdatert-dialog"
      hidden={false}
      onDismiss={null}
      title={intl.formatMessage({ id: 'ledetekst.bekreftAvbryt.tittel' })}
      minWidth="400px"
    >
      <p>{intl.formatMessage({ id: 'ledetekst.bekreftAvbryt.innhold' })}</p>

      <Dialog.Footer>
        <Button onClick={ikkeLukkDialog}>
          {intl.formatMessage({ id: 'knapp.nei' })}
        </Button>
        <Button onClick={() => dispatch(lukkDialog())}>
          {intl.formatMessage({ id: 'knapp.ja' })}
        </Button>
      </Dialog.Footer>
    </SmeDialog>
  );
};
