import { compact } from 'lodash';
import { formaterFoedselsnummer } from './formater';

const SKVIS_GRUPPE_SORTERING = [
  'saerskiltSkatteplikt',
  'barnetsPersonidentifikator',
  'kommune',
  'land',
];

export const sorterSkvisGrupper = (forekomster) => {
  return forekomster
    .map((forekomst) => ({
      ...forekomst,
      sortId: Object.keys(forekomst).filter((key) =>
        SKVIS_GRUPPE_SORTERING.includes(key),
      ).length,
      sortType: SKVIS_GRUPPE_SORTERING.findIndex((key) => forekomst[key]),
    }))
    .sort((a, b) => a.sortId - b.sortId)
    .sort((a, b) => a.sortType - b.sortType);
};

export const lagSkvisGruppeHeader = (
  { tekstId, saerskiltSkatteplikt, barnetsPersonidentifikator, kommune, land },
  intl,
  inntektsaar,
) => {
  return compact([
    tekstId && intl.formatMessage({ id: tekstId }),
    saerskiltSkatteplikt &&
      intl.formatMessage({ id: 'egenskap.saerskiltSkatteplikt' }),
    barnetsPersonidentifikator &&
      formaterFoedselsnummer(barnetsPersonidentifikator),
    kommune &&
      kommune !== '9999' &&
      intl.formatMessage({
        id: `kodeliste.${inntektsaar}_aktiveKommunenummer.${kommune}`,
      }),
    land &&
      intl.formatMessage({
        id: `kodeliste.${inntektsaar}_aktiveLandkoder.${land}`,
      }),
  ]).join(', ');
};
