import { forwardRef } from 'react';
import {
  Link as DSLink,
  LinkProps as DSLinkProps,
} from '@skatteetaten/ds-buttons';
import { StringishChildren } from './DSTypes';

export interface LinkProps extends Omit<DSLinkProps, 'children'> {
  children: StringishChildren;
}

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ children, ...props }, ref) => (
    <DSLink
      ref={ref}
      {...props}
      target={props.isExternal && !props.target ? '_blank' : props.target}
    >
      {children as DSLinkProps['children']}
    </DSLink>
  ),
);

Link.displayName = 'SkattekalkulatorLink';
