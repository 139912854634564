import { useContext } from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment/moment';

import DatePicker from '@skatteetaten/frontend-components/DatePicker';
import {
  lagFeilmeldinger,
  lagHjelpetekst,
  lagLabel,
} from '../../utils/feltUtils';
import { SmeDialogContext } from './dialog/smeDialog/SmeDialog';
import { useInntektsaar } from '../../hooks/useInntektsaar';
import { useAnalytics } from '../../analytics/analytics.hooks';

export const Datovelger = (props) => {
  const {
    disabled,
    felt,
    verdi,
    feil,
    obligatorisk,
    valideringsstate,
    onFocus,
    valider,
    onChange,
  } = props;
  const intl = useIntl();
  const { inntektsaar } = useInntektsaar();
  const { setPauseFocustrap } = useContext(SmeDialogContext);
  const analytics = useAnalytics();
  const {
    datovelger: { begrensTilInntektsaar, startdato, sluttdato, datoformat },
  } = felt;

  const handleOnClick = () => {
    setPauseFocustrap(true);
    analytics.dialogFeltKlikk(felt.id);
  };

  const handleOnChange = (date) => {
    valider(date);
    let format = 'YYYY-MM-DD';
    if (datoformat === 'ISO') {
      format = '';
    }
    // Format som er kompatibelt med datatype i skattemeldingen (xsd:date)
    const datoVerdi = !date ? date : moment(date, 'DD.MM.YYYY').format(format);

    onChange(datoVerdi);
  };

  const minimumsdato = begrensTilInntektsaar
    ? new Date(`${inntektsaar}-01-01`)
    : startdato
    ? new Date(startdato)
    : undefined;

  const maksimumsdato = begrensTilInntektsaar
    ? new Date(`${inntektsaar}-12-31`)
    : sluttdato
    ? new Date(sluttdato)
    : undefined;

  return (
    <DatePicker
      disabled={disabled}
      label={lagLabel(intl, felt, obligatorisk)}
      value={verdi ? new Date(verdi) : undefined}
      minDate={minimumsdato}
      maxDate={maksimumsdato}
      initialPickerDate={new Date(`${inntektsaar}-01-01`)}
      onFocus={onFocus}
      onClick={handleOnClick}
      calloutFloating={false}
      allowTextInput={true}
      showGoToToday={false}
      showWeekNumbers={true}
      onSelectDate={handleOnChange}
      help={lagHjelpetekst(intl, felt)}
      isRequired
      isRequiredErrorMessage={lagFeilmeldinger(
        feil,
        valideringsstate,
        intl,
        felt.id,
      )}
      onAfterMenuDismiss={() => setPauseFocustrap(true)}
    />
  );
};
