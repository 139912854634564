export const settFokusPaaSoekefelt = () => {
  const aapneSoekKnapp = document.getElementById('aapneSoekKnapp');
  let soekefelt = document.getElementById('soek');
  if (
    aapneSoekKnapp !== null &&
    aapneSoekKnapp.nodeName === 'BUTTON' &&
    soekefelt === null
  ) {
    setTimeout(() => {
      aapneSoekKnapp.click();
    }, 100);

    setTimeout(() => {
      soekefelt = document.getElementById('soek');
      try {
        soekefelt.focus();
      } catch (e) {
        console.warn(
          'Klarte ikke hente søkefelt etter klikk på "Søk"-knapp.',
          e,
        );
      }
    }, 100);
  } else {
    setTimeout(() => {
      soekefelt.focus();
    }, 100);
  }
};
