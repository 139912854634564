import { omit } from 'lodash';
import {
  NY_LEGG_TIL_EGENSKAPER_DIALOG,
  LEGG_TIL_EGENSKAP,
  SLETT_OBJEKT_EGENSKAP,
} from './kortOpplysninger.actions';
import { NY_DIALOG, VIS_DIALOG } from '../../../../actions/dialog';
import { hentSynligeEgenskaper } from './egenskaper.utils';
import { initierData } from '../../../../reducers/reducerUtil';

export default function egenskaperReducer(state, action) {
  switch (action.type) {
    case VIS_DIALOG: {
      const { alltidVisEgenskaper = [] } = action.kortdefinisjon || {};
      return {
        ...state,
        synligeEgenskaper: {
          ...hentSynligeEgenskaper(state.data, action.kortdefinisjon),
          ...alltidVisEgenskaper.reduce(
            (retObj, egenskap) => ({
              ...retObj,
              [egenskap]: true,
            }),
            {},
          ),
        },
      };
    }

    // case GAA_TIL_NESTE_STEG:
    case NY_DIALOG: {
      const { alltidVisEgenskaper = [] } = state.kortdefinisjon || {};
      if (state.kortdefinisjon) {
        return {
          ...state,
          synligeEgenskaper: alltidVisEgenskaper.reduce(
            (retObj, egenskap) => ({
              ...retObj,
              [egenskap]: true,
            }),
            action.egenskaper || {},
          ),
        };
      }
      return state;
    }
    case NY_LEGG_TIL_EGENSKAPER_DIALOG: {
      return {
        ...state,
        dialogtype: action.dialogtype,
        fraDialog: action.fraDialog,
        leggTilEgenskaper: {
          valgtEgenskap: action.valgtEgenskap,
          egenskaperSomKanLeggesTil: action.egenskaperSomKanLeggesTil,
        },
        isOpen: true,
      };
    }
    case LEGG_TIL_EGENSKAP: {
      const synligeEgenskaper = {
        ...state.synligeEgenskaper,
      };
      action.valgteEgenskaper.forEach(
        (egenskapSomErLagtTil) =>
          (synligeEgenskaper[egenskapSomErLagtTil.typeEgenskap] = true),
      );
      return {
        ...state,
        synligeEgenskaper,
      };
    }
    case SLETT_OBJEKT_EGENSKAP: {
      const { [action.egenskap.referanse]: _value, ...dataUtenEgenskap } =
        state.data;

      return {
        ...state,
        synligeEgenskaper: omit(
          state.synligeEgenskaper,
          action.egenskap.typeEgenskap,
        ),
        data: initierData(dataUtenEgenskap, state.kortdefinisjon),
      };
    }
    default:
      return state;
  }
}
