import { useSelector } from 'react-redux';
import { getVisningselementForFokus } from './fokus.reducer';

export const useSkalTemaFokuseres = (temaId) => {
  const visningselementForFokus = useSelector(getVisningselementForFokus);
  const id = visningselementForFokus?.id || {};

  // Når man klikker på element i søkevindu så settes id for både
  // kortgruppe og tema. Da skal kortgruppen fokuseres.
  return !id.kortgruppe && id.tema === temaId;
};

export const useSkalKortgruppeFokuseres = (kortgruppeId) => {
  const visningselementForFokus = useSelector(getVisningselementForFokus);
  const id = visningselementForFokus?.id || {};

  return id.kortgruppe === kortgruppeId;
};
