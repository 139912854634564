import classnames from 'classnames';
import commonStyle from '../../../common.module.scss';
import style from './GrunnlagForGjeldsreduksjon.module.scss';
import { FormatertTall } from '../../../util/FormatertTall';

export const GjeldsreduksjonLinje = ({
  tekst,
  value,
  uthevetSkrift = false,
}) => {
  if (value > 0) {
    return (
      <div
        className={classnames(
          { [commonStyle.bold]: uthevetSkrift },
          style.flexTabell,
        )}
      >
        <div style={{ width: '700px' }}>{tekst}</div>
        <div className={style.flexCol}>
          {value && <FormatertTall value={value} />}
        </div>
      </div>
    );
  }
  return null;
};
