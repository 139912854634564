import { useSelector } from 'react-redux';

import { LeggTilOpplysningerDialog } from './LeggTilOpplysningerDialog';
import {
  DIALOGTYPE_DIALOG,
  DIALOGTYPE_KORT_I_KORT,
  DIALOGTYPE_LEGG_TIL_OPPLYSNINGER,
  DIALOGTYPE_LEGG_TILOPPLYSNINGER,
  DIALOGTYPE_STEG,
  DIALOGTYPE_VELG_INNGANG_TIL_KORTTYPE,
  DIALOGTYPE_VELG_KORTTYPE,
} from '../../../actions/dialog';
import {
  getDialogIsOpen,
  getDialogtype,
  getSystemmeldingDialogIsOpen,
} from '../../../reducers';
import { KorttypeDialog } from './KorttypeDialog';
import { RedigerKorttypeDialog } from './RedigerKorttypeDialog';
import { DIALOGTYPE_LEGG_TIL_EGENSKAPER } from './KortOpplysninger/kortOpplysninger.actions';
import { LeggTilEgenskaperDialog } from './KortOpplysninger/LeggTilEgenskaperDialog';
import { LeggTilKortOpplysningerDialog } from './KortOpplysninger/LeggTilKortOpplysningerDialog';
import { KortIKortDialog } from './KortIKortDialog';
import { VelgInngangTilKorttypeDialog } from './VelgInngangTilKorttypeDialog';

export const Dialog = () => {
  const isOpen = useSelector(getDialogIsOpen);
  const dialogtype = useSelector(getDialogtype);
  const viserFeil = useSelector(getSystemmeldingDialogIsOpen);
  if (!isOpen || viserFeil) {
    return null;
  }
  switch (dialogtype) {
    case DIALOGTYPE_LEGG_TILOPPLYSNINGER:
      return <LeggTilOpplysningerDialog />;
    case DIALOGTYPE_LEGG_TIL_EGENSKAPER:
      return <LeggTilEgenskaperDialog />;
    case DIALOGTYPE_VELG_KORTTYPE:
      return <KorttypeDialog />;
    case DIALOGTYPE_VELG_INNGANG_TIL_KORTTYPE:
      return <VelgInngangTilKorttypeDialog />;
    case DIALOGTYPE_DIALOG:
    case DIALOGTYPE_STEG:
      return <RedigerKorttypeDialog />;
    case DIALOGTYPE_LEGG_TIL_OPPLYSNINGER:
      return <LeggTilKortOpplysningerDialog />;
    case DIALOGTYPE_KORT_I_KORT:
      return <KortIKortDialog />;

    default:
      // TODO: Feil: Ukjent dialog
      return null;
  }
};
