import { put, select } from 'redux-saga/effects';
import { leggTilNyKortgruppe } from '../actions/dialog';
import { nyttSoekeresultat } from '../actions/soek';
import {
  getSoekemotor,
  getVisningsdata,
  getVisningsdefinisjon,
} from '../reducers';
import { behandleResultaterSme } from '../utils/soek/resultatprosessering';
import { korttypeHarData } from '../utils/skalSkjules';
import { fokuserVisningsdefinisjonElement } from '../fokus/fokus.reducer';

export function* hentKontekst(action) {
  const {
    soekeresultat: { id },
  } = action;
  const visningsdata = yield select(getVisningsdata);
  const visningsdefinisjon = yield select(getVisningsdefinisjon);

  const tema = visningsdefinisjon.find((temaElem) => temaElem.id === id.tema);
  const kortgruppe = tema.barnliste.find(
    (kortgruppeElem) => kortgruppeElem.id === id.kortgruppe,
  );
  return { visningsdata, tema, kortgruppe, id };
}

export function* soek(action) {
  const {
    soekeresultat: { type },
  } = action;
  const { visningsdata, kortgruppe, id } = yield* hentKontekst(action);

  const skalFokusere = kortgruppe.barnliste.some((korttype) =>
    korttypeHarData(korttype, visningsdata),
  );
  if (skalFokusere) {
    yield put(fokuserVisningsdefinisjonElement(id, type));
  } else {
    yield put(leggTilNyKortgruppe(kortgruppe, id.tema));
  }
}

export function* utfoerSoekSaga(action) {
  if (action.soekestreng.length < 3) {
    yield put(nyttSoekeresultat([]));
  } else {
    const visningsdefinisjon = yield select(getVisningsdefinisjon);
    const visningsdata = yield select(getVisningsdata);
    const soekemotor = yield select(getSoekemotor);
    const resultaterRaw = soekemotor.soek(action.soekestreng);
    const resultater = behandleResultaterSme(
      resultaterRaw,
      visningsdata,
      visningsdefinisjon,
    );
    yield put(nyttSoekeresultat(resultater));
  }
}
