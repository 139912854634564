import { useEffect, useRef } from 'react';

export const ScrollIntoViewOnMount = ({
  children,
  skipScrolling,
  scrollCounter,
}) => {
  const myRef = useRef(null);
  useEffect(() => {
    if (!skipScrolling && myRef.current) {
      myRef.current.scrollIntoView(false);
    }
  }, [skipScrolling, scrollCounter]);
  return <span ref={myRef}>{children}</span>;
};
