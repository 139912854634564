export const formaterNavn = (mellomnavn, fornavn, etternavn) => {
  if (!fornavn && !etternavn) {
    return null;
  }
  return fornavn + ' ' + (mellomnavn ? mellomnavn + ' ' : '') + etternavn;
};

export const formaterFoedselsnummer = (foedselsnummer) => {
  if (!foedselsnummer) {
    return null;
  }
  return `${foedselsnummer.substring(0, 6)} ${foedselsnummer.substring(6)}`;
};
export const multilineStringSomArray = (str) =>
  str
    ?.split(/\r\n?|\n/)
    ?.map((s) => s.trim())
    ?.filter((s) => s !== '');
