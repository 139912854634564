import { useIntl } from 'react-intl';
import { transformerTall } from '../../../utils/transformerHooks';

export const formaterTall = (
  value,
  intl,
  minimumFractionDigits = null,
  maximumFractionDigits = null,
) => {
  return transformerTall(value, intl, {
    minimumFractionDigits,
    maximumFractionDigits,
  });
};

export const useFormaterTall = (
  value,
  minimumFractionDigits = null,
  maximumFractionDigits = null,
) => {
  const intl = useIntl();
  return formaterTall(
    value,
    intl,
    minimumFractionDigits,
    maximumFractionDigits,
  );
};

export const FormatertTall = ({
  value,
  minimumFractionDigits = null,
  maximumFractionDigits = null,
}) => {
  return useFormaterTall(value, minimumFractionDigits, maximumFractionDigits);
};
