import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import { language } from '@skatteetaten/visningsdefinisjoner-og-tekster';
import style from './BeregnetSkatt.module.scss';
import oversiktStyle from '../Oversikt.module.scss';
import { BeregnetSkattTabellRad } from './BeregnetSkattTabellRad';
import { skjulHvisAlleReferanserErNull } from './beregnetSkattUtils';
import { filterSvalbard } from '../summertskattegrunnlag/summertSkattegrunlagUtil';

export const BeregnetSkattGruppe = ({
  beregnetSkatt,
  gruppe,
  svalbard,
  skjulHvisBeloepErNull,
}) => {
  const { skjulHvisReferanserErNull } = gruppe;

  if (
    !gruppe.visSelvOmAllBeloepErNull &&
    skjulHvisAlleReferanserErNull(
      skjulHvisReferanserErNull,
      beregnetSkatt,
      gruppe.barnliste,
    )
  ) {
    return null;
  }

  if (gruppe.barnliste.length < 1) {
    return null;
  }

  const caption = !gruppe.skjulOverskrift ? (
    <caption className={style.overskriftBeregnetskatt}>
      <FormattedMessage id={language.beregnetskattOverskriftId(gruppe.id)} />
    </caption>
  ) : null;

  return (
    <table className={style.minimalTable}>
      {caption}
      <thead>
        <tr>
          <th
            scope="col"
            className={classnames(
              oversiktStyle.flexCol1,
              oversiktStyle.skjultOverskrift,
            )}
          >
            <FormattedMessage id="ledetekst.beregnetSkatt.kategori" />
          </th>
          <th
            scope="col"
            className={classnames(
              oversiktStyle.flexCol2,
              oversiktStyle.skjultOverskrift,
            )}
          >
            <FormattedMessage id="ledetekst.beregnetSkatt.grunnlag" />
          </th>
          <th
            scope="col"
            className={classnames(
              oversiktStyle.flexCol3,
              oversiktStyle.skjultOverskrift,
            )}
          >
            <FormattedMessage id="ledetekst.beregnetSkatt.skattOgAvgift" />
          </th>
        </tr>
      </thead>
      <tbody>
        {gruppe.barnliste
          .filter(filterSvalbard(svalbard))
          // TODO
          .filter((definisjon) => !definisjon.applikasjon?.includes('smia'))
          .map((linje) => (
            <BeregnetSkattTabellRad
              key={linje.id}
              linjeDef={linje}
              beregnetSkatt={beregnetSkatt}
              skjulHvisBeloepErNull={skjulHvisBeloepErNull}
            />
          ))}
      </tbody>
    </table>
  );
};
