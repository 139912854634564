import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Modal } from '@skatteetaten/ds-overlays';
import { Link } from '@skatteetaten/ds-buttons';
import { getSystemfeil } from '../../../../reducers';
import { toemSystemfeil } from '../../../../reducers/feil.action';
import { dialogInnholdForFeil } from './SystemmeldingDialog.util';
import { FormattedHTMLMessageWithFallbackToUgly } from '../../FormattedHTMLMessage';

export const SystemmeldingDialog = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const feil = useSelector(getSystemfeil);
  const refModalImportant = useRef(null);

  useEffect(() => {
    if (feil) {
      refModalImportant.current?.showModal();
    }
  }, [feil, refModalImportant]);

  if (!feil) {
    return null;
  }

  const { overskriftId, meldingId } = dialogInnholdForFeil(feil);
  const onLukk = () => {
    dispatch(toemSystemfeil());
    refModalImportant.current?.close();
  };

  return (
    <Modal
      ref={refModalImportant}
      variant={'important'}
      title={intl.formatMessage({ id: overskriftId })}
      onClose={onLukk}
      dismissOnOutsideClick={false}
    >
      <div>
        <FormattedHTMLMessageWithFallbackToUgly id={meldingId} />
        <p>
          <Link href={'#'} onClick={onLukk}>
            <FormattedHTMLMessageWithFallbackToUgly id="knapp.systemmeldingdialog.fortsett" />
          </Link>
        </p>
      </div>
    </Modal>
  );
};
