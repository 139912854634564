import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { language } from '@skatteetaten/visningsdefinisjoner-og-tekster';

import { getDialog, getVisningsdata } from '../../../reducers';
import { nyDialog } from '../../../actions/dialog';
import { useDispatcher } from '../../../utils/reduxHooks';
import { StorDialog } from './utils/StorDialog';
import { DialogKnapperad } from './utils/DialogKnapperad';
import { DialogAvbryt } from './utils/DialogAvbryt';
import { ListeIDialog } from './utils/ListeIDialog';
import {
  erMaksEttKortMedForekomst,
  kanIkkeLeggeTilKort,
} from '../../../utils/skalSkjules';
import { useAnalytics } from '../../../analytics/analytics.hooks';

export const KorttypeDialog = () => {
  const intl = useIntl();
  const velgKorttype = useDispatcher(nyDialog);
  //  TODO kan denne flyttes ut i hoook e.l.
  const visningsdata = useSelector(getVisningsdata);
  const { kortgruppe, temaId, data } = useSelector(getDialog);
  const analytics = useAnalytics(temaId);

  function lagElement(korttypeSkalDisables, korttype) {
    return {
      valgTekst: intl.formatMessage({
        id: language.flervalgId(korttype.id),
      }),
      klikkAksjon: () => {
        velgKorttype(korttype, temaId, data);
        analytics.leggTilKlikk(korttype.id);
      },
      disabled: korttypeSkalDisables,
      iconName: 'ArrowForward',
    };
  }

  const elementer = kortgruppe.barnliste
    .filter((korttype) => !kanIkkeLeggeTilKort(korttype, visningsdata))
    .map((korttype) => {
      const harMaksAntallKortAvKorttype = erMaksEttKortMedForekomst(
        korttype,
        visningsdata,
      );
      return lagElement(harMaksAntallKortAvKorttype, korttype);
    });

  return (
    <StorDialog
      titleId={kortgruppe.id}
      subtitleId={language.kortgruppeSubHeaderId(kortgruppe.id)}
      visObligatoriskFeltMelding={false}
    >
      <ListeIDialog elementer={elementer} />
      <DialogKnapperad>
        <DialogAvbryt />
      </DialogKnapperad>
    </StorDialog>
  );
};
