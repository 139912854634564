export const START_LOADING = 'START_LOADING';
export const STOP_LOADING = 'STOP_LOADING';

export const startLoading = (apiKall) => ({
  type: START_LOADING,
  apiKall,
});

export const stopLoading = (apiKall) => ({
  type: STOP_LOADING,
  kommando: apiKall,
});
