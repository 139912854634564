import { visningslogikk } from '@skatteetaten/visningsdefinisjoner-og-tekster';
import {
  cloneDeep,
  compact,
  get,
  has,
  isArray,
  isBoolean,
  isEmpty,
  isNil,
  isObject,
  isPlainObject,
  set,
} from 'lodash';
import { mapObjectValues } from './object';
import {
  alleFelter,
  filterDefinisjon,
  finnAlle,
} from './visningsdefinisjonUtil';
import {
  erDynamiskgruppe,
  FELTREFERANSE,
  finnDefinisjonFraVisningsdefinisjonBasertPaaReferanse,
  FOREKOMSTTYPE,
  medBehandlingsart,
  medFelt,
  medFeltMedEgenskaper,
  medForekomst,
  medUnderforekomst,
  medUnderforekomsttype,
  UNDERFOREKOMST,
  UNDERFOREKOMSTTYPE,
} from './visningsDataDefinisjonUtil';

const { felt: feltUtils } = visningslogikk;

export const not =
  (f) =>
  (...args) =>
    !f(...args);

const erForekomsttype = (def) => isArray(def[1]);
const erFeltMedEgenskaper = (def) => isObject(def[1]) && !isArray(def[1]);
const erFelt = (def) => !isObject(def[1]) && !isArray(def[1]);

export const lagDatasti = (forekomsttype) => ({
  [FOREKOMSTTYPE]: forekomsttype,
});

const emptyArray = [];
export const hentUnderforekomsttypeFraForekomst = (forekomst, dataSti) => {
  return (forekomst && forekomst[dataSti[UNDERFOREKOMSTTYPE]]) || emptyArray;
};

export const hentUnderforekomstFraForekomst = (forekomst, dataSti) => {
  const underforekomsttype = forekomst[dataSti[UNDERFOREKOMSTTYPE]];
  return (
    (underforekomsttype &&
      underforekomsttype.find(({ id }) => id === dataSti[UNDERFOREKOMST])) ||
    []
  );
};

export const lagFeltreferanseIDialog = (forekomstForDialog, dataSti) => {
  const undeforekomsttype = hentUnderforekomsttypeFraForekomst(
    forekomstForDialog,
    dataSti,
  );
  const underforekomstindex = undeforekomsttype
    ? undeforekomsttype.findIndex(({ id }) => id === dataSti[UNDERFOREKOMST])
    : -1;
  const harUnderforekomst = underforekomstindex !== -1;
  return [
    harUnderforekomst && dataSti[UNDERFOREKOMSTTYPE],
    harUnderforekomst && underforekomstindex,
    dataSti[FELTREFERANSE],
  ]
    .filter((x) => !isNil(x))
    .join('.');
};

export const erstattEllerLeggTilUnderforekomst = (
  forekomst,
  dataSti,
  underforekomst,
) => {
  const underforekomstType = forekomst[dataSti[UNDERFOREKOMSTTYPE]] || [];
  const indeksForUnderforekomst = underforekomstType.findIndex(
    (it) => it.id === dataSti[UNDERFOREKOMST],
  );
  if (indeksForUnderforekomst === -1) {
    return { ...forekomst, [dataSti[UNDERFOREKOMSTTYPE]]: [underforekomst] };
  }
  const nyUnderforekomsttype = [
    ...underforekomstType.slice(0, indeksForUnderforekomst),
    underforekomst,
    ...underforekomstType.slice(indeksForUnderforekomst + 1),
  ];
  return {
    ...forekomst,
    [dataSti[UNDERFOREKOMSTTYPE]]: nyUnderforekomsttype,
  };
};

export const mapFelterIVisningsdata = ({
  visningsdata,
  feltMapper = (verdi, _) => verdi,
  feltMedEgenskaperMapper = (data, _) => data,
  forekomstMapper = (forekomst, _) => forekomst,
}) => {
  const visningsdataEntries = Object.entries(visningsdata);
  const mapFelt =
    (dataSti) =>
    ([navn, verdi]) => {
      const nyDataSti = medFelt(dataSti, navn);
      return [navn, feltMapper(verdi, nyDataSti)];
    };
  const mapFeltMedEgenskaper =
    (dataSti) =>
    ([navn, forekomst]) => {
      const nyDataSti = medFeltMedEgenskaper(dataSti, navn);
      const forekomstEntries = Object.entries(forekomst);
      const transformerteForekomstEntries = [
        ...forekomstEntries.filter(not(erFelt)),
        ...forekomstEntries.filter(erFelt).map(mapFelt(nyDataSti)),
      ];
      return [
        navn,
        feltMedEgenskaperMapper(
          Object.fromEntries(transformerteForekomstEntries),
          nyDataSti,
        ),
      ];
    };
  const mapUnderforekomst = (dataSti) => (forekomst) => {
    const nyDataSti = medBehandlingsart(
      medUnderforekomst(dataSti, forekomst.id),
      forekomst.behandlingsart,
    );
    const forekomstEntries = Object.entries(forekomst);
    const transformerteForekomstEntries = [
      ...forekomstEntries.filter(erForekomsttype),
      ...forekomstEntries
        .filter(erFeltMedEgenskaper)
        .map(mapFeltMedEgenskaper(nyDataSti)),
      ...forekomstEntries.filter(erFelt).map(mapFelt(nyDataSti)),
    ];
    return forekomstMapper(
      Object.fromEntries(transformerteForekomstEntries),
      nyDataSti,
    );
  };
  const mapUnderforekomsttype =
    (dataSti) =>
    ([navn, forekomster]) => {
      const nyDataSti = medUnderforekomsttype(dataSti, navn);
      return [
        navn,
        forekomster.map(mapUnderforekomst(nyDataSti)).filter(not(isEmpty)),
      ];
    };
  const mapForekomst = (dataSti) => (forekomst) => {
    const nyDataSti = medForekomst(dataSti, forekomst.id);
    const forekomstEntries = Object.entries(forekomst);
    const transformerteForekomstEntries = [
      ...forekomstEntries
        .filter(erFeltMedEgenskaper)
        .map(mapFeltMedEgenskaper(nyDataSti)),
      ...forekomstEntries
        .filter(erForekomsttype)
        .map(mapUnderforekomsttype(nyDataSti)),
      ...forekomstEntries.filter(erFelt).map(mapFelt(nyDataSti)),
    ];
    return Object.fromEntries(transformerteForekomstEntries);
  };
  const mapForekomsttype = ([navn, forekomster]) => {
    const dataSti = lagDatasti(navn);
    return [navn, forekomster.map(mapForekomst(dataSti)).filter(not(isEmpty))];
  };
  const transformerteVisningsdataEntries = [
    ...visningsdataEntries.filter(not(erForekomsttype)),
    ...visningsdataEntries.filter(erForekomsttype).map(mapForekomsttype),
  ];
  return Object.fromEntries(transformerteVisningsdataEntries);
};

export const harVerdi = (feltVerdi) =>
  feltVerdi || feltVerdi === 0 || feltVerdi === false;

/**
 * sett default verdi for opsjon, enten fra defaultVerdi i definisjon eller fra første verdi i definisjonen
 */
export const defaultVerdiForOpsjon = (felt, data) => {
  return has(felt, 'opsjon.defaultVerdi')
    ? feltUtils.finnDefaultVerdi(felt.opsjon.defaultVerdi, data)
    : Array.isArray(felt.opsjon.verdier)
    ? felt.opsjon.verdier[0]
    : undefined;
};
/**
 * Skrive om alle bolske verdier til streng før visningsdata sendes til mellomlager. Mellomlager håndterer ikke bolske
 * verdier og setter alt til "false" hvis ikke verdien er en streng
 * @param forekomstData
 */
export const fiksBolskeVerdier = (forekomstData) => {
  return mapObjectValues(forekomstData, (verdi) => {
    if (isBoolean(verdi)) {
      return verdi ? 'true' : 'false';
    } else if (isPlainObject(verdi)) {
      return fiksBolskeVerdier(verdi);
    } else if (isArray(verdi)) {
      return verdi.map((subforekomst) => fiksBolskeVerdier(subforekomst));
    }
    return verdi;
  });
};

export const settEgenskapPaaAlleFelter = (
  gjeldendeRad,
  forekomstData,
  kortdefinisjon,
) => {
  const { dataSti } = gjeldendeRad;
  const gjeldendeDefinisjon = (
    finnAlle('repeterendefelt', kortdefinisjon) || []
  ).find((definisjon) => definisjon.referanse === dataSti.underforekomsttype);
  const fellesEgenskapListe = get(gjeldendeDefinisjon, 'fellesEgenskap', []);

  if (fellesEgenskapListe.length < 1) {
    return forekomstData;
  }

  const klonetForekomstData = cloneDeep(forekomstData);

  fellesEgenskapListe.forEach((fellesEgenskap) => {
    const egenskapReferanse = `${fellesEgenskap.referanse}.${fellesEgenskap.egenskap}`;
    let subforekomstListe = get(
      klonetForekomstData,
      dataSti.underforekomsttype,
      [],
    );
    // eslint-disable-next-line no-shadow
    const gjeldendeRad = subforekomstListe.find(
      (subforekomst) => subforekomst.id === dataSti.underforekomst,
    );
    const egenskapVerdi = get(gjeldendeRad, egenskapReferanse, '');

    subforekomstListe = subforekomstListe.map((subforekomst) => {
      return set(subforekomst, egenskapReferanse, egenskapVerdi);
    });
    set(klonetForekomstData, dataSti.underforekomsttype, subforekomstListe);
  });

  return klonetForekomstData;
};

/**
 * Denne metoden fungerer kun for ikke-nøstede felter.
 * Bør utvides dersom det er behov for bruk i repeterendefelter, dynamiskefelter eller feltMedEgenskaper
 *
 * @param data Forekomstobjektet
 * @param definisjon Kortdefinisjonen
 * @returns {*} Ny forekomstobjekt med defaultverdi hvis kortdefinisjon inneholder input-felt med defaultverdi
 */
export const leggTilDefaultVerdier = (data, definisjon) => {
  const erEkskludertType = (def) =>
    erDynamiskgruppe(def) || erFeltMedEgenskaper(def);

  const definisjonUtenEkskluderteTyper = filterDefinisjon(
    not(erEkskludertType),
  );
  const flatListeMedFelter = alleFelter(
    definisjonUtenEkskluderteTyper(definisjon),
  );
  const feltMedDefaultverdier = flatListeMedFelter.filter(
    (felt) => not(erEkskludertType(felt)) && !!felt.defaultVerdi,
  );

  if (feltMedDefaultverdier.length) {
    const klonetData = cloneDeep(data);
    feltMedDefaultverdier.forEach((felt) => {
      if (isEmpty(get(klonetData, felt.referanse))) {
        if (typeof felt.defaultVerdi === 'string') {
          set(klonetData, felt.referanse, felt.defaultVerdi);
        } else {
          const { referanse } = felt.defaultVerdi;
          const referanseData = get(klonetData, referanse);
          set(klonetData, felt.referanse, referanseData);
        }
      }
    });

    return klonetData;
  }

  return data;
};

const flatMap = (liste, v) => (v ? liste.concat(v) : liste);
export const hentForekomsterNedTilForekomstId = (forekomst, forekomstId) => {
  if (!forekomst) {
    return [];
  }

  if (forekomst.id === forekomstId) {
    return [forekomst];
  }

  const funnetForekomstSti = Object.values(forekomst)
    .filter((val) => typeof val === 'object')
    .map((val) =>
      Array.isArray(val)
        ? val
            .map((underforekomst) =>
              hentForekomsterNedTilForekomstId(underforekomst, forekomstId),
            )
            .reduce(flatMap, [])
        : hentForekomsterNedTilForekomstId(val, forekomstId),
    )
    .reduce(flatMap, [])
    .filter((v) => !!v);

  if (!funnetForekomstSti.length) {
    return [];
  }

  return forekomst.id
    ? [forekomst].concat(funnetForekomstSti)
    : funnetForekomstSti;
};

export const hentForekomsterMedRotForekomst = (forekomst) => {
  if (!forekomst) {
    return [];
  }

  const forekomster = Object.values(forekomst)
    .filter((val) => typeof val === 'object')
    .map((val) =>
      Array.isArray(val)
        ? val
            .map((underforekomst) =>
              hentForekomsterMedRotForekomst(underforekomst),
            )
            .reduce(flatMap, [])
        : hentForekomsterMedRotForekomst(val),
    )
    .reduce(flatMap, [])
    .filter((v) => !!v);

  return forekomst.id ? [forekomst].concat(forekomster) : forekomster;
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const oppdaterAndreFeltVedEndring = (action, kortdata) => {
  const definisjon = finnDefinisjonFraVisningsdefinisjonBasertPaaReferanse(
    action.kortdefinisjon,
    action.feltreferanse,
  );
  if (!definisjon) {
    return kortdata;
  }
  const oppdaterAnnetFeltVedEndring = definisjon.oppdaterAnnetFeltVedEndring;
  if (oppdaterAnnetFeltVedEndring) {
    const klonetData = cloneDeep(kortdata);
    oppdaterAnnetFeltVedEndring
      .filter((oppdatering) =>
        feltUtils.foelgerBetingelse(kortdata, oppdatering.gittVerdi),
      )
      .forEach((oppdatering) => {
        if (oppdatering.subforekomst) {
          const subforekomster = get(klonetData, oppdatering.subforekomst);
          subforekomster.forEach((subforekomst) => {
            if (oppdatering.settReferanseverdi != null) {
              set(
                subforekomst,
                oppdatering.referanse,
                oppdatering.settReferanseverdi,
              );
            } else {
              const kopiereFra = get(
                klonetData,
                oppdatering.settReferanseverdiFraFelt,
              );
              set(subforekomst, oppdatering.referanse, kopiereFra);
            }
          });
        } else {
          if (oppdatering.settReferanseverdi != null) {
            set(
              klonetData,
              oppdatering.referanse,
              oppdatering.settReferanseverdi,
            );
          } else {
            const kopiereFra = get(
              klonetData,
              oppdatering.settReferanseverdiFraFelt,
            );
            set(klonetData, oppdatering.referanse, kopiereFra);
          }
        }
      });
    return klonetData;
  }
  return kortdata;
};

export const hentValutakode = (data, referanse, valutaFelt) => {
  if (valutaFelt) {
    const valutaSti = compact([referanse, valutaFelt.referanse]).join('.');
    const valuta = get(data, valutaSti);
    if (
      !isEmpty(valuta) &&
      valuta !== get(valutaFelt, 'opsjon.defaultVerdi', 'NOK')
    ) {
      return valuta;
    }
  }

  return undefined;
};
