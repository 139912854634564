import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { isEmpty } from 'lodash';

import ActionButton from '@skatteetaten/frontend-components/ActionButton';
import { CircleDownSVGpath, CircleUpSVGpath } from '@skatteetaten/ds-icons';
import { InlineButton } from '@skatteetaten/ds-buttons';
import oversiktStyle from './Oversikt.module.scss';
import utkast from './utkast.png';
import { SummertSkattegrunnlag } from './summertskattegrunnlag/SummertSkattegrunnlag';
import { BeregnetSkatt } from './beregnetskatt/BeregnetSkatt';
import { getBeregnetSkatt, getBeregnetSkattSvalbard } from '../../../reducers';
import { useInntektsaar } from '../../../hooks/useInntektsaar';
import { useSynligSeksjon } from '../../../hooks/useSynligSeksjon';
import { formaterTall } from '../util/FormatertTall';

export const OppsummeringOgSkatteberegning = ({
  isSkatteberegningOpen,
  skatteberegningRef,
  toggleIsSkatteberegningOpen,
}) => {
  const intl = useIntl();

  useSynligSeksjon(
    'ledetekst.oppsummering.oppsummertGrunnlag',
    skatteberegningRef,
  );

  const { beregnetSkatt = 0 } = useSelector(getBeregnetSkatt) || {};
  const beregnetSkattSvalbard = useSelector(getBeregnetSkattSvalbard);
  const { inntektsaar } = useInntektsaar();

  const visSkrivUt = !!window?.print;
  const skrivUt = () => {
    window.print();
  };

  const visDetaljerIcon = isSkatteberegningOpen
    ? CircleUpSVGpath
    : CircleDownSVGpath;
  const visDetaljerTekstId = isSkatteberegningOpen
    ? 'knapp.skjul.detaljer'
    : 'knapp.vis.detaljer';

  return (
    <div
      className={oversiktStyle.oppsummeringOgBeregning}
      ref={skatteberegningRef}
    >
      <Vannmerke />

      <OppsummeringHeader
        titleId={'header.beregnetSkatt.oppsummering'}
        values={{
          aar: inntektsaar,
          beloep: formaterTall(beregnetSkatt, intl),
        }}
      />
      <>
        <div>
          <InlineButton
            svgPath={visDetaljerIcon}
            onClick={() => toggleIsSkatteberegningOpen()}
          >
            {intl.formatMessage({ id: visDetaljerTekstId })}
          </InlineButton>
        </div>
        {isSkatteberegningOpen && (
          <>
            {visSkrivUt && (
              <ActionButton
                className={oversiktStyle.skrivUtLenke}
                icon="Print"
                onClick={skrivUt}
              >
                <FormattedMessage id="knapp.oppsummering.skrivUt" />
              </ActionButton>
            )}
            <OppsummeringOgSkatteberegningComp />
          </>
        )}

        {!isEmpty(beregnetSkattSvalbard) && (
          <div className={oversiktStyle.borderTop}>
            <OppsummeringHeader titleId="ledetekst.oppsummering.oppsummertGrunnlagForSvalbard" />
            <OppsummeringOgSkatteberegningComp svalbard />
          </div>
        )}
      </>
    </div>
  );
};

export const OppsummeringHeader = ({ titleId, values = {} }) => {
  return (
    <>
      <h2>
        <FormattedMessage id={titleId} values={values} />
      </h2>
      <div className={oversiktStyle.description}>
        <FormattedMessage id="hjelpetekst.beregnetSkatt" />
      </div>
    </>
  );
};

export const OppsummeringOgSkatteberegningComp = ({ svalbard = false }) => (
  <>
    <SummertSkattegrunnlag svalbard={svalbard} />
    <BeregnetSkatt svalbard={svalbard} />
  </>
);

const Vannmerke = () => (
  <div className={oversiktStyle.vannmerkeContainer}>
    {[...Array(10)].map((_, i) => (
      <img
        key={i}
        alt="vannmerke"
        src={utkast}
        className={oversiktStyle.vannmerke}
      />
    ))}
  </div>
);
