const tekstBundles = (aar: number, locale: string) => {
  const mappings: Record<number, any> = {
    2024: {
      nb: () =>
        import(
          /* webpackChunkName: "teksterNb2024" */
          '@skatteetaten/visningsdefinisjoner-og-tekster/lib/bundles/language/forhaandsfastsetting/2024/nb.json'
        ),
      nn: () =>
        import(
          /* webpackChunkName: "teksterNn2024" */
          '@skatteetaten/visningsdefinisjoner-og-tekster/lib/bundles/language/forhaandsfastsetting/2024/nn.json'
        ),
      en: () =>
        import(
          /* webpackChunkName: "teksterEn2024" */
          '@skatteetaten/visningsdefinisjoner-og-tekster/lib/bundles/language/forhaandsfastsetting/2024/en.json'
        ),
      se: () =>
        import(
          /* webpackChunkName: "teksterSe2024" */
          '@skatteetaten/visningsdefinisjoner-og-tekster/lib/bundles/language/forhaandsfastsetting/2024/se.json'
        ),
    },
  };

  if (!mappings[aar] || !mappings[aar][locale]) {
    throw new Error('tekstBundles: Ugyldig aar/locale ' + aar + '/' + locale);
  }

  return mappings[aar][locale];
};

const tekstSkattekalkulatorBundles = (aar: number, locale: string) => {
  const mappings: Record<number, any> = {
    2024: {
      nb: () =>
        import(
          /* webpackChunkName: "teksterSkattekalkulatorNb2024" */
          '@skatteetaten/visningsdefinisjoner-og-tekster/lib/bundles/language/forhaandsfastsetting/2024/nb_skattekalkulator.json'
        ),
      nn: () =>
        import(
          /* webpackChunkName: "teksterSkattekalkulatorNn2024" */
          '@skatteetaten/visningsdefinisjoner-og-tekster/lib/bundles/language/forhaandsfastsetting/2024/nn_skattekalkulator.json'
        ),
      en: () =>
        import(
          /* webpackChunkName: "teksterSkattekalkulatorEn2024" */
          '@skatteetaten/visningsdefinisjoner-og-tekster/lib/bundles/language/forhaandsfastsetting/2024/en_skattekalkulator.json'
        ),
      se: () =>
        import(
          /* webpackChunkName: "teksterSkattekalkulatorSe2024" */
          '@skatteetaten/visningsdefinisjoner-og-tekster/lib/bundles/language/forhaandsfastsetting/2024/se_skattekalkulator.json'
        ),
    },
  };

  if (!mappings[aar] || !mappings[aar][locale]) {
    throw new Error('tekstBundles: Ugyldig aar/locale ' + aar + '/' + locale);
  }

  return mappings[aar][locale];
};

const kodelisteTekstBundles = (locale: string) => {
  const mappings: Record<string, any> = {
    nb: () =>
      import(
        /* webpackChunkName: "kodelisterNb" */
        '@skatteetaten/visningsdefinisjoner-og-tekster/lib/bundles/language/kodelister/nb.json'
      ),
    nn: () =>
      import(
        /* webpackChunkName: "kodelisterNn" */
        '@skatteetaten/visningsdefinisjoner-og-tekster/lib/bundles/language/kodelister/nn.json'
      ),
    en: () =>
      import(
        /* webpackChunkName: "kodelisterEn" */
        '@skatteetaten/visningsdefinisjoner-og-tekster/lib/bundles/language/kodelister/en.json'
      ),
    se: () =>
      import(
        /* webpackChunkName: "kodelisterSe" */
        '@skatteetaten/visningsdefinisjoner-og-tekster/lib/bundles/language/kodelister/se.json'
      ),
  };

  if (!mappings[locale]) {
    throw new Error('kodelisteTekstBundles: Ugyldig locale ' + locale);
  }

  return mappings[locale];
};

const kodelisterUtenInntektsaarBundles = () => {
  return import(
    /* webpackChunkName: "utenInntektsaar" */
    '@skatteetaten/visningsdefinisjoner-og-tekster/lib/kodeliste/kodelister/utenInntektsaar/index'
  );
};

const kodelisteBundles = (aar: number) => {
  const mappings: Record<number, any> = {
    2024: () =>
      import(
        /* webpackChunkName: "kodelister2024" */
        '@skatteetaten/visningsdefinisjoner-og-tekster/lib/kodeliste/kodelister/2024/index'
      ),
  };

  return mappings[aar];
};

const vdotBundles = (aar: number) => {
  const mappings: Record<number, any> = {
    2024: () =>
      import(
        /* webpackChunkName: "vdot2023" */
        '@skatteetaten/visningsdefinisjoner-og-tekster/lib/bundles/vdot/2024/skattekalkulator'
      ),
  };

  if (!mappings[aar]) {
    throw new Error('vdotBundles: Ugyldig aar ' + aar);
  }

  return mappings[aar];
};

export async function getVdot(inntektsaar: number) {
  const vdot = await vdotBundles(inntektsaar)();

  return vdot.default;
}

export async function getVdotTekster(inntektsaar: number, locale: string) {
  const tekster = await tekstBundles(inntektsaar, locale)();

  return tekster.default;
}

export async function getKalkulatorTekster(
  inntektsaar: number,
  locale: string,
) {
  const teksterSkattekalkulator = await tekstSkattekalkulatorBundles(
    inntektsaar,
    locale,
  )();

  return teksterSkattekalkulator.default;
}

export async function getKodelisteTekster(locale: string) {
  const kodelisteTekster = await kodelisteTekstBundles(locale)();

  return kodelisteTekster.default;
}

export async function getKodelisterPrAar(inntektsaar: number) {
  const kodelister = await kodelisteBundles(inntektsaar)();
  const utenInntektsaar = await kodelisterUtenInntektsaarBundles();

  return {
    ...kodelister.default,
    ...utenInntektsaar.default,
  };
}
