import classnames from 'classnames';
import MessageBar from '@skatteetaten/frontend-components/MessageBar';
import Icon from '@skatteetaten/frontend-components/Icon';
import style from './Infoboks.module.scss';
import { useMediaQuery } from '../../../hooks/mediaQueryHook';

export const Infoboks = ({
  children,
  size = 'default',
  icon = 'Info',
  className,
}) => {
  const classes = classnames(
    size === 'default' ? style.infoboks : style.infoboksLarge,
    className,
  );

  const { tablet, desktop } = useMediaQuery();
  return (
    <div className={classes}>
      {(tablet || desktop) && (
        <div className={style.ikon}>
          <Icon iconName={icon} />
        </div>
      )}
      <div>{children}</div>
    </div>
  );
};

Infoboks.Type = MessageBar.Type;
