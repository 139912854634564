export const SET_FEIL_I_DIALOG = 'feil_SET_FEIL_I_DIALOG';
export const SET_FLERE_FEIL_I_DIALOG = 'feil_SET_FLERE_FEIL_I_DIALOG';
export const FJERN_FEIL_I_DIALOG = 'feil_FJERN_FEIL_I_DIALOG';
export const FJERN_FLERE_FEIL_I_DIALOG = 'feil_FJERN_FLERE_FEIL_I_DIALOG';
export const FJERN_ALLE_FEIL_I_DIALOG = 'feil_FJERN_ALLE_FEIL_I_DIALOG';

export const FEILKILDE_SYSTEM = 'FEILKILDE_SYSTEM';
export const FEILKILDE_BRUKERINPUT = 'FEILKILDE_BRUKERINPUT';

export const SYSTEMFEIL = 'SYSTEMFEIL';
export const TOEM_ALLE_SYSTEMFEIL = 'TOEM_ALLE_SYSTEMFEIL';

export const setFeilIDialog = (feilreferanse, feil, kilde) => ({
  type: SET_FEIL_I_DIALOG,
  feilreferanse,
  feil,
  kilde,
});

export const overskrivFeilIDialog = (idFeilObjekt) => ({
  type: SET_FLERE_FEIL_I_DIALOG,
  idFeilObjekt,
});

export const fjernFeilIDialog = (feltRef, kategoriRef) => ({
  type: FJERN_FEIL_I_DIALOG,
  feltRef,
  kategoriRef,
});

export const fjernFlereFeilIDialog = (ider) => ({
  type: FJERN_FLERE_FEIL_I_DIALOG,
  ider,
});

export const fjernAlleFeilIDialog = () => ({
  type: FJERN_ALLE_FEIL_I_DIALOG,
});

export const systemfeilMedFeillokasjon = (feillokasjon) => (feil) => ({
  type: SYSTEMFEIL,
  feil: {
    feillokasjon,
    ...feil,
  },
});

/*
 * Signaliserer en fatal feil, alt stopper og bruker må starte applikasjonen på nytt.
 */
export const systemfeil = (feil) => ({
  type: SYSTEMFEIL,
  feil,
});
export const toemSystemfeil = () => ({
  type: TOEM_ALLE_SYSTEMFEIL,
});
