import { v4 as uuid } from 'uuid';
import {
  UNDERFOREKOMST,
  UNDERFOREKOMSTTYPE,
} from '../utils/visningsDataDefinisjonUtil';

export const DIALOG_ENDRE = 'DIALOG_ENDRE';
export const DIALOG_LAGRE_EKTEFELLE = 'DIALOG_LAGRE_EKTEFELLE';
export const DIALOG_ENDRE_OK = 'DIALOG_ENDRE_OK';
export const DIALOG_NY_LAGRE = 'DIALOG_NY_LAGRE';
export const DIALOG_NY_LAGRE_EKTEFELLE = 'DIALOG_NY_LAGRE_EKTEFELLE';
export const DIALOG_NY_OK = 'DIALOG_NY_OK';
export const DIALOG_SLETT_KORT = 'DIALOG_SLETT_KORT';
export const DIALOG_SLETT_KORT_EKTEFELLE = 'DIALOG_SLETT_KORT_EKTEFELLE';

export const UNDERFOREKOMST_FRA_LISTEVISNING =
  'dialog_REDIGER_UNDERFOREKOMST_FRA_LISTEVISNING';
export const LAGRE_UNDERFOREKOMST_FRA_LISTEVISNING =
  'dialog_LAGRE_UNDERFOREKOMST_FRA_LISTEVISNING';
export const AVBRYT_REDIGERING_UNDERFOREKOMST_FRA_LISTEVISNING =
  'dialog_AVBRYT_REDIGERING_UNDERFOREKOMST_FRA_LISTEVISNING';
export const SLETT_UNDERFOREKOMST_FRA_LISTEVISNING =
  'dialog_SLETT_UNDERFOREKOMST_FRA_LISTEVISNING';

export const VIS_DIALOG = 'dialog_VIS_DIALOG';
export const VIS_KORT_I_KORT_DIALOG = 'dialog_VIS_KORT_I_KORT_DIALOG';
export const BYTT_DIALOG = 'dialog_BYTT_DIALOG';
export const NY_DIALOG = 'dialog_NY_DIALOG';
export const OK_KLIKKET = 'dialog_OK_KLIKKET';
export const NY_DIALOG_MED_FLERE_KORTTYPER =
  'dialog_NY_DIALOG_MED_FLERE_KORTTYPER';
export const LUKK_DIALOG = 'dialog_LUKK_DIALOG';
export const ENDRE_FELT_I_DIALOG = 'dialog_ENDRE_FELT';
export const LEGG_TIL_REPETERENDEFELT_I_DIALOG =
  'LEGG_TIL_REPETERENDEFELT_I_DIALOG';
export const SLETT_REPETERENDEFELT_I_DIALOG = 'SLETT_REPETERENDEFELT_I_DIALOG';
export const SLETT_DYNAMISKFELT_I_DIALOG = 'SLETT_DYNAMISKFELT_I_DIALOG';
export const NY_LEGG_TIL_OPPLYSNINGER_DIALOG =
  'NY_LEGG_TIL_OPPLYSNINGER_DIALOG';
export const NY_DIALOG_MED_DIALOGTYPE = 'NY_DIALOG_MED_DIALOGTYPE';

export const DIALOGTYPE_LEGG_TILOPPLYSNINGER = 'leggTilOpplysninger';
export const DIALOGTYPE_STEG = 'steg';
export const DIALOGTYPE_VELG_KORTTYPE = 'velgKorttypeDialog';
export const DIALOGTYPE_VELG_INNGANG_TIL_KORTTYPE = 'velgInngangTilKorttype';
export const DIALOGTYPE_DIALOG = 'dialog';
export const DIALOGTYPE_TEKST = 'DIALOGTYPE_TEKST';
export const DIALOGTYPE_LEGG_TIL_OPPLYSNINGER =
  'DIALOGTYPE_LEGG_TIL_OPPLYSNINGER';
export const DIALOGTYPE_KORT_I_KORT = 'DIALOGTYPE_KORT_I_KORT';
export const TOEM_HURTIGSOEK = 'TOEM_HURTIGSOEK';

export const FJERN_FOKUS_BEHANDLINGSART = 'FJERN_FOKUS_BEHANDLINGSART';
export const FELT_ENDRET = 'FELT_ENDRET';

export const BEREGN_DIALOG = 'BEREGN_VED_ENDRING_I_DIALOG';
export const API_BEREGN_DIALOG_SUKSESS = 'API_BEREGN_DIALOG_SUKSESS';

// TODO ser ikke ut som om dialogEndre og dialogNy har noen forskjeller i denne appen...
export const dialogEndre = (props) => ({
  type: DIALOG_ENDRE,
  props,
});

export const dialogLagreEktefelle = () => ({
  type: DIALOG_LAGRE_EKTEFELLE,
});

export const dialogNyLagreEktefelle = () => ({
  type: DIALOG_NY_LAGRE_EKTEFELLE,
});

export const dialogEndreOk = () => ({
  type: DIALOG_ENDRE_OK,
});

export const dialogNyLagre = () => ({
  type: DIALOG_NY_LAGRE,
});

export const dialogNyOk = () => ({
  type: DIALOG_NY_OK,
});

export const slettKortViaDialog = () => ({
  type: DIALOG_SLETT_KORT,
});

export const slettKortViaDialogEktefelle = () => ({
  type: DIALOG_SLETT_KORT_EKTEFELLE,
});

export const endreFeltIDialog = (
  id,
  feltreferanse,
  verdi,
  kortdefinisjon = {},
) => ({
  type: ENDRE_FELT_I_DIALOG,
  id,
  feltreferanse,
  verdi,
  kortdefinisjon,
});

export const redigerUnderforekomstFraListevisning = (dataSti) => ({
  type: UNDERFOREKOMST_FRA_LISTEVISNING,
  dataSti,
});
export const lagreUnderforekomstFraListevisning = () => ({
  type: LAGRE_UNDERFOREKOMST_FRA_LISTEVISNING,
});
export const avbrytRedigeringAvUnderforekomstFraListevisning = () => ({
  type: AVBRYT_REDIGERING_UNDERFOREKOMST_FRA_LISTEVISNING,
});

export const slettUnderforekomstFraListevisning = (dataSti) => ({
  type: SLETT_UNDERFOREKOMST_FRA_LISTEVISNING,
  feltreferanse: dataSti[UNDERFOREKOMSTTYPE],
  id: dataSti[UNDERFOREKOMST],
});

export const leggTilRepeterendefeltIDialog = (
  feltreferanse,
  erUnderforekomstIListevisning,
) => ({
  type: LEGG_TIL_REPETERENDEFELT_I_DIALOG,
  feltreferanse,
  erUnderforekomstIListevisning,
});

export const slettRepeterendefeltIDialog = (feltreferanse, id) => ({
  type: SLETT_REPETERENDEFELT_I_DIALOG,
  feltreferanse,
  id,
});

export const slettDynamiskfeltIDialog = (feltreferanse, dynamiskfelt) => ({
  type: SLETT_DYNAMISKFELT_I_DIALOG,
  feltreferanse,
  dynamiskfelt,
});

export const visDialog = (
  dialogtype,
  kortdefinisjon,
  data,
  temaId,
  kortgruppe,
) => ({
  type: VIS_DIALOG,
  kortdefinisjon,
  dialogtype,
  data,
  temaId,
  kortgruppe,
});

export const visFeilDialog = (action, tittel, beskrivelse) => {
  // logger.warn(beskrivelse, `action: ${JSON.stringify(action)}`);

  return visDialog(
    DIALOGTYPE_TEKST,
    {},
    {
      tittel,
      beskrivelse,
    },
  );
};

export const nyDialog = (
  kortdefinisjon,
  temaId,
  // TODO trengs disse to?
  data = {},
  egenskaper = undefined,
) => ({
  type: NY_DIALOG,
  dialogtype: DIALOGTYPE_DIALOG,
  kortdefinisjon,
  data: {
    id: kortdefinisjon.statiskId || uuid(),
    ...data,
  },
  temaId,
  egenskaper,
});

export const okKlikket = () => ({
  type: OK_KLIKKET,
  dato: new Date(),
});

export const onFeltEndret = (
  feltId,
  feltsti,
  erEgenskap = false,
  verdiFoerEndring,
  nyVerdi,
) => ({
  type: FELT_ENDRET,
  feltId,
  feltsti,
  erEgenskap,
  verdiFoerEndring,
  nyVerdi,
});

export const nyDialogMedFlereKortTyper = (kortgruppe, temaId, data = []) => ({
  type: NY_DIALOG_MED_FLERE_KORTTYPER,
  dialogtype: DIALOGTYPE_VELG_KORTTYPE,
  kortgruppe,
  temaId,
  data,
});

export const nyDialogMedDialogtype = (
  kortgruppe,
  temaId,
  dialogtype,
  data,
  utvidetVeiledningId,
  kortdefinisjon,
  egenskaper,
) => ({
  type: NY_DIALOG_MED_DIALOGTYPE,
  dialogtype: dialogtype || kortgruppe.dialogtype,
  kortgruppe,
  temaId,
  data,
  utvidetVeiledningId,
  kortdefinisjon,
  egenskaper,
});

export const lukkDialog = () => ({
  type: LUKK_DIALOG,
});

export const nyLeggTilOpplysningerDialog = (temaId) => ({
  type: NY_LEGG_TIL_OPPLYSNINGER_DIALOG,
  dialogtype: DIALOGTYPE_LEGG_TILOPPLYSNINGER,
  temaId,
});

export const leggTilNyKortgruppe = (kortgruppe, temaId) => {
  const liste = kortgruppe.barnliste.filter((korttype) => !korttype.ikkeny);

  if (kortgruppe.dialogtype && kortgruppe.dialogtype !== DIALOGTYPE_STEG) {
    return nyDialogMedDialogtype(kortgruppe, temaId);
  } else if (liste.length === 1) {
    return nyDialog(liste[0], temaId);
  }
  return nyDialogMedFlereKortTyper(kortgruppe, temaId);
};

export const byttDialog = (dialogtype, data, fraDialog) => ({
  type: BYTT_DIALOG,
  dialogtype,
  data,
  fraDialog,
});

export const visKortIKortDialog = (kortIKortDialogProps, fraDialog) => ({
  type: VIS_KORT_I_KORT_DIALOG,
  dialogtype: DIALOGTYPE_KORT_I_KORT,
  kortIKortDialogProps,
  fraDialog,
});

export const fjernFokusBehandlingsart = () => ({
  type: FJERN_FOKUS_BEHANDLINGSART,
});

export const toemHurtigsoek = () => ({
  type: TOEM_HURTIGSOEK,
});

export const beregnDialog = () => ({
  type: BEREGN_DIALOG,
});

export const beregnDialogSuksess = (beregnetVisningsdata) => ({
  type: API_BEREGN_DIALOG_SUKSESS,
  visningsdata: beregnetVisningsdata,
});
