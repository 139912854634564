const barn = (def) => def.barnliste;

const erTema = (def) => def.type === 'tema';

const erKortgruppe = (def) => def.type === 'kortgruppe';

const alleKortgrupper = (visningsdefinisjon) =>
  visningsdefinisjon.filter(erTema).flatMap(barn).filter(erKortgruppe);

/**
 * Henter kortgruppedefinisjon som tilhører id
 */
export const hentKortgruppedefinisjon = (visningsdefinisjon, id) =>
  alleKortgrupper(visningsdefinisjon).find(
    (kortgruppedef) => kortgruppedef.id === id,
  );

export const hentKorttyperForKortgruppe = (kortgruppedefinisjon) =>
  kortgruppedefinisjon.barnliste.filter((it) => it.type === 'korttype');

export const forekomsterForReferanse = (visningsdata, referanse) =>
  visningsdata[referanse];

export const forekomsterForKorttype = (korttypedefinisjon, visningsdata) =>
  forekomsterForReferanse(visningsdata, korttypedefinisjon.referanse) || [];
