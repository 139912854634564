import { visningslogikk } from '@skatteetaten/visningsdefinisjoner-og-tekster';
import { each, find, isArray, isUndefined } from 'lodash';
import { mapObjectKeys } from './object';

const { visningsdefinisjonUtils } = visningslogikk;

export const TEMA = 'tema';
export const KORTGRUPPE = 'kortgruppe';
export const KORTTYPE = 'korttype';
export const FELTGRUPPE = 'feltgruppe';
export const REPETERENDE_FELTGRUPPE = 'repeterendefelt';
export const DYNAMISK_GRUPPE = 'dynamiskgruppe';
export const DYNAMISK_FELT = 'dynamiskfelt';
export const FELT = 'felt';
export const FELT_MED_EGENSKAPER = 'feltMedEgenskaper';
export const FLER_FELT_VISNING = 'flerFeltVisning';
export const FELTSEPARATOR = 'feltseparator';

export const FELTREFERANSE = 'feltreferanse';
export const FELTMEDEGENSKAPER = 'feltmedegenskaper';
export const UNDERFOREKOMST = 'underforekomst';
export const UNDERFOREKOMSTTYPE = 'underforekomsttype';
export const FOREKOMST = 'forekomst';
export const FOREKOMSTTYPE = 'forekomsttype';
export const BEHANDLINGSART = 'behandlingsart';
export const BELOEPSFELT = 'belopsfelt';

export const PREFIX_KOBLING = 'kobling_';
export const leggTilPrefixPaaFellesNoekler = (objekt, prefix) =>
  mapObjectKeys(objekt, (noekkel) =>
    harFellesNoekler(noekkel) ? `${prefix}${noekkel}` : noekkel,
  );

export const erOpsjon = (def) => def.opsjon;
export const erTema = (def) => def.type === TEMA;

export const erKortgruppe = (def) => def.type === KORTGRUPPE;

export const erKorttype = (def) => def.type === KORTTYPE;

export const erFeltgruppe = (def) => def.type === FELTGRUPPE;

export const erRepeterendeFeltgruppe = (def) =>
  def?.type === REPETERENDE_FELTGRUPPE;

export const erDynamiskgruppe = (def) => def?.type === DYNAMISK_GRUPPE;

export const erDynamiskfelt = (def) => def.type === DYNAMISK_FELT;

export const erFelt = (def) => def.type === FELT;

export const erFeltMedEgenskaper = (def) => def?.type === FELT_MED_EGENSKAPER;

export const erKortIKort = (def) => def.kortIKort;

export const erBeregnetFelt = (def) => def.beregnetFelt === true;

export const erLesModus = (def) => def.modus === 'les';

export const erFlerFeltVisning = (def) => def.type === FLER_FELT_VISNING;

export const medReferanse = (ref) => (felt) => !!felt && felt.referanse === ref;

export const erEgenskapsfelt = (def) => def.typeEgenskap;

export const erEgenskapPaaObjekt = (def) => def.erEgenskapPaaObjekt;

export const erBeloepsfelt = (def) =>
  def.referanse.endsWith('.beloep') || def.referanse.endsWith('.beloepIValuta');

export const fjernBeloepFraReferanse = (ref) =>
  ref.replace('.beloepIValuta', '').replace('.beloep', '');

export const tomDatasti = {};

export const medFelt = (dataSti, feltreferanse) => ({
  ...dataSti,
  [FELTREFERANSE]: feltreferanse,
});

export const medFeltMedEgenskaper = (dataSti, forekomst) => ({
  ...dataSti,
  [FELTMEDEGENSKAPER]: forekomst,
});

export const medUnderforekomst = (dataSti, underforekomst) => ({
  ...dataSti,
  [UNDERFOREKOMST]: underforekomst,
});

export const medUnderforekomsttype = (dataSti, underforekomsttype) => ({
  ...dataSti,
  [UNDERFOREKOMSTTYPE]: underforekomsttype,
});

export const medForekomst = (dataSti, forekomst) => ({
  ...dataSti,
  [FOREKOMST]: forekomst,
});

export const medBehandlingsart = (dataSti, behandlingsart) => ({
  ...dataSti,
  [BEHANDLINGSART]: behandlingsart,
});

export const medForekomsttype = (dataSti, forekomsttype) => ({
  ...dataSti,
  [FOREKOMSTTYPE]: forekomsttype,
});

export const finnDefinisjonFraVisningsdefinisjonBasertPaaReferanse = (
  visningsdefinisjon,
  referanse,
) => {
  let retObj = find(visningsdefinisjon, { referanse: referanse });

  if (isUndefined(retObj)) {
    const barner = isArray(visningsdefinisjon)
      ? visningsdefinisjon
      : [visningsdefinisjon];
    each(barner, (barn) => {
      if (erFeltMedEgenskaper(barn)) {
        const referanseArray = referanse.split('.');
        if (barn.referanse === referanseArray[0]) {
          retObj = finnDefinisjonFraVisningsdefinisjonBasertPaaReferanse(
            [barn.belopsfelt, ...barn.barnliste],
            referanseArray[1],
          );
        }
      } else if (barn.barnliste) {
        retObj = finnDefinisjonFraVisningsdefinisjonBasertPaaReferanse(
          barn.barnliste,
          referanse,
        );
      }
      return isUndefined(retObj); // Avbryte loop hvis objekt finnes
    });
  }
  return retObj;
};

export const harFellesNoekler = (noekkel) => {
  const fellesNoekler = visningsdefinisjonUtils.hentFellesDataAttributter();
  return fellesNoekler.includes(noekkel);
};
