export const BEREGN = 'BEREGN';
export const API_BEREGN_SUKSESS = 'api_BEREGN_SUKSESS';

export const beregn = () => ({
  type: BEREGN,
});

export const beregnSuksess = (data) => ({
  type: API_BEREGN_SUKSESS,
  skattemeldingResultat: data,
  skattemeldingResultatTilgjengelig: data.skattemeldingResultatTilgjengelig,
});
