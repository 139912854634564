const generellFeilDialog = {
  skalFeilHaandteres: () => true,
  overskriftId: 'feil.teknisk.overskrift',
  meldingId: 'feil.teknisk.generell',
};

export const dialogInnholdForFeil = (feil) =>
  [generellFeilDialog].find((dialogInnhold) =>
    dialogInnhold.skalFeilHaandteres(feil),
  );
