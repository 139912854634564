import { ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'universal-cookie';
import { useIntl } from 'react-intl';
import { TopBanner } from '@skatteetaten/frontend-components/TopBanner';
import { TopStripe } from '@skatteetaten/frontend-components/TopStripe';
import { Typography } from '@skatteetaten/frontend-components/Typography';
import { LanguagePicker } from '@skatteetaten/frontend-components/TopStripe/LanguagePicker';
import { getTopDomain, langToFulllocale } from '../../language/util';
import { getLocale } from '../../reducers';
import { oppdaterLocale } from '../../reducers/intl';
import { Footer } from '@skatteetaten/ds-layout';
import style from './Layout.module.css';
import { useAnalytics } from '../../analytics/analytics.hooks';

const cookies = new Cookies();

type Props = {
  title: string | JSX.Element | undefined;
  children: ReactNode;
};

export function Layout(props: Props) {
  const intl = useIntl();
  const analytics = useAnalytics();
  const locale = useSelector(getLocale);
  const dispatch = useDispatch();
  const onByttSpraak = (language: any) => {
    document.documentElement.setAttribute('lang', language);

    cookies.set('spraak', langToFulllocale(language), {
      path: '/',
      domain: getTopDomain(),
    });
    dispatch(oppdaterLocale(language));
  };

  return (
    <div className={style.layout}>
      <TopStripe className={style.topStripe}>
        <LanguagePicker
          selectedLanguage={locale}
          setLanguage={onByttSpraak}
          showOnMobile={true}
          showSami={false}
          className={style.languagePicker}
        />
      </TopStripe>
      <TopBanner
        external={true}
        homeText={intl.formatMessage({ id: 'header.link' })}
        homeUrl="https://www.skatteetaten.no"
        title={props.title}
        onClick={() => analytics.knappKlikk('Naviger til skatteetaten.no', '')}
      />
      <div className={style.container}>
        <div className={style.content}>
          <Typography>{props.children}</Typography>
        </div>
      </div>
      <Footer
        titleFirstColumn={intl.formatMessage({
          id: 'ledetekst.omSkatteetaten',
        })}
      />
    </div>
  );
}
