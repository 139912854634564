import { forwardRef } from 'react';
import {
  Alert as DSAlert,
  AlertProps as DSAlertProps,
} from '@skatteetaten/ds-status';

export interface AlertProps extends Omit<DSAlertProps, 'variant'> {
  variant?: DSAlertProps['variant'];
}

export const Alert = forwardRef<HTMLDivElement, AlertProps>(
  ({ children, variant = 'neutral', showAlert = true, ...props }, ref) => (
    <DSAlert ref={ref} {...props} variant={variant} showAlert={showAlert}>
      {children}
    </DSAlert>
  ),
);

Alert.displayName = 'SkattekalkulatorAlert';
