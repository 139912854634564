import {
  Card,
  CardBorder,
  CardColor,
} from '@skatteetaten/frontend-components/Card';
import { NavigationTile } from '@skatteetaten/frontend-components/NavigationTile';
import { NavigationContent } from '@skatteetaten/frontend-components/NavigationTile/NavigationContent';
import style from './VelgKalkulator.module.scss';
import { useIntl } from 'react-intl';
import { useAnalytics } from '../../analytics/analytics.hooks';
import { useEffect } from 'react';

export const urlTilGammelKalkulator =
  'https://skattekalkulator.app.skatteetaten.no/';

export const VelgKalkulator = () => {
  const intl = useIntl();
  const analytics = useAnalytics();

  const analyticsLokasjon = 'Velg kalkulator side';
  useEffect(() => analytics.visning(analyticsLokasjon), [analytics]);

  return (
    <div className={style.cardWrapper}>
      <Card
        color={CardColor.WHITE}
        border={CardBorder.RED_BORDER}
        title={intl.formatMessage({ id: 'ledetekst.velgKalkulator.tittel' })}
        titlesize={'large'}
        className={style.card}
      >
        <div>
          <NavigationTile
            ariaLabel={intl.formatMessage({
              id: 'ledetekst.velgKalkulator.ariaLabel',
            })}
          >
            <NavigationContent
              icon={'ArrowForward'}
              heading={intl.formatMessage({
                id: 'ledetekst.velgKalkulator.nyKalkulator',
              })}
              to={'/skattekalkulator/skatteplikt'}
              onClick={() =>
                analytics.knappKlikk(
                  'Naviger til ny kalkulator',
                  analyticsLokasjon,
                )
              }
            />
            <NavigationContent
              icon={'ArrowForward'}
              heading={intl.formatMessage({
                id: 'ledetekst.velgKalkulator.gammelKalkulator',
              })}
              to={urlTilGammelKalkulator}
              onClick={() =>
                analytics.knappKlikk(
                  'Naviger til gammel kalkulator',
                  analyticsLokasjon,
                )
              }
            />
          </NavigationTile>
        </div>
      </Card>
    </div>
  );
};
