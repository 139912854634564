import { visningslogikk } from '@skatteetaten/visningsdefinisjoner-og-tekster';

import { Inputfelt } from '../../felter/Inputfelt';
import { Opsjon } from '../../Opsjon';
import { Datovelger } from '../../Datovelger';

const { felt: feltUtils } = visningslogikk;

export const RedigerbartfeltIDialog = ({
  disabled,
  felt,
  verdi,
  visningsVerdi,
  onChange,
  feil,
  valider,
  onFocus,
  obligatorisk,
  placeholder,
  valideringsstate,
  focusElement,
  suffix,
  maksLengde,
  feltReferanse,
}) => {
  const beholdVerdi = feltUtils.feltHarBeholdVerdi(felt);

  if (felt.opsjon) {
    return (
      <Opsjon
        disabled={disabled}
        felt={felt}
        verdi={verdi}
        onChange={onChange}
        feil={!disabled || beholdVerdi ? feil : []}
        valider={valider}
        onFocus={onFocus}
        obligatorisk={obligatorisk}
        valideringsstate={valideringsstate}
        feltReferanse={feltReferanse}
      />
    );
  }

  if (felt.datovelger) {
    return (
      <Datovelger
        disabled={disabled}
        felt={felt}
        verdi={verdi}
        onFocus={onFocus}
        obligatorisk={obligatorisk}
        feil={!disabled || beholdVerdi ? feil : []}
        valider={valider}
        onChange={onChange}
        valideringsstate={valideringsstate}
      />
    );
  }

  return (
    <Inputfelt
      disabled={disabled}
      felt={felt}
      verdi={verdi}
      visningsVerdi={visningsVerdi}
      onChange={onChange}
      feil={!disabled || beholdVerdi ? feil : []}
      valider={valider}
      obligatorisk={obligatorisk}
      onFocus={onFocus}
      placeholder={placeholder}
      valideringsstate={valideringsstate}
      focusElement={focusElement}
      suffix={suffix}
      maksLengde={maksLengde}
    />
  );
};
