import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { v4 as uuid } from 'uuid';
import FocusTrap from 'focus-trap-react';

import style from './SmeDialog.module.css';
import SkeBasis from '@skatteetaten/frontend-components/SkeBasis';
import IconButton from '@skatteetaten/frontend-components/IconButton';
import { useWindowWidth } from '../../../../hooks/useWindowWidth';

export const SmeDialogContext = createContext({});

export const SmeDialogProvider = ({ children }) => {
  const [synligeDialoger, setSynligeDialoger] = useState([]);
  const [pauseFocustrap, setPauseFocustrap] = useState(false);

  //Hindrer problemer med scrolling når du viser dialog.
  //https://stackoverflow.com/questions/39962757/prevent-scrolling-using-css-on-react-rendered-components
  useEffect(() => {
    if (synligeDialoger.length) {
      document.body.style['overflow-y'] = 'hidden';
    } else {
      document.body.style['overflow-y'] = 'auto';
    }
  }, [synligeDialoger]);

  const viserDialog = useCallback(
    (id) => setSynligeDialoger((dialoger) => dialoger.concat(id)),
    [],
  );
  const skjulerDialog = useCallback(
    (id) =>
      setSynligeDialoger((dialoger) =>
        dialoger.filter((dialogId) => dialogId !== id),
      ),
    [],
  );

  return (
    <SmeDialogContext.Provider
      value={{
        viserDialog,
        skjulerDialog,
        setPauseFocustrap,
        pauseFocustrap,
      }}
    >
      <div style={synligeDialoger.length ? { ariaHidden: true } : {}}>
        {children}
      </div>
    </SmeDialogContext.Provider>
  );
};

export const SmeDialog = ({
  id,
  children,
  hidden,
  title,
  isBlocking = false,
  onDismiss,
  maxWidth,
}) => {
  const { viserDialog, skjulerDialog, pauseFocustrap } =
    useContext(SmeDialogContext);
  const idRef = useRef(uuid());
  const focusElRef = useRef();
  const dialogWidth = useWindowWidth();

  useEffect(() => {
    const dialogId = idRef.current;
    const focusEl = focusElRef.current;

    if (hidden) {
      skjulerDialog(dialogId);

      if (focusEl) {
        focusEl.focus?.();
      }
    } else {
      viserDialog(dialogId);
      focusElRef.current = document.querySelectorAll(':focus')[0];
    }

    return () => {
      skjulerDialog(dialogId);
      if (focusEl) {
        focusEl.focus?.();
      }
    };
  }, [hidden, skjulerDialog, viserDialog]);

  if (hidden) {
    return null;
  }

  const onOverlayClick = () => !isBlocking && onDismiss?.();

  return createPortal(
    <SkeBasis>
      <FocusTrap paused={pauseFocustrap}>
        <div
          role="alertdialog"
          aria-modal={true}
          aria-label={title}
          style={{ outline: 'none' }}
        >
          <div
            id={id}
            className={style.dialogContainer}
            style={{ width: dialogWidth, maxWidth }}
          >
            <div className={style.dialog}>
              <div className={style.dialogHeaderContainer}>
                <h1 className={style.dialogHeader}>{title}</h1>
                {onDismiss && <IconButton icon="Cancel" onClick={onDismiss} />}
              </div>
              <div className={style.dialogContent}>{children}</div>
            </div>
            <div className={style.overlay} onClick={onOverlayClick}></div>
          </div>
        </div>
      </FocusTrap>
    </SkeBasis>,
    document.getElementById('dialogRoot'),
  );
};
