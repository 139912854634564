import { get } from 'lodash';
import { Kortfelt } from './Kortfelt';
import { KortfeltMedEgenskaper } from './KortfeltMedEgenskaper';
import { DynamiskGruppe } from './DynamiskGruppe';
import {
  DYNAMISK_GRUPPE,
  FELT,
  FELT_MED_EGENSKAPER,
  FELTGRUPPE,
} from '../../../../utils/visningsDataDefinisjonUtil';
import { useBetingelseHook } from '../../../../hooks/useBetingelseHook';

export const KortFeltgruppe = ({ feltgruppe, data }) => {
  const { skalFeltDisables, skalFeltSkjules, skalBeholdeVerdi } =
    useBetingelseHook();

  return (feltgruppe.barnliste || [])
    .filter((barn) => !barn.skjulIKort)
    .map((barn) => {
      const hidden =
        (skalFeltSkjules(barn, data) || skalFeltDisables(barn, data)) &&
        !skalBeholdeVerdi(barn, data);

      switch (barn.type) {
        case FELT:
          return (
            <Kortfelt
              key={`${data.id}.${barn.referanse || barn.id}`}
              hidden={hidden}
              feltdefinisjon={barn}
              data={get(data, barn.referanse || barn.id)}
            />
          );
        case FELT_MED_EGENSKAPER:
          return (
            <KortfeltMedEgenskaper
              key={`${data.id}.${barn.id}`}
              data={data}
              hidden={hidden}
              {...barn}
            />
          );
        case DYNAMISK_GRUPPE:
          return <DynamiskGruppe key={barn.id} data={data} gruppe={barn} />;
        case FELTGRUPPE:
          return <KortFeltgruppe key={barn.id} data={data} feltgruppe={barn} />;

        default:
          return null;
      }
    });
};
