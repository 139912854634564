import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { language } from '@skatteetaten/visningsdefinisjoner-og-tekster';
import { StorDialog } from '../utils/StorDialog';
import { ListeBehandlingsart } from './ListeBehandlingsart';
import { ListeEgenskaper } from './ListeEgenskaper';
import { DialogKnapperad } from '../utils/DialogKnapperad';
import { DialogAvbryt } from '../utils/DialogAvbryt';
import FormattedHTMLMessage from '../../FormattedHTMLMessage';
import { byttDialog, DIALOGTYPE_DIALOG } from '../../../../actions/dialog';
import { getDefinisjonForId, getDialog } from '../../../../reducers';
import { REPETERENDE_FELTGRUPPE } from '../../../../utils/visningsDataDefinisjonUtil';
import { useKildeSkattPaaLoenn } from '../../kildeskattePaaLoenn/useKildeskattPaaLoenn';

export const LeggTilKortOpplysningerDialog = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { beregnKplModus } = useKildeSkattPaaLoenn();

  const {
    kortdefinisjon,
    data,
    kortIKortDialogProps = {},
    fraDialog = DIALOGTYPE_DIALOG,
  } = useSelector(getDialog);

  const {
    definisjonsSti = {},
    underforekomstDataSti: {
      underforekomsttype,
      underforekomst: underforekomstId,
    } = {},
  } = kortIKortDialogProps;

  const dialogData = underforekomsttype
    ? data[underforekomsttype].find(
        (underforekomst) => underforekomst.id === underforekomstId,
      )
    : data;

  const kortgruppeFelt = useSelector(
    getDefinisjonForId(definisjonsSti[REPETERENDE_FELTGRUPPE]),
  );

  const definisjon = kortgruppeFelt || kortdefinisjon;

  const avbryt = () => dispatch(byttDialog(fraDialog));

  const title = intl.formatMessage({
    id: language.leggtilKnappId(definisjon.id),
    defaultMessage: intl.formatMessage({
      id: 'ledetekst.leggTilOpplysninger.dialog.tittel',
    }),
  });

  return (
    <StorDialog
      title={title}
      avbrytAksjon={avbryt}
      visObligatoriskFeltMelding={false}
    >
      <FormattedHTMLMessage
        id="ledetekst.opplysninger.du.kan.legge.til"
        values={{
          korttype: intl.formatMessage({
            id: language.dialogHeaderId(definisjon.id),
          }),
        }}
      />
      <ListeEgenskaper
        kortdefinisjon={definisjon}
        data={dialogData}
        beregnKplModus={beregnKplModus}
      />
      {!beregnKplModus && (
        <ListeBehandlingsart kortdefinisjon={definisjon} data={dialogData} />
      )}

      <DialogKnapperad>
        <DialogAvbryt aksjon={avbryt} lukkEtterAksjon={false} />
      </DialogKnapperad>
    </StorDialog>
  );
};
