import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { get, isEmpty } from 'lodash';

import { language } from '@skatteetaten/visningsdefinisjoner-og-tekster';
import style from '../beregnetskatt/BeregnetSkatt.module.scss';
import { getErPaaNaeringsspesifikasjonSide } from '../../../../reducers';
import { Oppsummeringsgruppe } from './Oppsummeringsgruppe';
import { OppsummeringLinje, OppsummeringLinjeV2 } from './OppsummeringLinje';
import { OverskriftLinje } from './OverskriftLinje';
import { useAktivSkvis, useSkvisData } from '../../../../hooks/useAktivSkvis';
// import { NaeringOppsummeringDetaljer } from '../../naeringsspesifikasjon/NaeringOppsummeringDetaljer';
import {
  lagSkvisGruppeHeader,
  sorterSkvisGrupper,
} from '../../../../utils/skvisUtils';
import { filterSvalbard } from './summertSkattegrunlagUtil';
import { useInntektsaar } from '../../../../hooks/useInntektsaar';

export const SummertSkattegrunnlag = ({ svalbard = false }) => {
  const { inntektsaar } = useInntektsaar();
  const skvisningsdefinisjon = useAktivSkvis();
  const skvisData = useSkvisData(svalbard);

  // TODO sjekk hva dette er
  const beregningIkkeTilgjengelig = false;

  return (
    <div>
      {skvisningsdefinisjon
        // TODO kunne vi isteden hatt to definisjoner? 1 for svalbard, 1 for vanlig
        .filter(filterSvalbard(svalbard))
        .filter((linje) => isEmpty(linje.barnliste))
        .map((linje) => (
          <DelOppsummering
            key={linje.id}
            linje={linje}
            skvisData={skvisData}
            beregningIkkeTilgjengelig={beregningIkkeTilgjengelig}
            svalbard={svalbard}
          />
        ))}
      {skvisData && (
        <OppsummeringSkattefri
          skvis={skvisningsdefinisjon}
          skvisData={skvisData}
          beregningIkkeTilgjengelig={beregningIkkeTilgjengelig}
          inntektsaar={inntektsaar}
        />
      )}
      {skvisningsdefinisjon
        .filter(filterSvalbard(svalbard))
        .filter((linje) => !isEmpty(linje.barnliste))
        .map((linje) => (
          <DelOppsummering
            key={linje.id}
            linje={linje}
            skvisData={skvisData}
            beregningIkkeTilgjengelig={beregningIkkeTilgjengelig}
            svalbard={svalbard}
          />
        ))}
    </div>
  );
};

const DelOppsummering = ({
  linje,
  skvisData,
  beregningIkkeTilgjengelig,
  containerHeaderId,
  svalbard,
}) => {
  const erPaaNaeringsspesifikasjonSide = useSelector(
    getErPaaNaeringsspesifikasjonSide,
  );
  // const skalViseDetaljer = linje.harDetaljer && erPaaNaeringsspesifikasjonSide;

  if (isEmpty(linje.barnliste)) {
    const verdi = get(skvisData, linje.referanse, linje.defaultVerdi);
    const tekstId = erPaaNaeringsspesifikasjonSide
      ? language.naeringssammendragId(linje.id)
      : language.skvisLedetekstId(linje.id);

    if (!Array.isArray(verdi)) {
      return (
        <OppsummeringLinje
          id={linje.id}
          tekstId={tekstId}
          beregningIkkeTilgjengelig={beregningIkkeTilgjengelig}
          isFrittstaaende
          value={verdi}
          svalbard={svalbard}
        />
      );
    }

    // TODO er dette skvis v1 vs v2 greier?
    return (
      <OppsummeringLinjeV2
        verdi={verdi}
        tekstId={tekstId}
        linje={linje}
        beregningIkkeTilgjengelig={beregningIkkeTilgjengelig}
      />
    );
  }

  if (linje.erHeader) {
    const harVerdier = linje.barnliste
      .filter(filterSvalbard(svalbard))
      .some((barn) => get(skvisData, barn.referanse));

    if (!harVerdier) {
      return null;
    }

    return (
      <div>
        <OverskriftLinje
          id={
            erPaaNaeringsspesifikasjonSide
              ? language.naeringssammendragOverskriftId(linje.id)
              : language.skvisOverskriftId(linje.id)
          }
        />
        <dl>
          {linje.barnliste.filter(filterSvalbard(svalbard)).map((barn) => (
            <DelOppsummering
              key={barn.id}
              linje={barn}
              skvisData={skvisData}
              beregningIkkeTilgjengelig={beregningIkkeTilgjengelig}
              containerHeaderId={linje.id}
              svalbard={svalbard}
            />
          ))}
          {/*{skalViseDetaljer && (*/}
          {/*  <NaeringOppsummeringDetaljer definisjon={linje} data={data} />*/}
          {/*)}*/}
        </dl>
      </div>
    );
  }
  const sum = get(skvisData, linje.referanse, linje.defaultVerdi);
  // Viser negative verdier i oppsummering på næringsspec, ikke i skattemelding
  if (
    !Array.isArray(sum) &&
    (isNaN(sum) || (!erPaaNaeringsspesifikasjonSide && sum < 0))
  ) {
    return null;
  }
  return (
    <Fragment key={linje.id}>
      <Oppsummeringsgruppe
        skvisData={skvisData}
        oppsummeringsgruppe={linje}
        beregningIkkeTilgjengelig={beregningIkkeTilgjengelig}
        containerHeaderId={containerHeaderId}
        svalbard={svalbard}
      />
      {/*{skalViseDetaljer && (*/}
      {/*  <NaeringOppsummeringDetaljer definisjon={linje} data={data} />*/}
      {/*)}*/}
    </Fragment>
  );
};

export const OppsummeringSkattefri = ({
  skvis,
  skvisData,
  beregningIkkeTilgjengelig,
  inntektsaar,
}) => {
  const intl = useIntl();
  const definisjoner = skvis
    .filter((barn) => barn.barnliste)
    .map((barn) => barn.barnliste)
    .flat(2);

  const oppsummeringslinjer = definisjoner
    .filter(
      (barn) => get(skvisData, barn.referanse, barn.defaultVerdi) !== undefined,
    )
    .map((barn) =>
      get(skvisData, barn.referanse, [{ beloep: barn.defaultVerdi }]).map(
        (forekomst) => ({
          ...forekomst,
          referanse: barn.referanse,
          tekstId: barn.id,
        }),
      ),
    )
    .flat(2)
    .filter(({ skalVisesSomSkattefri }) => skalVisesSomSkattefri === true);

  if (isEmpty(oppsummeringslinjer)) {
    return null;
  }

  return (
    <div>
      <OverskriftLinje
        id="ledetekst.oppsummering.skattefriInntektOgFormue"
        uppercase={true}
        stil={style.overskriftLinjeMedToppMarg}
      />
      <dl className={style.linjeMedMargin}>
        {sorterSkvisGrupper(oppsummeringslinjer).map(
          (
            {
              tekstId,
              beloep,
              saerskiltSkatteplikt,
              barnetsPersonidentifikator,
              kommune,
              land,
            },
            index,
          ) => {
            const tekst = lagSkvisGruppeHeader(
              {
                tekstId,
                saerskiltSkatteplikt,
                barnetsPersonidentifikator,
                kommune,
                land,
              },
              intl,
              inntektsaar,
            );
            return (
              <OppsummeringLinje
                id={index}
                key={index}
                tekst={tekst}
                value={beloep}
                beregningIkkeTilgjengelig={beregningIkkeTilgjengelig}
              />
            );
          },
        )}
      </dl>
    </div>
  );
};
