import { visningslogikk } from '@skatteetaten/visningsdefinisjoner-og-tekster';
import { get } from 'lodash';
import { flatListeMedKorttyper } from './feltUtils';
import { BEHANDLINGSART } from './visningsDataDefinisjonUtil';
import { flatUtFeltgrupper } from './visningsdefinisjonUtil';

const { felt: feltUtils, kort: kortUtils } = visningslogikk;

const harIngenVerdier = ({
  korttyper,
  visningsdata,
  kortSomHarBlittOpprettet = [],
}) => {
  if (!visningsdata) {
    return true;
  }
  const erOpprettet = (korttype) =>
    kortSomHarBlittOpprettet?.referanser?.includes(korttype.referanse);
  return korttyper.every(
    (korttype) =>
      !erOpprettet(korttype) && !korttypeHarData(korttype, visningsdata),
  );
};

/** Sjekker om et kort har data i visningsdata */
export const korttypeHarData = (korttype, visningsdata) => {
  return get(visningsdata, korttype.referanse, []).some((forekomst) =>
    harDataSomFinnesIKortdefinisjon(korttype, forekomst),
  );
};

/** Sjekker om en forekomst inneholder data som er definert i kortets definisjon.
 *  Vi gjør dette fordi vi ved tilfeller kan få forekomst med bare beregna verdi(er)
 *  som ikke finnes i visningsdefinisjon. I disse tilfellene ønsker vi å behandle
 *  det som at forekomsten ikke eksisterer. F.eks. vi vil ikke vise kortet, det skal
 *  gå an å legge til nytt, etc.
 *
 *  I skrivende stund gjelder dette spesifikt for resultat og balanse i næringsspesifikasjonen,
 *  der alle kortene bare inneholder en dynamisk feltgruppe. Denne koden er derfor
 *  spesialisert for dette use caset, spesifikt for dynamiske feltgrupper.
 *  */
export const harDataSomFinnesIKortdefinisjon = (korttype, forekomst) => {
  const erDynamiskFeltgruppeData = (feltVerdi) =>
    Array.isArray(feltVerdi) &&
    feltVerdi.every((subforekomst) => subforekomst[BEHANDLINGSART]);

  return Object.entries(forekomst)
    .filter(([dynamiskFeltgruppeRef, _]) => dynamiskFeltgruppeRef !== 'id')
    .some(([dynamiskFeltgruppeRef, underforekomstVerdi]) => {
      if (erDynamiskFeltgruppeData(underforekomstVerdi)) {
        const underforekomstFelt = flatUtFeltgrupper(korttype.barnliste).find(
          (felt) => felt.referanse === dynamiskFeltgruppeRef,
        );

        return underforekomstVerdi.some((subforekomst) =>
          underforekomstFelt?.barnliste.some(
            (felt) => felt.datatype === subforekomst[BEHANDLINGSART],
          ),
        );
      }
      // verdier for alle andre felttyper tolkes som at kortet har relevante verdier
      return true;
    });
};

export const korttyperSomKanLeggesTil = (visningsdata, kortgruppe) => {
  return (kortgruppe.barnliste || [])
    .filter((korttype) => korttype.type === 'korttype')
    .filter((korttype) => korttypeKanLeggesTil(korttype, visningsdata));
};

export const kanIkkeLeggeTilKort = (korttype, visningsdata) =>
  kortUtils.kanIkkeLeggeTilKort(korttype, visningsdata);

export function erMaksEttKortMedForekomst(korttype, visningsdata) {
  return korttype.maksEttKort && korttypeHarData(korttype, visningsdata);
}

export const korttypeKanLeggesTil = (korttype, visningsdata) => {
  return !(
    kanIkkeLeggeTilKort(korttype, visningsdata) ||
    erMaksEttKortMedForekomst(korttype, visningsdata)
  );
};

export const skalTemaSkjules = (
  kortgrupper,
  visningsdata,
  kortSomHarBlittOpprettet,
) => {
  const korttyper = flatListeMedKorttyper(kortgrupper);
  return harIngenVerdier({
    korttyper,
    visningsdata,
    kortSomHarBlittOpprettet,
  });
};

export const skalKortgruppeSkjules = (
  kortgruppe,
  visningsdata,
  kortSomHarBlittOpprettet,
) => {
  const { barnliste } = kortgruppe;
  return harIngenVerdier({
    korttyper: barnliste,
    visningsdata,
    kortSomHarBlittOpprettet,
  });
};

export const skalFeltDisables = (definisjon, data, visningsdata) => {
  return feltUtils.feltSkalDeaktiveres(definisjon, data, visningsdata);
};

export const skalFeltSkjules = (definisjon, data, visningsdata) => {
  return feltUtils.feltSkalSkjules(definisjon, data, visningsdata);
};

export const skalBeholdeVerdi = (definisjon, data, visningsdata) => {
  return !feltUtils.feltSkalSlettes(definisjon, data, visningsdata);
};

export const skalFeltgruppeSkjules = (definisjon, data, visningsdata) =>
  (definisjon.barnliste || []).every((barn) => {
    return skalFeltSkjules(barn, data, visningsdata);
  });

export const skalSkjuleFeltIKort = (definisjon, data) =>
  feltUtils.feltSkalSkjulesIKort(definisjon, data);

export const skalEkspandereFeltgruppe = (definisjon, data, visningsdata) =>
  feltUtils.skalEkspandereFeltgruppe(definisjon, data, visningsdata);
