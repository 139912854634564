/*
Vi rapporterer for det meste feil sentralt, dvs. at feil kan oppstå
mange forksjellige steder i applikasjonen, men vi er bare interessert
i å rapportere dem noen få steder.
Dermed er det fint hvis vi kan pakke feilene på en standard måte,
slik at vi kan legge til ekstrainformasjon de stedene vi vil uten
å måtte ha masse spesialisert feilhåndteringskode sentralt.
Feil er et standard pakkeformat. Det består av tre informasjoner:
- feilkode: Hva har skjedd? Kan være sendt videre fra backend
- feillokasjon: Hvor behandlet vi feilen først? Ettersom feil kan sendes
rundt i reduxevents og sagaer, og at stacktracer kan være minified, kan
det være greit å ha det eksplisitt.
- Kontekst: Diverse informasjon som kan være kjekt å ha

Kast gjerne en feil som en exception. Alle feilhåndteringer skal
ta hensyn til både feil og js-error
 */
import { MSSF_011_programmeringsfeil_browser } from '../constants/feilkoder';
import { multilineStringSomArray } from './formater';

/*
 * Denne konstruktøren bruker et sett med transformere for å lage feil.
 * Det er kanskje litt vel abstrakt, men bakgrunnen var at det tidligere
 * ble masse forskjellige metoder hvis du skulle ha kontekst, feilkode,
 * logasinfo osv. Det begynte å bli uoversiktelig, og det ville bare bli værre.
 *
 * Nå kan du skrive nyFeil("FEIL01", [medKontekst({foo:"bar"})]) istedenfor
 * nyFeilUtenFeillokasjonMedKontekst("FEIL01", {foo:"bar"})
 */
export const nyFeil = (feilkode, transformere = []) => {
  const feil = { feilkode };
  return mapFeil(transformere)(feil);
};

export const feilFraErrorOgLokasjon = (
  error,
  feillokasjon,
  transformere = [],
) => {
  return feilFraError(error, [medFeillokasjon(feillokasjon), ...transformere]);
};

export const throwFeilFraErrorOgLokasjon =
  (feillokasjon, transformere = []) =>
  (error) => {
    throw feilFraErrorOgLokasjon(error, feillokasjon, transformere);
  };

//Bruker ikke defaultverdi for error da noen script kan returnere null.
export const feilFraError = (error, transformere = []) => {
  const [feil, kontekst] = erFeil(error)
    ? [error, {}]
    : [tomFeil, kontekstFraError(error)];
  return mapFeil([
    overskrivKontekst(kontekst),
    ...transformere,
    medFeilkode(MSSF_011_programmeringsfeil_browser),
  ])(feil);
};

export const erFeil = (err) => !!err?.feilkode;

export const medFeillokasjon = (feillokasjon) => (feil) => {
  return { feillokasjon, ...feil };
};
export const medFeilkode = (feilkode) => (feil) => {
  return { feilkode, ...feil };
};
export const medMelding = (melding) => (feil) => {
  return { melding, ...feil };
};
export const medLoggSomInfo = (feil) => {
  return { logAsInfo: true, ...feil };
};
export const settFeilLoggetIBackend = (logget) => (feil) => {
  return { feilLoggetIBackend: logget, ...feil };
};
export const noopTransformer = (feil) => feil;

export const overskrivKontekst =
  (kontekst = {}) =>
  (feil) => {
    const { kontekst: gammelKontekst = {}, ...andreFelter } = feil;
    const nyKontekst = removeNullAndUndefinedFromObject({
      ...gammelKontekst,
      ...kontekst,
    });
    return Object.keys(nyKontekst).length === 0
      ? andreFelter
      : { ...feil, kontekst: nyKontekst };
  };

const removeNullAndUndefinedFromObject = (obj) => {
  return Object.fromEntries(
    Object.entries(obj).reduce(
      (ret, [key, value]) =>
        value === undefined || value === null ? ret : [...ret, [key, value]],
      [],
    ),
  );
};

const mapFeil =
  (transformere = []) =>
  (feil) => {
    return transformere.reduce((f, transformer) => {
      return transformer(f);
    }, feil);
  };

const kontekstFraError = (error) => {
  const melding = error?.message || 'Tom exception!';

  const stacktrace = multilineStringSomArray(error?.stack);

  return {
    stacktrace,
    melding,
    error,
  };
};
const tomFeil = {};
