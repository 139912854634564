import { START_LOADING, STOP_LOADING } from './spinner.action';
import { Action } from '../@types/redux';

type SpinnerState = {
  count: number;
  time: number;
};

const defaultState: SpinnerState = {
  count: 0,
  time: 0,
};

export const getSpinner = (state: { spinner: SpinnerState }) => state.spinner;

export default function spinnerReducer(state = defaultState, action: Action) {
  switch (action.type) {
    case START_LOADING:
      return {
        ...state,
        time: Date.now(),
        count: state.count + 1,
      };
    case STOP_LOADING:
      return {
        ...state,
        count: state.count - 1,
      };
    default:
      return state;
  }
}
