import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { language } from '@skatteetaten/visningsdefinisjoner-og-tekster';
import style from './BeregnetSkatt.module.scss';
import oversiktStyle from '../Oversikt.module.scss';
import { harIkkeFradrag, harIkkeGrunnlag } from './beregnetSkattUtils';
import { BeregnetSkattCelle } from './BeregnetSkattCelle';
import { defaultValueZero, hentVerdi } from './beregnetSkattUtils';

export const BeregnetSkattTabellRad = ({
  linjeDef,
  beregnetSkatt,
  skjulHvisBeloepErNull,
}) => {
  const { skjulGrunnlag, harSkillelinje } = linjeDef;

  const verdi = hentVerdi(linjeDef, beregnetSkatt);

  if (
    harIkkeGrunnlag(linjeDef, verdi) ||
    harIkkeFradrag(linjeDef, verdi) ||
    (skjulHvisBeloepErNull && !(verdi.beloep > 0))
  ) {
    return null;
  }

  return (
    <tr className={classnames({ [style.skillelinje]: harSkillelinje })}>
      <th scope="row" className={oversiktStyle.flexCol1}>
        <FormattedMessage id={language.beregnetskattLedetekstId(linjeDef.id)} />
      </th>
      <td className={oversiktStyle.flexCol2}>
        <BeregnetSkattCelle
          value={verdi.grunnlag}
          defaultValue={defaultValueZero.grunnlag}
          skjulGrunnlag={skjulGrunnlag}
        />
      </td>
      <td className={oversiktStyle.flexCol3}>
        <BeregnetSkattCelle
          value={verdi.beloep}
          defaultValue={defaultValueZero.beloep}
        />
      </td>
    </tr>
  );
};
