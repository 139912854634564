import { useInntektsaar } from './useInntektsaar';
import { getInntektsaar } from '../reducers';
import { NYESTE_INNTEKTSAAR } from '../constants/inntektsaar';

export const useValideringsstate = () => {
  const { inntektsaar } = useInntektsaar();
  return {
    inntektsaar,
    inntektsaarPluss1: inntektsaar + 1,
  };
};

// TODO denne brukes bare i saga nå...
export const getValideringsstate = (state) => {
  const inntektsaar = getInntektsaar(state) || NYESTE_INNTEKTSAAR;
  return {
    inntektsaar,
    inntektsaarPluss1: inntektsaar + 1,
  };
};
