import { FormatertTall } from '../../util/FormatertTall';

export const BeregnetSkattCelle = ({ value, defaultValue, skjulGrunnlag }) => {
  if (skjulGrunnlag) {
    return null;
  }
  if (value === undefined) {
    return <span>{defaultValue}</span>;
  }
  return <FormatertTall value={value} />;
};
