import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { visningslogikk } from '@skatteetaten/visningsdefinisjoner-og-tekster';
import { FeltTabellIDialog } from './FeltTabellIDialog/FeltTabellIDialog';
import { FeltlisteIDialog } from './FeltlisteIDialog';
import { KortIKortListeIDialog } from './KortIKortListeIDialog';
import { getDefinisjonsstiForId } from '../../../../reducers';
import { medUnderforekomsttype } from '../../../../utils/visningsDataDefinisjonUtil';
import {
  leggTilRepeterendefeltIDialog,
  slettRepeterendefeltIDialog,
} from '../../../../actions/dialog';
import { useDispatcher } from '../../../../utils/reduxHooks';
import { useBetingelseHook } from '../../../../hooks/useBetingelseHook';

const { sorter } = visningslogikk;

export const RepeterendefeltIDialog = ({
  felt,
  data,
  dataStiTilForelder,
  locale,
  ...props
}) => {
  const { skalFeltDisables } = useBetingelseHook();
  const dataSti = useMemo(
    () => medUnderforekomsttype(dataStiTilForelder, felt.referanse),
    [dataStiTilForelder, felt.referanse],
  );
  const definisjonsSti = useSelector(getDefinisjonsstiForId(felt.id));
  const sorterteForekomster = sorter.sorterForekomster(
    data[felt.referanse],
    felt.sortering,
    locale,
  );
  const leggTilRepeterendefelt = useDispatcher(leggTilRepeterendefeltIDialog);
  const slettRepeterendefelt = useDispatcher(slettRepeterendefeltIDialog);

  if (felt.kortIKort) {
    return (
      <KortIKortListeIDialog
        repeterendeFeltgruppe={felt}
        repeterendeFeltData={data}
        definisjonsSti={definisjonsSti}
        dataSti={dataSti}
        leggTilRepeterendefelt={leggTilRepeterendefelt}
        {...props}
      />
    );
  } else if (felt.tabell) {
    return (
      <FeltTabellIDialog
        definisjonsSti={definisjonsSti}
        dataSti={dataSti}
        leggTilRepeterendefelt={leggTilRepeterendefelt}
        sorterteForekomster={sorterteForekomster}
        {...props}
      />
    );
  }
  return (
    <FeltlisteIDialog
      kortdata={data}
      felt={felt}
      sorterteForekomster={sorterteForekomster}
      {...{
        leggTilRepeterendefelt,
        slettRepeterendefelt,
        ...props,
      }}
      disabled={skalFeltDisables(felt, data)}
    />
  );
};
