export const NY_SOEKESTRENG = 'NY_SOEKESTRENG';
export const VALGT_SOEKERESULTAT = 'VALGT_SOEKERESULTAT';
export const NYTT_SOEKERESULTAT = 'NYTT_SOEKERESULTAT';
export const SOEK_FOKUSERT = 'SOEK_FOKUSERT';

export const utfoerSoek = (soekestreng) => ({
  type: NY_SOEKESTRENG,
  soekestreng,
});

export const valgtSoekeResultat = (soekeresultat) => {
  return {
    type: VALGT_SOEKERESULTAT,
    soekeresultat,
  };
};

export const nyttSoekeresultat = (resultater) => ({
  type: NYTT_SOEKERESULTAT,
  resultater,
});

export const soekFokusert = () => ({
  type: SOEK_FOKUSERT,
});
