import { API_BEREGN_SUKSESS, BEREGN } from '../actions/skattemelding';

export const getBeregnetSkatt = (state) => state.beregnetSkatt;
export const getBeregnetSkattSvalbard = (state) => state.beregnetSkattSvalbard;
export const getSummertSkattegrunnlagForVisning = (state) =>
  state.summertSkattegrunnlagForVisning;
export const getSummertSkattegrunnlagForVisningSvalbard = (state) =>
  state.summertSkattegrunnlagForVisningSvalbard;
export const getSkattemeldingResultatTilgjengelig = (state) =>
  state.skattemeldingResultatTilgjengelig;
export const getBeregningPaagaar = (state) => state.beregningPaagaar;

export const defaultState = {
  beregnetSkatt: {},
  beregnetSkattSvalbard: {},
  summertSkattegrunnlagForVisning: {},
  summertSkattegrunnlagForVisningSvalbard: {},
  skattemeldingResultatTilgjengelig: true,
  beregningPaagaar: false,
};

export default function beregnetSkattReducer(
  state = defaultState,
  action = {},
  erBeregnetSkattForEktefelle = false,
) {
  switch (action.type) {
    case BEREGN: {
      return {
        ...state,
        beregningPaagaar: true,
      };
    }
    case API_BEREGN_SUKSESS: {
      const { skattemeldingResultat, skattemeldingResultatTilgjengelig } =
        action;
      const beregninger = erBeregnetSkattForEktefelle
        ? skattemeldingResultat.beregningsresultatEktefelle
        : skattemeldingResultat.beregningsresultat;

      return {
        ...state,
        beregnetSkatt: beregninger.beregnetSkatt,
        beregnetSkattSvalbard: beregninger.beregnetSkattSvalbard,
        summertSkattegrunnlagForVisning:
          beregninger.summertSkattegrunnlagForVisning,
        summertSkattegrunnlagForVisningSvalbard:
          beregninger.summertSkattegrunnlagForVisningSvalbard,
        skattemeldingResultatTilgjengelig:
          skattemeldingResultatTilgjengelig ?? true,
        beregningPaagaar: false,
      };
    }
    default:
      return state;
  }
}
