import { sortArrayOfObjects, synkendeSortering } from './object';
import { util } from '@skatteetaten/visningsdefinisjoner-og-tekster';
const {
  EGENSKAP_BELOEP,
  EGENSKAP_BELOEP_I_VALUTA,
  EGENSKAP_METODE_VED_DOBBELTBESKATNING,
  EGENSKAP_TRYGDE_ELLER_SKATTEMESSIG_UNNTAK,
  EGENSKAP_VALUTAKODE,
} = util;

/*
 * Ref. SH-7429, så skal skattetyter nå ha muligheten til å anngi pensjonsinntekt og pensjonsgrad for året samlet,
 * istedenfor hver måned, dersom de ønsker. De nye feltene for dette skal _ikke_ lagres i XSD, og er ikke en del
 * av fastsettingsberegninger. Derfor skal totalbeløpet oversettes til månedsbeløp ved beregninger og lagring.
 * Tilsvarende, når vi laster en skattemelding, så må vi kunne utlede verdien av disse feltene fra månedsbeløpene.
 * */

const erAnnenPensjon = (korttype) =>
  korttype.referanse === 'pensjonsinntektFraArbeidsforhold';

/** Utleder verdi for felter som ikke er lagret i XSD i pensjonskort */
export const utledFeltverdierForPensjon = (kortdefinisjon, data) => {
  if (!erAnnenPensjon(kortdefinisjon)) {
    return data;
  }
  let nyData = utledVerdiForAarligEllerMaanedligUtfylling(kortdefinisjon, data);
  if (!harBeloepIAlleMnd(data.pensjonsinntektPerMaaned)) {
    return nyData;
  }
  const skalUtledeSumPensjonsinntekt =
    skalUtledeSumPensjonsinntektForAaretFraMaaneder(data);
  const skalUtledePensjonsgrad = skalUtledePensjonsgradFraMaaneder(data);
  const skalUtledeEgenskaper = skalUtledeEgenskaperFraMaaneder(data);
  if (
    skalUtledeSumPensjonsinntekt ||
    skalUtledePensjonsgrad ||
    skalUtledeEgenskaper
  ) {
    nyData = utledAarligPensjonsdataFraMaaneder(nyData);
    if (!skalUtledePensjonsgrad) {
      nyData.pensjonsgradForAaret = undefined;
    }

    if (!skalUtledeEgenskaper) {
      nyData.sumPensjonsinntektForAaret.trygdeEllerSkattemessigUnntak =
        undefined;
      nyData.sumPensjonsinntektForAaret.metodeVedDobbeltbeskatning = undefined;
    }

    if (!skalUtledeSumPensjonsinntekt || !skalUtledePensjonsgrad) {
      if (
        nyData.sumPensjonsinntektForAaret.trygdeEllerSkattemessigUnntak ===
          undefined &&
        nyData.sumPensjonsinntektForAaret.metodeVedDobbeltbeskatning ===
          undefined
      ) {
        nyData.sumPensjonsinntektForAaret = undefined;
      } else {
        nyData.sumPensjonsinntektForAaret.beloep = undefined;
        nyData.sumPensjonsinntektForAaret.beloepIValuta = undefined;
        nyData.sumPensjonsinntektForAaret.valutakode = undefined;
        nyData.sumPensjonsinntektForAaret.valutakurs = undefined;
      }
    }
    return nyData;
  }
  return data;
};

/** Utleder verdien til radiovalget som styrer visning av årlig vs månedlig utfylling av pensjonsinntekt */
const utledVerdiForAarligEllerMaanedligUtfylling = (kortdef, data) => {
  if (data.utfyllingsmetode) {
    return data;
  }

  const erAarligUtfylling = aarligInnleggingsmetodeSkalVaereMarkertSomValgt(
    kortdef,
    data,
  );
  const utfyllingsmetode = erAarligUtfylling ? 'aarlig' : 'maanedlig';

  return {
    ...data,
    utfyllingsmetode,
  };
};

/** Utleder verdien til årlig pensjonsinntekt og pensjonsgrad basert på
 * data i månedsinntektene. */
const utledAarligPensjonsdataFraMaaneder = (data) => {
  const pensjonsinntektPerMaaned = data.pensjonsinntektPerMaaned;

  const summerEgenskap = (feltEgenskap) =>
    pensjonsinntektPerMaaned.reduce((delSum, inntekt) => {
      const number = parseInt(inntekt.pensjonsinntekt[feltEgenskap]);
      return delSum + number;
    }, 0);
  const sumBeloepINOK = summerEgenskap(EGENSKAP_BELOEP);
  const sumBeloepIValuta = summerEgenskap(EGENSKAP_BELOEP_I_VALUTA);
  const enMaanedsinntekt = pensjonsinntektPerMaaned[0];

  const nyData = { ...data };
  nyData.sumPensjonsinntektForAaret = {
    ...enMaanedsinntekt.pensjonsinntekt, // alle felter unntatt beløp er like for året
    [EGENSKAP_BELOEP]: sumBeloepINOK.toString(),
    [EGENSKAP_BELOEP_I_VALUTA]: sumBeloepIValuta.toString(),
  };
  if (!nyData.pensjonsgradForAaret) {
    nyData.pensjonsgradForAaret = enMaanedsinntekt.pensjonsgrad;
  }
  return nyData;
};

const harBeloepIAlleMnd = (maaneder = []) =>
  maaneder.every((mnd) => mnd?.pensjonsinntekt?.beloepIValuta);

const skalUtledePensjonsgradFraMaaneder = (data) => {
  const likPensjonsgrad = (mnd) =>
    mnd.pensjonsgrad === data.pensjonsinntektPerMaaned[0].pensjonsgrad;

  return data.pensjonsinntektPerMaaned.every((mnd) => likPensjonsgrad(mnd));
};

const skalUtledeEgenskaperFraMaaneder = (data) => {
  const enMnd = data.pensjonsinntektPerMaaned[0];

  const likeEgenskaper = (mnd) =>
    mnd.pensjonsinntekt[EGENSKAP_TRYGDE_ELLER_SKATTEMESSIG_UNNTAK] ===
      enMnd.pensjonsinntekt[EGENSKAP_TRYGDE_ELLER_SKATTEMESSIG_UNNTAK] &&
    mnd.pensjonsinntekt[EGENSKAP_METODE_VED_DOBBELTBESKATNING] ===
      enMnd.pensjonsinntekt[EGENSKAP_METODE_VED_DOBBELTBESKATNING] &&
    mnd.pensjonsinntekt[EGENSKAP_VALUTAKODE] ===
      enMnd.pensjonsinntekt[EGENSKAP_VALUTAKODE];

  return data.pensjonsinntektPerMaaned.every((mnd) => likeEgenskaper(mnd));
};

export const skalUtledeAarligPensjonsinntektFraMaaneder = (
  kortdefinisjon,
  data,
) => {
  if (
    !erAnnenPensjon(kortdefinisjon) ||
    !harBeloepIAlleMnd(data.pensjonsinntektPerMaaned)
  ) {
    return false;
  }

  return (
    skalUtledeSumPensjonsinntektForAaretFraMaaneder(data) ||
    skalUtledePensjonsgradFraMaaneder(data) ||
    skalUtledeEgenskaperFraMaaneder(data)
  );
};

const aarligInnleggingsmetodeSkalVaereMarkertSomValgt = (
  kortdefinisjon,
  data,
) => {
  if (
    !erAnnenPensjon(kortdefinisjon) ||
    !harBeloepIAlleMnd(data.pensjonsinntektPerMaaned)
  ) {
    return false;
  }

  const skalUtledeSumPensjonsinntektForAaretFraMaaneder1 =
    skalUtledeSumPensjonsinntektForAaretFraMaaneder(data);
  const skalUtledePensjonsgradFraMaaneder1 =
    skalUtledePensjonsgradFraMaaneder(data);
  const skalUtledeEgenskaperFraMaaneder1 =
    skalUtledeEgenskaperFraMaaneder(data);
  return (
    skalUtledeSumPensjonsinntektForAaretFraMaaneder1 &&
    skalUtledePensjonsgradFraMaaneder1 &&
    skalUtledeEgenskaperFraMaaneder1
  );
};

/** Basert på algoritmen i {@link fordelAarligPensjonsinntektPaaMaaneder}, så sjekker denne om verdiene i månedsinntektene
 * for pensjon er av en form og struktur som tilsier at bruker har fylt ut for året som helhet. */
const skalUtledeSumPensjonsinntektForAaretFraMaaneder = (data) => {
  const sorterteInntekter = sortArrayOfObjects(
    data.pensjonsinntektPerMaaned,
    (element) => element.pensjonsinntekt.beloepIValuta,
    synkendeSortering,
  );
  const enMndInntekt = parseInt(
    sorterteInntekter[0].pensjonsinntekt.beloepIValuta,
  );
  const liktBeloep = (mnd) => {
    if (!mnd?.pensjonsinntekt) {
      return false;
    }
    const maanedsBeloep = parseInt(mnd.pensjonsinntekt.beloepIValuta);
    return maanedsBeloep === enMndInntekt || maanedsBeloep === enMndInntekt - 1;
  };

  return data.pensjonsinntektPerMaaned.every((mnd) => liktBeloep(mnd));
};

/** Dersom bruker har fylt ut for årlig pensjonsinntekt, så fordeler vi dette på månedene.
 * De fleste verdier og egenskaper kopieres rett over. Unntaket er beløpet, bruker en
 * algoritme der restbeløpet fordeles jevnt bland månedene. Som konsekvens vil månedene
 * kunne ha maks 1 NOK/annen valuta i diff i beløpet. */
export const fordelAarligPensjonsinntektPaaMaaneder = (data) => {
  const maanedligPensjonsinntektHeltall = Math.trunc(
    data.sumPensjonsinntektForAaret.beloepIValuta / 12,
  );
  let restBeloep = data.sumPensjonsinntektForAaret.beloepIValuta % 12;

  return {
    ...data,
    pensjonsinntektPerMaaned: data.pensjonsinntektPerMaaned
      // vi sorterer så evt restbeløp fordeles fra januar og utover. Dette brukes i reverseringsalgoritmen
      .sort((a, b) => (a.periode > b.periode ? 1 : -1))
      .map((maanedData) => {
        let fordeltBeloep = maanedligPensjonsinntektHeltall;
        if (restBeloep > 0) {
          fordeltBeloep += 1;
          restBeloep -= 1;
        }
        return {
          ...maanedData,
          pensjonsinntekt: {
            ...data.sumPensjonsinntektForAaret,
            beloepIValuta: fordeltBeloep.toString(),
          },
          pensjonsgrad: data.pensjonsgradForAaret,
        };
      }),
  };
};

export const erPensjonskortMedAarligInntektIDialog = (kortdefinisjon, data) => {
  return (
    erAnnenPensjon(kortdefinisjon) &&
    data.utfyllingsmetode === 'aarlig' &&
    data?.sumPensjonsinntektForAaret?.beloepIValuta
  );
};
