import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';

import { visningslogikk } from '@skatteetaten/visningsdefinisjoner-og-tekster';
import {
  finnAlle,
  getDynamiskegrupper,
} from '../../../../utils/visningsdefinisjonUtil';
import {
  DYNAMISK_FELT,
  erBeregnetFelt,
  erDynamiskgruppe,
  erEgenskapPaaObjekt,
  erFeltgruppe,
  erFeltMedEgenskaper,
  erKortIKort,
  erRepeterendeFeltgruppe,
  FELT,
  FELT_MED_EGENSKAPER,
  FELTGRUPPE,
} from '../../../../utils/visningsDataDefinisjonUtil';
import { lagFeltreferanseIDialog } from '../../../../utils/visningsdataUtil';
import { flatListeMedFilter } from '../../../../utils/feltUtils';
import { erEgenskap } from './egenskaper.utils';

const { felt: feltUtils } = visningslogikk;

export const finnDynamiskeFelterSomIkkeErLagtTil = (
  kortdefinisjon,
  forekomstData,
  underforekomstDataSti,
) => {
  const dynamiskegrupper = getDynamiskegrupper(kortdefinisjon);

  if (!dynamiskegrupper.length) {
    return [];
  }

  return dynamiskegrupper
    .map((dynamiskgruppe) => {
      const dynamiskeFelter = finnAlle(DYNAMISK_FELT, dynamiskgruppe);
      const { referanse, kodeliste } = dynamiskgruppe;

      const feltReferanse = isEmpty(underforekomstDataSti)
        ? referanse
        : [
            lagFeltreferanseIDialog(forekomstData, underforekomstDataSti),
            referanse,
          ].join('.');

      const dynamiskFeltListe = get(forekomstData, feltReferanse) || [];

      return dynamiskeFelter
        .filter(
          (dynamiskFelt) =>
            !feltUtils.feltSkalSkjules(dynamiskFelt, forekomstData),
        )
        .filter(inneholderIkkeBareBeregnedeFelt)
        .filter(erIkkeAlleredeLagtTil(dynamiskFeltListe))
        .filter(harSynligeBarnFelt(forekomstData))
        .map((felt) => ({
          ...felt,
          dynamiskGruppeReferanse: referanse,
          dynamiskGruppeKodeliste: kodeliste,
        }));
    })
    .flat();
};

export const erIkkeAlleredeLagtTil = (dynamiskFeltListe) => (barn) =>
  !dynamiskFeltListe.find((art) => art.behandlingsart === barn.datatype);

export const harSynligeBarnFelt = (data) => (barn) =>
  finnAlle(FELT, barn).some((felt) => !feltUtils.feltSkalSkjules(felt, data)) ||
  finnAlle(FELT_MED_EGENSKAPER, barn).some(
    (felt) => !feltUtils.feltSkalSkjules(felt, data),
  );

const inneholderIkkeBareBeregnedeFelt = (dynamiskFelt) => {
  return !dynamiskFelt?.barnliste.every(erBeregnetFelt);
};

export const egenskaperSomErLagtTil = (synligeEgenskaper) => (egenskap) =>
  !Object.keys(synligeEgenskaper).includes(egenskap.typeEgenskap);

export const finnAlleEgenskaper = (kortdefinisjon, forekomstData) => {
  return [
    ...finnObjektEgenskaper(kortdefinisjon, forekomstData),
    ...finnUnikeFeltEgenskaper(kortdefinisjon, forekomstData),
  ];
};

const finnObjektEgenskaper = (kortdefinisjon, forekomstData) => {
  return [
    ...flatListeMedFilter(
      FELT,
      kortdefinisjon.barnliste,
      (felt) => !erKortIKort(felt),
    ),
    ...flatListeMedFilter(
      FELTGRUPPE,
      kortdefinisjon.barnliste,
      (felt) => !erKortIKort(felt),
    ),
  ]
    .filter(erEgenskapPaaObjekt)
    .filter((egenskap) => !feltUtils.feltSkalSkjules(egenskap, forekomstData));
};

export const filterDefinisjon = (definisjon, data) => {
  const { barnliste = [] } = definisjon;
  if (barnliste.length) {
    return {
      ...definisjon,
      barnliste: barnliste
        .filter((barn) => !feltUtils.feltSkalSkjules(barn, data))
        .map((barn) => {
          switch (true) {
            case erFeltgruppe(barn):
              return filterDefinisjon(barn, data);

            case erDynamiskgruppe(barn):
              const dynamiskgruppeData = get(data, barn.referanse, []);
              return {
                ...barn,
                barnliste: barn.barnliste
                  .filter((dynamiskfelt) =>
                    dynamiskgruppeData.some(
                      (subforekomst) =>
                        subforekomst.behandlingsart === dynamiskfelt.datatype,
                    ),
                  )
                  .map((dynamiskfelt) => {
                    const subforekomstData = dynamiskgruppeData.find(
                      (subforekomst) =>
                        subforekomst.behandlingsart === dynamiskfelt.datatype,
                    );
                    return filterDefinisjon(dynamiskfelt, {
                      ...subforekomstData,
                      ...data,
                    });
                  }),
              };

            case erRepeterendeFeltgruppe(barn):
              const repeterendefeltGruppeData = get(data, barn.referanse, []);
              return filterDefinisjon(barn, {
                ...repeterendefeltGruppeData[0],
                ...data,
              });

            case erFeltMedEgenskaper(barn):
              const egenskaperData = get(data, barn.referanse);
              return filterDefinisjon(barn, { ...egenskaperData, ...data });

            default:
              return barn;
          }
        }),
    };
  }
  return definisjon;
};

export const finnUnikeFeltEgenskaper = (kortdefinisjon, forekomstData) => {
  const filtrertKortdefinisjon = filterDefinisjon(
    kortdefinisjon,
    forekomstData,
  );
  const alleFelterMedEgenskaper = flatListeMedFilter(
    FELT_MED_EGENSKAPER,
    filtrertKortdefinisjon.barnliste,
    (felt) => !erKortIKort(felt),
  ).filter((felt) => !feltUtils.feltSkalSkjules(felt, forekomstData));

  const alleEgenskaperPaaFeltSomSkalVises = alleFelterMedEgenskaper.flatMap(
    (felt) => {
      return felt.barnliste
        .filter(erEgenskap)
        .filter((egenskapsfelt) => !egenskapsfelt.beregnetFelt);
    },
  );

  return uniqBy(alleEgenskaperPaaFeltSomSkalVises, (felt) => felt.typeEgenskap);
};
