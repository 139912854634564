import {
  definisjonerForId,
  definisjonsstiForId,
} from '../utils/visningsdefinisjonUtil';

export const INIT_VDOT_OG_TEKSTER_SUKSESS = 'INIT_VDOT_OG_TEKSTER_SUKSESS';

export const vdotOgTeksterHentet = (tekster, visningsdefinisjon) => ({
  type: INIT_VDOT_OG_TEKSTER_SUKSESS,
  tekster,
  visningsdefinisjon,
});

export const getVisningsdefinisjon = (state) => state.visningsdefinisjon;
export const getDefinisjonsstiForId = (id, state) => state.definisjonsstier[id];
export const getDefinisjonForId = (id, state) => state.definisjoner[id];

export const createState = (vdef) => ({
  visningsdefinisjon: vdef,
  definisjonsstier: definisjonsstiForId(vdef),
  definisjoner: definisjonerForId(vdef),
});

const emptyState = createState([]);

export default function visningsdefinisjonReducer(state = emptyState, action) {
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (action.type) {
    case INIT_VDOT_OG_TEKSTER_SUKSESS: {
      return createState(action.visningsdefinisjon);
    }
    default:
      return state;
  }
}
