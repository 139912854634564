import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import classnames from 'classnames';
import ActionButton from '@skatteetaten/frontend-components/ActionButton';

import oversiktStyle from '../Oversikt.module.scss';
import style from '../beregnetskatt/BeregnetSkatt.module.scss';
import commonStyle from '../../common.module.scss';
import { GrunnlagForGjeldsreduksjon } from './grunnlagForGjeldsreduksjon/GrunnlagForGjeldsreduksjon';
import { FormatertTall } from '../../util/FormatertTall';
import { getErPaaNaeringsspesifikasjonSide } from '../../../../reducers';
import {
  lagSkvisGruppeHeader,
  sorterSkvisGrupper,
} from '../../../../utils/skvisUtils';
import { useInntektsaar } from '../../../../hooks/useInntektsaar';

const PresentationValue = ({ val, beregningIkkeTilgjengelig }) => {
  const erNSP = useSelector(getErPaaNaeringsspesifikasjonSide);
  const minDesimaler = erNSP ? 2 : 0;
  return beregningIkkeTilgjengelig ? (
    <span>-</span>
  ) : (
    <FormatertTall value={val} minimumFractionDigits={minDesimaler} />
  );
};

export const OppsummeringLinje = ({
  id,
  tekstId,
  tekst,
  uppercase = false,
  value,
  isSumLinje,
  isFrittstaaende,
  beregningIkkeTilgjengelig,
  svalbard,
}) => {
  const [visBeskrivelse, settVis] = useState(false);
  const intl = useIntl();
  const WrapperTag = isFrittstaaende ? 'dl' : 'div';
  let val = value;
  if (isNaN(val)) {
    return null;
  }
  const erGjeldsreduksjon =
    id === 'summertSkattegrunnlagForVisning.sumGjeldsreduksjon';
  function closeDialog() {
    settVis(false);
  }
  return (
    <WrapperTag
      className={classnames(
        oversiktStyle.flexTabell,
        { [style.sumLinje]: isSumLinje },
        isFrittstaaende && style.linjeMedMargin,
      )}
    >
      <dt className={oversiktStyle.flexCol1}>
        <span
          className={classnames({
            [commonStyle.uppercase]: uppercase,
          })}
        >
          {tekst ? (
            <span dangerouslySetInnerHTML={{ __html: tekst }} />
          ) : (
            <span
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({ id: tekstId }),
              }}
            />
          )}
        </span>
        {erGjeldsreduksjon && !beregningIkkeTilgjengelig && (
          <>
            <br />
            <ActionButton
              onClick={() => settVis(true)}
              className={style.knappen}
            >
              {intl.formatMessage({
                id: 'ledetekst.gjeldsreduksjon.seUtregning',
              })}
            </ActionButton>

            {visBeskrivelse && (
              <GrunnlagForGjeldsreduksjon
                closeDialog={closeDialog}
                svalbard={svalbard}
              />
            )}
          </>
        )}
      </dt>
      <dd className={oversiktStyle.flexCol3}>
        <PresentationValue
          val={val}
          beregningIkkeTilgjengelig={beregningIkkeTilgjengelig}
        />
      </dd>
    </WrapperTag>
  );
};

export const OppsummeringLinjeV2 = ({
  verdi,
  tekstId,
  linje,
  beregningIkkeTilgjengelig,
}) => {
  const intl = useIntl();
  const { inntektsaar } = useInntektsaar();

  return sorterSkvisGrupper(
    verdi.filter(({ skalVisesSomSkattefri }) => !skalVisesSomSkattefri),
  )
    .filter(({ beloep }) => !isNaN(beloep))
    .map(
      (
        {
          saerskiltSkatteplikt,
          barnetsPersonidentifikator,
          kommune,
          land,
          beloep,
        },
        index,
      ) => {
        const tekst = lagSkvisGruppeHeader(
          {
            tekstId,
            saerskiltSkatteplikt,
            barnetsPersonidentifikator,
            kommune,
            land,
          },
          intl,
          inntektsaar,
        );

        return (
          <OppsummeringLinje
            id={linje.id}
            key={`${linje.id}:${index}`}
            tekst={tekst}
            value={beloep}
            beregningIkkeTilgjengelig={beregningIkkeTilgjengelig}
          />
        );
      },
    );
};
