import moment from 'moment';
import { IntlShape } from 'react-intl';

// Typene itereres over på skattepliktsopplysningersiden
export enum SivilstandType {
  GIFT = 'gift',
  UGIFT = 'ugift',
  SEPARERT = 'separert',
  SKILT = 'skilt',
  ENKE = 'enke',
  ENKEMANN = 'enkemann',
}

export interface SkattepliktsopplysningerType {
  skatteberegningAar: number;
  sivilstand: SivilstandType | undefined;
  foedselsaar: number | undefined;
  foedselsaarEktefelle: number | undefined;
  borINorgeKunDelerAvAaret: boolean;
  finnmarksfradrag: boolean;
  antallMaanederINorge: number | undefined;
  antallMaanederINorgeEktefelle: number | undefined;
  vilBeregneKildeskattPaaLoenn: boolean;
}

export const validerSkattepliktsopplysninger = (
  state: SkattepliktsopplysningerType,
  intl: IntlShape,
) => {
  const erGyldigAar = (aar: number) => moment(aar, 'YYYY', true).isValid();

  const erGift = state.sivilstand === SivilstandType.GIFT;

  const obligatoriskFeltFeil = intl.formatMessage({
    id: 'feil.felt.obligatorisk',
  });

  const validerFoedselsaar = (aar: number | undefined) => {
    if (!aar) {
      return obligatoriskFeltFeil;
    }
    if (!erGyldigAar(aar)) {
      return intl.formatMessage({ id: 'feil.felt.aarstall' });
    }
    if (aar >= state.skatteberegningAar) {
      return intl.formatMessage({
        id: 'feil.skattepliktsopplysninger.foedselsaarMaaVaereMindreEnnInntektsaar',
      });
    }
    return undefined;
  };

  const nyErrors: any = {};

  if (!state.sivilstand) {
    nyErrors.sivilstand = obligatoriskFeltFeil;
  }

  if (validerFoedselsaar(state.foedselsaar)) {
    nyErrors.foedselsaar = validerFoedselsaar(state.foedselsaar);
  }

  if (erGift && validerFoedselsaar(state.foedselsaarEktefelle)) {
    nyErrors.foedselsaarEktefelle = validerFoedselsaar(
      state.foedselsaarEktefelle,
    );
  }

  if (state.borINorgeKunDelerAvAaret) {
    if (!state.antallMaanederINorge) {
      nyErrors.antallMaanederINorge = obligatoriskFeltFeil;
    }
    if (erGift && !state.antallMaanederINorgeEktefelle) {
      nyErrors.antallMaanederINorgeEktefelle = obligatoriskFeltFeil;
    }
  }
  return nyErrors;
};
