import { useSelector } from 'react-redux';
import { visningslogikk } from '@skatteetaten/visningsdefinisjoner-og-tekster';
import { getAktivVisningsdata } from '../reducers';
import {
  skalFeltSkjules as skalFeltSkjules_,
  skalFeltDisables as skalFeltDisables_,
  skalFeltgruppeSkjules as skalFeltgruppeSkjules_,
  skalSkjuleFeltIKort as skalSkjuleFeltIKort_,
  skalBeholdeVerdi as skalBeholdeVerdi_,
  skalEkspandereFeltgruppe as skalEkspandereFeltgruppe_,
} from '../utils/skalSkjules';

const { felt: feltUtils } = visningslogikk;

export const useBetingelseHook = () => {
  const aktivVisningsdata = useSelector(getAktivVisningsdata);

  const foelgerBetingelse = (data, betingelse) =>
    feltUtils.foelgerBetingelse(data, betingelse, aktivVisningsdata);

  const skalFeltSkjules = (definisjon, data) =>
    skalFeltSkjules_(definisjon, data, aktivVisningsdata);

  const skalFeltDisables = (definisjon, data) =>
    skalFeltDisables_(definisjon, data, aktivVisningsdata);

  const skalFeltgruppeSkjules = (definisjon, data) =>
    skalFeltgruppeSkjules_(definisjon, data, aktivVisningsdata);

  const skalSkjuleFeltIKort = (definisjon, data) =>
    skalSkjuleFeltIKort_(definisjon, data);

  const skalBeholdeVerdi = (definisjon, data) =>
    skalBeholdeVerdi_(definisjon, data, aktivVisningsdata);

  const skalEkspandereFeltgruppe = (definisjon, data) =>
    skalEkspandereFeltgruppe_(definisjon, data, aktivVisningsdata);

  const kanIkkeSletteKort = (definisjon, data) => {
    if (definisjon.kanIkkeSlettes) {
      return true;
    }
    if (definisjon.kanIkkeSlettesGitt === undefined) {
      return false;
    }
    return foelgerBetingelse(data, definisjon.kanIkkeSlettesGitt);
  };

  return {
    foelgerBetingelse,
    skalFeltSkjules,
    skalFeltDisables,
    skalFeltgruppeSkjules,
    skalSkjuleFeltIKort,
    skalBeholdeVerdi,
    skalEkspandereFeltgruppe,
    kanIkkeSletteKort,
  };
};
