import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  getBeregnetSkatt,
  getBeregnetSkattSvalbard,
  getSummertSkattegrunnlagForVisning,
  getSummertSkattegrunnlagForVisningSvalbard,
} from '../reducers';
import { filterSvalbard } from '../web/components/oversikt/summertskattegrunnlag/summertSkattegrunlagUtil';
import { useVdotContext } from '../web/components/VdotContext';

export const useAktivSkvis = () => {
  const vdot = useVdotContext();
  return vdot.skvisningsdefinisjon;
};

export const useSkvisData = (erSvalbard = false) => {
  const skvisData = useSelector(getSummertSkattegrunnlagForVisning);
  const skvisSvalbardData = useSelector(
    getSummertSkattegrunnlagForVisningSvalbard,
  );
  return erSvalbard ? skvisSvalbardData : skvisData;
};

export const useBeregnetSkattDefinisjon = (erSvalbard = false) => {
  const vdot = useVdotContext();
  return useMemo(
    () =>
      vdot.beregnetskattdefinisjon.map((definisjon) => ({
        ...definisjon,
        barnliste: definisjon.barnliste
          ?.filter(
            (barnDef) =>
              !barnDef.applikasjon || barnDef.applikasjon.includes('sme'),
          )
          .filter(filterSvalbard(erSvalbard)),
      })),
    [erSvalbard, vdot.beregnetskattdefinisjon],
  );
};

export const useBeregnetSkattData = (erSvalbard = false) => {
  const beregnetSkattData = useSelector(getBeregnetSkatt);
  const beregnetSkattSvalbardData = useSelector(getBeregnetSkattSvalbard);

  return erSvalbard ? beregnetSkattSvalbardData : beregnetSkattData;
};
