import { useSelector } from 'react-redux';
import { getErNyDialog, getGjeldendeSide } from '../../../reducers';
import { useDispatcher } from '../../../utils/reduxHooks';
import {
  dialogEndre,
  dialogLagreEktefelle,
  dialogNyLagre,
  dialogNyLagreEktefelle,
  slettKortViaDialog,
  slettKortViaDialogEktefelle,
} from '../../../actions/dialog';
import { EKTEFELLESIDE } from '../../../reducers/navigasjon';

export const useLagreDialog = () => {
  const gjeldendeSide = useSelector(getGjeldendeSide);
  const lagreNyDialogSkattemelding = useDispatcher(dialogNyLagre);
  const lagreDialogSkattemelding = useDispatcher(dialogEndre);
  const lagreDialogEktefelle = useDispatcher(dialogLagreEktefelle);
  const lagreNyDialogEktefelle = useDispatcher(dialogNyLagreEktefelle);

  const erNyttKort = useSelector(getErNyDialog);

  if (gjeldendeSide === EKTEFELLESIDE) {
    return erNyttKort ? lagreNyDialogEktefelle : lagreDialogEktefelle;
  }

  return erNyttKort ? lagreNyDialogSkattemelding : lagreDialogSkattemelding;
};

export const useSlettKortViaDialog = () => {
  const gjeldendeSide = useSelector(getGjeldendeSide);
  const slettKortISkattemeldingen = useDispatcher(slettKortViaDialog);
  const slettKortISkattemeldingenEktefelle = useDispatcher(
    slettKortViaDialogEktefelle,
  );

  return gjeldendeSide === EKTEFELLESIDE
    ? slettKortISkattemeldingenEktefelle
    : slettKortISkattemeldingen;
};
