import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import Button from '@skatteetaten/frontend-components/Button';
import style from './DialogKnapperad.module.css';
import { lukkDialog } from '../../../../actions/dialog';

export const DialogOk = ({
  hoved = false,
  aksjon = null,
  submit = false,
  lukkEtterAksjon = true,
  disabled = false,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const aksjon_ = useMemo(() => {
    return (e) => {
      aksjon && aksjon(e);
      lukkEtterAksjon && dispatch(lukkDialog());
    };
  }, [dispatch, aksjon, lukkEtterAksjon]);
  const typeProp = (submit && { type: 'submit' }) || {};

  return (
    <Button
      buttonStyle={hoved ? 'primary' : 'secondary'}
      hoved={hoved}
      onClick={aksjon_}
      disabled={disabled}
      className={style.luftMellomKnapper}
      {...typeProp}
    >
      {intl.formatMessage({ id: 'knapp.dialog.ok' })}
    </Button>
  );
};
