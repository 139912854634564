import { forwardRef } from 'react';
import classnames from 'classnames';
import {
  Button as DSButton,
  ButtonProps as DSButtonProps,
} from '@skatteetaten/ds-buttons';
import Spinner from '@skatteetaten/frontend-components/Spinner';

import style from './Button.module.css';
import { StringishChildren } from './DSTypes';

// https://skatteetaten.github.io/frontend-components/#button

export interface ButtonProps extends Omit<DSButtonProps, 'children'> {
  children: StringishChildren; // kan pr nå bare være string, som er tullete
  pressed?: boolean;
  loading?: boolean;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, pressed, ...props }, ref) =>
    props.loading ? (
      <LoadingButton ref={ref} {...props}>
        {children}
      </LoadingButton>
    ) : (
      <DSButton
        ref={ref}
        className={classnames(style.button, props.className, {
          [style.pressed]: pressed,
        })}
        {...props}
      >
        {children as DSButtonProps['children']}
      </DSButton>
    ),
);

// Dette kommer i DS på et eller annet tidspunkt. Bytt til det hvis det blir brukbart.
const LoadingButton = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, ...props }, ref) => {
    return (
      <DSButton
        ref={ref}
        className={classnames(style.button, props.className)}
        {...props}
      >
        {
          (
            <span className={style.loadingButtonContent}>
              <Spinner
                className={style.spinner}
                spinnerColor={
                  props.variant === 'secondary' || props.disabled
                    ? 'black'
                    : 'white'
                }
              />
              {children}
            </span>
          ) as unknown as DSButtonProps['children']
        }
      </DSButton>
    );
  },
);

Button.displayName = 'SkattekalkulatorButton';
LoadingButton.displayName = 'LoadingButton';
