import {
  ForwardedRef,
  forwardRef,
  MutableRefObject,
  ReactNode,
  useRef,
  useState,
} from 'react';
import {
  IconButton as DSIconButton,
  IconButtonProps as DSIconButtonProps,
} from '@skatteetaten/ds-buttons';
import classnames from 'classnames';
import style from './IconButton.module.css';
import Callout from '@skatteetaten/frontend-components/Callout';

// https://skatteetaten.github.io/frontend-components/#iconbutton

export interface IconButtonProps extends DSIconButtonProps {
  pressed?: boolean;
}

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ pressed = false, ...props }, ref) => (
    <DSIconButton
      ref={ref}
      {...props}
      className={classnames(props.className, {
        [style.pressed]: pressed,
      })}
      isOutlined={pressed || props.isOutlined}
    />
  ),
);

IconButton.displayName = 'SkattekalkulatorIconButton';

interface IconButtonWithCalloutProps extends Omit<IconButtonProps, 'onClick'> {
  calloutContent: ReactNode;
}

const isRefObj = (
  ref: ForwardedRef<HTMLButtonElement>,
): ref is MutableRefObject<HTMLButtonElement | null> =>
  !!ref && typeof ref === 'object';

export const IconButtonWithCallout = forwardRef<
  HTMLButtonElement,
  IconButtonWithCalloutProps
>(({ calloutContent, ...props }, ref) => {
  const [showCallout, setShowCallout] = useState(false);
  const localRef = useRef<HTMLButtonElement | null>(null);

  const actualRef = isRefObj(ref) ? ref : localRef;

  return (
    <>
      <DSIconButton
        ref={actualRef}
        onClick={() => setShowCallout(!showCallout)}
        {...props}
      />
      {showCallout && (
        <Callout
          target={actualRef.current}
          onClose={() => setShowCallout(false)}
          onDismiss={() => setShowCallout(false)}
          doNotLayer
          directionalHint={Callout.POS_BOTTOM_LEFT}
        >
          {calloutContent}
        </Callout>
      )}
    </>
  );
});

IconButtonWithCallout.displayName = 'SkattekalkulatorIconButtonWithCallout';
