import { DIALOGTYPE_DIALOG } from '../../../../actions/dialog';

export const LEGG_TIL_BEHANDLINGSART = 'LEGG_TIL_BEHANDLINGSART';
export const DIALOGTYPE_LEGG_TIL_EGENSKAPER = 'leggTilEgenskaper';
export const NY_LEGG_TIL_EGENSKAPER_DIALOG = 'NY_LEGG_TIL_EGENSKAPER_DIALOG';
export const LEGG_TIL_EGENSKAP = 'LEGG_TIL_EGENSKAP';
export const SLETT_OBJEKT_EGENSKAP = 'SLETT_OBJEKT_EGENSKAP';

export const leggTilDynamiskFelt = (
  dynamiskgruppeReferanse,
  behandlingsart,
  underforekomstDataSti,
  fraDialog = DIALOGTYPE_DIALOG,
) => ({
  type: LEGG_TIL_BEHANDLINGSART,
  dialogtype: fraDialog,
  dynamiskgruppeReferanse,
  behandlingsart,
  underforekomstDataSti,
});

export const nyLeggTilEgenskaperDialog = (
  valgtEgenskap,
  egenskaperSomKanLeggesTil,
  fraDialog,
) => ({
  type: NY_LEGG_TIL_EGENSKAPER_DIALOG,
  dialogtype: DIALOGTYPE_LEGG_TIL_EGENSKAPER,
  fraDialog,
  valgtEgenskap,
  egenskaperSomKanLeggesTil,
});

export const leggTilEgenskaper = (
  valgteEgenskaper,
  fraDialog = DIALOGTYPE_DIALOG,
) => ({
  type: LEGG_TIL_EGENSKAP,
  dialogtype: fraDialog,
  valgteEgenskaper,
});

export const slettEgenskap = (egenskap) => ({
  type: SLETT_OBJEKT_EGENSKAP,
  egenskap,
});
