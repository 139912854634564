import { useIntl } from 'react-intl';
import { language } from '@skatteetaten/visningsdefinisjoner-og-tekster';
import ActionButton from '@skatteetaten/frontend-components/ActionButton';

const dontHandleClick = (e) => {
  e.preventDefault();
};

export const EndreKnapp = ({ id }) => {
  const intl = useIntl();

  return (
    <ActionButton onClick={dontHandleClick} icon="edit" ariaLabel={''}>
      {intl.formatMessage({
        id: language.endreKnappId(id),
        defaultMessage: intl.formatMessage({ id: 'knapp.endre' }),
      })}
    </ActionButton>
  );
};
