import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { getSpinner } from '../../reducers/spinner';

const modalStyle = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.75)',
    zIndex: 1000,
  },
};

export const Spinner = () => {
  const { time, count } = useSelector(getSpinner);

  const [_, setTimer] = useState<number>(Date.now());
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | undefined>();

  useEffect(() => {
    if (!intervalId && count > 0) {
      const intervalId_ = setInterval(() => setTimer(Date.now()), 50);
      setIntervalId(intervalId_);
    }

    if (intervalId && count === 0) {
      clearInterval(intervalId);
      setIntervalId(undefined);
    }
  }, [count, intervalId]);

  const moreThanThreshold = (time: number) => {
    return Date.now() - time > 500;
  };
  const spinnerSkalVises = count > 0 && moreThanThreshold(time);

  if (!spinnerSkalVises) {
    return null;
  }

  return (
    // @ts-ignore
    <div style={modalStyle.overlay}>
      <div className="spinnerWrapper">
        <div className="laster">
          <span className="label">
            <FormattedMessage id="ledetekst.spinner.vennligstVent" />
          </span>
          <div className="dotdotdot">
            <div className="dot" />
            <div className="dot" />
            <div className="dot" />
          </div>
        </div>
      </div>
    </div>
  );
};
