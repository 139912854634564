import { useSelector } from 'react-redux';
import { INNTEKTSAAR, NYESTE_INNTEKTSAAR } from '../constants/inntektsaar';
import { getInntektsaar } from '../reducers';

export const useInntektsaar = () => {
  // TODO skal vi her ta hensyn til inntektsaar i path..? Må i så fall tenke på når denne er tilgjengelig...
  const inntektsaar = useSelector(getInntektsaar) || NYESTE_INNTEKTSAAR;

  return {
    inntektsaar: inntektsaar,
    // TODO denne brukes ikke lenger, vurdere å fjerne...
    vdotAar: INNTEKTSAAR[inntektsaar].vdotAar,
  };
};
