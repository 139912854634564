import { useEffect, useRef, useState } from 'react';
import { scrollTilFoersteFeil } from '../../../utils/scrollUtils';

export const useScrollTilFoersteFeil = () => {
  const [counter, setCounter] = useState(0);
  const _scrollTilFoersteFeil = () => {
    setCounter(counter + 1);
  };
  const scrollTilForesteFeilRef = useRef(null);
  useEffect(() => {
    if (counter !== 0 && scrollTilForesteFeilRef.current) {
      scrollTilFoersteFeil(scrollTilForesteFeilRef.current);
    }
  }, [counter]);
  return { scrollTilForesteFeilRef, _scrollTilFoersteFeil };
};
