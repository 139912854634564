import { cloneDeep, get, isArray, isEmpty, pickBy } from 'lodash';
import fp from 'lodash/fp';
import { v4 as uuid } from 'uuid';
import { visningslogikk } from '@skatteetaten/visningsdefinisjoner-og-tekster';

import {
  API_BEREGN_DIALOG_SUKSESS,
  AVBRYT_REDIGERING_UNDERFOREKOMST_FRA_LISTEVISNING,
  BYTT_DIALOG,
  DIALOG_ENDRE_OK,
  DIALOG_NY_OK,
  DIALOG_SLETT_KORT,
  DIALOG_SLETT_KORT_EKTEFELLE,
  ENDRE_FELT_I_DIALOG,
  FELT_ENDRET,
  FJERN_FOKUS_BEHANDLINGSART,
  LAGRE_UNDERFOREKOMST_FRA_LISTEVISNING,
  LEGG_TIL_REPETERENDEFELT_I_DIALOG,
  LUKK_DIALOG,
  NY_DIALOG,
  NY_DIALOG_MED_DIALOGTYPE,
  NY_DIALOG_MED_FLERE_KORTTYPER,
  NY_LEGG_TIL_OPPLYSNINGER_DIALOG,
  OK_KLIKKET,
  SLETT_DYNAMISKFELT_I_DIALOG,
  SLETT_REPETERENDEFELT_I_DIALOG,
  SLETT_UNDERFOREKOMST_FRA_LISTEVISNING,
  TOEM_HURTIGSOEK,
  UNDERFOREKOMST_FRA_LISTEVISNING,
  VIS_DIALOG,
  VIS_KORT_I_KORT_DIALOG,
} from '../actions/dialog';
import {
  flatListeMedRepeterendeFelter,
  flatListMangeTyper,
} from '../utils/feltUtils';
import {
  defaultVerdiForOpsjon,
  erstattEllerLeggTilUnderforekomst,
  hentUnderforekomstFraForekomst,
  hentUnderforekomsttypeFraForekomst,
  oppdaterAndreFeltVedEndring,
  settEgenskapPaaAlleFelter,
} from '../utils/visningsdataUtil';
import { FELT, FELT_MED_EGENSKAPER } from '../utils/visningsDataDefinisjonUtil';
import egenskaperReducer from '../web/components/dialog/KortOpplysninger/egenskaper.reducer';
import {
  LEGG_TIL_BEHANDLINGSART,
  LEGG_TIL_EGENSKAP,
} from '../web/components/dialog/KortOpplysninger/kortOpplysninger.actions';
import {
  finnDynamiskfeltDefinisjon,
  flatUtFeltgrupper,
} from '../utils/visningsdefinisjonUtil';
import { toemInaktiveFeltverdier } from '../utils/deaktiverteFeltUtils';
import { initierData, leggTilDefaultDynamiskeFelt } from './reducerUtil';
import {
  erPensjonskortMedAarligInntektIDialog,
  fordelAarligPensjonsinntektPaaMaaneder,
  skalUtledeAarligPensjonsinntektFraMaaneder,
  utledFeltverdierForPensjon,
} from '../utils/pensjon';
import {
  erEndringSomPaavirkerTSU,
  medTSUEndringer,
} from '../utils/trygdeEllerSkattemessigUnntak';

const { kort: kortUtils } = visningslogikk;

export const getIsOpen = (state) => state.isOpen;
export const getDialogtype = (state) => state.dialogtype;
export const getData = (state) => state.data;
// Henter dialogdata i en form som er gyldig visningsdata, dvs. den kan mappes til gm og brukes til beregninger etc.
export const getDataIDialogVasket = (state) =>
  kortUtils.fjernTommeDynamiskeFelt(getKortdefinisjon(state), getData(state));
export const getKortdefinisjon = (state) => state.kortdefinisjon;
export const getKortgruppeIDialog = (state) => state.kortgruppe;
export const getSistOkKlikketTimestamp = (state) =>
  state.sistOkKlikketTimestamp;
export const getNyBehandlingsart = (state) => state.nyBehandlingsart;
export const getDialogErITabellredigering = (state) =>
  !isEmpty(state.underforekomstStateFoerRedigering);
export const getDialogTemaId = (state) => state.temaId;
export const getDialogFeltSomErEndret = (state) => state.feltSomErEndret;
export const getTypeIDialog = (state) => state.type;
export const getErNyDialog = (state) => state.type === 'ny';
export const getKortIKortDialogProps = (state) => state.kortIKortDialogProps;
export const getSynligeEgenskaper = (state) => state.synligeEgenskaper;
export const getUnderforekomsttypeIDialog = (dataSti) => (state) =>
  hentUnderforekomsttypeFraForekomst(state.data, dataSti);

export const getUnderforekomstIDialog = (dataSti, state) =>
  hentUnderforekomstFraForekomst(state.data, dataSti);

export const getForekomstForDialog = (state) => state.data;
export const getKodelisteConfig = (state) =>
  getKortdefinisjon(state)?.kodelisteConfig;

export const getAlleFelterIKortdefinisjon = (kortdefinisjon) =>
  flatListMangeTyper(kortdefinisjon.barnliste, FELT, FELT_MED_EGENSKAPER);

const defaultState = {
  isOpen: false,
  barnliste: [],
  underforekomstStateFoerRedigering: {},
  feltSomErEndret: {},
};

const skalHaMaksEnAvDefaultVerdi = (barnfelt, endretUnderforekomst = {}) => {
  const { defaultVerdi, maksEnAv } = get(barnfelt, 'opsjon', {});

  return (
    defaultVerdi &&
    maksEnAv &&
    endretUnderforekomst[barnfelt.referanse] === maksEnAv
  );
};

// Denne muterer data, så ikke sendt inn dialogdata-objektet
function sjekkMaksEnAvForRepeterendeFelt(
  nyData,
  underforekomstStateFoerRedigering,
  feltListe,
) {
  feltListe.forEach((felt) => {
    if (nyData[felt.referanse]) {
      const endretUnderforekomst = nyData[felt.referanse].find(
        (underforekosmt) =>
          underforekosmt.id === underforekomstStateFoerRedigering.data.id,
      );
      felt.barnliste.forEach((barnfelt) => {
        if (skalHaMaksEnAvDefaultVerdi(barnfelt, endretUnderforekomst)) {
          nyData[felt.referanse]
            .filter(
              (underforekomst) =>
                underforekomst.id !== underforekomstStateFoerRedigering.data.id,
            )
            .forEach(
              (underforekomst) =>
                (underforekomst[barnfelt.referanse] =
                  barnfelt.opsjon.defaultVerdi),
            );
        }
      });
    }
  });
  return nyData;
}

const stateMedNullStillUnderforekomstFraListevisningRedigering = (state) => {
  if (isEmpty(state.underforekomstStateFoerRedigering)) {
    return state;
  }
  return {
    ...state,
    underforekomstStateFoerRedigering: {},
    kortIKortDialogProps: {},
    data: erstattEllerLeggTilUnderforekomst(
      state.data,
      state.underforekomstStateFoerRedigering.dataSti,
      state.underforekomstStateFoerRedigering.data,
    ),
  };
};

// TODO burde gjøre noe med kompleksisten her...
/* eslint-disable sonarjs/cognitive-complexity */
const dialogReducer = (
  state,
  action,
  // TODO trengs disse?
  { inntektsaar, skatteplikt, visningsdata },
) => {
  const kortdefinisjon = getKortdefinisjon(state) || action.kortdefinisjon;

  const medSkatteplikt = (data) => {
    return Array.isArray(data)
      ? data.map((forekomst) => medSkatteplikt(forekomst))
      : {
          ...data,
          skatteplikt,
        };
  };
  const medInitielleVerdier = (data) =>
    fp.flow(
      (data_) =>
        leggTilDefaultDynamiskeFelt(data_, kortdefinisjon, inntektsaar),
      (data_) => initierData(data_, kortdefinisjon),
    )(data);

  switch (action.type) {
    case API_BEREGN_DIALOG_SUKSESS: {
      // LUKK_DIALOG action kan ha skjedd siden beregningen ble trigget
      if (!kortdefinisjon) {
        return state;
      }
      let visningsdataFraBeregning =
        action.visningsdata[kortdefinisjon.referanse];

      const dialogData = getData(state);

      let oppdaterteDialogData = visningsdataFraBeregning?.find(
        (beregningData) => dialogData.id === beregningData.id,
      ) || {
        id: dialogData.id,
      };

      if (
        skalUtledeAarligPensjonsinntektFraMaaneder(
          kortdefinisjon,
          oppdaterteDialogData,
        )
      ) {
        oppdaterteDialogData = utledFeltverdierForPensjon(
          kortdefinisjon,
          oppdaterteDialogData,
        );
      }

      return {
        ...state,
        data: fp.flow(medSkatteplikt, (data_) =>
          initierData(data_, kortdefinisjon, true),
        )(oppdaterteDialogData),
      };
    }

    case NY_DIALOG: {
      const dataForNyDialog = fp.flow(
        medSkatteplikt,
        medInitielleVerdier,
      )(action.data);

      return {
        ...defaultState,
        data: dataForNyDialog,
        kortdefinisjon: action.kortdefinisjon,
        alleFelterIKortdefinisjon: getAlleFelterIKortdefinisjon(
          action.kortdefinisjon,
        ),
        dialogtype: action.dialogtype,
        synligeEgenskaper: action.egenskaper,
        temaId: action.temaId,
        type: 'ny',
        isOpen: true,
      };
    }

    case VIS_DIALOG: {
      let dataForVisDialog = fp.flow(
        medSkatteplikt,
        medInitielleVerdier,
      )(action.data);

      if (
        skalUtledeAarligPensjonsinntektFraMaaneder(
          kortdefinisjon,
          dataForVisDialog,
        )
      ) {
        dataForVisDialog = utledFeltverdierForPensjon(
          kortdefinisjon,
          dataForVisDialog,
        );
      }

      return {
        ...defaultState,
        data: dataForVisDialog,
        kortdefinisjon: action.kortdefinisjon,
        alleFelterIKortdefinisjon: getAlleFelterIKortdefinisjon(
          action.kortdefinisjon,
        ),
        dialogtype: action.dialogtype,
        type: 'endre',
        isOpen: true,
        temaId: action.temaId,
        kortgruppe: action.kortgruppe,
      };
    }

    case BYTT_DIALOG:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.data,
        },
        dialogtype: action.dialogtype,
        fraDialog: action.fraDialog,
      };

    case VIS_KORT_I_KORT_DIALOG:
      return {
        ...state,
        kortIKortDialogProps: action.kortIKortDialogProps,
        dialogtype: action.dialogtype,
        fraDialog: action.fraDialog,
      };

    case LEGG_TIL_BEHANDLINGSART: {
      const {
        dialogtype,
        dynamiskgruppeReferanse,
        behandlingsart,
        underforekomstDataSti,
      } = action;

      const dynamiskfeltDefinisjon = finnDynamiskfeltDefinisjon(
        state.kortdefinisjon,
        dynamiskgruppeReferanse,
        behandlingsart,
      );
      const initiertBehandlingsart = dynamiskfeltDefinisjon
        ? initierData(behandlingsart, dynamiskfeltDefinisjon)
        : behandlingsart;

      let nyeData = {};
      if (underforekomstDataSti?.underforekomsttype) {
        const underforekomster = get(
          state.data,
          underforekomstDataSti.underforekomsttype,
          [],
        ).map((underforekomst) => {
          if (underforekomst.id === underforekomstDataSti.underforekomst) {
            return {
              ...underforekomst,
              [dynamiskgruppeReferanse]: [
                ...get(underforekomst, dynamiskgruppeReferanse, []),
                initiertBehandlingsart,
              ],
            };
          }
          return underforekomst;
        });
        nyeData = {
          [underforekomstDataSti.underforekomsttype]: underforekomster,
        };
      } else {
        nyeData = {
          [dynamiskgruppeReferanse]: [
            ...get(state.data, dynamiskgruppeReferanse, []),
            initiertBehandlingsart,
          ],
        };
      }

      return {
        ...state,
        data: {
          ...state.data,
          ...nyeData,
        },
        dialogtype,
        fraDialog: action.fraDialog, // TODO denne finnes ikke på action, selv om den sendes til action creator
        nyBehandlingsart: behandlingsart, // Dette for å sette fokus på ny behandlingsart-input
      };
    }

    case FJERN_FOKUS_BEHANDLINGSART: {
      return {
        ...state,
        nyBehandlingsart: undefined,
      };
    }

    case SLETT_DYNAMISKFELT_I_DIALOG: {
      const { feltreferanse } = action;
      const [referanse, index, subforekomstReferanse, subforekomstIndex] =
        feltreferanse.split('.');
      let behandlingsarter = get(state, `data.${referanse}`, []);
      if (subforekomstReferanse) {
        let dynamiskGruppe = behandlingsarter.find(
          (a, i) => parseInt(index) === i,
        );
        let dynamiskeFelt = get(dynamiskGruppe, subforekomstReferanse, []);
        dynamiskeFelt = dynamiskeFelt.filter(
          (a, i) => parseInt(subforekomstIndex) !== i,
        );
        dynamiskGruppe = {
          ...dynamiskGruppe,
          [subforekomstReferanse]: dynamiskeFelt,
        };
        behandlingsarter[parseInt(index)] = dynamiskGruppe;
      } else {
        behandlingsarter = behandlingsarter.filter(
          (a, i) => parseInt(index) !== i,
        );
      }
      return {
        ...state,
        data: {
          ...state.data,
          [referanse]: behandlingsarter,
        },
      };
    }

    case DIALOG_ENDRE_OK:
    case DIALOG_NY_OK:
      return {
        ...state,
      };

    case DIALOG_SLETT_KORT:
    case DIALOG_SLETT_KORT_EKTEFELLE:
    case LUKK_DIALOG:
      return {
        ...defaultState,
      };

    // TODO trengs ikke..?
    case OK_KLIKKET:
      return {
        ...state,
        sistOkKlikketTimestamp: action.dato,
      };

    case FELT_ENDRET:
      const { feltSomErEndret } = state;
      const { feltId, feltsti, erEgenskap, verdiFoerEndring, nyVerdi } = action;

      return {
        ...state,
        feltSomErEndret: {
          ...feltSomErEndret,
          [feltsti]: {
            feltId,
            feltsti,
            erEgenskap,
            verdiFoerEndring,
            nyVerdi,
          },
        },
      };

    case ENDRE_FELT_I_DIALOG: {
      let nyState = {
        ...state,
        data: toemInaktiveFeltverdier(
          kortdefinisjon,
          fp.set(action.feltreferanse, action.verdi, state.data),
          {},
          false,
          visningsdata,
        ),
      };

      if (
        erEndringSomPaavirkerTSU(
          action,
          kortdefinisjon,
          state.alleFelterIKortdefinisjon,
        )
      ) {
        const tsuEndring = medTSUEndringer(action, nyState, kortdefinisjon);
        nyState.data = tsuEndring.data;
      }

      if (erPensjonskortMedAarligInntektIDialog(kortdefinisjon, nyState.data)) {
        nyState.data = fordelAarligPensjonsinntektPaaMaaneder(nyState.data);
      }

      return {
        ...nyState,
        data: oppdaterAndreFeltVedEndring(action, nyState.data),
      };
    }

    case UNDERFOREKOMST_FRA_LISTEVISNING:
      if (isEmpty(state.underforekomstStateFoerRedigering)) {
        const data = cloneDeep(getUnderforekomstIDialog(action.dataSti, state));
        return {
          ...state,
          underforekomstStateFoerRedigering: {
            dataSti: action.dataSti,
            data,
          },
        };
      }
      return state;

    case LAGRE_UNDERFOREKOMST_FRA_LISTEVISNING:
      const vaskBortNyUnderforekomstState = (data) => {
        const subForekomstLister = pickBy(data, isArray);
        Object.values(subForekomstLister).forEach((subForekomster) => {
          subForekomster.forEach(
            (subForekomst) => delete subForekomst.erNyUnderforekomst,
          );
        });
        return data;
      };

      const medEgenskaperSattPaaAlleFelt = settEgenskapPaaAlleFelter(
        state.underforekomstStateFoerRedigering,
        cloneDeep(state.data),
        state.kortdefinisjon,
      );

      const utenInaktiveFeltverdier = toemInaktiveFeltverdier(
        kortdefinisjon,
        medEgenskaperSattPaaAlleFelt,
      );

      const data = sjekkMaksEnAvForRepeterendeFelt(
        vaskBortNyUnderforekomstState(utenInaktiveFeltverdier),
        state.underforekomstStateFoerRedigering,
        flatListeMedRepeterendeFelter(kortdefinisjon.barnliste),
      );

      return {
        ...state,
        data,
        underforekomstStateFoerRedigering: {},
        kortIKortDialogProps: {},
      };

    case AVBRYT_REDIGERING_UNDERFOREKOMST_FRA_LISTEVISNING:
      return stateMedNullStillUnderforekomstFraListevisningRedigering(state);

    case LEGG_TIL_REPETERENDEFELT_I_DIALOG:
      const repeterendefeltDef = flatUtFeltgrupper(
        state.kortdefinisjon.barnliste,
      ).find((barn) => barn.referanse === action.feltreferanse);

      const medErNyUnderforekomst = (feltData) => {
        return action.erUnderforekomstIListevisning
          ? fp.set('erNyUnderforekomst', true, feltData)
          : feltData;
      };
      const nyttRepeterendeFelt = fp.flow(
        (feltData) =>
          leggTilDefaultDynamiskeFelt(
            feltData,
            repeterendefeltDef,
            inntektsaar,
          ),
        (feltData) => medErNyUnderforekomst(feltData),
        (feltData) => initierData(feltData, repeterendefeltDef),
      )({ id: uuid() });

      return {
        ...state,
        data: {
          ...state.data,
          [action.feltreferanse]: [
            ...get(state.data, action.feltreferanse, []),
            {
              ...nyttRepeterendeFelt,
              meta: 'endretEgenfastsetting',
            },
          ],
        },
      };

    case SLETT_REPETERENDEFELT_I_DIALOG:
    case SLETT_UNDERFOREKOMST_FRA_LISTEVISNING:
      const stateMedNullstillredigering =
        stateMedNullStillUnderforekomstFraListevisningRedigering(state);
      return {
        ...stateMedNullstillredigering,
        data: {
          ...stateMedNullstillredigering.data,
          [action.feltreferanse]: get(
            stateMedNullstillredigering.data,
            action.feltreferanse,
          ).filter((felt) => felt.id !== action.id),
        },
      };

    case NY_LEGG_TIL_OPPLYSNINGER_DIALOG:
      return {
        ...defaultState,
        dialogtype: action.dialogtype,
        isOpen: true,
        temaId: action.temaId,
      };

    case NY_DIALOG_MED_FLERE_KORTTYPER:
      return {
        ...defaultState,
        korttyper: action.korttyper || [],
        dialogtype: action.dialogtype,
        kortgruppe: action.kortgruppe,
        isOpen: true,
        data: action.data,
        temaId: action.temaId,
      };

    case NY_DIALOG_MED_DIALOGTYPE:
      const dataForNyDialogMedDialogtype = action.kortdefinisjon
        ? fp.flow(medSkatteplikt, medInitielleVerdier)(action.data)
        : action.data;

      return {
        ...defaultState,
        dialogtype: action.dialogtype,
        kortgruppe: action.kortgruppe,
        kortdefinisjon: action.kortdefinisjon,
        isOpen: true,
        temaId: action.temaId,
        data: {
          ...dataForNyDialogMedDialogtype,
          skatteplikt,
        },
        synligeEgenskaper: action.egenskaper,
      };

    case TOEM_HURTIGSOEK: {
      return {
        ...state,
        hurtigsoek: {},
      };
    }

    case LEGG_TIL_EGENSKAP: {
      let nyData = state.data;

      const erEgenskapPaaObjektMedDefaultVerdi = (egenskap) =>
        egenskap.erEgenskapPaaObjekt && egenskap.opsjon?.defaultVerdi;

      const leggTilDefaultVerdiIData = (egenskap) => {
        const defaultVerdi = defaultVerdiForOpsjon(egenskap, state.data);
        nyData = fp.set(egenskap.referanse, defaultVerdi, nyData);
      };

      action.valgteEgenskaper
        .filter(erEgenskapPaaObjektMedDefaultVerdi)
        .forEach(leggTilDefaultVerdiIData);

      return {
        ...state,
        data: nyData,
        dialogtype: action.dialogtype,
        fraDialog: action.fraDialog,
      };
    }

    default:
      return state;
  }
};

export default function dialogOgEgenskaperReducer(
  state = defaultState,
  action = {},
  otherState = {},
) {
  return reducers.reduce(
    (s, r) =>
      r(s, action, {
        inntektsaar: undefined,
        skatteplikt: {},
        ...otherState,
      }),
    state,
  );
}

const reducers = [dialogReducer, egenskaperReducer];
