import matomoCoreUtil from './matomoCoreUtil';

export default (() => {
  const { tekst, getInntektsaar, sendEventTilPiwik } = matomoCoreUtil;

  return {
    sendEventEndringAvFelt(temaId, korttypeId, feltId, nyVerdi) {
      if (!getInntektsaar()) return;
      const korttype = tekst(korttypeId);
      const felt = tekst(feltId) || feltId;
      sendEventTilPiwik([
        `Tema: ${tekst(temaId)}`,
        'Endring - Felt',
        korttype ? `${korttype}: ${felt} (${nyVerdi})` : `${felt} (${nyVerdi})`,
      ]);
    },

    sendEventLeggTilEgenskap(temaId, korttypeId, feltId) {
      if (!getInntektsaar()) return;
      const korttype = tekst(korttypeId);
      const felt = tekst(feltId);
      sendEventTilPiwik([
        `Tema: ${tekst(temaId)}`,
        'Legg til egenskap',
        korttype ? `${korttype}: ${felt}` : felt,
      ]);
    },

    sendEventEndringKansellert(temaId, korttypeId) {
      if (!getInntektsaar()) return;
      sendEventTilPiwik([
        `Tema: ${tekst(temaId)}`,
        'Avbryt - Endring',
        tekst(korttypeId),
      ]);
    },

    sendEventSlettkort(temaId, korttypeId) {
      if (!getInntektsaar()) return;
      sendEventTilPiwik([
        `Tema: ${tekst(temaId)}`,
        'Klikk - Slett',
        tekst[korttypeId],
      ]);
    },

    sendEventKlikkEndreKort(temaId, korttypeId) {
      if (!getInntektsaar()) return;
      sendEventTilPiwik([
        `Tema: ${tekst(temaId)}`,
        'Klikk - Endre',
        tekst[korttypeId],
      ]);
    },

    sendEventKlikkFelt(temaId, korttypeId, feltId) {
      if (!getInntektsaar()) return;
      const korttype = tekst(korttypeId);
      const felt = tekst(feltId);
      sendEventTilPiwik([
        `Tema: ${tekst(temaId)}`,
        'Klikk - Felt',
        korttype ? `${korttype}: ${felt}` : felt,
      ]);
    },

    sendEventKlikkLeggTil(temaId, korttypeId) {
      if (!getInntektsaar()) return;
      sendEventTilPiwik([
        `Tema: ${tekst(temaId)}`,
        'Klikk - Legg til',
        tekst(korttypeId),
      ]);
    },

    sendEventKlikkLeggTilFullfoert(temaId, korttypeId) {
      if (!getInntektsaar()) return;
      sendEventTilPiwik([
        `Tema: ${tekst(temaId)}`,
        'Fullført - Legg til',
        tekst(korttypeId),
      ]);
    },

    sendEventKlikkHjelpetekst(temaId, korttypeId, feltId) {
      if (!getInntektsaar()) return;
      const korttype = tekst(korttypeId);
      const felt = tekst(feltId);
      sendEventTilPiwik([
        `Tema: ${tekst(temaId)}`,
        'Klikk - Hjelpetekst',
        korttype ? `${korttype}: ${felt}` : felt,
      ]);
    },

    sendEventLukkKlikkHjelpetekst(temaId, korttypeId, feltId) {
      if (!getInntektsaar()) return;
      const korttype = tekst(korttypeId);
      const felt = tekst(feltId);
      sendEventTilPiwik([
        `Tema: ${tekst(temaId)}`,
        'Lukk klikk - Hjelpetekst',
        korttype ? `${korttype}: ${felt}` : felt,
      ]);
    },

    sendEventTilAnalytics(informasjon) {
      sendEventTilPiwik(informasjon);
    },

    sendFeilvisningTilPiwik({ feillokasjon, feilkode }) {
      sendEventTilPiwik(['VisFeilmelding', feillokasjon, feilkode]);
    },
  };
})();
