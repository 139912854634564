import { useSelector } from 'react-redux';
import { get } from 'lodash';

import { language } from '@skatteetaten/visningsdefinisjoner-og-tekster';
import style from '../beregnetskatt/BeregnetSkatt.module.scss';
import { OverskriftLinje } from './OverskriftLinje';
import { OppsummeringLinje, OppsummeringLinjeV2 } from './OppsummeringLinje';
import { getErPaaNaeringsspesifikasjonSide } from '../../../../reducers';
import { filterSvalbard } from './summertSkattegrunlagUtil';

const GRUPPE_SKJUL_HVIS_INGEN_LINJER =
  'summertSkattegrunnlagForVisning.samletGrunnlagForInntektsskattTilKommuneOgFylkeskommuneStatsskattOgFellesskatt';

export const Oppsummeringsgruppe = ({
  skvisData,
  oppsummeringsgruppe,
  beregningIkkeTilgjengelig,
  containerHeaderId,
  svalbard,
}) => {
  const erPaaNaeringsspesifikasjonSide = useSelector(
    getErPaaNaeringsspesifikasjonSide,
  );

  const visningslinjer = oppsummeringsgruppe.barnliste
    .filter(filterSvalbard(svalbard))
    .filter(
      (linje) =>
        get(skvisData, linje.referanse, linje.defaultVerdi) !== undefined,
    )
    .map((linje) => {
      const verdi = get(skvisData, linje.referanse, linje.defaultVerdi);
      const tekstId = erPaaNaeringsspesifikasjonSide
        ? language.naeringssammendragId(linje.id)
        : language.skvisLedetekstId(linje.id);

      if (!Array.isArray(verdi)) {
        return (
          <OppsummeringLinje
            id={linje.id}
            key={linje.id}
            tekstId={tekstId}
            value={verdi}
            beregningIkkeTilgjengelig={beregningIkkeTilgjengelig}
          />
        );
      }

      return (
        <OppsummeringLinjeV2
          key={linje.id}
          verdi={verdi}
          tekstId={tekstId}
          linje={linje}
          beregningIkkeTilgjengelig={beregningIkkeTilgjengelig}
        />
      );
    })
    .filter((linje) => !!linje);

  if (
    oppsummeringsgruppe.id === GRUPPE_SKJUL_HVIS_INGEN_LINJER &&
    !visningslinjer.length
  ) {
    return null;
  }

  const sum = get(
    skvisData,
    oppsummeringsgruppe.referanse,
    oppsummeringsgruppe.defaultVerdi,
  );
  return (
    <div>
      {!containerHeaderId && (
        <OverskriftLinje
          stil={style.overskriftLinjeMedToppMarg}
          id={
            erPaaNaeringsspesifikasjonSide
              ? language.naeringssammendragOverskriftId(oppsummeringsgruppe.id)
              : language.skvisOverskriftId(oppsummeringsgruppe.id, svalbard)
          }
        />
      )}
      <dl>
        {visningslinjer}
        <OppsummeringLinje
          id={oppsummeringsgruppe.id}
          tekstId={
            erPaaNaeringsspesifikasjonSide
              ? language.naeringssammendragId(oppsummeringsgruppe.id)
              : language.skvisLedetekstId(oppsummeringsgruppe.id)
          }
          value={Array.isArray(sum) ? get(sum[0], 'beloep') : sum}
          isSumLinje
          beregningIkkeTilgjengelig={beregningIkkeTilgjengelig}
        />
      </dl>
    </div>
  );
};
