import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import get from 'lodash/get';
import { useEffect, useRef } from 'react';

import { visningslogikk } from '@skatteetaten/visningsdefinisjoner-og-tekster';
import style from './Kort.module.scss';
import { KortHeader } from './KortHeader';
import { KortFeltType } from './KortFeltType';
import { dialogtypeForKortgruppe } from '../../../../utils/visningsdefinisjonUtil';
import {
  // getErUtenrikstjenestemann,
  // getHarSkattepliktTilSvalbard,
  getVisningsdefinisjon,
} from '../../../../reducers';
import { visDialog } from '../../../../actions/dialog';
import { useDispatcher } from '../../../../utils/reduxHooks';
import { useInntektsaar } from '../../../../hooks/useInntektsaar';
import { EndreKnapp } from './KortKnapper';
import {
  getFokus,
  lagKortForekomstId,
  toemOpprettet,
} from '../../../../fokus/fokus.reducer';
import { scrollToPosition } from '../../../../utils/scroll';
import { getPos } from '../../../../utils/scrollUtils';
import { useAnalytics } from '../../../../analytics/analytics.hooks';
import { useKildeSkattPaaLoenn } from '../../kildeskattePaaLoenn/useKildeskattPaaLoenn';

const { transformer, visningsdefinisjonUtils } = visningslogikk;

const useSkalFokusereEtterDialog = (data, kortdefinisjon) => {
  const fokusRef = useRef();
  const fokusState = useSelector(getFokus);
  const dispatch = useDispatch();

  const opprettetId = get(fokusState, 'opprettetId');

  const kortSkalHaFokus =
    kortdefinisjon &&
    opprettetId === lagKortForekomstId(kortdefinisjon, get(data, 'id'));

  if (kortSkalHaFokus && fokusRef.current) {
    scrollToPosition(getPos(fokusRef.current));
    fokusRef.current?.focus();

    dispatch(toemOpprettet());
  }

  return fokusRef;
};

export const Kort = ({ kortdefinisjon, data = {}, temaId }) => {
  const intl = useIntl();
  const analytics = useAnalytics(temaId);
  const { header, subHeader } = kortdefinisjon;

  const fokusRef = useSkalFokusereEtterDialog(data, kortdefinisjon);

  const visningsdefinisjon = useSelector(getVisningsdefinisjon);
  const { beregnKplModus } = useKildeSkattPaaLoenn();
  const { inntektsaar } = useInntektsaar();
  // const harSkattepliktTilSvalbard = useSelector(getHarSkattepliktTilSvalbard);
  // const erUtenrikstjenestemann = useSelector(getErUtenrikstjenestemann);

  const kortgruppe =
    visningsdefinisjonUtils.finnDefinisjonGittKorttypereferanse(
      kortdefinisjon.referanse,
      'kortgruppe',
      visningsdefinisjon,
    );

  const visDialog_ = useDispatcher(
    visDialog,
    dialogtypeForKortgruppe(kortgruppe),
    kortdefinisjon,
    data,
    temaId,
    kortgruppe,
  );

  useEffect(() => {
    if (beregnKplModus) {
      // Ved beregning av KPL er bare lønnskortet tilgjengelig, og vi ønsker da
      // å åpne dette automatisk når bruker laster siden
      visDialog_();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [beregnKplModus]);

  const visDialogHandler = () => {
    visDialog_();
    analytics.endreKortKlikk(kortdefinisjon.id);
  };

  const keyHandler = (e) => {
    if (e.keyCode === 13 || e.keyCode === 32) {
      e.preventDefault();
      visDialogHandler();
    }
  };

  const classes = classnames('mss-renderkort', 'style.aktivtKort');

  return (
    <div className={classes} ref={fokusRef} tabIndex="-1">
      <div
        className={style.klikkbarKort}
        onClick={visDialogHandler}
        onKeyDown={keyHandler}
      >
        <div className={style.innholdKort}>
          {header && !beregnKplModus && (
            <KortHeader
              tittel={transformer.transformerHeader(
                header,
                data,
                kortdefinisjon,
                { inntektsaar, intl, inline: true },
              )}
              undertittel={transformer.transformerSubHeader(
                subHeader,
                data,
                kortdefinisjon,
                { inntektsaar, intl, inline: true },
              )}
            />
          )}
          <KortFeltType kortdefinisjon={kortdefinisjon} data={data} />
        </div>
        <div className={classnames('mss-renderkort__bunn', style.footer)}>
          <EndreKnapp id={kortdefinisjon.id} />
        </div>
      </div>
    </div>
  );
};
