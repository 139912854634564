import { useSelector } from 'react-redux';
import { getKortdefinisjonIDialog } from '../../../reducers';
import { StorDialog } from './utils/StorDialog';
import { KortIDialog } from './KortIDialog';

export const RedigerKorttypeDialog = () => {
  const kortDefinisjon = useSelector(getKortdefinisjonIDialog);
  const title = (kortDefinisjon && kortDefinisjon.id) || '';
  return (
    <StorDialog titleId={title} visObligatoriskFeltMelding>
      <KortIDialog />
    </StorDialog>
  );
};
