import { cloneDeep } from 'lodash';
import { SivilstandType } from '../web/routes/skattepliktsopplysninger.validator';

export const SKATTEPLIKT_OPPDATERT = 'SKATTEPLIKT_OPPDATERT';

export const skattepliktOppdatert = (opplysninger) => ({
  type: SKATTEPLIKT_OPPDATERT,
  opplysninger,
});

export const getErSivilstandGift = (state) =>
  erSivilstandGift(getSkattepliktsopplysningerSide(state.skatteplikt));

export const getVilBeregneKildeskattPaaLoenn = (state) =>
  getSkattepliktsopplysningerSide(state.skatteplikt)
    ?.vilBeregneKildeskattPaaLoenn;

// TODO reuse denne, og flytte til noe util..?
export const erSivilstandGift = (opplysninger = {}) =>
  opplysninger.sivilstand === SivilstandType.GIFT;

export const getSkatteberegningsaar = (skattepliktState = {}) =>
  getSkattepliktsopplysningerSide(skattepliktState)?.skatteberegningAar;

export const getSkattepliktsopplysningerSide = (skattepliktState = {}) =>
  skattepliktState.skattepliktsopplysningerSide;

// TODO må gå gjennom disse, evt. initiere dette på server. Også evt. oppdatere fra svar fra beregn..?
const defaultSkattepliktVisningsdata = {
  skattested: '0301',
  skattepliktTilNorge: 'global',
  tolvdelVedArbeidsoppholdINorge: '12',
  harEktefelle: 'false',
  harSamboer: 'false',
  bosattILandEtterSkatteavtale: 'false',
  kildeskattepliktPaaLoenn: 'false',
  kildeskattepliktPaaPensjon: 'false',
  skattepliktAvNaeringsdriftEiendomMv: 'false',
  skattepliktEtterPetroleumsskatteloven: 'false',
  skattepliktPaaLoennFraDenNorskeStatOpptjentIUtlandet: 'false',
  skattepliktSomSjoemann: 'false',
  skattepliktTilSvalbard: undefined,
  skattepliktVedUtenriksoppholdINorskStatstjenesteEllerNato: 'false',
  tolvdelVedArbeidsoppholdIUtlandet: undefined,
};

const defaultState = {
  skattepliktsopplysningerSide: {},
  skattepliktVisningsdata: defaultSkattepliktVisningsdata,
  skattepliktVisningsdataEktefelle: {},
};

const oppdaterSkatteplikt = (
  erGift,
  antallMaanederINorge,
  vilBeregneKildeskattPaaLoenn = false,
) => {
  const nySkattepliktVisningsdata = cloneDeep(defaultSkattepliktVisningsdata);
  nySkattepliktVisningsdata.harEktefelle = `${erGift}`;
  if (antallMaanederINorge) {
    nySkattepliktVisningsdata.tolvdelVedArbeidsoppholdINorge = `${antallMaanederINorge}`;
  }
  if (vilBeregneKildeskattPaaLoenn) {
    nySkattepliktVisningsdata.kildeskattepliktPaaLoenn = 'true';
  }
  return nySkattepliktVisningsdata;
};

const skattepliktReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case SKATTEPLIKT_OPPDATERT: {
      const {
        sivilstand,
        antallMaanederINorge,
        antallMaanederINorgeEktefelle,
        vilBeregneKildeskattPaaLoenn,
      } = action.opplysninger;

      const nyState = cloneDeep(defaultState);
      const erGift = sivilstand === 'gift';

      nyState.skattepliktsopplysningerSide = action.opplysninger;
      nyState.skattepliktVisningsdata = oppdaterSkatteplikt(
        erGift,
        antallMaanederINorge,
        vilBeregneKildeskattPaaLoenn,
      );

      if (erGift) {
        nyState.skattepliktVisningsdataEktefelle = oppdaterSkatteplikt(
          erGift,
          antallMaanederINorgeEktefelle,
        );
      }

      return nyState;
    }
    default: {
      return state;
    }
  }
};

export default skattepliktReducer;
