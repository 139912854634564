import {
  DIALOG_LAGRE_EKTEFELLE,
  DIALOG_NY_LAGRE_EKTEFELLE,
  DIALOG_SLETT_KORT_EKTEFELLE,
} from '../actions/dialog';
import { API_BEREGN_SUKSESS } from '../actions/skattemelding';
import { getBeregnetVisningsdata, lagreKort, slettKort } from './visningsdata';
import { erSivilstandGift, SKATTEPLIKT_OPPDATERT } from './skatteplikt';
import { NYESTE_INNTEKTSAAR } from '../constants/inntektsaar';

export const defaultEktefelleState = { inntektsaar: NYESTE_INNTEKTSAAR };

export default function visningsdataEktefelleReducer(
  state = defaultEktefelleState,
  action = {},
  visningsdefinisjon = {},
  gammelDialog = {},
) {
  switch (action.type) {
    case DIALOG_LAGRE_EKTEFELLE:
    case DIALOG_NY_LAGRE_EKTEFELLE: {
      return lagreKort(state, gammelDialog);
    }

    case DIALOG_SLETT_KORT_EKTEFELLE: {
      return slettKort(state, gammelDialog);
    }

    case API_BEREGN_SUKSESS: {
      const {
        skattemeldingResultat: { beregningsresultatEktefelle },
      } = action;

      let beregnetVisningsdata = getBeregnetVisningsdata(
        beregningsresultatEktefelle,
        visningsdefinisjon,
      );

      return beregnetVisningsdata || defaultEktefelleState;
    }

    case SKATTEPLIKT_OPPDATERT: {
      if (erSivilstandGift(action.opplysninger)) {
        return {
          ...defaultEktefelleState,
          inntektsaar: action.opplysninger.skatteberegningAar,
        };
      }
      return state;
    }

    default:
      return state;
  }
}
