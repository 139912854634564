export const kontaktopplysningerUrl = {
  nb: 'https://www.skatteetaten.no/om-skatteetaten/ebruker/bli-elektronisk-bruker/#husk-a-oppdatere-kontaktinformasjon',
  nn: 'https://www.skatteetaten.no/nn/om-skatteetaten/ebruker/bli-elektronisk-bruker/#husk-a-oppdatere-kontaktinformasjon',
  en: 'https://www.skatteetaten.no/en/about-the-tax-administration/online-user/become-an-online-user/#remember-to-update-your-contact-details',
};

export const omNySkattemeldingFooterUrl =
  'https://www.skatteetaten.no/nyskattemelding';

export const kidnummerUrl =
  'https://www.skatteetaten.no/person/skatt/skatteoppgjor/restskatt/kid-for-tilleggsforskudd-og-forskuddsskatt/';
export const skatteoppkreverUrl =
  'https://www.skatteetaten.no/kontakt/kontor/skatteoppkrever/';

export const verdsettingsrabatt = 'http://skatteetaten.no/verdsettingsrabatt';

export const kontaktSkatteetaten = 'https://www.skatteetaten.no/kontakt/';
export const skrivTilSkatteetaten =
  'https://www.skatteetaten.no/kontakt/skriv/?13887=0';
export const klagePaaMyndighetsfastsattUrl =
  'https://www.skatteetaten.no/skjema/klage-pa-vedtak/';

export const infoOmRestskattBetalingUrl = {
  nb: 'https://www.skatteetaten.no/person/skatt/skatteoppgjor/restskatt/',
  nn: 'https://www.skatteetaten.no/nn/person/skatt/skatteoppgjer/restskatt/',
  en: 'https://www.skatteetaten.no/en/person/taxes/tax-assessment/underpaid-tax---you-owe-money/',
};

export const tilgjengelighetserklaering = {
  nb: 'https://uustatus.no/nb/erklaringer/publisert/53e8e046-5b41-49f5-9dff-d5cfd12ff40d',
  nn: 'https://uustatus.no/nn/erklaringer/publisert/53e8e046-5b41-49f5-9dff-d5cfd12ff40d',
};

export const i18nLenke = (url, locale) => url[locale] ?? url.nb;
