import { all, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  beregnDialogVedFeltEndringSaga,
  dialogLagretSaga,
  endreDialogSaga,
  lagreDialogSaga,
  slettKortViaDialogSaga,
} from './dialogSaga';
import {
  AVBRYT_REDIGERING_UNDERFOREKOMST_FRA_LISTEVISNING,
  BEREGN_DIALOG,
  DIALOG_ENDRE,
  DIALOG_ENDRE_OK,
  DIALOG_LAGRE_EKTEFELLE,
  DIALOG_NY_LAGRE,
  DIALOG_NY_LAGRE_EKTEFELLE,
  DIALOG_NY_OK,
  DIALOG_SLETT_KORT,
  DIALOG_SLETT_KORT_EKTEFELLE,
  ENDRE_FELT_I_DIALOG,
  LAGRE_UNDERFOREKOMST_FRA_LISTEVISNING,
  SLETT_UNDERFOREKOMST_FRA_LISTEVISNING,
} from '../actions/dialog';
import { BEREGN } from '../actions/skattemelding';
import { beregnDialogSaga, beregnSaga } from './beregnSaga';
import {
  GAA_TIL_EKTEFELLESIDE,
  GAA_TIL_SKATTEMELDING,
} from '../actions/navigasjon';
import {
  gaaTilEktefelleSideSaga,
  gaaTilSkattemeldingSideSaga,
} from './navigasjonSaga';
import { soek, utfoerSoekSaga } from './soek';
import { NY_SOEKESTRENG, VALGT_SOEKERESULTAT } from '../actions/soek';
import { SKATTEPLIKT_OPPDATERT } from '../reducers/skatteplikt';
import { analyticsSagas } from '../analytics/analytics.saga';

export const sagas = [
  //navigasjon
  takeEvery([GAA_TIL_SKATTEMELDING], gaaTilSkattemeldingSideSaga),
  takeEvery([GAA_TIL_EKTEFELLESIDE], gaaTilEktefelleSideSaga),

  takeEvery([BEREGN, SKATTEPLIKT_OPPDATERT], beregnSaga),
  takeLatest([BEREGN_DIALOG], beregnDialogSaga),
  // må ha 'takeLatest' for debounce i saga
  takeLatest(
    [
      ENDRE_FELT_I_DIALOG,
      LAGRE_UNDERFOREKOMST_FRA_LISTEVISNING,
      AVBRYT_REDIGERING_UNDERFOREKOMST_FRA_LISTEVISNING,
      SLETT_UNDERFOREKOMST_FRA_LISTEVISNING,
    ],
    beregnDialogVedFeltEndringSaga,
  ),

  // dialog
  takeEvery([DIALOG_ENDRE], endreDialogSaga),
  takeEvery([DIALOG_NY_LAGRE], lagreDialogSaga),
  takeEvery(
    [
      DIALOG_ENDRE_OK,
      DIALOG_NY_OK,
      DIALOG_LAGRE_EKTEFELLE,
      DIALOG_NY_LAGRE_EKTEFELLE,
    ],
    dialogLagretSaga,
  ),
  takeEvery(
    [DIALOG_SLETT_KORT, DIALOG_SLETT_KORT_EKTEFELLE],
    slettKortViaDialogSaga,
  ),

  // soek
  takeEvery([NY_SOEKESTRENG], utfoerSoekSaga),
  takeEvery([VALGT_SOEKERESULTAT], soek),
];

export const rootSaga = function* () {
  yield all([...sagas, ...analyticsSagas]);
};
