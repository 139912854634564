import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { get, has } from 'lodash';

import {
  language,
  visningslogikk,
} from '@skatteetaten/visningsdefinisjoner-og-tekster';
import Grid from '@skatteetaten/frontend-components/Grid';
import style from './KortIDialog.module.scss';
import { FeltIDialog } from './utils/FeltIDialog';
import { DialogKnapperad } from './utils/DialogKnapperad';
import { DialogKnapp } from './utils/DialogKnapp';
import { DialogAvbryt } from './utils/DialogAvbryt';
import { DialogOk } from './utils/DialogOk';
import { FlerFeltVisning } from '../FlerFeltVisning';
import { FeltMedEgenskaperIDialog } from './utils/FeltMedEgenskaperIDialog';
import {
  DYNAMISK_GRUPPE,
  FELT,
  FELT_MED_EGENSKAPER,
  FLER_FELT_VISNING,
  KORTTYPE,
  REPETERENDE_FELTGRUPPE,
  TEMA,
} from '../../../utils/visningsDataDefinisjonUtil';
import { FeltIDialogWrapper } from './utils/FeltIDialogWrapper';
import {
  getAktivVisningsdata,
  getDefinisjonForId,
  getDialog,
  getDialogTemaId,
  getForekomstForDialog,
  getKortIKortDialogProps,
  getUnderforekomstIDialog,
} from '../../../reducers';
import {
  fjernFlereFeilIDialog,
  overskrivFeilIDialog,
} from '../../../reducers/feil.action';
import {
  avbrytRedigeringAvUnderforekomstFraListevisning,
  byttDialog,
  DIALOGTYPE_DIALOG,
  lagreUnderforekomstFraListevisning,
  redigerUnderforekomstFraListevisning,
  slettUnderforekomstFraListevisning,
} from '../../../actions/dialog';
import { lagFeltreferanseIDialog } from '../../../utils/visningsdataUtil';
import { medFelt } from '../../../utils/visningsDataDefinisjonUtil';
import {
  feilForFelt,
  lagFeltReferanser,
} from './utils/FeltTabellIDialog/TabellradRedigering';
import { StorDialog } from './utils/StorDialog';
import { DynamiskGruppeIDialog } from './DynamiskGruppeIDialog';
import { LeggTilKortopplysninger } from './LeggTilKortopplysninger';
import { useBetingelseHook } from '../../../hooks/useBetingelseHook';
import { useScrollTilFoersteFeil } from '../util/useScrollTilFoersteFeil';
import { getValideringsstate } from '../../../hooks/useValideringsstate';

const { valider } = visningslogikk;

export const KortIKortDialog = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { scrollTilForesteFeilRef, scrollTilFoersteFeil } =
    useScrollTilFoersteFeil();

  const dialog = useSelector(getDialog);
  const {
    definisjonsSti,
    underforekomstDataSti: dataSti,
    feltEndring = [],
    readOnly,
  } = useSelector(getKortIKortDialogProps);

  const korttypeDefinisjon = useSelector(
    getDefinisjonForId(definisjonsSti[KORTTYPE]),
  );
  const underforekomst = useSelector(getUnderforekomstIDialog(dataSti));
  const valideringsstate = getValideringsstate();

  const kortgruppeFelt = useSelector(
    getDefinisjonForId(definisjonsSti[REPETERENDE_FELTGRUPPE]),
  );
  const temaId = useSelector(getDialogTemaId);
  const kanSlette = has(kortgruppeFelt, 'kanSlette')
    ? kortgruppeFelt.kanSlette
    : has(kortgruppeFelt, 'tabell.kanSletteRader')
    ? kortgruppeFelt.tabell.kanSletteRader
    : true;

  const forekomstForDialog = useSelector(getForekomstForDialog);
  const aktivVisningsdata = useSelector(getAktivVisningsdata);
  const { skalFeltDisables, skalFeltSkjules } = useBetingelseHook();

  const erNyUnderforekomst =
    !!dialog.underforekomstStateFoerRedigering?.data?.erNyUnderforekomst;

  const feltDefinisjoner = kortgruppeFelt.barnliste
    .filter(
      (felt) =>
        felt.type === FELT ||
        felt.type === FELT_MED_EGENSKAPER ||
        felt.type === FLER_FELT_VISNING ||
        felt.type === DYNAMISK_GRUPPE,
    )
    .filter((felt) => !felt.skjulIDialog)
    .filter(
      (felt) =>
        !skalFeltSkjules(felt, { ...forekomstForDialog, ...underforekomst }),
    );

  const feltReferanser = lagFeltReferanser(
    feltDefinisjoner,
    definisjonsSti,
    dataSti,
  );

  const dataStiForFelt = useMemo(() => {
    return Object.fromEntries(
      feltDefinisjoner.map(({ id, referanse }) => [
        id,
        medFelt(dataSti, referanse),
      ]),
    );
  }, [dataSti, feltDefinisjoner]);

  useEffect(() => {
    if (!readOnly) {
      dispatch(redigerUnderforekomstFraListevisning(dataSti));
    }
  }, [dispatch, dataSti, readOnly]);

  const avbrytAction = () => {
    if (erNyUnderforekomst) {
      return slettAction();
    }
    if (!readOnly) {
      dispatch(fjernFlereFeilIDialog(feltReferanser));
      dispatch(avbrytRedigeringAvUnderforekomstFraListevisning());
    }
    dispatch(byttDialog(dialog.fraDialog || DIALOGTYPE_DIALOG));
  };

  const lagreAction = (event) => {
    event.preventDefault();

    const feilmeldinger = valider.feilmeldingerForKortType(
      korttypeDefinisjon,
      forekomstForDialog,
      valideringsstate,
      forekomstForDialog,
      aktivVisningsdata,
    );

    const feilmeldingerForUnderforekomstfelt = feilForFelt(
      feltReferanser,
      feilmeldinger,
    );
    const feltIUnderforekomstHarFeil = Object.values(
      feilmeldingerForUnderforekomstfelt,
    ).some((feilmeldingliste) => feilmeldingliste.length > 0);
    dispatch(overskrivFeilIDialog(feilmeldinger));

    if (feltIUnderforekomstHarFeil) {
      scrollTilFoersteFeil();
    } else {
      dispatch(byttDialog(dialog.fraDialog || DIALOGTYPE_DIALOG));
      dispatch(lagreUnderforekomstFraListevisning());
    }
  };

  const slettAction = () => {
    dispatch(byttDialog(dialog.fraDialog || DIALOGTYPE_DIALOG));
    dispatch(fjernFlereFeilIDialog(feltReferanser));
    dispatch(slettUnderforekomstFraListevisning(dataSti));
  };

  const felt = (feltDefinisjon) => {
    const dataStiTilFelt = dataStiForFelt[feltDefinisjon.id];
    const feltReferanse = lagFeltreferanseIDialog(
      forekomstForDialog,
      dataStiTilFelt,
    );

    const feltErDisabled = skalFeltDisables(feltDefinisjon, {
      ...forekomstForDialog,
      ...underforekomst,
    });

    switch (feltDefinisjon.type) {
      case FELT:
        return (
          <Grid.Col lg={12} key={feltDefinisjon.id}>
            <FeltIDialogWrapper
              key={feltDefinisjon.id}
              temaId={temaId}
              readOnly={readOnly}
            >
              <FeltIDialog
                disabled={feltErDisabled}
                readOnly={readOnly}
                felt={feltDefinisjon}
                feltReferanse={feltReferanse}
                feltData={get(underforekomst, feltDefinisjon.referanse)}
                kortdefinisjon={korttypeDefinisjon}
                repeterendeFeltId={definisjonsSti[REPETERENDE_FELTGRUPPE]}
                data={underforekomst}
                forekomstId={underforekomst.id}
                placeholder={feltDefinisjon.placeholder}
                underforekomstId={underforekomst.id}
                meta={underforekomst.meta}
              />
            </FeltIDialogWrapper>
          </Grid.Col>
        );

      case FELT_MED_EGENSKAPER:
        return (
          <FeltMedEgenskaperIDialog
            key={feltDefinisjon.id}
            disabled={feltErDisabled}
            readOnly={readOnly}
            data={forekomstForDialog}
            feltReferanse={feltReferanse}
            kortdefinisjon={korttypeDefinisjon}
            felt={feltDefinisjon}
            temaId={definisjonsSti[TEMA]}
            endringslogg={feltEndring}
            underforekomst={underforekomst}
            repeterendeFeltId={definisjonsSti[REPETERENDE_FELTGRUPPE]}
          />
        );

      case FLER_FELT_VISNING:
        return (
          <FlerFeltVisning
            key={feltDefinisjon.id}
            feltdefinisjon={feltDefinisjon}
            kortdefinisjon={korttypeDefinisjon}
            forekomstData={underforekomst}
            parentForekomstData={forekomstForDialog}
          />
        );

      case DYNAMISK_GRUPPE:
        return (
          <DynamiskGruppeIDialog
            key={feltDefinisjon.id}
            data={forekomstForDialog}
            kortdefinisjon={korttypeDefinisjon}
            gruppe={feltDefinisjon}
            referanse={feltReferanse}
            temaId={definisjonsSti[TEMA]}
            readOnly={readOnly}
            repeterendeFeltId={definisjonsSti[REPETERENDE_FELTGRUPPE]}
            underforekomstId={underforekomst.id}
          />
        );

      default:
        return false;
    }
  };

  const slettKnappTekst = intl.formatMessage({
    id: language.slettKnappId(korttypeDefinisjon.id),
    defaultMessage: intl.formatMessage({ id: 'knapp.fjern' }),
  });

  return (
    <StorDialog titleId={kortgruppeFelt.referanse} visObligatoriskFeltMelding>
      <form onSubmit={lagreAction}>
        <div
          ref={scrollTilForesteFeilRef}
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          {feltDefinisjoner.map(felt)}
        </div>

        <LeggTilKortopplysninger
          readOnly={readOnly}
          forekomst={underforekomst}
          kortdefinisjon={kortgruppeFelt}
        />

        <DialogKnapperad>
          <span className={style.actionButtons}>
            {!readOnly && !erNyUnderforekomst && kanSlette && (
              <span>
                <DialogKnapp tekst={slettKnappTekst} aksjon={slettAction} />
              </span>
            )}
            <span>
              <DialogAvbryt aksjon={avbrytAction} lukkEtterAksjon={false} />
              {!readOnly && (
                <DialogOk
                  hoved
                  submit
                  aksjon={lagreAction}
                  lukkEtterAksjon={false}
                />
              )}
            </span>
          </span>
        </DialogKnapperad>
      </form>
    </StorDialog>
  );
};
