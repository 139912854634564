import Icon from '@skatteetaten/frontend-components/Icon';

import style from './Tema.module.scss';

export const TemaIkon = ({ id, className = style.ikon }) => {
  return <Icon iconName={finnIconNameFraId(id)} className={className} />;
};

export const finnIconNameFraId = (id) => {
  // Skattemeldingen
  switch (id) {
    case 'arbeidTrygdOgPensjon':
      return 'TemaArbeidTrygdPensjon';
    case 'bankLaanOgForsikring':
      return 'TemaBankLaanForsikring';
    case 'eiendelerOgFasteEiendommer':
      return 'TemaBoligEiendeler';
    case 'finans':
      return 'TemaFinans';
    case 'skattefriArvGaveOgGevinst':
      return 'TemaGaveArv';
    case 'personligeForhold':
      return 'TemaPersonligeForhold';
    case 'familieOgHelse':
      return 'TemaFamilie';
    case 'andreForhold':
      return 'TemaAndreForhold';
    case 'naering':
      return 'Company';
    case 'metodeForAaUnngaaDobbeltbeskatning':
      return 'TemaKredittfradrag';
    case 'formueOgInntektISelskapMedDeltakerfastsetting':
      return 'TemaSelskapDeltakerfastsetting';

    default:
      return 'TemaAndreForhold';
  }
};
