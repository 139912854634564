import { compact, get } from 'lodash';
import { flatListe } from '../../../../utils/feltUtils';
import {
  DYNAMISK_GRUPPE,
  erEgenskapPaaObjekt,
  erFeltMedEgenskaper,
  FELT,
  FELT_MED_EGENSKAPER,
  FELTGRUPPE,
  REPETERENDE_FELTGRUPPE,
} from '../../../../utils/visningsDataDefinisjonUtil';

export const erEgenskap = (egenskap) => egenskap.typeEgenskap;
const harFeltMedEgenskaper = (dynamiskfelt) =>
  dynamiskfelt.barnliste.some((barn) => erFeltMedEgenskaper(barn));
const opplysningenErLagtTil = (data) => (dynamiskfelt) =>
  data.some((felt) => felt.behandlingsart === dynamiskfelt.datatype);

const hentVerdiForEgenskap = (dataIDialog, referanse, egenskap) => {
  const sti = compact([referanse, egenskap.referanse]).join('.');
  const verdi = get(dataIDialog, sti);

  const erNorskValuta =
    egenskap.referanse === 'valutakode' &&
    verdi === get(egenskap, 'opsjon.defaultVerdi', 'NOK');
  if (!erNorskValuta) {
    return verdi;
  }

  return false;
};

const feltgruppeEgenskaper = (data, kortdefinisjon) => {
  const synligeEgenskaper = {};
  const alleFeltgruppeEgenskaper = flatListe(
    FELTGRUPPE,
    kortdefinisjon.barnliste,
  ).filter(erEgenskapPaaObjekt);

  const feltHarVerdi = (felt) => {
    if (erFeltMedEgenskaper(felt)) {
      return get(data, `${felt.referanse}.${felt.belopsfelt.referanse}`);
    } else {
      return get(data, felt.referanse);
    }
  };

  alleFeltgruppeEgenskaper.forEach((feltgruppe) => {
    if (feltgruppe.barnliste.some(feltHarVerdi)) {
      synligeEgenskaper[feltgruppe.typeEgenskap] = true;
    }
  });

  return synligeEgenskaper;
};

const objektEgenskaper = (data, kortdefinisjon, synligeEgenskaper) => {
  const alleObjektEgenskaper = flatListe(FELT, kortdefinisjon.barnliste).filter(
    erEgenskapPaaObjekt,
  );

  alleObjektEgenskaper.forEach((felt) => {
    if (get(data, felt.referanse)) {
      synligeEgenskaper[felt.typeEgenskap] = true;
    }
  });

  return synligeEgenskaper;
};

const egenskaperIDynamiskefelter = (data, kortdefinisjon) => {
  const synligeEgenskaper = {};

  const dynamiskegrupper = flatListe(DYNAMISK_GRUPPE, kortdefinisjon.barnliste);
  dynamiskegrupper.forEach((gruppe) => {
    const dynamiskgruppeData = get(data, gruppe.referanse, []);

    const synligeDynamiskefelterMedEgenskaper = gruppe.barnliste
      .filter(harFeltMedEgenskaper)
      .filter(opplysningenErLagtTil(dynamiskgruppeData));

    const alleFelterMedEgenskaper = flatListe(
      FELT_MED_EGENSKAPER,
      synligeDynamiskefelterMedEgenskaper,
    );

    alleFelterMedEgenskaper.forEach((felt) => {
      const { barnliste = [], referanse } = felt;
      barnliste.filter(erEgenskap).forEach((egenskap) => {
        if (
          dynamiskgruppeData.some((behandlingsart) =>
            hentVerdiForEgenskap(behandlingsart, referanse, egenskap),
          )
        ) {
          synligeEgenskaper[egenskap.typeEgenskap] = true;
        }
      });
    });
  });

  return synligeEgenskaper;
};

const egenskaperIRepeterendefelter = (data, kortdefinisjon) => {
  const synligeEgenskaper = {};

  const repeterendeFeltgrupper = flatListe(
    REPETERENDE_FELTGRUPPE,
    kortdefinisjon.barnliste,
  );
  repeterendeFeltgrupper.forEach((gruppe) => {
    const gruppeData = get(data, gruppe.referanse, []);

    gruppe.barnliste.filter(erFeltMedEgenskaper).forEach((felt) => {
      const { barnliste = [], referanse } = felt;
      barnliste.filter(erEgenskap).forEach((egenskap) => {
        if (
          gruppeData.some((repeterendeFelt) =>
            hentVerdiForEgenskap(repeterendeFelt, referanse, egenskap),
          )
        ) {
          synligeEgenskaper[egenskap.typeEgenskap] = true;
        }
      });
    });
  });

  return synligeEgenskaper;
};

const egenskaperIFelter = (data, kortdefinisjon) => {
  const synligeEgenskaper = {};

  const alleFelterMedEgenskaper = flatListe(
    FELT_MED_EGENSKAPER,
    kortdefinisjon.barnliste,
    DYNAMISK_GRUPPE,
  );
  alleFelterMedEgenskaper.forEach((felt) => {
    const { barnliste = [], referanse } = felt;
    barnliste.filter(erEgenskap).forEach((egenskap) => {
      if (hentVerdiForEgenskap(data, referanse, egenskap)) {
        synligeEgenskaper[egenskap.typeEgenskap] = true;
      }
    });
  });

  return synligeEgenskaper;
};

export const hentSynligeEgenskaper = (dataIDialog, kortdefinisjon) => {
  const synligeEgenskaper = {
    ...egenskaperIDynamiskefelter(dataIDialog, kortdefinisjon),
    ...egenskaperIRepeterendefelter(dataIDialog, kortdefinisjon),
    ...egenskaperIFelter(dataIDialog, kortdefinisjon),
  };

  return {
    ...synligeEgenskaper,
    ...objektEgenskaper(dataIDialog, kortdefinisjon, synligeEgenskaper),
    ...feltgruppeEgenskaper(dataIDialog, kortdefinisjon),
  };
};
