import { call, put, select } from 'redux-saga/effects';
import { isArray, isEmpty, pickBy } from 'lodash';
import { visningslogikk } from '@skatteetaten/visningsdefinisjoner-og-tekster';

import {
  getAktivVisningsdata,
  getDataIDialog,
  getDataIDialogVasket,
  getGjeldendeSide,
  getKortdefinisjonIDialog,
  getSkatteplikt,
  getVisningsdataEktefelle,
  getVisningsdataSkattemelding,
} from '../reducers';
import { beregnForSkattemelding } from './api';
import { beregnSuksess } from '../actions/skattemelding';
import {
  harVisningsdata,
  oppdaterVisningsdata,
} from '../reducers/visningsdata';
import { beregnDialogSuksess } from '../actions/dialog';
import { systemfeil } from '../reducers/feil.action';
import { API_BEREGN } from '../constants/feillokasjoner';
import { feilFraErrorOgLokasjon } from '../utils/feil';
import { startLoading, stopLoading } from '../reducers/spinner.action';
import { getValideringsstate } from '../hooks/useValideringsstate';
import { EKTEFELLESIDE } from '../reducers/navigasjon';
import { INNTEKTSAAR } from '../constants/inntektsaar';
import { erSivilstandGift } from '../reducers/skatteplikt';

const { valider } = visningslogikk;

function* lagBeregnBody(visningsdataTransformer = (vdata) => vdata) {
  const visningsdata = yield select(getVisningsdataSkattemelding);
  const visningsdataEktefelle = yield select(getVisningsdataEktefelle);
  const { skattepliktsopplysningerSide } = yield select(getSkatteplikt);
  const gjeldendeSide = yield select(getGjeldendeSide);

  const erGift = erSivilstandGift(skattepliktsopplysningerSide);
  const erBeregningAvEktefelle = gjeldendeSide === EKTEFELLESIDE;
  const inntektsaar = visningsdata.inntektsaar;

  const skattepliktDto = (foedselsaar, antallMaanederINorge) => ({
    alder: inntektsaar - foedselsaar,
    antallMaanederINorge: antallMaanederINorge,
    skattestedITiltakssone: skattepliktsopplysningerSide.finnmarksfradrag,
  });

  return erBeregningAvEktefelle
    ? {
        // på ektefellesiden snur vi om visningsdata og skatteplikt
        inntektsaar: inntektsaar,
        tekniskInntektsaar: INNTEKTSAAR[inntektsaar].vdotAar,
        visningsdata: visningsdataTransformer(visningsdataEktefelle),
        visningsdataEktefelle: visningsdata,
        skatteplikt: skattepliktDto(
          skattepliktsopplysningerSide.foedselsaarEktefelle,
          skattepliktsopplysningerSide.antallMaanederINorgeEktefelle,
        ),
        skattepliktEktefelle: skattepliktDto(
          skattepliktsopplysningerSide.foedselsaar,
          skattepliktsopplysningerSide.antallMaanederINorge,
        ),
      }
    : {
        inntektsaar: inntektsaar,
        tekniskInntektsaar: INNTEKTSAAR[inntektsaar].vdotAar,
        visningsdata: visningsdataTransformer(visningsdata),
        visningsdataEktefelle: harVisningsdata(visningsdataEktefelle)
          ? visningsdataEktefelle
          : undefined,
        skatteplikt: skattepliktDto(
          skattepliktsopplysningerSide.foedselsaar,
          skattepliktsopplysningerSide.antallMaanederINorge,
        ),
        skattepliktEktefelle: erGift
          ? skattepliktDto(
              skattepliktsopplysningerSide.foedselsaarEktefelle,
              skattepliktsopplysningerSide.antallMaanederINorgeEktefelle,
            )
          : undefined,
      };
}

function tilBeregningsresultat(apiRespons, gjeldendeSide) {
  const erBeregningAvEktefelle = gjeldendeSide === EKTEFELLESIDE;
  return erBeregningAvEktefelle
    ? {
        // på ektefellesiden snur vi om beregningsresultatet
        ...apiRespons,
        beregningsresultat: apiRespons.beregningsresultatEktefelle,
        beregningsresultatEktefelle: apiRespons.beregningsresultat,
      }
    : apiRespons;
}

export function* beregnSaga(_action) {
  const gjeldendeSide = yield select(getGjeldendeSide);

  const beregnBody = yield lagBeregnBody();

  try {
    yield put(startLoading(API_BEREGN));

    const resultat = yield call(beregnForSkattemelding, beregnBody);

    const beregninger = tilBeregningsresultat(resultat, gjeldendeSide);
    yield put(beregnSuksess(beregninger));
  } catch (err) {
    const feil = feilFraErrorOgLokasjon(err, API_BEREGN);
    yield put(systemfeil(feil));
  } finally {
    yield put(stopLoading(API_BEREGN));
  }
}

function* validerDialogState() {
  const dialogData = yield select(getDataIDialog);
  const kortdefinisjon = yield select(getKortdefinisjonIDialog);
  const valideringsstate = yield select(getValideringsstate);
  const aktivVisningsdata = yield select(getAktivVisningsdata);

  const feilmeldinger = valider.feilmeldingerForKortType(
    kortdefinisjon,
    dialogData,
    {
      ...valideringsstate,
      beregning: true,
    },
    dialogData,
    aktivVisningsdata,
  );

  const harFeilIDialog = () => {
    if (isEmpty(feilmeldinger)) {
      return false;
    }
    return Object.values(pickBy(feilmeldinger, isArray)).some(
      (feilListe) => feilListe.length > 0,
    );
  };

  return {
    feil: feilmeldinger,
    harValideringsfeil: harFeilIDialog(),
  };
}

export function* beregnDialogSaga(_action) {
  const dialogDataVasket = yield select(getDataIDialogVasket);
  const kortdefinisjon = yield select(getKortdefinisjonIDialog);
  const { harValideringsfeil } = yield validerDialogState();

  // LUKK_DIALOG action kan ha skjedd siden beregningen ble trigget (ved bruk av ENTER i felt), da mangler kortdef
  if (harValideringsfeil || !kortdefinisjon) {
    return;
  }

  const beregnBody = yield lagBeregnBody((vdata) =>
    oppdaterVisningsdata(vdata, kortdefinisjon, dialogDataVasket),
  );

  try {
    const resultat = yield call(beregnForSkattemelding, beregnBody);

    yield put(beregnDialogSuksess(resultat.beregningsresultat.visningsdata));
  } catch (err) {
    yield put(systemfeil(feilFraErrorOgLokasjon(err, API_BEREGN)));
  }
}
