import { call, put, select, takeEvery } from 'redux-saga/effects';
import { compact, isEmpty } from 'lodash';

import { visningsdefinisjonUtils } from '@skatteetaten/visningsdefinisjoner-og-tekster/lib/visningslogikk';
import matomoCoreUtil from './matomoCoreUtil';
import analyticsApi from './analytics.api';
import {
  SEND_TIL_ANALYTICS,
  sendEnkelhendelseTilAnalytics,
  sendTilAnalytics,
} from './analytics.actions';
import {
  FEILKILDE_BRUKERINPUT,
  SET_FEIL_I_DIALOG,
} from '../reducers/feil.action';
import { getDialog, getSoekeStreng, getVisningsdefinisjon } from '../reducers';
import { LEGG_TIL_BEHANDLINGSART } from '../web/components/dialog/KortOpplysninger/kortOpplysninger.actions';
import { SLETT_DYNAMISKFELT_I_DIALOG } from '../actions/dialog';
import { SOEK_FOKUSERT, VALGT_SOEKERESULTAT } from '../actions/soek';
import { hentKontekst } from '../sagas/soek';

function* sendTilAnalyticsSaga(action) {
  const kall = (action.hendelser || []).map((hendelse) => {
    return call(analyticsApi.sendEventTilAnalytics, hendelse);
  });
  //Gammel for-løkke istedenfor forEach for å kunne yielde hvert enkelt kall :(
  for (let i = 0; i < kall.length; i++) {
    yield kall[i];
  }
}

export function* dynamiskGruppeHendelserTilPiwikSaga(action) {
  let value, feltLabel;
  const { temaId, kortdefinisjon } = yield select(getDialog);
  const korttypereferanse = kortdefinisjon.referanse;
  const visningsdefinisjon = yield select(getVisningsdefinisjon);
  const kortgruppeDef =
    visningsdefinisjonUtils.finnDefinisjonGittKorttypereferanse(
      korttypereferanse,
      'kortgruppe',
      visningsdefinisjon,
    );

  const tekster = matomoCoreUtil.getTekster();
  const temaLabel = tekster[temaId];
  const kortgruppeLabel = tekster[kortgruppeDef.id];

  switch (action.type) {
    case LEGG_TIL_BEHANDLINGSART:
      feltLabel = `Behandlingsart ${action.behandlingsart.behandlingsart}`;
      break;
    case SLETT_DYNAMISKFELT_I_DIALOG:
      const { dynamiskfelt } = action;
      feltLabel = `Behandlingsart ${dynamiskfelt.datatype}`;
      break;
    default:
      break;
  }

  yield put(
    sendEnkelhendelseTilAnalytics({
      action: action.type,
      name: compact([temaLabel, kortgruppeLabel, feltLabel]).join(' - '),
      value,
    }),
  );
}

function* valideringsfeilTilPiwikSaga(action) {
  if (action.kilde === FEILKILDE_BRUKERINPUT) {
    const tekster = matomoCoreUtil.getTekster();
    const felt = tekster[action.feilreferanse]
      ? tekster[action.feilreferanse]
      : action.feilreferanse;
    const hendelser = (action.feil || []).map((valideringsfeil) => {
      return [
        'Valideringsfeil',
        `Feil ${valideringsfeil.feilmelding}`, //Piwik tåler ikke `:` i dette feltet!
        `Felt: ${felt}`,
      ];
    });

    if (!isEmpty(hendelser)) {
      yield put(sendTilAnalytics(hendelser));
    }
  }
}

export function* soekFikkFokusTilPiwikTransformatorSaga() {
  yield put(
    sendEnkelhendelseTilAnalytics({
      action: 'SoekStartet',
    }),
  );
}

export function* valgtSoekeresultatTilPiwikTransformatorSaga(action) {
  const tekster = matomoCoreUtil.getTekster();
  const { tema, kortgruppe } = yield* hentKontekst(action);
  const temaLabel = tekster[tema?.id];
  const kortgruppeLabel = tekster[kortgruppe.id];
  const soekestreng = yield select(getSoekeStreng);

  yield put(
    sendEnkelhendelseTilAnalytics({
      action: 'ValgtSoekeresultat',
      name: `${temaLabel} - ${kortgruppeLabel}`,
      value: soekestreng,
    }),
  );
}

export const analyticsSagas = [
  takeEvery(SEND_TIL_ANALYTICS, sendTilAnalyticsSaga),
  takeEvery(SET_FEIL_I_DIALOG, valideringsfeilTilPiwikSaga),
  takeEvery(
    [LEGG_TIL_BEHANDLINGSART, SLETT_DYNAMISKFELT_I_DIALOG],
    dynamiskGruppeHendelserTilPiwikSaga,
  ),
  takeEvery(SOEK_FOKUSERT, soekFikkFokusTilPiwikTransformatorSaga),
  takeEvery(VALGT_SOEKERESULTAT, valgtSoekeresultatTilPiwikTransformatorSaga),
];
