import { useCallback, useEffect, useState } from 'react';

export const useScrolledPast = (ref, refProp = 'offsetTop') => {
  const [initialYPos, setInitialYPos] = useState(null);
  const [scrolledPast, setScrolledPast] = useState(false);

  const scrollListener = useCallback(() => {
    const scrollTop =
      window.pageYOffset ||
      document.body.scrollTop ||
      document.documentElement.scrollTop;
    setScrolledPast(scrollTop > initialYPos);
  }, [initialYPos]);

  useEffect(() => {
    setInitialYPos(ref.current?.[refProp]);

    document.addEventListener('scroll', scrollListener);

    return () => document.removeEventListener('scroll', scrollListener);
  }, [ref, scrollListener, refProp]);

  return scrolledPast;
};
