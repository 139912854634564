import { put } from 'redux-saga/effects';
import {
  gaaTilEktefelleSideSuksess,
  gaaTilSkattemeldingSideSuksess,
} from '../actions/navigasjon';

export function* gaaTilSkattemeldingSideSaga() {
  yield put(gaaTilSkattemeldingSideSuksess());
}

export function* gaaTilEktefelleSideSaga() {
  yield put(gaaTilEktefelleSideSuksess());
}
