import { FormattedMessage } from 'react-intl';

import style from './Feilmelding.module.scss';
import { FeilmeldingHeader } from './FeilmeldingHeader';

export const Feilmelding = ({
  forekomstId,
  overskrift,
  melding,
  valideringsstate,
}) => (
  <div
    id={forekomstId}
    className={style.feilmeldingBoks}
    data-type="feilmelding"
    aria-live="assertive"
    role="alert"
  >
    <FeilmeldingHeader
      overskrift={overskrift}
      melding={
        <FormattedMessage
          id={melding}
          defaultMessage={melding}
          values={valideringsstate}
        />
      }
    />
  </div>
);
