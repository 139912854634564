import { useIntl } from 'react-intl';

import TextField from '@skatteetaten/frontend-components/TextField';
import style from './VisningsfeltIDialog.module.css';
import calloutStyle from '../../tema/KortgruppeHeader.module.scss';
import { lagHjelpetekst, lagLabel } from '../../../../utils/feltUtils';
import { Opsjon } from '../../Opsjon';
import { useTransformerFelt } from '../../../../utils/transformerHooks';
import { useAnalytics } from '../../../../analytics/analytics.hooks';

export const VisningsfeltIDialog = ({ felt, data }) => {
  const intl = useIntl();
  const transformerFelt = useTransformerFelt();
  const analytics = useAnalytics();

  const erTittel = !!felt.erTittelHvisLesmodus;
  if (erTittel) {
    return (
      <legend className={style.feltErTittel} tabIndex={-1}>
        {transformerFelt(felt, data)}
      </legend>
    );
  }

  if (felt.opsjon) {
    return <Opsjon disabled felt={felt} verdi={data || false} />;
  }

  const transformertVerdi = transformerFelt(felt, data);
  const multiline = transformertVerdi?.length > 50;

  return (
    <TextField
      readOnly
      box
      multiline={multiline}
      label={lagLabel(intl, felt)}
      value={transformertVerdi || '-'}
      help={lagHjelpetekst(intl, felt)}
      onCalloutToggle={analytics.haandterHjelpetekstToggle(felt.id)}
      labelWithCalloutProps={{
        customClassNames: { callout: `${calloutStyle.calloutStyle}` },
      }}
    />
  );
};
