import { connect } from 'react-redux';
import { KorttypeComp } from './Korttype';
import {
  getForekomster,
  getLocale,
  getSkattepliktVisningsdata,
} from '../../../../reducers';
import { harDataSomFinnesIKortdefinisjon } from '../../../../utils/skalSkjules';

export const mapStateToProps = (state, props) => {
  const { korttype } = props;
  return {
    forekomster: (getForekomster(korttype.referanse)(state) || []).filter(
      (forekomst) => harDataSomFinnesIKortdefinisjon(korttype, forekomst),
    ),
    locale: getLocale(state),
    skatteplikt: getSkattepliktVisningsdata(state),
  };
};

export const Korttype = connect(mapStateToProps)(KorttypeComp);
