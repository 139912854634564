import { useSelector } from 'react-redux';
import { compact, get, isEmpty } from 'lodash';

import style from './FeltMedEgenskaperIDialog.module.scss';
import { FeltIDialogWrapper } from './FeltIDialogWrapper';
import { FeltIDialog } from './FeltIDialog';
import { getSynligeEgenskaperIDialog } from '../../../../reducers';
import { erEgenskapsfelt } from '../../../../utils/visningsDataDefinisjonUtil';
import { hentValutakode, not } from '../../../../utils/visningsdataUtil';
import { useBetingelseHook } from '../../../../hooks/useBetingelseHook';

export const FeltMedEgenskaperIDialog = ({
  disabled,
  data = {},
  readOnly,
  kortdefinisjon,
  felt,
  temaId,
  feltReferanse,
  onSlett,
  erFoersteFeltIGruppe,
  erSisteFeltIGruppe,
  gruppenHarFlereFelt,
  lesmodus,
  erFeltIGruppe,
  focusElement,
  underforekomst,
  repeterendeFeltId,
}) => {
  const synligeEgenskaper = useSelector(getSynligeEgenskaperIDialog) ?? {};
  const { skalFeltDisables, skalFeltSkjules } = useBetingelseHook();
  const { barnliste = [], belopsfelt = {} } = felt;
  belopsfelt.erFraForekomstKobling = felt.erFraForekomstKobling;

  const ikkeEgenskaper = barnliste.filter(not(erEgenskapsfelt));
  const valutaFelt = barnliste.find(
    (barn) => barn.referanse === belopsfelt.valuta,
  );
  const suffixValuta = hentValutakode(data, feltReferanse, valutaFelt);

  // eslint-disable-next-line no-shadow
  const filterSynligeEgenskaper = (egenskap) => {
    const skalSkjules = skalFeltSkjules(egenskap, {
      ...data,
      ...get(data, feltReferanse),
    });
    const egenskapenErLagtTil = Object.keys(synligeEgenskaper).includes(
      egenskap.typeEgenskap,
    );
    const harUtenlandskValuta =
      egenskap.typeEgenskap === 'beloep' && suffixValuta && !skalSkjules;

    return (
      egenskapenErLagtTil ||
      (egenskapenErLagtTil && !skalSkjules) ||
      harUtenlandskValuta
    );
  };

  const harSynligeEgenskaper =
    barnliste.filter(filterSynligeEgenskaper).length > 0;

  // Inneholder full datasti for et beløpsfelt, inkl. evt. underforekomst
  const beloepsfeltDatasti = compact([
    feltReferanse,
    belopsfelt.referanse,
  ]).join('.');

  const erFeltDisabled =
    disabled || skalFeltDisables(belopsfelt, get(data, feltReferanse));

  const synligeFelt = barnliste.filter(
    (barn) => !skalFeltSkjules(barn, { ...get(data, feltReferanse), ...data }),
  );

  return (
    <FeltIDialogWrapper
      temaId={temaId}
      onSlett={onSlett}
      erFoersteFeltIGruppe={erFoersteFeltIGruppe}
      erSisteFeltIGruppe={erSisteFeltIGruppe}
      gruppenHarFlereFelt={gruppenHarFlereFelt || harSynligeEgenskaper}
      readOnly={lesmodus || readOnly}
      erFeltIGruppe={erFeltIGruppe}
    >
      <FeltIDialog
        key={belopsfelt.id}
        disabled={erFeltDisabled}
        readOnly={readOnly || lesmodus}
        felt={belopsfelt}
        feltReferanse={beloepsfeltDatasti}
        feltData={get(data, beloepsfeltDatasti)}
        kortdefinisjon={kortdefinisjon}
        forekomstId={data.id}
        suffix={suffixValuta}
        focusElement={focusElement}
        underforekomstId={underforekomst?.id}
        repeterendeFeltId={repeterendeFeltId}
      />
      {synligeFelt.some(erEgenskapsfelt) && !isEmpty(synligeEgenskaper) && (
        <div className={style.egenskaper}>
          {synligeFelt.filter(filterSynligeEgenskaper).map((barn) => (
            <FeltWrapper
              key={barn.id}
              lesmodus={lesmodus}
              disabled={disabled}
              barn={barn}
              data={data}
              readOnly={readOnly}
              feltReferanse={feltReferanse}
              kortdefinisjon={kortdefinisjon}
              temaId={temaId}
              className={style.egenskap}
            />
          ))}
          {!!suffixValuta &&
            ikkeEgenskaper.map((barn) => (
              <FeltWrapper
                key={barn.id}
                lesmodus={lesmodus}
                disabled={disabled}
                barn={barn}
                data={data}
                readOnly={readOnly}
                feltReferanse={feltReferanse}
                kortdefinisjon={kortdefinisjon}
                temaId={temaId}
                className={style.egenskap}
              />
            ))}
        </div>
      )}
    </FeltIDialogWrapper>
  );
};

const FeltWrapper = ({
  lesmodus,
  disabled,
  barn,
  data,
  readOnly,
  feltReferanse,
  kortdefinisjon,
  temaId,
  suffix,
  className,
}) => {
  const { skalFeltDisables } = useBetingelseHook();
  const referanse = compact([feltReferanse, barn.referanse]).join('.');

  return (
    <FeltIDialogWrapper
      key={barn.id}
      temaId={temaId}
      readOnly={lesmodus}
      erEgenskap
    >
      <div className={className}>
        <FeltIDialog
          disabled={
            disabled || skalFeltDisables(barn, get(data, feltReferanse))
          }
          readOnly={readOnly || lesmodus}
          felt={barn}
          feltReferanse={referanse}
          feltData={get(data, referanse)}
          kortdefinisjon={kortdefinisjon}
          forekomstId={data.id}
          suffix={suffix}
          erEgenskap
        />
      </div>
    </FeltIDialogWrapper>
  );
};
