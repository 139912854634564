import style from './Feltseparator.module.scss';
import { FELTSEPARATOR } from '../../utils/visningsDataDefinisjonUtil';

export const erFeltseparator = (felt) => felt.type === FELTSEPARATOR;

export const skalViseFeltseparator = (type, felt) =>
  (!felt.visI && type === 'dialog') ||
  (felt.visI && felt.visI.indexOf(type) >= 0);

export const Feltseparator = ({ horisontalLinje }) => {
  const linje = horisontalLinje ? <br /> : null;
  return <div className={style.container}>{linje}</div>;
};
