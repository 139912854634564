import { compact, get } from 'lodash';

import { Kortfelt } from './Kortfelt';
import { hentValutakode } from '../../../../utils/visningsdataUtil';
import { useBetingelseHook } from '../../../../hooks/useBetingelseHook';

export const KortfeltMedEgenskaper = ({
  data = {},
  barnliste = [],
  belopsfelt = {},
  feltReferanse,
  referanse,
  hidden,
}) => {
  const {
    skalBeholdeVerdi,
    skalFeltDisables,
    skalFeltSkjules,
    skalSkjuleFeltIKort,
  } = useBetingelseHook();
  const referanseSti = compact([feltReferanse, referanse]).join('.');
  const valutaFelt = barnliste.find(
    (barn) => barn.referanse === belopsfelt.valuta,
  );
  const referanseTilData = compact([referanseSti, belopsfelt.referanse]).join(
    '.',
  );

  const dataMedUnderforekomst = { ...data, ...get(data, referanseSti) };
  const skalSkjuleBelopsfelt =
    (skalFeltSkjules(belopsfelt, dataMedUnderforekomst) ||
      skalFeltDisables(belopsfelt, dataMedUnderforekomst)) &&
    skalSkjuleFeltIKort(belopsfelt, dataMedUnderforekomst) &&
    !skalBeholdeVerdi(belopsfelt, dataMedUnderforekomst);

  return (
    <Kortfelt
      key={`${data.id}.${belopsfelt.referanse}`}
      feltdefinisjon={belopsfelt}
      data={get(data, referanseTilData)}
      hidden={hidden || skalSkjuleBelopsfelt}
      suffix={hentValutakode(data, referanseSti, valutaFelt)}
    />
  );
};
