/* eslint-disable no-fallthrough */
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import {
  FJERN_ALLE_FEIL_I_DIALOG,
  FJERN_FEIL_I_DIALOG,
  FJERN_FLERE_FEIL_I_DIALOG,
  SET_FEIL_I_DIALOG,
  SET_FLERE_FEIL_I_DIALOG,
} from './feil.action';
import { LUKK_DIALOG } from '../actions/dialog';

export const getFeilIDialog = (state) => state.feilIDialog;

const defaultState = {
  feilIDialog: {},
};

const harIkkeFeilOgSomFinnesFraFoer = (
  { feil, feilreferanse },
  { feilIDialog },
) => isEmpty(feil) && !isEmpty(feilIDialog[feilreferanse]);

const harFeilOgSomIkkeFinnesFraFoer = (
  { feil, feilreferanse },
  { feilIDialog = {} },
) => !isEmpty(feil) && !isEqual(feilIDialog[feilreferanse], feil);

export default function feilReducer(state = defaultState, action = {}) {
  switch (action.type) {
    case SET_FEIL_I_DIALOG: {
      if (harIkkeFeilOgSomFinnesFraFoer(action, state)) {
        const feilIDialog = { ...state.feilIDialog };
        delete feilIDialog[action.feilreferanse];
        return {
          ...state,
          feilIDialog,
        };
      }
      if (harFeilOgSomIkkeFinnesFraFoer(action, state)) {
        return {
          ...state,
          feilIDialog: {
            ...state.feilIDialog,
            [action.feilreferanse]: action.feil,
          },
        };
      }
      return state;
    }
    case SET_FLERE_FEIL_I_DIALOG:
      return {
        ...state,
        feilIDialog: action.idFeilObjekt,
      };
    case FJERN_FEIL_I_DIALOG: {
      const feilIDialog = { ...state.feilIDialog };
      delete feilIDialog[action.feltRef];
      delete feilIDialog[action.kategoriRef];
      return {
        ...state,
        feilIDialog,
      };
    }
    case FJERN_FLERE_FEIL_I_DIALOG:
      const nyFeilIDialog = { ...state.feilIDialog };
      (action.ider || []).forEach((id) => {
        delete nyFeilIDialog[id];
      });
      return {
        ...state,
        feilIDialog: nyFeilIDialog,
      };
    case FJERN_ALLE_FEIL_I_DIALOG:
    case LUKK_DIALOG:
      return defaultState;
    default:
      return state;
  }
}
