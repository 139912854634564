import { useIntl } from 'react-intl';
import classnames from 'classnames';

import style from './GrunnlagForGjeldsreduksjon.module.scss';
import commonStyle from '../../../common.module.scss';
import { gjeldsreduksjonDetaljTabell } from './GjeldsreduksjonDetaljTabell';
import { GjeldsreduksjonTabellLinje } from './GjeldsreduksjonTabellLinje';
import { useInntektsaar } from '../../../../../hooks/useInntektsaar';

export const GjeldsreduksjonTabell = ({ harEktefelle, svalbard }) => {
  const intl = useIntl();
  // TODO skal vi ha gjeldsreduksjonstabell? I så fall, heller bruke vdotAar her?
  const { inntektsaar } = useInntektsaar();

  return (
    <table>
      <tr className={classnames(commonStyle.bold, style.flexTabell)}>
        <th
          scope={'col'}
          className={classnames(style.emneOverskrift, style.tittelKolonne)}
        >
          {intl.formatMessage({
            id: 'ledetekst.gjeldsreduksjon.overskrift.formue',
          })}
        </th>
        <th scope={'col'} className={style.flexCol}>
          {intl.formatMessage({
            id: 'ledetekst.gjeldsreduksjon.overskrift.bruttoverdi',
          })}
        </th>
        <th scope={'col'} className={style.flexCol}>
          {intl.formatMessage({
            id: 'ledetekst.gjeldsreduksjon.overskrift.verdsettingsrabatt',
          })}
        </th>
        <th scope={'col'} className={style.flexCol}>
          {intl.formatMessage({
            id: 'ledetekst.gjeldsreduksjon.overskrift.formuesverdi',
          })}
        </th>
      </tr>
      {gjeldsreduksjonDetaljTabell(inntektsaar, svalbard).map((linje) => (
        <GjeldsreduksjonTabellLinje
          key={linje[0]}
          linje={linje}
          harEktefelle={harEktefelle}
          svalbard={svalbard}
        />
      ))}
    </table>
  );
};
