import { forwardRef } from 'react';
import { useIntl } from 'react-intl';

import LabelWithCallout from '@skatteetaten/frontend-components/LabelWithCallout';
import style from './KortgruppeHeader.module.scss';
import { lagHjelpetekst } from '../../../utils/feltUtils';
import { useAnalytics } from '../../../analytics/analytics.hooks';

export const KortgruppeHeader = forwardRef(
  ({ kortgruppe, title, temaId }, ref) => {
    const intl = useIntl();
    const analytics = useAnalytics(temaId);

    return (
      <div className={style.kortgruppeHeader}>
        <span
          ref={ref}
          tabIndex={-1}
          className={style.undertitteltekst}
          role="heading"
          aria-level="3"
        >
          <LabelWithCallout
            label={title}
            id={kortgruppe.id}
            help={lagHjelpetekst(intl, kortgruppe)}
            customClassNames={{ callout: `${style.calloutStyle}` }}
            onCalloutToggle={analytics.haandterHjelpetekstToggle(kortgruppe.id)}
          />
        </span>
      </div>
    );
  },
);
