import { util } from '@skatteetaten/visningsdefinisjoner-og-tekster';
import { referanseForFelt } from './visningsdefinisjonUtil';
import { mapFelterIVisningsdata } from './visningsdataUtil';

const { EGENSKAP_TRYGDE_ELLER_SKATTEMESSIG_UNNTAK } = util;

const erEndringITrygdeEllerSkattemessigUnntak = (action) =>
  action.feltreferanse.includes(EGENSKAP_TRYGDE_ELLER_SKATTEMESSIG_UNNTAK);

const kortdefHarKodelisteConfigMedAvhengighetTilEndretDropdownFelt = (
  action,
  kortdefinisjon,
  alleFelterIKortdefinisjon,
) => {
  const endretFeltDefinisjon = alleFelterIKortdefinisjon?.find(
    (felt) => referanseForFelt(felt) === action.feltreferanse,
  );

  const erDropdownMedVerdierFraKodeliste =
    endretFeltDefinisjon?.opsjon?.type === 'dropdown' &&
    endretFeltDefinisjon.transformer?.id === 'kodeliste';

  const harKodelisteConfigMedAvhengighetTilEndretDropdownFelt =
    kortdefinisjon.kodelisteConfig?.some(
      (config) =>
        config?.gyldigeOpsjonerGitt?.referanse === action.feltreferanse,
    );
  return (
    erDropdownMedVerdierFraKodeliste &&
    harKodelisteConfigMedAvhengighetTilEndretDropdownFelt
  );
};

/* REDUCER-FUNKSJONER */
export const erEndringSomPaavirkerTSU = (
  action,
  kortdefinisjon,
  alleFelterIKortdefinisjon,
) => {
  return (
    erEndringITrygdeEllerSkattemessigUnntak(action) ||
    kortdefHarKodelisteConfigMedAvhengighetTilEndretDropdownFelt(
      action,
      kortdefinisjon,
      alleFelterIKortdefinisjon,
    )
  );
};

export const medTSUEndringer = (action, nyState, kortdefinisjon) => {
  if (
    kortdefHarKodelisteConfigMedAvhengighetTilEndretDropdownFelt(
      action,
      kortdefinisjon,
      nyState.alleFelterIKortdefinisjon,
    )
  ) {
    nyState =
      medNullstilteVerdierOgEndringerForTrygdeEllerSkattemessigeUnntak(nyState);
  }
  return {
    data: nyState.data,
  };
};

/** I visningsdefinisjon så kan en korttype ha en 'kodelisteConfig'. Denne igjen kan spesifisere gyldige opsjoner fra en
 * kodeliste i en nedtrekksmeny, avhengig av verdi i en annen nedtrekksmeny (f.eks. pensjonstype i pensjonskortet).
 * I tilfeller hvor bruker endrer verdi i den styrende nedtrekksmenyen, så nullstilles tidligere verdier satt av brukeren
 * i de avhengige nedtrekksmenyene. Dette gjøres fordi tidligere satt verdi ikke nødvendigvis lenger er en gyldig opsjon
 *
 * P.S. Pdd. så gjelder dette bare for trygde- og skattemessige unntak, så denne funksjonen er spesialisert for det.
 * */
const medNullstilteVerdierOgEndringerForTrygdeEllerSkattemessigeUnntak = (
  state,
) => {
  let nyState = state;
  const { dataMedNullstilteVerdierForTrygdeEllerSkattemessigeUnntak } =
    nullstillTrygdeOgSkattemessigeUnntakOpsjonValgSomErAvhengigAvAndreOpsjonsvalg(
      nyState.data,
    );

  if (dataMedNullstilteVerdierForTrygdeEllerSkattemessigeUnntak) {
    nyState.data = dataMedNullstilteVerdierForTrygdeEllerSkattemessigeUnntak;
  }

  return nyState;
};

const nullstillTrygdeOgSkattemessigeUnntakOpsjonValgSomErAvhengigAvAndreOpsjonsvalg =
  (data) => {
    const dataMedNullstilteVerdierForTrygdeEllerSkattemessigeUnntak =
      mapFelterIVisningsdata({
        visningsdata: data,
        feltMapper: (verdi, dataSti) => {
          if (
            verdi &&
            dataSti.feltreferanse === EGENSKAP_TRYGDE_ELLER_SKATTEMESSIG_UNNTAK
          ) {
            return '';
          }
          return verdi;
        },
      });

    return {
      dataMedNullstilteVerdierForTrygdeEllerSkattemessigeUnntak,
    };
  };
