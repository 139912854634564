import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { get, has } from 'lodash';

import { language } from '@skatteetaten/visningsdefinisjoner-og-tekster';
import Table from '@skatteetaten/frontend-components/Table';
import Grid from '@skatteetaten/frontend-components/Grid';
import Icon from '@skatteetaten/frontend-components/Icon';
import LabelWithCallout from '@skatteetaten/frontend-components/LabelWithCallout';
import Button from '@skatteetaten/frontend-components/Button';
import style from './FeltTabellIDialog.module.scss';
import { TabellradRedigering } from './TabellradRedigering';
import {
  erFelt,
  erFeltMedEgenskaper,
  REPETERENDE_FELTGRUPPE,
} from '../../../../../utils/visningsDataDefinisjonUtil';
import {
  getDefinisjonForId,
  getFeilIDialog,
  getForekomstForDialog,
} from '../../../../../reducers';
import { feilForFelt, lagFeltReferanser } from './TabellradRedigering';
import { Feilmelding } from '../../../feilmelding/Feilmelding';
import { mapObject } from '../../../../../utils/object';
import { skalFeltSkjules } from '../../../../../utils/skalSkjules';
import {
  hjelpetekstId,
  lagLabel,
  tekstId,
} from '../../../../../utils/feltUtils';
import { transformerFelt } from '../../../../../utils/transformerHooks';
import { useValideringsstate } from '../../../../../hooks/useValideringsstate';
import { FormattedHTMLMessageUglyInnerHtml } from '../../../FormattedHTMLMessage';
import { useAnalytics } from '../../../../../analytics/analytics.hooks';

const tilTransformertVerdi = (felt, intl) => (underforekomst) => {
  function erTomtBeloepsfeltUnderforekomst() {
    return !has(
      underforekomst,
      felt.referanse + '.' + felt.belopsfelt.referanse,
    );
  }

  if (erFeltMedEgenskaper(felt) && underforekomst[felt.referanse]) {
    if (erTomtBeloepsfeltUnderforekomst()) {
      underforekomst[felt.referanse] = undefined;
    } else {
      underforekomst = mapObject(underforekomst, (entries) =>
        entries.map(([key, value]) => {
          if (
            value &&
            value[felt.belopsfelt.referanse] &&
            key === felt.referanse
          ) {
            return [felt.referanse, value[felt.belopsfelt.referanse]];
          }
          return [key, value];
        }),
      );
    }
  }
  const verdi = get(underforekomst, felt.referanse);
  const harTransformerMedReferanser = has(felt, 'transformer.referanser');
  if (!verdi && !harTransformerMedReferanser) {
    return underforekomst;
  }
  const transformerData = harTransformerMedReferanser ? underforekomst : verdi;
  const transformertverdi = transformerFelt({
    felt,
    verdi: transformerData,
    intl,
  });
  return { ...underforekomst, [felt.referanse]: transformertverdi };
};

const leggTilFeilikon =
  (foersteKolonneFieldName, feltReferanser, feilmeldingerForTabellfelt) =>
  (underforekomst) => {
    const feilForFeltIUnderforekomst = feltReferanser.find(
      (referanse) =>
        feilmeldingerForTabellfelt[`${referanse}.${underforekomst.id}`]?.length,
    );

    if (!feilForFeltIUnderforekomst) {
      return underforekomst;
    }

    return {
      ...underforekomst,
      [foersteKolonneFieldName]: (
        <span className={style.kolonneMedFeil}>
          <Icon iconName="Error" className={style.feilikon} />
          {underforekomst[foersteKolonneFieldName]}
        </span>
      ),
    };
  };

const transformerUnderforekomster = (
  underforekomster,
  felter,
  intl,
  nyUnderforekomstId,
) => {
  const nyUnderforekomst = underforekomster.filter(
    (f) => f.id === nyUnderforekomstId,
  );
  const underforekomsterUtenNy = [
    ...underforekomster.filter((f) => f.id !== nyUnderforekomstId),
    ...nyUnderforekomst,
  ];

  return felter.reduce(
    (underforekomsterTilTransformasjon, felt) =>
      underforekomsterTilTransformasjon.map(tilTransformertVerdi(felt, intl)),
    underforekomsterUtenNy,
  );
};

export const FeltTabellIDialog = ({
  definisjonsSti,
  dataSti,
  leggTilRepeterendefelt,
  readOnly,
  sorterteForekomster,
}) => {
  const repeterendeFeltgruppe = useSelector(
    getDefinisjonForId(definisjonsSti[REPETERENDE_FELTGRUPPE]),
  );
  const intl = useIntl();
  const valideringsstate = useValideringsstate();
  const forekomstForDialog = useSelector(getForekomstForDialog);
  const feilmeldinger = useSelector(getFeilIDialog);
  const analytics = useAnalytics();

  // Nye rader, som oppstår enten når man oppretter nytt kort, eller når bruker legger til, har flagget 'erNyUnderforekomst'
  // Denne staten vil derimot forsvinne når vi kaller beregn, siden server-state overskriver frontend-state for visningsdata
  // Vi lagrer derfor verdien lokalt i komponentens state
  const [nyUnderforekomstId, setNyUnderforekomstId] = useState();
  useEffect(() => {
    const nyUnderforekomst = sorterteForekomster?.find(
      (uf) => uf.erNyUnderforekomst,
    );
    if (nyUnderforekomst) {
      setNyUnderforekomstId(nyUnderforekomst.id);
    }
  }, [sorterteForekomster]);

  const {
    barnliste = [],
    id,
    tabell: repeterendeFeltTabell = {},
  } = repeterendeFeltgruppe;

  const {
    kanEkspandereRader,
    kanLeggeTilRader,
    skalIkkeOppretteRadVedTomListe,
  } = repeterendeFeltTabell;

  const harKunEnNyUnderforekomst =
    sorterteForekomster.length === 1 &&
    sorterteForekomster[0].erNyUnderforekomst;

  const skalAapneRedigerbarRad =
    harKunEnNyUnderforekomst && !skalIkkeOppretteRadVedTomListe;

  const [aapenRedigerbarRadIndeks, setAapenRedigerbarRadIndeks] = useState(
    skalAapneRedigerbarRad ? 0 : undefined,
  );

  if (harKunEnNyUnderforekomst && !kanLeggeTilRader) {
    return null;
  }

  const felter = barnliste.filter(
    (felt) => erFelt(felt) || erFeltMedEgenskaper(felt),
  );

  const tabellKolonner = felter
    .filter((definisjon) => !definisjon?.tabell?.skjulKolonne)
    .filter((definisjon) => !skalFeltSkjules(definisjon, forekomstForDialog))
    .map((definisjon) => ({
      name: intl.formatMessage({ id: definisjon.id }),
      fieldName: definisjon.referanse,
    }));

  const feltReferanser = lagFeltReferanser(felter, definisjonsSti, dataSti);
  const feilmeldingerForTabellfelt = feilForFelt(feltReferanser, feilmeldinger);
  const feltITabellHarFeil = Object.values(feilmeldingerForTabellfelt).some(
    (feilmeldingliste) => feilmeldingliste.length > 0,
  );

  let transformerteUnderforekomster = transformerUnderforekomster(
    sorterteForekomster,
    felter,
    intl,
    nyUnderforekomstId,
  );

  if (tabellKolonner.length && feltITabellHarFeil) {
    transformerteUnderforekomster = transformerteUnderforekomster.map(
      leggTilFeilikon(
        tabellKolonner[0].fieldName,
        feltReferanser,
        feilmeldingerForTabellfelt,
      ),
    );
  }

  const editableContent = (data, close) => {
    const kollapsRad = () => {
      close();
      setAapenRedigerbarRadIndeks(undefined);
      setNyUnderforekomstId(undefined);
    };

    return (
      <TabellradRedigering
        definisjonsSti={definisjonsSti}
        datastiTilFeltgruppe={dataSti}
        radData={data}
        kollapsRad={kollapsRad}
        erNyUnderforekomst={data.id === nyUnderforekomstId}
      />
    );
  };

  const tittel = (
    <LabelWithCallout
      label={lagLabel(intl, repeterendeFeltgruppe)}
      help={
        <FormattedHTMLMessageUglyInnerHtml
          id={hjelpetekstId(repeterendeFeltgruppe)}
        />
      }
      onCalloutToggle={analytics.haandterHjelpetekstToggle(id)}
    />
  );

  const leggTilRad = () => {
    const antallRader = transformerteUnderforekomster.length;
    leggTilRepeterendefelt(repeterendeFeltgruppe.referanse, true);
    setAapenRedigerbarRadIndeks(antallRader);
  };

  return (
    <div className={style.gruppe}>
      <Table
        id={`table-${id}`}
        customClassNames={{
          wrapper: style.tabell,
        }}
        data={transformerteUnderforekomster}
        editableContent={editableContent}
        editableRows={!readOnly && kanEkspandereRader}
        title={intl.formatMessage({ id: tekstId(repeterendeFeltgruppe) })}
        columns={tabellKolonner}
        aria-labelledby={id}
        openEditableRowIndex={aapenRedigerbarRadIndeks}
        openEditableOnRowClick={kanEkspandereRader}
        caption={tittel}
      />
      {feltITabellHarFeil && (
        <Feilmelding
          forekomstId={id}
          overskrift={intl.formatMessage({ id: 'feilmelding' })}
          melding={intl.formatMessage({ id: 'tabell.inneholder.feil' })}
          valideringsstate={valideringsstate}
        />
      )}
      {!readOnly && kanLeggeTilRader && (
        <Grid.Row>
          <Button buttonStyle="secondary" onClick={leggTilRad}>
            {intl.formatMessage({
              id: language.leggtilKnappId(tekstId(repeterendeFeltgruppe)),
              defaultMessage: intl.formatMessage({ id: 'knapp.leggTil' }),
            })}
          </Button>
        </Grid.Row>
      )}
    </div>
  );
};
