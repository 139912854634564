import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import style from '../beregnetskatt/BeregnetSkatt.module.scss';
import commonStyle from '../../common.module.scss';

export const OverskriftLinje = ({
  id,
  headerTekst,
  stil,
  uppercase = false,
}) => {
  const classes = classnames(style.overskriftLinje, stil, {
    [commonStyle.uppercase]: uppercase,
  });

  return (
    <h3 className={classes}>{headerTekst || <FormattedMessage id={id} />}</h3>
  );
};
