import { getLocaleFromCookie } from '../language/util';

const defautState = {
  locale: getLocaleFromCookie(),
};

export const OPPDATER_LOCALE = 'OPPDATER_LOCALE';
export const oppdaterLocale = (locale) => ({
  type: OPPDATER_LOCALE,
  locale,
});

export const getLocale = (state) => state.locale;

export default (state = defautState, action) => {
  switch (action.type) {
    case OPPDATER_LOCALE:
      return {
        ...state,
        locale: action.locale,
      };
    default:
      return state;
  }
};
