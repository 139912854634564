import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import CheckBox from '@skatteetaten/frontend-components/CheckBox';
import { StorDialog } from '../utils/StorDialog';
import { DialogKnapperad } from '../utils/DialogKnapperad';
import { DialogAvbryt } from '../utils/DialogAvbryt';
import { DialogOk } from '../utils/DialogOk';
import {
  byttDialog,
  DIALOGTYPE_LEGG_TIL_OPPLYSNINGER,
} from '../../../../actions/dialog';
import { leggTilEgenskaper } from './kortOpplysninger.actions';
import { getDialog } from '../../../../reducers';
import { useAnalytics } from '../../../../analytics/analytics.hooks';

export const LeggTilEgenskaperDialog = () => {
  const {
    leggTilEgenskaper: { valgtEgenskap, egenskaperSomKanLeggesTil },
    fraDialog,
  } = useSelector(getDialog);

  const intl = useIntl();
  const analytics = useAnalytics();
  const dispatch = useDispatch();
  const [valgteEgenskaper, setValgteEgenskaper] = useState([valgtEgenskap]);

  const avbryt = () => dispatch(byttDialog(DIALOGTYPE_LEGG_TIL_OPPLYSNINGER));
  const leggTilEgenskap = () => {
    dispatch(leggTilEgenskaper(valgteEgenskaper, fraDialog));

    valgteEgenskaper.forEach((egenskap) =>
      analytics.leggTilEgenskap(egenskap.id),
    );
  };

  const title = intl.formatMessage({
    id: 'ledetekst.legg.til.egenskaper.dialog.tittel',
  });

  const isChecked = (egenskap) =>
    valgteEgenskaper.some(
      // eslint-disable-next-line no-shadow
      (valgtEgenskap) => valgtEgenskap.referanse === egenskap.referanse,
    );

  const onChange = (endretEgenskap) => {
    if (!isChecked(endretEgenskap)) {
      setValgteEgenskaper([...valgteEgenskaper, endretEgenskap]);
    } else {
      setValgteEgenskaper(
        valgteEgenskaper.filter(
          // eslint-disable-next-line no-shadow
          (valgtEgenskap) =>
            valgtEgenskap.referanse !== endretEgenskap.referanse,
        ),
      );
    }
  };

  return (
    <StorDialog title={title} visObligatoriskFeltMelding={false}>
      <div>
        <fieldset style={{ border: 'none' }}>
          <legend style={{ fontSize: '14px', marginBottom: '10px' }}>
            {intl.formatMessage({ id: 'ledetekst.legg.til.egenskaper.dialog' })}
          </legend>

          {egenskaperSomKanLeggesTil.map((egenskap) => (
            <CheckBox
              name={egenskap.id}
              key={egenskap.id}
              boxSide={'start'}
              label={intl.formatMessage({ id: egenskap.id })}
              checked={isChecked(egenskap)}
              onChange={() => onChange(egenskap)}
            />
          ))}
        </fieldset>
      </div>

      <DialogKnapperad>
        <DialogAvbryt aksjon={avbryt} lukkEtterAksjon={false} />
        <DialogOk
          aksjon={leggTilEgenskap}
          lukkEtterAksjon={false}
          hoved={true}
        />
      </DialogKnapperad>
    </StorDialog>
  );
};
