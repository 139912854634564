import isEmpty from 'lodash/isEmpty';

export const SEND_TIL_ANALYTICS = 'SEND_TIL_ANALYTICS';

export const sendTilAnalytics = (hendelser) =>
  !isEmpty(hendelser) && {
    type: SEND_TIL_ANALYTICS,
    hendelser: hendelser,
  };

export const analyticsPayload = ({ action, name = action, value = '' }) =>
  action && [action, name, value];

export const sendEnkelhendelseTilAnalytics = (hendelse) => ({
  type: SEND_TIL_ANALYTICS,
  hendelser: [analyticsPayload(hendelse)],
});
