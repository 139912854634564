import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

/*
 * Hjelpefunksjon for å lage handlere for å dispatche events til redux
 *
 * Begrunnelse:
 * Redux tilbyr useDispatch som gir komponentene tilgang til dispatch.
 * Et vanlig mønster vil da være
 * const dispatch = useDispatch()
 * const onClick = () => dispatch(actionCreator(props.id))
 *
 * Dette har en stor ulempe, onClick får en ny verdi hver render, og vil dermed
 * trigge rerender av komponenter hvor den brukes som prop. Korrekt blir dermed:
 * const dispatch = useDispatch()
 * const onClick = useMemo (() =>
 *  () => dispatch(actionCreator()),
 *  props.id
 * )
 *
 * For å slippe å skrive og lese alt dette:
 * const onClick = useDispatcher(actionCreator)
 *
 * Den returnerte funksjonen sender parametre videre til action creator:
 *
 * const onClick = useDispatcher(actionCreator)
 *
 * onClick (props.id) === dispatch(actionCreator(props.id))
 *
 * Hvis useDispatcher kalles med flere parametre legges de som parametre til
 * actioncreator, *før parametre fra returfunksjon* :
 *
 * const onClick = useDispatcher(actionCreator, tema.id, kortgruppe.id)
 *
 * onClick (props.id) === dispatch(actionCreator(tema.id, kortgruppe.id, props.id))
 *
 */
export const useDispatcher = (actionCreator, ...props) => {
  const dispatch = useDispatch();

  return useMemo(() => {
    return (...args) => dispatch(actionCreator(...props, ...args));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, actionCreator, ...props]);
};
