import get from 'lodash/get';
import { Kortfelt } from './Kortfelt';
import { KortFeltgruppe } from './KortFeltgruppe';
import { DynamiskGruppe } from './DynamiskGruppe';
import { KortfeltMedEgenskaper } from './KortfeltMedEgenskaper';
import {
  DYNAMISK_GRUPPE,
  FELT,
  FELT_MED_EGENSKAPER,
  FELTGRUPPE,
  FELTSEPARATOR,
} from '../../../../utils/visningsDataDefinisjonUtil';
import { useBetingelseHook } from '../../../../hooks/useBetingelseHook';

export const KortFeltType = ({ kortdefinisjon, data = {} }) => {
  const {
    skalBeholdeVerdi,
    skalFeltDisables,
    skalFeltSkjules,
    skalSkjuleFeltIKort,
  } = useBetingelseHook();

  const { barnliste } = kortdefinisjon;
  const shouldDisplay = (barn, kortData) => {
    const synligGitt = barn.synligGitt;
    return synligGitt && synligGitt.harVerdi && kortData[synligGitt.referanse];
  };

  const erOverstyrt = (overstyrtAv, kortData) => {
    return (
      overstyrtAv && kortData[overstyrtAv.referanse] === overstyrtAv.medVerdi
    );
  };

  return (
    <dl>
      {barnliste
        .filter((barn) => !skalSkjuleFeltIKort(barn, data))
        .map((barn) => {
          const hidden =
            (skalFeltSkjules(barn, data) || skalFeltDisables(barn, data)) &&
            !skalBeholdeVerdi(barn, data);

          switch (barn.type) {
            case FELTSEPARATOR:
              if (shouldDisplay(barn, data)) {
                return (
                  <div key={`${data.id}.${barn.id}`}>
                    <hr />
                  </div>
                );
              }
              return null;

            case FELT:
              const visSomUtledetVerdi =
                barn.formatteringIKort === 'utledetVerdi'
                  ? true
                  : barn.formatteringIKort === 'vanligVerdi'
                  ? false
                  : barn.beregnetFelt;

              return (
                <Kortfelt
                  key={`${data.id}.${barn.referanse}`}
                  hidden={hidden}
                  feltdefinisjon={barn}
                  data={get(data, barn.referanse)}
                  visSomUtledetVerdi={visSomUtledetVerdi}
                  overstyrt={erOverstyrt(barn.overstyrtAv, data)}
                  obligatorisk={barn.valideringer?.includes('obligatorisk')}
                />
              );

            case FELTGRUPPE:
              return (
                <KortFeltgruppe
                  key={`${data.id}.${barn.id}`}
                  feltgruppe={barn}
                  data={data}
                />
              );
            case DYNAMISK_GRUPPE:
              return <DynamiskGruppe key={barn.id} data={data} gruppe={barn} />;

            case FELT_MED_EGENSKAPER:
              return (
                <KortfeltMedEgenskaper
                  key={`${data.id}.${barn.id}`}
                  data={data}
                  hidden={hidden}
                  {...barn}
                />
              );

            default:
              return null;
          }
        })}
    </dl>
  );
};
